export default {
  LOGIN_SUCCESS: '1/login_success',
  // LOGIN_FAILED:'1/login_failed',

  AUTO_LOGIN_SUCCESS: '2/login_success',
  // AUTO_LOGIN_FAILED:'2/login_failed',

  RECONNECT_SUCCESS: '3/login_success',
  // RECONNECT_FAILED:'3/login_failed',

  FORCE_KICK_OUT: 'force_kick_out',
  TOKEN_FAILED: 'token_failed',

  // COMET_MESSAGE:'comet_message',
  COMET_NOTIFY: 'comet_notify',

  COMET_PUSHID: 'pushId',
};
/* ts-lint:enable */
