export default {
  'pages.addLink.BigTitle': '申请加入任务',
  'pages.addLink.backToIndex': '返回首页',
  'pages.addLink.submitApply': '提交申请',
  'pages.addLink.hasSubmittedApply': '已提交申请',
  'pages.addLink.hasSubmitted.tips': '请等待管理员审核通过',
  'pages.addLink.refresh': '刷新',
  'pages.addLink.refuse': '管理员已拒绝',
  'pages.addLink.applyAgain': '重新申请',
  'pages.addLink.linkLose': '链接已失效',
  'pages.addLink.linkLose.tips': '请联系分享人重新生成',
};
