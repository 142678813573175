export default {
  'cpb.paramSetting': 'Parameter settings',
  'cpb.begin': 'Begin',
  'cpb.pause': 'Pause',
  'cpb.end': 'End',
  'cpb.result': 'Result',
  'cpb.chartFill': 'Chart Fill',
  'cpb.unit': 'Unit',
  'cpb.table.channel': 'Channel',
  'cpb.table.color': 'Show color',
  'cpb.table.maxFrequency': 'Max Frequency',
  'cpb.table.totalAverage': 'Total Average',
  'cpb.reset': 'Reset',
  'cpb.frequency': 'Frequency',
  'cpb.amplitude': 'Amplitude',
  'cpb.analysisFail': ' analysis failed！',
  'cpb.creatingChart': 'Generating Chart',
  'cpb.creatingTable': 'Generating Table',
};
