import {
  dtlTaskAddTaskAttachments,
  dtlTaskAddTaskLabels,
  dtlTaskAddTaskMembers,
  dtlTaskAgreeInviteTaskMembers,
  dtlTaskCreateTask,
  dtlTaskDeleteMsg,
  dtlTaskDelTaskAttachments,
  dtlTaskDelTaskInvitees,
  dtlTaskDelTaskLabels,
  dtlTaskDelTaskMembers,
  dtlTaskDismissTask,
  dtlTaskGetTask,
  dtlTaskInviteTaskMembers,
  dtlTaskQuitTask,
  dtlTaskReplyMsg,
  dtlTaskSendMsg,
  dtlTaskUpdateTaskDesc,
  dtlTaskUpdateTaskName,
} from '@/services/datalib/api/task';
import { Task } from '@/services/datalib/bean/Task';
import { doErrResponse, doResponse } from '@/services/datalib/ctrl/util';
import { Err, TaskType } from '@/services/datalib/enum';
import { doInitDtlStatus, dtlStatus, updateDtlVerLog } from '../index';

export async function getTaskSummary(
  body: DtlTask.GetTaskParam,
): Promise<DtlComm.Result<DtlTask.TaskResult | undefined>> {
  const [task, err] = await doGetTask(body);
  if (err) return err;
  task.updateMembersByContacts(dtlStatus.contacts);
  return {
    code: Err.OK[0],
    data: { task: task.format() },
    message: Err.OK[1],
  };
}

export async function getTaskDetail(
  body: DtlTask.GetTaskParam,
): Promise<
  DtlComm.Result<
    | { allFiles: DtlBean.TaskFile; members: Record<string, DtlBean.User>; msgs?: DtlBean.Msg[] }
    | undefined
  >
> {
  const [task, err] = await doGetTask(body);
  if (err) return err;
  task.updateMembersByContacts(dtlStatus.contacts);
  return {
    code: Err.OK[0],
    data: {
      allFiles: task.files.formatTree(),
      members: task.formatMembers(),
      msgs: task.msgs.formatSome(),
    },
    message: Err.OK[1],
  };
}

export async function getTask(
  body: DtlTask.GetTaskParam,
): Promise<DtlComm.Result<DtlTask.TaskResult | undefined>> {
  const [task, err] = await doGetTask(body);
  if (err) return err;
  task.updateMembersByContacts(dtlStatus.contacts);
  return {
    code: Err.OK[0],
    data: { task: task.format() },
    message: Err.OK[1],
  };
}

export async function doGetTask(body: {
  taskId: string;
}): Promise<[Task, DtlComm.Result<undefined> | undefined]> {
  const err = await doInitDtlStatus();
  if (err) return [new Task(), err];
  const [task, err2] = getCacheTask(body.taskId);
  if (err2) return [task, err2];
  if (!task.initialized) {
    const response = await dtlStatus.syncGetOrAdd<DtlComm.ResultV<DtlTask.TaskResultV>>(
      body,
      dtlTaskGetTask,
    );
    const { code, message, data, verLog } = response;
    if (code !== Err.OK[0]) return [new Task(), doErrResponse(code, message)];
    if (verLog) updateDtlVerLog(verLog);
    dtlStatus.initTask(new Task(data?.task));
  }
  return getCacheTask(body.taskId);
}

function getCacheTask(taskId: string): [Task, DtlComm.Result<undefined> | undefined] {
  const task = dtlStatus.getTask(taskId);
  if (!task || task.deleted) {
    return [
      new Task(),
      {
        code: Err.TASK_NOT_EXIST[0],
        data: undefined,
        message: Err.TASK_NOT_EXIST[1],
      },
    ];
  }
  if (
    task.type !== TaskType.OFFICIAL &&
    !task.userIds.some((userId) => userId === dtlStatus.userInfo.userId) &&
    !task.visitorIds.some((userId) => userId === dtlStatus.userInfo.userId) &&
    !task.uploaderIds.some((userId) => userId === dtlStatus.userInfo.userId)
  ) {
    return [
      new Task(),
      {
        code: Err.USER_NOT_EXIST_IN_TASK[0],
        data: undefined,
        message: Err.USER_NOT_EXIST_IN_TASK[1],
      },
    ];
  }
  return [task, undefined];
}

export async function getMsgList(
  body: DtlTask.GetMsgListParam,
): Promise<DtlComm.Result<DtlTask.GetMsgListResult | undefined>> {
  const [task, err] = await doGetTask(body);
  if (err) return err;
  return {
    code: Err.OK[0],
    data: { msgList: task.msgs.format() },
    message: Err.OK[1],
  };
}

export async function createTask(
  body: DtlTask.CreateTaskParam,
): Promise<DtlComm.Result<DtlTask.TaskResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskCreateTask(body);
  const { code, message, data, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    if (data) {
      const [task, err] = await doGetTask({ taskId: data.taskId });
      if (err) return err;
      return {
        code: Err.OK[0],
        data: { task: task.format() },
        message: Err.OK[1],
      };
    }
  }
  return doErrResponse(code, message);
}

export async function updateTaskName(
  body: DtlTask.UpdateTaskNameParam,
): Promise<DtlComm.Result<DtlTask.TaskResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskUpdateTaskName(body);
  const { code, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    const task = dtlStatus.getTask(body.taskId);
    if (task) {
      return {
        code: Err.OK[0],
        data: { task: task.format() },
        message: Err.OK[1],
      };
    }
  }
  return doErrResponse(code, message);
}

export async function updateTaskDesc(
  body: DtlTask.UpdateTaskDescParam,
): Promise<DtlComm.Result<DtlTask.TaskResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskUpdateTaskDesc(body);
  const { code, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    const task = dtlStatus.getTask(body.taskId);
    if (task) {
      return {
        code: Err.OK[0],
        data: { task: task.format() },
        message: Err.OK[1],
      };
    }
  }
  return doErrResponse(code, message);
}

export async function addTaskLabels(
  body: DtlTask.AddTaskLabelsParam,
): Promise<DtlComm.Result<DtlTask.TaskResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskAddTaskLabels(body);
  const { code, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    const task = dtlStatus.getTask(body.taskId);
    if (task) {
      return {
        code: Err.OK[0],
        data: { task: task.format() },
        message: Err.OK[1],
      };
    }
  }
  return doErrResponse(code, message);
}

export async function delTaskLabels(
  body: DtlTask.DelTaskLabelsParam,
): Promise<DtlComm.Result<DtlTask.TaskResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskDelTaskLabels(body);
  const { code, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    const task = dtlStatus.getTask(body.taskId);
    if (task) {
      return {
        code: Err.OK[0],
        data: { task: task.format() },
        message: Err.OK[1],
      };
    }
  }
  return doErrResponse(code, message);
}

export async function addTaskMembers(
  body: DtlTask.AddTaskMembersParam,
): Promise<DtlComm.Result<DtlTask.TaskResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskAddTaskMembers(body);
  const { code, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    const task = dtlStatus.getTask(body.taskId);
    if (task) {
      return {
        code: Err.OK[0],
        data: { task: task.format() },
        message: Err.OK[1],
      };
    }
  }
  return doErrResponse(code, message);
}

export async function inviteTaskMembers(
  body: DtlTask.InviteTaskMembersParam,
): Promise<DtlComm.Result<DtlTask.TaskResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskInviteTaskMembers(body);
  const { code, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    const task = dtlStatus.getTask(body.taskId);
    if (task) {
      return {
        code: Err.OK[0],
        data: { task: task.format() },
        message: Err.OK[1],
      };
    }
  }
  return doErrResponse(code, message);
}

export async function agreeInviteTaskMembers(
  body: DtlTask.AgreeInviteTaskMembersParam,
): Promise<DtlComm.Result<DtlTask.TaskResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskAgreeInviteTaskMembers(body);
  const { code, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    const task = dtlStatus.getTask(body.taskId);
    if (task) {
      return {
        code: Err.OK[0],
        data: { task: task.format() },
        message: Err.OK[1],
      };
    }
  }
  return doErrResponse(code, message);
}

export async function delTaskMembers(
  body: DtlTask.DelTaskMembersParam,
): Promise<DtlComm.Result<DtlTask.TaskResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskDelTaskMembers(body);
  const { code, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    const task = dtlStatus.getTask(body.taskId);
    if (task) {
      return {
        code: Err.OK[0],
        data: { task: task.format() },
        message: Err.OK[1],
      };
    }
  }
  return doErrResponse(code, message);
}

export async function delTaskInvitees(
  body: DtlTask.DelTaskInviteesParam,
): Promise<DtlComm.Result<DtlTask.TaskResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskDelTaskInvitees(body);
  const { code, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    const task = dtlStatus.getTask(body.taskId);
    if (task) {
      return {
        code: Err.OK[0],
        data: { task: task.format() },
        message: Err.OK[1],
      };
    }
  }
  return doErrResponse(code, message);
}

export async function addTaskAttachments(
  body: DtlTask.AddTaskAttachmentsParam,
): Promise<DtlComm.Result<DtlTask.TaskResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskAddTaskAttachments(body);
  const { code, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    const task = dtlStatus.getTask(body.taskId);
    if (task) {
      return {
        code: Err.OK[0],
        data: { task: task.format() },
        message: Err.OK[1],
      };
    }
  }
  return doErrResponse(code, message);
}

export async function delTaskAttachments(
  body: DtlTask.DelTaskAttachmentsParam,
): Promise<DtlComm.Result<DtlTask.TaskResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskDelTaskAttachments(body);
  const { code, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    const task = dtlStatus.getTask(body.taskId);
    if (task) {
      return {
        code: Err.OK[0],
        data: { task: task.format() },
        message: Err.OK[1],
      };
    }
  }
  return doErrResponse(code, message);
}

export async function dismissTask(
  body: DtlTask.DismissTaskParam,
): Promise<DtlComm.Result<undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskDismissTask(body);
  return doResponse(response);
}

export async function quitTask(body: DtlTask.QuitTaskParam): Promise<DtlComm.Result<undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskQuitTask(body);
  return doResponse(response);
}

// export async function updateRole(
//   body: DtlTask.UpdateRoleParam,
// ): Promise<DtlComm.Result<DtlTask.TaskResult | undefined>> {
//   const err = await doInitDtlStatus();
//   if (err) return err;
//   const response = await dtlTaskUpdateRole(body);
//   const { code, message, verLog } = response;
//   if (code === Err.OK[0]) {
//     if (verLog) {
//       updateDtlVerLog(verLog);
//     }
//     const task = dtlStatus.getTask(body.taskId);
//     if (task) {
//       return {
//         code: Err.OK[0],
//         data: { task: task.format() },
//         message: Err.OK[1],
//       };
//     }
//   }
//   return doErrResponse(code, message);
// }

export async function sendMsg(body: DtlTask.SendMsgParam): Promise<DtlComm.Result<undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskSendMsg(body);
  return doResponse(response);
}

export async function replyMsg(body: DtlTask.ReplyMsgParam): Promise<DtlComm.Result<undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskReplyMsg(body);
  return doResponse(response);
}

export async function deleteMsg(body: DtlTask.DeleteMsgParam): Promise<DtlComm.Result<undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskDeleteMsg(body);
  return doResponse(response);
}
