export class UserExt {
  toolsExt: string = '';
  usualTools: string = '';
  v: number = 0;

  constructor(userExt?: DtlBean.UserExt) {
    if (!userExt) {
      return;
    }
    if (userExt.usualTools) {
      this.usualTools = userExt.usualTools;
    }
    if (userExt.toolsExt) {
      this.toolsExt = userExt.toolsExt;
    }
    if (userExt.v) {
      this.v = userExt.v;
    }
  }

  format(): DtlBean.UserExt {
    return {
      usualTools: this.usualTools,
      toolsExt: this.toolsExt,
      v: this.v,
    };
  }

  updateUsualTools(usualTools: string) {
    this.usualTools = usualTools;
  }

  updateToolsExt(toolsExt: string) {
    this.toolsExt = toolsExt;
  }
}
