export class Log {
  tid: string = '';
  uid: string = '';
  ver: number = 0;
  k: number = 0;
  v?: any;

  constructor(t?: DtlBean.Log) {
    if (!t) {
      return;
    }
    if (t.tid) {
      this.tid = t.tid;
    }
    if (t.uid) {
      this.uid = t.uid;
    }
    if (t.ver) {
      this.ver = t.ver;
    }
    if (t.k) {
      this.k = t.k;
    }
    if (t.v) {
      this.v = t.v;
    }
  }
}
