import { DeptUserStatus, dtlStatus } from '@/services/datalib';
import { Enterprise } from '@/services/datalib/bean/Enterprise';

export class EnterpriseList {
  allEnterprise: Enterprise[] = [];

  constructor(t?: DtlBean.Enterprise[]) {
    if (!t) {
      return;
    }

    this.allEnterprise = t.map((a) => new Enterprise(a));
  }

  addOrUpdateEnterprise(enterprise: DtlBean.Enterprise): Enterprise {
    const e = new Enterprise(enterprise);
    const idx = this.allEnterprise.findIndex((a) => a.id === e.id);
    if (idx > -1) {
      this.allEnterprise[idx] = e;
    } else {
      this.allEnterprise.push(e);
    }
    return e;
  }

  getAllEnterprise(): DtlBean.Enterprise[] {
    return this.allEnterprise
      .filter(
        (a) => !a.dismiss && a.users[dtlStatus.userInfo.userId]?.status === DeptUserStatus.Normal,
      )
      .map((a) => a.format());
  }

  getSelfEnterprise(): DtlBean.Enterprise | undefined {
    return this.allEnterprise.find((a) => a.creatorUid === dtlStatus.userInfo.userId)?.format();
  }

  getEnterpriseById(body: { enterpriseId: string }): DtlBean.Enterprise | undefined {
    return this.allEnterprise.find((a) => a.id === body.enterpriseId)?.format();
  }

  getEnterpriseByIds(enterpriseIds: string[]): Record<string, DtlBean.Enterprise> {
    if (!enterpriseIds) {
      return {};
    }
    const all: Record<string, Enterprise> = {};
    this.allEnterprise.forEach((a) => (all[a.id] = a));
    const rs: Record<string, DtlBean.Enterprise> = {};
    enterpriseIds.forEach((id) => {
      if (all[id]) {
        rs[id] = all[id].format();
      }
    });
    return rs;
  }
}
