export default {
  'FeedBackContent.title': '我要反馈',
  'FeedBackContent.popover.title': '我要反馈',
  'FeedBackContent.popover.title.official': '查看反馈',
  'FeedBackContent.title.official': '查看反馈',
  'FeedBackContent.type': '反馈类型',
  'FeedBackContent.adivce': '困惑与建议',
  'FeedBackContent.bug': 'Bug缺陷',
  'FeedBackContent.subType': '反馈功能分类',
  'FeedBackContent.subType.err': '请选择反馈功能分类',
  'FeedBackContent.contact': '联系方式',
  'FeedBackContent.contact.default': '请留下邮箱',
  'FeedBackContent.contact.err': '请填写正确的邮箱',
  'FeedBackContent.desc': '描述',
  'FeedBackContent.desc1': '请提供详细',
  'FeedBackContent.desc2': '使用场景描述+必要截图',
  'FeedBackContent.desc3': '帮助我们准确理解需求、为你提供支持！',
  'FeedBackContent.desc.default': '请输入详细描述并附上相关截图',
  'FeedBackContent.desc.err': '请输入不低于3字的有效描述',
  'FeedBackContent.insertPic': '插入图片',
  'FeedBackContent.submit': '提交反馈',
  'FeedBackContent.pushAdvice': '提出困惑与建议',
  'FeedBackContent.pushBug': '提出 Bug 缺陷',
  'FeedBackContent.resolved': '已解决',
  'FeedBackContent.check': '查看',
  'FeedBackContent.toResolve': '设为已解决',
  'FeedBackContent.subType.placeholder': '请选择',
  'FeedBackContent.subType.other': '其他',
  'FeedBackContent.subType.space': '任务空间',
  'FeedBackContent.subType.account': '账户',
  'FeedBackContent.success.title': '反馈成功',
  'FeedBackContent.success.tip': '感谢您的反馈，我们将尽快回复',
  'FeedBackContent.success.continue': '继续反馈',
  'FeedBackContent.noItem': '暂无反馈',
};
