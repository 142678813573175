export default {
  'myFile.BigTitle': '我的数据',
  'myFile.recent.tab': '最近',
  'myFile.collection.tab': '收藏',
  'myFile.recent.input.placeholder': '搜索最近使用的文件',
  'myFile.time.today': '今天',
  'myFile.time.yesterday': '昨天',
  'myFile.time.sevenday': '七天内',
  'myFile.time.thisMonth': '本月更早',
  'myFile.time.more': '更早',
  'myFile.time.January': '一月',
  'myFile.time.February': '二月',
  'myFile.time.March': '三月',
  'myFile.time.April': '四月',
  'myFile.time.May': '五月',
  'myFile.time.June': '六月',
  'myFile.time.July': '七月',
  'myFile.time.August': '八月',
  'myFile.time.September': '九月',
  'myFile.time.October': '十月',
  'myFile.time.November': '十一月',
  'myFile.time.December': '十二月',
  'myFile.recent.empty': '打开过的文件将在这里',
  'myFile.recent.searchEmpty': '暂无匹配数据',
  'myFile.collection.input.placeholder': '搜索收藏的文件',
  'myFile.collection.new.placeholder': '新建收藏夹',
  'myFile.collection.allCollection': '全部收藏',
  'myFile.collection.table.name': '名称',
  'myFile.collection.table.source': '来源',
  'myFile.collection.table.size': '文件大小',
  'myFile.collection.table.operation': '操作',
  'myFile.collection.modal.title': '选择收藏夹',
  'myFile.collection.modal.desc': '可以选择其他收藏夹或',
  'myFile.collection.modal.cancelCollect': '取消收藏',
  'myFile.collection.table.empty': '收藏过的文件将在这里',
  'myFile.collection.table.firstEmpty': '该收藏夹还未收藏过文件',
};
