export default {
  'Strain.timeHistory': 'Time History',
  'Strain.strainData': 'Strain Data',
  'Strain.channelSetting': 'Channel Settings',
  'Strain.searchType.placeholder': 'Search Here',
  'Strain.report.channelName': 'Name',
  'Strain.report.max': 'Max',
  'Strain.report.min': 'Min',
  'Strain.report.peak': 'Peak',
  'Strain.report.peakValue': 'Peak',
  'Strain.report.freq': 'Frequency',
  'Strain.report.cursor': 'Cursor',
  'Strain.report.interval': 'Interval',
  'Strain.report.stdDeviation': 'Standard Deviation',
  'Strain.report.mean': 'Mean',
  'Strain.statisticalData': 'Statistical Data',
  'Strain.exportSpreadsheet': 'Export Data',
  'Strain.addFilter': 'Add',
};
