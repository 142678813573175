import { dtlStatus, FileRecordStatus } from '@/services/datalib';
import { Anals } from '@/services/datalib/bean/Anals';
import { Conv } from '@/services/datalib/bean/Conv';
import { Msg } from '@/services/datalib/bean/Msg';
import { OnlyOffice } from '@/services/datalib/bean/OnlyOffice';
import { RecordV } from '@/services/datalib/bean/RecordV';
import { getPathName } from '@/services/datalib/bean/util';
import { TaskFileStatus } from '@/services/datalib/enum';

export class TaskFile {
  id: string = '';
  path: string = '';
  type: number = 0;
  url: string = '';
  size: number = 0;
  content: string = '';
  convs: Conv[] = [];
  openWay: string = '';
  anals: Anals = new Anals();
  records: RecordV[] = [];
  msgs: Msg[] = [];
  onlyOffice: OnlyOffice = new OnlyOffice();
  status: number = TaskFileStatus.NORMAL;
  attrs: string = '';

  get name(): string {
    return getPathName(this.path);
  }

  // copy() {
  //   const n = new TaskFile();
  //   n.id = this.id;
  //   n.path = this.path;
  //   n.type = this.type;
  //   n.url = this.url;
  //   n.size = this.size;
  //   n.content = this.content;
  //   n.convs = this.convs.map((v) => v.copy());
  //   n.openWay = this.openWay;
  //   n.anals = this.anals.copy();
  //   n.records = this.records.map((v) => v.copy());
  //   n.msgs = this.msgs.map((v) => v.copy());
  //   n.updateConvsOrder();
  //   return n;
  // }

  fromBean(taskFile?: DtlBean.TaskFile): TaskFile {
    if (!taskFile) {
      return this;
    }
    if (taskFile.id) {
      this.id = taskFile.id;
    }
    if (taskFile.path) {
      this.path = taskFile.path;
    }
    if (taskFile.type) {
      this.type = taskFile.type;
    }
    if (taskFile.url) {
      this.url = taskFile.url;
    }
    if (taskFile.size) {
      this.size = taskFile.size;
    }
    if (taskFile.content) {
      this.content = taskFile.content;
    }
    if (taskFile.convs) {
      this.convs = taskFile.convs.map((c) => new Conv(c));
    }
    if (taskFile.openWay) {
      this.openWay = taskFile.openWay;
    }
    if (taskFile.anals) {
      this.anals = new Anals(taskFile.anals);
    }
    if (taskFile.records) {
      this.records = taskFile.records.map((r) => new RecordV(r));
    }
    if (taskFile.msgs) {
      this.msgs = taskFile.msgs.map((m) => new Msg(m));
    }
    if (taskFile.onlyOffice) {
      this.onlyOffice = new OnlyOffice(taskFile.onlyOffice);
    }
    if (taskFile.status) {
      this.status = taskFile.status;
    }
    if (taskFile.attrs) {
      this.attrs = taskFile.attrs;
    }
    this.updateConvsOrder();
    return this;
  }

  constructor(taskFileV?: DtlBean.TaskFileV, filePath?: DtlBean.FilePath) {
    if (taskFileV) {
      if (taskFileV.id) {
        this.id = taskFileV.id;
      }
      if (taskFileV.url) {
        this.url = taskFileV.url;
      }
      if (taskFileV.size) {
        this.size = taskFileV.size;
      }
      if (taskFileV.content) {
        this.content = taskFileV.content;
      }
      if (taskFileV.convs) {
        this.convs = taskFileV.convs.map((c) => new Conv(c));
      }
      if (taskFileV.openWay) {
        this.openWay = taskFileV.openWay;
      }
      if (taskFileV.anals) {
        this.anals = new Anals(taskFileV.anals);
      }
      if (taskFileV.records) {
        this.records = taskFileV.records.map((r) => new RecordV(r));
      }
      if (taskFileV.msgs) {
        this.msgs = taskFileV.msgs.map((m) => new Msg(m));
      }
      if (taskFileV.onlyOffice) {
        this.onlyOffice = new OnlyOffice(taskFileV.onlyOffice);
      }
      if (taskFileV.status) {
        this.status = taskFileV.status;
      }
      if (taskFileV.attrs) {
        this.attrs = taskFileV.attrs;
      }
    }
    if (filePath) {
      if (filePath.id) {
        this.id = filePath.id;
      }
      if (filePath.path) {
        this.path = filePath.path;
      }
      if (filePath.type) {
        this.type = filePath.type;
      }
    }
    this.updateConvsOrder();
  }

  updateFromFilePath(file: DtlBean.FilePath) {
    this.id = file.id;
    this.path = file.path;
    this.type = file.type;
  }

  copyFrom(file: TaskFile) {
    this.id = file.id;
    this.path = file.path;
    this.type = file.type;
    this.url = file.url;
    this.size = file.size;
    this.content = file.content;
    if (file.convs) {
      this.convs = file.convs.map((s) => s.copy());
    } else {
      this.convs = [];
    }
    this.openWay = file.openWay;
    if (file.anals) {
      this.anals = file.anals.copy();
    } else {
      this.anals = new Anals();
    }
    if (file.records) {
      this.records = file.records.map((r) => r.copy());
    } else {
      this.records = [];
    }
    if (file.msgs) {
      this.msgs = file.msgs.map((m) => m.copy());
    } else {
      this.msgs = [];
    }
    if (file.onlyOffice) {
      this.onlyOffice = file.onlyOffice.copy();
    } else {
      this.onlyOffice = new OnlyOffice();
    }
    this.status = file.status;
    this.attrs = file.attrs;
    this.updateConvsOrder();
  }

  updatePath(path: string) {
    this.path = path;
  }

  format(): DtlBean.TaskFile {
    let createTime = 0;
    let creator = '';
    let lastModifyTime = 0;
    let lastModifier = '';
    let lastOpenTime = 0;
    const size = this.records.length;
    if (size > 0) {
      creator = this.records[0].userId;
      createTime = this.records[0].ts;
    }
    if (size > 1) {
      lastModifier = this.records[size - 1].userId;
      lastModifyTime = this.records[size - 1].ts;
    }
    const fileRecord = dtlStatus.fileRecords.recentFilesM[this.id];
    if (fileRecord) {
      lastOpenTime = fileRecord.ts;
    }
    const collectFileRecord = dtlStatus.fileRecords.collectFilesM[this.id];
    const isCollected = collectFileRecord && collectFileRecord.s !== FileRecordStatus.Deleted;
    return {
      id: this.id,
      path: this.path,
      name: this.name,
      type: this.type,
      createTime,
      creator,
      lastModifier,
      lastModifyTime,
      lastOpenTime,
      url: this.url,
      size: this.size,
      content: this.content,
      convs: this.convs.map((s) => s.format()),
      openWay: this.openWay,
      anals: this.anals.format(),
      records: this.records.map((r) => r.format()),
      msgs: this.msgs.map((m) => m.format()),
      onlyOffice: this.onlyOffice.format(),
      status: this.status,
      isCollected: isCollected,
      attrs: this.attrs,
    };
  }

  updateConvsOrder() {
    if (this.openWay) {
      const arr = this.openWay.split('/');
      let program = '';
      if (arr.length > 2) {
        program = `${arr[1]}/${arr[2]}`;
      } else if (arr.length > 1) {
        program = `${arr[1]}`;
      }
      const size = this.convs.length;
      for (let i = 0; i < size; i += 1) {
        const conv = this.convs[i];
        if ((conv.program === program || conv.originP === program) && conv.result) {
          this.convs[i] = this.convs[size - 1];
          this.convs[size - 1] = conv;
          return;
        }
      }
    }
  }
}
