import t from '@/locales';
import { Attachment } from '@/services/datalib/bean/Attachment';
import { DelTask } from '@/services/datalib/bean/DelTask';
import { DtlStatus } from '@/services/datalib/bean/DtlStatus';
import { Label } from '@/services/datalib/bean/Label';
import { LabelTpl } from '@/services/datalib/bean/LabelTpl';
import { Log } from '@/services/datalib/bean/Log';
import { Msg } from '@/services/datalib/bean/Msg';
import { Task } from '@/services/datalib/bean/Task';
import { TaskFile } from '@/services/datalib/bean/TaskFile';
import { TaskGroup } from '@/services/datalib/bean/TaskGroup';
import { TaskGroupList } from '@/services/datalib/bean/TaskGroupList';
import { Unread } from '@/services/datalib/bean/Unread';
import { User } from '@/services/datalib/bean/user';
import { VerLog } from '@/services/datalib/bean/VerLog';
import { doInitDtlStatus, ping } from '@/services/datalib/ctrl/user';
import { isDev } from '@/services/datalib/ctrl/util';
import type { TaskMark, TaskStatus } from '@/services/datalib/enum';
import { DtlCmd, IsReg } from '@/services/datalib/enum';
import cometTopic from '@/utils/comet/cometTopic';
import type { ICometMessage, ICometOptions } from '@/utils/comet/websock';
import { Comet } from '@/utils/comet/websock';
import { getLocale } from 'umi';

export * from '@/services/datalib/ctrl/AlpFile';
export * from '@/services/datalib/ctrl/category';
export * from '@/services/datalib/ctrl/contacts';
export * from '@/services/datalib/ctrl/enterprise';
export * from '@/services/datalib/ctrl/excel';
export * from '@/services/datalib/ctrl/external';
export * from '@/services/datalib/ctrl/feedback';
export * from '@/services/datalib/ctrl/file';
export * from '@/services/datalib/ctrl/fileRecord';
export * from '@/services/datalib/ctrl/grid';
export * from '@/services/datalib/ctrl/grr';
export * from '@/services/datalib/ctrl/login';
export * from '@/services/datalib/ctrl/onlyOffice/onlyoffice';
export * from '@/services/datalib/ctrl/PrmFile';
export * from '@/services/datalib/ctrl/recycle';
export * from '@/services/datalib/ctrl/SoundCheckDat';
export * from '@/services/datalib/ctrl/task';
export * from '@/services/datalib/ctrl/taskFile';
export * from '@/services/datalib/ctrl/taskGroup';
export * from '@/services/datalib/ctrl/taskInvite';
export * from '@/services/datalib/ctrl/taskShare';
export * from '@/services/datalib/ctrl/taskUser';
export * from '@/services/datalib/ctrl/tools';
export * from '@/services/datalib/ctrl/user';
export * from '@/services/datalib/ctrl/userExt';
export * from '@/services/datalib/ctrl/util';
export * from '@/services/datalib/ctrl/wav';
export * from '@/services/datalib/enum';
export { getFileType } from '@/utils/fileHelper';
export { readFileMd5 } from '@/utils/fileOptions';
export { dtlStatus };

const enableLog = isDev();
const dtlComet: Comet = new Comet(handleSocketMsg);

const dtlStatus = new DtlStatus();
let dtlVersion = 0;
let pushAction: (data: any) => void;
const waitPush: any[] = [];
let pushTimeout: any = undefined;

export function formatStr(str: string, defaultStr?: string) {
  return t(str, defaultStr);
}

export function myLocale() {
  return getLocale();
}

export function dtlReset() {
  dtlStatus.reset();
  dtlComet.ShutDown();
  dtlVersion = 0;
  pushAction = () => 0;
}

export function isSelfOfficialUser(): boolean {
  return dtlStatus.isSelfOfficialUser;
}

export function getVersion() {
  return dtlVersion;
}

export function initDtlStatus(s?: DtlLogin.LoginResult) {
  if (!s) {
    return;
  }
  dtlStatus.setServerToken(s.serverToken);
  dtlStatus.setPushServer(s.pushServer);
  dtlStatus.setUserInfo(s.user);
  dtlStatus.setContacts(s.contacts);
  dtlStatus.setTaskGroupList(s.user.userId, s.taskGroups, s.officialTask);
  dtlStatus.setLabelTemplates(s.userAllTpl);
  dtlStatus.setUnreadList(s.allUnread);
  dtlStatus.setOpenTypes(s.convPrograms);
  dtlStatus.setUserExt(s.userExt);
  dtlStatus.setFileRecords(s.recentFiles, s.collectFiles);
  dtlStatus.setCollectFolders(s.collectFolders);
  dtlStatus.setEnterpriseList(s.allEnterprise);
  dtlStatus.initFinished();
  if (enableLog) {
    console.log('dtlStatus initialized', dtlStatus);
  }
}

export function initDtlVerLog(verLog: DtlBean.VerLog) {
  if (verLog) {
    dtlVersion = verLog.version;
  }
}

export function initDtlSocket() {
  let host = dtlStatus.pushServer;
  const hostName = window.location.hostname;
  if (hostName === 'dtl5.alphagraph.cn') {
    host = 'wss://dtl5.alphagraph.cn';
  }
  const option: ICometOptions = {
    host: host,
    token: dtlStatus.serverToken,
    isAutoConnect: true,
  };
  dtlComet.ShutDown();
  dtlComet.Run(option);
}

export function listenPush(action: (data: any) => void) {
  pushAction = action;
  if (!dtlStatus.initialized) {
    if (enableLog) {
      console.log('listenPush');
    }
    doInitDtlStatus();
  }
}

function handleSocketMsg(arg: ICometMessage) {
  const { topic, id, message } = arg;
  let logVersion = 0;
  if (id) logVersion = id;
  if (enableLog) {
    // console.log(`topic:${topic} dtlVersion:${dtlVersion} logVersion:${logVersion} log:${message}`);
  }
  switch (topic) {
    case cometTopic.LOGIN_SUCCESS: {
      break;
    }
    case cometTopic.AUTO_LOGIN_SUCCESS: {
      break;
    }
    case cometTopic.RECONNECT_SUCCESS: {
      break;
    }
    case cometTopic.FORCE_KICK_OUT: {
      break;
    }
    case cometTopic.TOKEN_FAILED: {
      notify('', '', DtlCmd.APP_LOGOUT, undefined);
      dtlReset();
      break;
    }
    case cometTopic.COMET_NOTIFY: {
      if (!message) return;
      let log;
      try {
        log = JSON.parse(message);
      } catch (e) {
        console.error(e);
      }
      if (logVersion > dtlVersion + 1) {
        ping();
      } else if (logVersion === dtlVersion + 1) {
        const l = new Log(log);
        if (enableLog) {
          console.log(
            `[push exec] lVer:${dtlVersion} sVer:${logVersion} cmd:${DtlCmd[l.k]} tid:${
              l.tid
            } uid:${l.uid} ver:${l.ver} k:${l.k}`,
            l.v,
          );
        }
        const { needNotify, value } = exec(l);
        if (enableLog) {
          console.log(dtlStatus);
        }
        dtlVersion = logVersion;
        if (needNotify) {
          notify(l.tid, l.uid, l.k, value);
        }
      }
      break;
    }
    case cometTopic.COMET_PUSHID: {
      if (dtlVersion < logVersion) {
        ping();
      }
      break;
    }
    default: {
      break;
    }
  }
}

function notify(taskId: string, userId: string, cmd: number, value: any) {
  const msg = {
    userId,
    taskId,
    cmd,
    name: DtlCmd[cmd],
    value,
  };
  if (cmd === DtlCmd.APP_LOGOUT) {
    addPushAction(msg);
    return;
  }
  waitPush.push(msg);
  if (pushTimeout) {
    return;
  }
  pushTimeout = setTimeout(function () {
    while (waitPush.length > 0) {
      addPushAction(waitPush.shift());
    }
    pushTimeout = undefined;
  }, 200);
}

function addPushAction(msg: any) {
  try {
    pushAction(msg);
  } catch (e) {
    console.error(e);
  }
}

export function updateDtlVerLog(verLog: DtlBean.VerLog) {
  if (!verLog || !dtlStatus.initialized) {
    return;
  }
  const vlog = new VerLog(verLog);
  if (dtlVersion >= vlog.version) {
    return;
  }
  if (dtlVersion < vlog.start - 1) {
    // todo 从服务器更新
    doInitDtlStatus();
    return;
  }
  const start = dtlVersion + 1 - vlog.start;
  for (let i = start; i < vlog.logs.length; i += 1) {
    try {
      const logVersion = vlog.start + i;
      const l = vlog.logs[i];
      if (enableLog) {
        console.log(
          `[http exec] lVer:${dtlVersion} sVer:${logVersion} cmd:${DtlCmd[l.k]} tid:${l.tid} uid:${
            l.uid
          } ver:${l.ver} k:${l.k}`,
          l.v,
        );
      }
      const { needNotify, value } = exec(l);
      if (enableLog) {
        console.log(dtlStatus);
      }
      dtlVersion = logVersion;
      if (needNotify) {
        notify(l.tid, l.uid, l.k, value);
      }
    } catch (e) {
      console.error(vlog.logs[i], e);
    }
  }
}

function execEnd(needNotify: boolean, value: any = undefined): { needNotify: boolean; value: any } {
  return {
    needNotify,
    value,
  };
}

function exec(log: Log): any {
  const { tid: taskId, uid: userId, k: cmd, v: value } = log;

  switch (cmd) {
    case DtlCmd.User_register: {
      const friend = dtlStatus.getFriend(userId);
      friend?.updateReg(IsReg.USER_ISREG_YES);
      return execEnd(!!friend);
    }
    case DtlCmd.User_update_attrs: {
      const user = value as DtlBean.User;
      if (!user) return execEnd(false);
      if (userId === dtlStatus.userInfo.userId) {
        dtlStatus.userInfo.updateBasic(user);
      }
      const friend = dtlStatus.getFriend(userId);
      friend?.updateBasic(user);
      return execEnd(true, value);
    }
    case DtlCmd.User_update_pwd: {
      if (userId !== dtlStatus.userInfo.userId) {
        return execEnd(false);
      }
      dtlStatus.userInfo.updatePwd();
      return execEnd(true);
    }
    case DtlCmd.User_ext_update_usualtools: {
      if (userId !== dtlStatus.userInfo.userId) {
        return execEnd(false);
      }
      const usualTools = value as string;
      dtlStatus.userExt.updateUsualTools(usualTools);
      return execEnd(true);
    }
    case DtlCmd.User_ext_update_toolsext: {
      if (userId !== dtlStatus.userInfo.userId) {
        return execEnd(false);
      }
      const toolsExt = value as string;
      dtlStatus.userExt.updateToolsExt(toolsExt);
      return execEnd(true);
    }
    case DtlCmd.User_unread_add: {
      if (
        !(
          (taskId && dtlStatus.taskGroupList.officialTask?.taskId === taskId) ||
          userId === dtlStatus.userInfo.userId
        )
      ) {
        return execEnd(false);
      }
      const unread = value as DtlBean.Unread;
      if (!unread) return execEnd(false);
      const ur = new Unread(unread);
      {
        const officialTask = dtlStatus.taskGroupList.officialTask;
        if (ur.taskId === officialTask?.taskId) {
          officialTask.oAddUnread(ur);
        } else {
          dtlStatus.unreadList.addUnread(ur);
        }
      }
      return execEnd(true, ur.format());
    }
    case DtlCmd.User_unread_delete: {
      if (userId !== dtlStatus.userInfo.userId) return execEnd(false);
      const deleted = value as string[];
      if (!deleted) return execEnd(false);
      dtlStatus.unreadList.deleteUnread(deleted);
      return execEnd(true, deleted);
    }
    case DtlCmd.Taskgroup_add_gorup:
    case DtlCmd.TaskArchive_add_gorup: {
      if (userId !== dtlStatus.userInfo.userId) return execEnd(false);
      const param = value as DtlBean.TaskGroup;
      if (!param) return execEnd(false);
      const taskGroup = new TaskGroup(param);
      dtlStatus.addTaskGroup(taskGroup, cmd === DtlCmd.TaskArchive_add_gorup);
      return execEnd(true, taskGroup.format());
    }
    case DtlCmd.Taskgroup_update_gorup_name:
    case DtlCmd.TaskArchive_update_gorup_name: {
      if (userId !== dtlStatus.userInfo.userId) return execEnd(false);
      const param = value as { groupId: string; groupName: string };
      if (!param || !param.groupId || !param.groupName) return execEnd(false);
      dtlStatus.updateTaskGroupName(
        param.groupId,
        param.groupName,
        cmd === DtlCmd.TaskArchive_update_gorup_name,
      );
      return execEnd(true, param);
    }
    case DtlCmd.Taskgroup_del_group:
    case DtlCmd.TaskArchive_del_group: {
      if (userId !== dtlStatus.userInfo.userId) return execEnd(false);
      const groupId = value as string;
      if (!groupId) return execEnd(false);
      dtlStatus.deleteTaskGroup(groupId, cmd === DtlCmd.TaskArchive_del_group);
      return execEnd(true, groupId);
    }
    case DtlCmd.Taskgroup_move_task_to_group:
    case DtlCmd.TaskArchive_move_task_to_group: {
      if (userId !== dtlStatus.userInfo.userId) return execEnd(false);
      const param = value as DtlTaskGroup.MoveTaskParam;
      if (!param || !param.taskId) return execEnd(false);
      dtlStatus.taskGroupMoveTaskToGroup(
        param.groupId,
        param.taskId,
        cmd === DtlCmd.TaskArchive_move_task_to_group,
      );
      return execEnd(true, param);
    }
    case DtlCmd.Taskgroup_unarchive_task_group:
    case DtlCmd.Taskgroup_unarchive_task:
    case DtlCmd.Taskgroup_archive_task_group:
    case DtlCmd.Taskgroup_archive_task: {
      if (userId !== dtlStatus.userInfo.userId) return execEnd(false);
      const param = value as DtlBean.TaskGroups;
      if (!param) return execEnd(false);
      const taskGroupList = new TaskGroupList(param);
      dtlStatus.updateTaskGroupList(taskGroupList);
      return execEnd(true, undefined);
    }
    case DtlCmd.Taskgroup_update_seq:
    case DtlCmd.TaskArchive_update_seq: {
      if (userId !== dtlStatus.userInfo.userId) return execEnd(false);
      const param = value as { groupId: string; index: number };
      if (!param || !param.groupId) return execEnd(false);
      dtlStatus.updateTaskGroupSeq(
        param.groupId,
        param.index,
        cmd === DtlCmd.TaskArchive_update_seq,
      );
      return execEnd(true, param);
    }
    case DtlCmd.User_create_label_template: {
      if (userId !== dtlStatus.userInfo.userId) return execEnd(false);
      const param = value as DtlBean.LabelTpl;
      if (!param) return execEnd(false);
      const labelTpl = new LabelTpl(param);
      dtlStatus.createLabelTemplate(labelTpl);
      return execEnd(true, labelTpl.format());
    }
    case DtlCmd.User_del_label_templates: {
      if (userId !== dtlStatus.userInfo.userId) return execEnd(false);
      const deleted = value as string[];
      if (!deleted) return execEnd(false);
      dtlStatus.deleteLabelTemplate(deleted);
      return execEnd(true, deleted);
    }
    case DtlCmd.User_contacts_add_users: {
      if (userId !== dtlStatus.userInfo.userId) return execEnd(false);
      const added = value as DtlBean.User[];
      if (!added) return execEnd(false);
      for (let i = 0; i < added.length; i += 1) {
        dtlStatus.addFriend(new User(added[i]));
      }
      return execEnd(true, undefined);
    }
    case DtlCmd.User_contacts_del_users: {
      if (userId !== dtlStatus.userInfo.userId) return execEnd(false);
      const deleted = value as string[];
      if (!deleted) return execEnd(false);
      for (let i = 0; i < deleted.length; i += 1) {
        dtlStatus.delFriend(deleted[i]);
      }
      return execEnd(true, undefined);
    }
    case DtlCmd.User_contacts_remark_user: {
      if (userId !== dtlStatus.userInfo.userId) return execEnd(false);
      const param = value as { userId: string; remark: string };
      if (!param) return execEnd(false);
      dtlStatus.remarkFriend(param.userId, param.remark);
      return execEnd(true, param);
    }
    case DtlCmd.User_contacts_add_group: {
      if (userId !== dtlStatus.userInfo.userId) return execEnd(false);
      const groupName = value as string;
      if (!groupName) return execEnd(false);
      dtlStatus.addGroup(groupName);
      return execEnd(true, groupName);
    }
    case DtlCmd.User_contacts_update_group_name: {
      if (userId !== dtlStatus.userInfo.userId) return execEnd(false);
      const param = value as { groupName: string; newGroupName: string };
      if (!param) return execEnd(false);
      dtlStatus.updateGroupName(param.groupName, param.newGroupName);
      return execEnd(true, param);
    }
    case DtlCmd.User_contacts_del_group: {
      if (userId !== dtlStatus.userInfo.userId) return execEnd(false);
      const groupName = value as string;
      if (!groupName) return execEnd(false);
      dtlStatus.delGroup(groupName);
      return execEnd(true, groupName);
    }
    case DtlCmd.User_contacts_move_member_to_group: {
      if (userId !== dtlStatus.userInfo.userId) return execEnd(false);
      const param = value as { groupName: string; userId: string };
      if (!param) return execEnd(false);
      dtlStatus.moveMemberToGroup(param.userId, param.groupName);
      return execEnd(true, param);
    }
    case DtlCmd.Taskgroup_add_creating_task:
    case DtlCmd.Task_create: {
      const param = value as { task: DtlBean.TaskV; taskGroupId: string; isArchive: boolean };
      if (!param) return execEnd(false);
      const task = new Task(param.task);
      if (!task.existUserId(dtlStatus.userInfo.userId)) return execEnd(false);
      dtlStatus.addTask(param.taskGroupId, param.isArchive, task);
      dtlStatus.initTask(task);
      return execEnd(true, { task: task.format(), taskGroupId: param.taskGroupId });
    }
    case DtlCmd.Task_update_name: {
      const taskName = value as string;
      if (!taskName) return execEnd(false);
      const task = dtlStatus.getTask(taskId);
      task?.updateTaskName(taskName);
      return execEnd(!!task, taskName);
    }
    case DtlCmd.Task_update_desc: {
      const desc = value as string;
      // if (!desc) return execEnd(false);
      const task = dtlStatus.getTask(taskId);
      task?.updateTaskDesc(desc);
      return execEnd(!!task, desc);
    }
    case DtlCmd.Task_add_labels: {
      const added = value as DtlBean.Label[];
      if (!added) return execEnd(false);
      const labels: Label[] = added.map((la) => new Label(la));
      const task = dtlStatus.getTask(taskId);
      task?.addLabels(labels);
      return execEnd(
        !!task,
        labels.map((la) => la.format()),
      );
    }
    case DtlCmd.Task_del_labels: {
      const deleted = value as string[];
      if (!deleted) return execEnd(false);
      const task = dtlStatus.getTask(taskId);
      task?.delLabels(deleted);
      return execEnd(!!task, deleted);
    }
    case DtlCmd.Task_invite_members:
    case DtlCmd.Task_agree_invite_members:
    case DtlCmd.Task_add_members: {
      const param = value as {
        added: Record<string, DtlBean.User>;
        admList: string[];
        userIds: string[];
        inviteeIds: string[];
        visitorIds: string[];
        uploaderIds: string[];
        allUserIds: string[];
      };
      if (!param || !param.added) return execEnd(false);
      const task = dtlStatus.getTask(taskId);
      if (task) {
        const users: User[] = [];
        Object.keys(param.added).forEach((id) => {
          users.push(new User(param.added[id]));
        });
        task.addUsers(
          users,
          param.admList,
          param.userIds,
          param.inviteeIds,
          param.visitorIds,
          param.uploaderIds,
          param.allUserIds,
        );
        return execEnd(!!task, {
          added: users.map((u) => u.format()),
          task: task.format(),
        });
      }
      return execEnd(false);
    }
    case DtlCmd.Taskgroup_add_exist_task:
    case DtlCmd.Taskgroup_task_recover:
    case DtlCmd.Taskgroup_add_assign_creator_task: {
      if (userId !== dtlStatus.userInfo.userId) return execEnd(false);
      const param = value as { task: DtlBean.TaskV; isArchive: boolean; groupId: string };
      if (!param || !param.task) return execEnd(false);
      const task = new Task(param.task);
      if (!task.existUserId(dtlStatus.userInfo.userId)) {
        return execEnd(false);
      }
      dtlStatus.addTask(param.groupId || '', param.isArchive || false, task);
      dtlStatus.initTask(task, false);
      return execEnd(!!task, {
        task: task.format(),
      });
    }
    case DtlCmd.Taskgroup_task_dismiss_completely: {
      if (userId !== dtlStatus.userInfo.userId) return execEnd(false);
      const param = value as { taskIds: string[] };
      if (!param || !param.taskIds) return execEnd(false);
      dtlStatus.delTaskCompletely(param.taskIds);
      return execEnd(true, param.taskIds);
    }
    case DtlCmd.Taskgroup_task_deleted_by_quit:
    case DtlCmd.Taskgroup_task_deleted_by_others:
    case DtlCmd.Taskgroup_task_deleted_by_depart: {
      if (userId !== dtlStatus.userInfo.userId) return execEnd(false);
      dtlStatus.delTask(taskId);
      return execEnd(true, taskId);
    }
    case DtlCmd.Taskgroup_task_deleted_by_dismiss: {
      if (userId !== dtlStatus.userInfo.userId) return execEnd(false);
      const param = value as { delTask: DtlBean.DelTask; taskId: string };
      dtlStatus.delTask(taskId, new DelTask(param.delTask));
      return execEnd(true, taskId);
    }
    case DtlCmd.Task_agree_del_invitees:
    case DtlCmd.Task_quit:
    case DtlCmd.Task_del_members: {
      const deleted = value as string[];
      if (!deleted) return execEnd(false);
      const task = dtlStatus.getTask(taskId);
      task?.delUsers(deleted);
      if (deleted.some((uid) => uid === dtlStatus.userInfo.userId)) {
        dtlStatus.delTask(taskId);
      }
      return execEnd(!!task, deleted);
    }
    case DtlCmd.Task_add_attachments: {
      const added = value as DtlBean.Attachment[];
      if (!added) return execEnd(false);
      const attachments: Attachment[] = added.map((a) => new Attachment(a));
      const task = dtlStatus.getTask(taskId);
      task?.addAttachments(attachments);
      return execEnd(
        !!task,
        attachments.map((a) => a.format()),
      );
    }
    case DtlCmd.Task_del_attachments: {
      const deleted = value as string[];
      if (!deleted) return execEnd(false);
      const task = dtlStatus.getTask(taskId);
      task?.delAttachments(deleted);
      return execEnd(!!task, deleted);
    }
    case DtlCmd.Task_dismiss: {
      const task = dtlStatus.getTask(taskId);
      task?.setDeleted();
      dtlStatus.delTask(taskId);
      return execEnd(true, undefined);
    }
    case DtlCmd.Task_update_role: {
      const param = value as {
        admList: string[];
        userIds: string[];
        inviteeIds: string[];
        visitorIds: string[];
        uploaderIds: string[];
      };
      if (!param) return execEnd(false);
      const task = dtlStatus.getTask(taskId);
      task?.updateUserRole(
        param.admList,
        param.userIds,
        param.inviteeIds,
        param.visitorIds,
        param.uploaderIds,
      );
      return execEnd(!!task);
    }
    case DtlCmd.Task_update_user_status: {
      const param = value as { taskId: string; status: TaskStatus };
      if (!param) return execEnd(false);
      const task = dtlStatus.getTask(param.taskId);
      task?.updateUserStatus(userId, param.status);
      return execEnd(!!task, param);
    }
    case DtlCmd.Task_update_user_ext: {
      const param = value as { taskId: string; ext: string };
      if (!param) return execEnd(false);
      const task = dtlStatus.getTask(param.taskId);
      task?.updateUserExt(userId, param.ext);
      return execEnd(!!task, param);
    }
    case DtlCmd.Task_update_user_file_lastopentime: {
      if (userId !== dtlStatus.userInfo.userId) return execEnd(false);
      const param = value as { taskId: string; fileId: string; record: string };
      if (!param) return execEnd(false);
      const task = dtlStatus.getTask(param.taskId);
      const taskFile = task?.updateTaskFileLastOpenTime(userId, param.fileId, param.record);
      task?.updateLotInfo(userId);
      return execEnd(!!task, taskFile?.format());
    }
    case DtlCmd.Task_update_user_task_lastopentime: {
      if (userId !== dtlStatus.userInfo.userId) return execEnd(false);
      const lot = value as number;
      const task = dtlStatus.getTask(taskId);
      task?.updateTaskLastOpenTime(userId, lot);
      return execEnd(!!task, lot);
    }
    case DtlCmd.Task_update_user_mark: {
      const param = value as { taskId: string; mark: TaskMark };
      if (!param) return execEnd(false);
      const task = dtlStatus.getTask(param.taskId);
      task?.updateTaskMark(userId, param.mark);
      return execEnd(!!task, param);
    }
    case DtlCmd.Task_send_msg: {
      const m = value as DtlBean.Msg;
      if (!m) return execEnd(false);
      const msg = new Msg(m);
      const task = dtlStatus.getTask(taskId);
      task?.addMsg(msg);
      return execEnd(!!task);
    }
    case DtlCmd.Task_reply_msg: {
      const param = value as { msg: DtlBean.Msg; replyId: string };
      if (!param || !param.msg || !param.replyId) return execEnd(false);
      const msg = new Msg(param.msg);
      const task = dtlStatus.getTask(taskId);
      task?.updateMsgAddReply(param.replyId, msg);
      return execEnd(!!task);
    }
    case DtlCmd.Task_delete_msg: {
      const param = value as { deleteId: string };
      if (!param || !param.deleteId) return execEnd(false);
      const task = dtlStatus.getTask(taskId);
      task?.deleteMsg(param.deleteId);
      return execEnd(!!task);
    }
    case DtlCmd.Task_creator_depart: {
      const task = dtlStatus.getTask(taskId);
      task?.setCreatorDepart();
      return execEnd(!!task);
    }
    case DtlCmd.Task_creator_ressign: {
      const param = value as { task: DtlBean.TaskV };
      if (!param || !param.task) return execEnd(false);
      const task = dtlStatus.getTask(taskId);
      if (task) {
        dtlStatus.taskGroupList.replaceTask(new Task(param.task));
      }
      return execEnd(!!task);
    }
    case DtlCmd.Task_add_enterprise_id: {
      const param = value as { enterpriseId: string };
      if (!param || !param.enterpriseId) return execEnd(false);
      const task = dtlStatus.getTask(taskId);
      if (task) {
        task.enterpriseId = param.enterpriseId;
      }
      return execEnd(!!task, param);
    }
    case DtlCmd.Task_create_dir: {
      const file = value as DtlBean.TaskFile;
      if (!file) return execEnd(false);
      const task = dtlStatus.getTask(taskId);
      task?.addTaskFile(new TaskFile().fromBean(file));
      return execEnd(!!task, task?.files.existFileById(file.id)?.format());
    }
    case DtlCmd.Task_rename_file: {
      const param = value as { file: DtlBean.FilePath; path: string };
      if (!param || !param.file || !param.path) return execEnd(false);
      const task = dtlStatus.getTask(taskId);
      const f = task?.updateTaskFileName(param.path, param.file);
      return execEnd(!!f, {
        file: f?.format(),
        path: param.path,
      });
    }
    case DtlCmd.Task_move_files: {
      const moves = value as Record<string, DtlBean.FilePath>;
      if (!moves) return execEnd(false);
      const task = dtlStatus.getTask(taskId);
      const updated = task?.moveTaskFiles(moves);
      return execEnd(!!updated, updated);
    }
    case DtlCmd.Task_del_files: {
      const deleted = value as DtlBean.DelFile[];
      if (!deleted) return execEnd(false);
      const task = dtlStatus.getTask(taskId);
      task?.delTaskFiles(deleted);
      return execEnd(!!task, deleted);
    }
    case DtlCmd.Task_del_files_completely: {
      const deleted = value as { taskId: string; id: string };
      if (!deleted) return execEnd(false);
      const task = dtlStatus.getTask(taskId);
      task?.delTaskFilesCompletely(deleted.id);
      return execEnd(!!task, value);
    }
    case DtlCmd.Task_del_files_recover: {
      const param = value as { taskId: string; id: string; files: DtlBean.TaskFile[] };
      if (!param) return execEnd(false);
      const task = dtlStatus.getTask(taskId);
      task?.delTaskFilesCompletely(param.id);
      let adds: TaskFile[] = [];
      if (param.files) {
        adds = param.files.map((a) => new TaskFile().fromBean(a));
      }
      task?.addTaskFiles(adds);
      return execEnd(!!task, { taskId: param.taskId, id: param.id });
    }
    case DtlCmd.Task_add_files: {
      const param = value as { added: DtlBean.TaskFile[] };
      if (!param) return execEnd(false);
      let adds: TaskFile[] = [];
      if (param.added) {
        adds = param.added.map((a) => new TaskFile().fromBean(a));
      }
      const task = dtlStatus.getTask(taskId);
      task?.addTaskFiles(adds);
      return execEnd(!!task, {
        added: adds.map((a) => a.format()),
      });
    }
    case DtlCmd.Task_copy_files:
    case DtlCmd.Task_copy_files_to_task:
    case DtlCmd.Task_add_share_file_to_task: {
      const param = value as DtlBean.TaskFile[];
      if (!param) return execEnd(false);
      const adds = param.map((a) => new TaskFile().fromBean(a));
      const task = dtlStatus.getTask(taskId);
      task?.addTaskFiles(adds, undefined);
      return execEnd(
        !!task,
        adds.map((a) => a.format()),
      );
    }
    case DtlCmd.Task_file_update_content: {
      const param = value as { taskId: string; fileId: string; content: string };
      if (!param) return execEnd(false);
      const task = dtlStatus.getTask(param.taskId);
      task?.updateTaskFileContent(param.fileId, param.content);
      return execEnd(!!task, param);
    }
    case DtlCmd.Task_file_update_status: {
      const param = value as { taskId: string; fileId: string; status: number };
      if (!param) return execEnd(false);
      const task = dtlStatus.getTask(param.taskId);
      task?.updateTaskFileStatus(param.fileId, param.status);
      return execEnd(!!task, param);
    }
    case DtlCmd.Task_convert_file_result: {
      const param = value as { id: string; conv: DtlBean.Conv; file: DtlBean.TaskFile };
      if (!param) return execEnd(false);
      const taskFile = new TaskFile().fromBean(param.file);
      const task = dtlStatus.getTask(taskId);
      task?.updateTaskFile(taskFile);
      return execEnd(!!task, {
        id: param.id,
        conv: param.conv,
        file: task?.files.existFileById(taskFile.id)?.format(),
      });
    }
    case DtlCmd.Task_file_add_analysis:
    case DtlCmd.Task_file_update_analysis:
    case DtlCmd.Task_file_del_analysis:
    case DtlCmd.Task_set_opentype: {
      const param = value as DtlBean.TaskFile;
      if (!param) return execEnd(false);
      const task = dtlStatus.getTask(taskId);
      task?.updateTaskFile(new TaskFile().fromBean(param));
      return execEnd(!!task, task?.files.existFileById(param.id)?.format());
    }
    case DtlCmd.Task_file_add_msg: {
      const param = value as { path: string; msg: DtlBean.Msg };
      if (!param || !param.path || !param.msg) return execEnd(false);
      const task = dtlStatus.getTask(taskId);
      const taskfile = task?.addTaskFileMsg(param.path, param.msg);
      return execEnd(!!task, taskfile?.format());
    }
    case DtlCmd.Task_file_del_msg: {
      const param = value as { fileId: string; msgId: string };
      if (!param || !param.fileId || !param.msgId) return execEnd(false);
      const task = dtlStatus.getTask(taskId);
      const taskfile = task?.delTaskFileMsg(param.fileId, param.msgId);
      return execEnd(!!task, taskfile?.format());
    }
    case DtlCmd.Task_onlyoffice_update_info: {
      const param = value as { fileId: string; onlyOffice: DtlBean.OnlyOffice };
      if (!param || !param.fileId || !param.onlyOffice) return execEnd(false);
      const task = dtlStatus.getTask(taskId);
      const taskfile = task?.updateTaskFileOnlyOffice(param.fileId, param.onlyOffice);
      return execEnd(!!task, taskfile?.format());
    }
    case DtlCmd.User_notify_drawingdistribution:
    case DtlCmd.User_notify_materialmatch:
    case DtlCmd.User_notify_convert_ca_file:
    case DtlCmd.User_notify_convert_weighting_file:
    case DtlCmd.User_notify_convert_file: {
      dtlStatus.listenEvent.fireEvent(cmd, value);
      return execEnd(true, value);
    }
    case DtlCmd.Category_add:
    case DtlCmd.Category_addChild:
    case DtlCmd.Category_updateChild:
    case DtlCmd.Category_deleteChild:
    case DtlCmd.Category_setRead: {
      return execEnd(true, value);
    }
    case DtlCmd.User_filerecord_add: {
      if (userId !== dtlStatus.userInfo.userId) return execEnd(false);
      const param = value as { fileRecord: DtlBean.FileRecord; recentOrCollect: string };
      if (!param || !param.fileRecord || !param.recentOrCollect) return execEnd(false);
      dtlStatus.fileRecords.addFileRecord(param.recentOrCollect, param.fileRecord);
      return execEnd(true, param);
    }
    case DtlCmd.User_filerecord_delete: {
      if (userId !== dtlStatus.userInfo.userId) return execEnd(false);
      const param = value as { id: string; recentOrCollect: string };
      if (!param || !param.id || !param.recentOrCollect) return execEnd(false);
      dtlStatus.fileRecords.deleteFileRecord(param.recentOrCollect, param.id);
      return execEnd(true, param);
    }
    case DtlCmd.User_filerecord_addCollectFolder: {
      if (userId !== dtlStatus.userInfo.userId) return execEnd(false);
      const param = value as DtlBean.CollectFolder[];
      dtlStatus.setCollectFolders(param);
      return execEnd(true, dtlStatus.collectFolders.format());
    }
    case DtlCmd.Enterprise_create:
    case DtlCmd.Enterprise_addDept:
    case DtlCmd.Enterprise_renameDept:
    case DtlCmd.Enterprise_addUser:
    case DtlCmd.Enterprise_updateUser:
    case DtlCmd.Enterprise_update:
    case DtlCmd.Enterprise_departUser:
    case DtlCmd.Enterprise_updateUserExt:
    case DtlCmd.Enterprise_ressignCreator:
    case DtlCmd.Enterprise_delUser:
    case DtlCmd.Enterprise_recoverUser:
    case DtlCmd.Enterprise_delDept:
    case DtlCmd.Enterprise_dismiss: {
      const param = value as { enterprise: DtlBean.Enterprise };
      if (!param || !param.enterprise) return execEnd(false);
      const enterprise = dtlStatus.enterpriseList.addOrUpdateEnterprise(param.enterprise);
      param.enterprise = enterprise.format();
      return execEnd(true, param);
    }
    default: {
      break;
    }
  }
  return execEnd(false);
}
