import API_VERSION from '@/services/datalib/api/config';
import request from '@/utils/request';

export async function dtlTaskShareOpenShare(body: DtlTaskShare.OpenShareParam) {
  return request<DtlComm.ResultV<DtlBean.TaskShare>>(`${API_VERSION}/api/task/share/openShare`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskShareAdd(body: DtlTaskShare.AddParam) {
  return request<DtlComm.ResultV<DtlBean.TaskShare>>(`${API_VERSION}/api/task/share/add`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskShareUpdateAccessCode(body: DtlTaskShare.UpdateAccessCodeParam) {
  return request<DtlComm.ResultV<DtlBean.TaskShare>>(
    `${API_VERSION}/api/task/share/updateAccessCode`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlTaskShareVerify(body: DtlTaskShare.VerifyParam) {
  return request<DtlComm.ResultV<DtlBean.TaskShare>>(`${API_VERSION}/api/task/share/verify`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskShareVerifyExt(body: DtlTaskShare.VerifyParam) {
  return request<DtlComm.ResultV<DtlTaskShare.VerifyExtResult>>(
    `${API_VERSION}/api/task/share/verifyExt`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlTaskShareGetShareInfo(body: DtlTaskShare.GetShareInfoParam) {
  return request<DtlComm.ResultV<DtlBean.TaskShare>>(`${API_VERSION}/api/task/share/getShareInfo`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}
