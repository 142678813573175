export default {
  'detailsSide.detail': '详情',
  'detailsSide.tool.title': '分析工具',
  'detailsSide.tool.select.all': '全部',
  'detailsSide.remark.title': '备注',
  'detailsSide.remark.allPeople': '全部成员',
  'detailsSide.info.title': '信息',
  'detailsSide.info.size': '大小',
  'detailsSide.info.openWay': '方式',
  'detailsSide.info.openWay.default': '默认预览',
  'detailsSide.info.openWay.select': '选择',
  'detailsSide.info.uploadInfo': '上传',
  'detailsSide.remark.delete.desc': '确认删除备注?',
  'detailsSide.description': '说明',
  'detailsSide.revise': '修改',
};
