import { ErrType, TaskFileType, updateDtlVerLog } from '@/services/datalib';
import { Err } from '@/services/datalib/enum';

export function checkNameLegal(str: string) {
  const pattern = new RegExp("[`^*|{}':;',\\.<>《》/?￥……*|{}‘；：”“'。，、？]");
  return pattern.test(str);
}

export function IsNonToken(): DtlComm.Result<undefined> | undefined {
  const token = localStorage.getItem('x-auth-token');
  if (!token)
    return {
      code: Err.TOKEN_ERROR[0],
      data: undefined,
      message: Err.TOKEN_ERROR[1],
    };
  return undefined;
}

// 指定长度和基数
export function uuid2(len: number, radix: number) {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  const uuid = [];
  let i;
  // eslint-disable-next-line no-param-reassign
  radix = radix || chars.length;

  if (len) {
    // Compact form
    // eslint-disable-next-line no-bitwise
    for (i = 0; i < len; i += 1) uuid[i] = chars[0 | (Math.random() * radix)];
  } else {
    // rfc4122, version 4 form
    let r;

    // rfc4122 requires these characters
    // eslint-disable-next-line no-multi-assign
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i += 1) {
      if (!uuid[i]) {
        // eslint-disable-next-line no-bitwise
        r = 0 | (Math.random() * 16);
        // eslint-disable-next-line no-bitwise
        uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }

  return uuid.join('');
}

export function isDev() {
  return process.env.NODE_ENV === 'development';
}

export function doErrResponse(code?: number, message?: string): DtlComm.Result<undefined> {
  if (code !== undefined) {
    return {
      code,
      data: undefined,
      message: message as string,
    };
  }
  return {
    code: Err.SERVER_ERROR[0],
    data: undefined,
    message: Err.SERVER_ERROR[1],
  };
}

export function doResponse<T>(
  response: DtlComm.ResultV<T>,
  newData?: T,
  debug?: string,
): DtlComm.Result<T | undefined> {
  if (response) {
    const { code, message, data, verLog } = response;
    if (code !== undefined) {
      if (code === Err.OK[0]) {
        if (verLog) {
          updateDtlVerLog(verLog);
        }
        return {
          code,
          data: newData ?? data,
          message,
          debug,
        };
      }
      return {
        code,
        data,
        message,
        debug,
      };
    }
  }
  return {
    code: Err.SERVER_ERROR[0],
    data: undefined,
    message: Err.SERVER_ERROR[1],
  };
}

export function doSimpleResponse<T>(response: DtlComm.ResultV<T>): DtlComm.Result<T | undefined> {
  if (response) {
    const { code, message, data } = response;
    if (code !== undefined) {
      return {
        code,
        data,
        message,
      };
    }
  }
  return {
    code: Err.SERVER_ERROR[0],
    data: undefined,
    message: Err.SERVER_ERROR[1],
  };
}

export function reply<T>(err: ErrType, data?: T, debug?: string): DtlComm.Result<T> {
  return {
    code: err[0],
    data,
    message: err[1],
    debug,
  };
}

export function reReply<T, U>(r: DtlComm.Result<U>, data?: T, debug?: string): DtlComm.Result<T> {
  return {
    code: r.code,
    data,
    message: r.message,
    debug: debug ?? r.debug,
  };
}

export function isPrmFile(fileType: number): boolean {
  return fileType >= TaskFileType.TASKFIEL_TYPE_Correlation && fileType < 10000;
}

function initEnvName() {
  let domain = process.env.REACT_APP_DOMAIN || '';
  if (domain.indexOf('alphagraph.cn') > -1) {
    return 'pre';
  }
  if (domain.indexOf('prmeasure.com') > -1) {
    return 'prod';
  }
  return 'dev';
}

const _envName: string = initEnvName();

export function getEnvName() {
  return _envName;
}

export function getBucketName() {
  return `datalib-${getEnvName()}`;
}

export function getDownloadPreFixPath() {
  return `https://${getBucketName()}.oss-cn-shenzhen.aliyuncs.com`;
}
