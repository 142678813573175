export default {
  'common.uploadData.title': 'Import Data',
  'common.uploadData.single.title': 'Single file import',
  'common.uploadData.multiple.title': 'Multiple File Import',
  'common.uploadData.dragIn.title': 'Drag in file',
  'common.uploadData.multiple.desc':
    'Drag and drop files or directories to import, supporting local files',
  'common.uploadData.multiple.desc.gridlab': 'Drag and drop files, supporting local files',
  'common.uploadData.selectFile.title': 'Select File',
  'common.uploadData.selectFile.desc':
    'Click “Task Import” or “Local Import” to select file to analyze.',
  'common.uploadData.importFromTask': 'Task Import',
  'common.uploadData.importFromLocal': 'Local Import',
  'common.uploadData.hasUploaded.tip':
    'The file has been imported, you can drag in another file to replace it',
  'common.calculateData.title': 'Parsing data',
  'common.selectTemplate.tip': 'No template selected',
  'common.selectTemplate.tip.none': 'No template required',
  'common.dataMission': 'Data Task',
  'common.dataMission.tip': 'Obtaining data tasks after parsing files',
  'common.dataMission.selectAll': 'Select All',
  'common.chartPage.dataItem.title': 'Data Item',
  'common.chartPage.dataItem.data.title': 'Data',
  'common.chartPage.dataItem.sensitivity.title': 'Single Values',
  'common.chartPage.dataItem.noItem': 'No data items',
  'common.chartPage.dataItem.select': 'MultiSelect',
  'common.chartPage.dataItem.cancelSelect': 'Cancel MultiSelect',
  'common.chartPage.dataItem.search': 'Search Item',
  'common.chartPage.dataItem.searchEmpty': 'No matching items',
  'common.chartPage.dataItem.all': 'All Items',
  'common.chartPage.noItem': 'No data items',
  'common.chartPage.gridSetting': 'Grid Settings',
  'common.chartPage.grid.xMain': 'Main horizontal lines',
  'common.chartPage.grid.yMain': 'Main vertical lines',
  'common.chartPage.grid.xSecondary': 'Secondary horizontal lines',
  'common.chartPage.grid.ySecondary': 'Secondary vertical lines',
  'common.chartPage.overlimit.redCurve': 'curve in red',
  'common.chartPage.overlimit.redPoint': 'point in red',
  'common.chartPage.mergeError': 'Merge chart error',
  'common.analyseWay': 'Parsing method',
  'common.aircondition.result': 'Result',
  'common.coordinate': 'Coordinate',
  'common.unit': 'Unit',
  'common.CustomData': 'Custom data',
  'common.nowType': 'Current type',
  'common.OtherType': 'Other types',
  'common.channel': 'Channel ',
  'common.channel.errorRate': ' set incorrect sampling rate',
  'common.shortcutCopy.ios': 'Please use command+c to copy!',
  'common.shortcutCopy.windows': 'Please use ctrl+c to copy!',
  'common.shortcutPaste.ios': 'Please use command+v to paste!',
  'common.shortcutPaste.windows': 'Please use ctrl+v to paste!',
  'common.onlyCsvSupport': 'Only CSV files are supported',
  'common.addChannel': 'Add Channel',
  'common.render.wait': 'Rendering, please wait...',
  'commo.export.wait': 'Exporting, please wait...',
  'common.analysis.wait': 'Parsing, please wait...',
  'common.analysis.fail': 'Analysis Fail',
  'common.analysis.fail.tip': 'Please select the correct type to open this file',
  'common.import.wait': 'Importing, please wait...',
  'common.fileName.placeholder': 'File Name',
  'common.openFail.incorrectType': 'Please select the correct type to open',
  'common.pasteFormatError': 'Paste format error',
  'common.result': ' Result',
  'common.CurveInterpolation': 'Curve Interpolation',
  'common.AdditionOperation': 'Addition Operation',
  'common.AdditionOperation.placeholder': 'Enter number',
  'common.MultiplicationOperation': 'Multiplication Operation',
  'common.MultiplicationOperation.placeholder': 'Enter number',
  'common.newTemplate': 'Untitled Parsing Template',
  'common.customizedData': 'Customized Data',
  'common.testRequire': 'Please fill in the complete template data!',
  'common.comma': ', ',
  'common.autoLimit': 'Auto Limit',
  'common.autoLimit.tip': 'Generate according to ± 4 Sigma of the mean of each frequency point',
  'common.autoLimit.applyAll': 'Apply to all projects',
};
