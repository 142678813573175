const openWayModal = {
  'openWayModal.BigTitle': 'Open with',
  'openWayModal.defaultWay.tips': 'Default Method',
  'openWayModal.wav.name': 'Waveform Preview',
  'openWayModal.wav.desc': 'Support for waveform preview',
  'openWayModal.office.name': 'Office Preview',
  'openWayModal.office.desc': 'Support for previewing Office documents',
  'openWayModal.img.name': 'Picture Preview',
  'openWayModal.img.desc': 'Support for picture viewing and preview',
  'openWayModal.pdf.name': 'PDF Preview',
  'openWayModal.pdf.desc': 'Support for PDF viewing and preview',
  'openWayModal.video.name': 'Video Preview',
  'openWayModal.video.desc': 'Support for video viewing and preview',
  'openWayModal.audio.name': 'Audio Previews',
  'openWayModal.audio.desc': 'Support for audio viewing and preview',
  'openWayModal.cpb.name': 'CPB Analyse',
  'openWayModal.cpb.desc': 'Support CPB analysis of audio files',
  'openWayModal.dsp.name': 'Audio Editor',
  'openWayModal.dsp.desc': 'Support processing of audio files',
  'openWayModal.grr.name': 'GRR Analyse',
  'openWayModal.grr.desc': 'Support GRR analysis of table files',
  'openWayModal.dapt.name': 'Audio Graph',
  'openWayModal.dapt.desc': 'Support for Audio Graph analysis of table and Dat files',
  'openWayModal.correlation.name': 'Correlation',
  'openWayModal.correlation.desc': 'Support for correlation analysis of table files',
  'openWayModal.scic.name': 'Scientific Computing',
  'openWayModal.scic.desc': 'Support for Scientific Computing analysis of files',
  'openWayModal.spc.name': 'Multimodal Detection',
  'openWayModal.spc.desc': 'Support multimodal detection of files',
  'openWayModal.allKindTitle': 'All types:',
  'openWayModal.openByOtherWay': 'Open by different type:',
  'openWayModal.searchTool.placeholder': 'Search by tool name',
  'openWayModal.searchType.placeholder': 'Search by file types',
  'openWayModal.nowDefaultWay': 'Current default: ',
  'openWayModal.autoType': 'Automatic recognition type',
  'analyseModal.BigTitle': 'Analysis Method',
  'openWayModal.document.name': 'Document Preview',
};

const deleteDirModal = {
  'deleteDirModal.title': 'Delete this folder?',
  'deleteDirModal.desc': 'Files in the folder will be deleted',
};

// 模板
const templateModal = {
  // 重置模板
  'resetModal.BigTitle': 'Reset Project Template',
  // 通用模板
  'templateModal.title.separator': 'Separator',
  'templateModal.separator.desc.one':
    'Select the symbol before the frequency as the separator, such as: FB_Mic AMic FR',
  'templateModal.separator.placeholder': 'Delimiters in data column',
  'templateModal.title.snFormat': 'SN Format',
  'templateModal.snFormat.desc':
    'Enter the column name of the sample, such as: Serial Number / serial_ID',
  'templateModal.snFormat.placeholder': 'Field in SN column',
  'templateModal.title.sensitivityFormat': 'Single valued',
  'templateModal.sensitivityFormat.desc.one':
    'Select the characteristic fields included in the single valued column name, such as: FB_Mic Amic ',
  'templateModal.sensitivityFormat.placeholder': 'Field in single valued column',
  'templateModal.sensitivityFormat.auto': 'Automatic recognition',
  'templateModal.sensitivityFormat.hand': 'Manual recognition',
  'templateModal.sensitivityFormat.danger':
    '(Risk: Possible identification of meaningless projects)',
  'templateModal.title.stationID': 'Site ID',
  'templateModal.stationID.placeholder': 'Column of equipment',
  'templateModal.title.fileName': 'File Name',
  'templateModal.stationID.desc':
    'Enter the column name where the test device is located, such as: StationID / station_id',
  'templateModal.title.station': 'Work station',
  'templateModal.station.desc': 'Select the appropriate station as the distinction',
  'templateModal.title.sampleSize': 'Samples',
  'templateModal.sampleSize.desc': 'Number of samples selected, automatic row comparison',
  // 导入模板
  'importTemplateModal.BigTitle': 'Import Template',
  'importTemplateModal.searchTask.tip': 'Search Task',
  // 新建解析模板
  'newTemplateModal.BigTitle': 'New Template',
  'newTemplateModal.templateName': 'Template Name',
  // 编辑解析模板
  'editTemplateModal.BigTitle': 'Edit Template',
  // 保存模板覆盖提示
  'templateModal.replace.tip1': 'Do you want to overwrite the template with the same name',
  'templateModal.replace.tip2': 'There is a template with the same name in the task',
  'templateModal.notSave': 'Cancel',
  'templateModal.SaveTwo': 'Keep both',
  'templateModal.replace': 'Cover',
  'autoTplFailModal.title': 'Automatic parsing failed',
  'autoTplFailModal.content': 'Automatic parsing failed, please manually edit the parsing template',
};

const copyModal = {
  'copyModal.BigTitle': 'Copy File',
  'copyModal.fileName': 'File Name',
  'copyModal.copyToTask.title': 'Copy to task',
  'copyModal.searchInput.placeholder': 'Search task',
};

const shareModal = {
  'shareModal.BigTitle': 'Share file',
  'shareModal.shareFileDesc': 'Others will see files in their current state',
  'shareModal.shareResultDesc': 'Others will see results in their current state',
  'shareModal.canOtherSee.title': 'Allow non task members to view',
  'shareModal.password': 'Password:',
  'shareModal.shareTime': 'Term of validity:',
  'shareModal.comments': 'Include remarks',
  'shareModal.messages': 'Include information',
  'shareModal.data': 'Include data sources',
  'shareModal.time.one': 'one day',
  'shareModal.time.three': 'three days',
  'shareModal.time.seven': 'seven days',
  'shareModal.time.month': 'one month',
  'shareModal.time.forever': 'Never Expires',
  'shareModal.time.effective': ' effective',
  'sharePage.password.title': 'Password',
  'sharePage.password.placeholder': '4-8 digit password',
  'sharePage.passwordError': 'Password error',
  'sharePage.viewResult': 'View Results',
  'sharePage.changeSharePassword': 'Change Password',
  'sharePage.changeSharePassword.title': 'Link Password Settings',
  'sharePage.changeSharePassword.inputPlaceHolder': 'Please enter a new password',
  'sharePage.changeSharePassword.randomBtn': 'Randomly Generated',
  'sharePage.changeSharePassword.rule1': 'Contains letters or numbers',
  'sharePage.changeSharePassword.rule2': 'Enter 4-8 characters in length',
  'sharePage.changeSharePassword.safeTip':
    'Security reminder: Do not use passwords commonly used by individuals or from other platforms',
};

const deleteAttachmentModal = {
  'deleteAttachmentModal.title': 'Confirm to delete ',
  'deleteAttachmentModal.desc': 'Not recoverable after deletion',
};

const deleteMessageModal = {
  'deleteMessageModal.title': 'Do you want to delete this message?',
  'deleteMessageModal.desc': 'Replies will also be deleted',
  'deleteMessageModal.reply': 'Do you want to delete this reply?',
  'deleteMessageModal.reply.desc': 'Not recoverable after deletion',
};

const CorClearDataModal = {
  'CorClearDataModal.title': 'Clear Data',
  'CorClearDataModal.desc': 'Selecting another parsing method will clear the current data',
};

const addLinkModal = {
  'addLinkModal.BigTitle': 'Add Members',
  'addLinkModal.addByLink': 'Add by invite link',
  'addLinkModal.addByLink.btn': 'Copy',
  'addLinkModal.addByLink.tips': 'Link validity: 3 days',
  'addLinkModal.addByMailList': 'Add by address book',
  'addLinkModal.input.placeholder': 'Enter phone or email',
  'addLinkModal.addByInput.emptyTips': 'Multiple members separated by spaces or commas',
  'addLinkModal.addByMailList.BigTitle': 'Add By Address Book',
  'addLinkModal.addByMailList.input.placeholder': 'Search Contacts',
  'addLinkModal.addByMailList.myFriend': 'My contacts',
  'addLinkModal.addByMailList.myFriend.empty': 'No contacts',
  'addLinkModal.addByMailList.enterprise': 'Organizational structure',
  'addLinkModal.addByMailList.right.emptyTitle': 'Please select to add a contact',
  'addLinkModal.addByMailList.right.selectTitle': 'Selected',
  'addLinkModal.addByMailList.searchEmpty': 'No matching contacts found',
  'addLinkModal.addByMailList.searchFriend': 'Find and add this user',
  'addFriend.group': 'Group',
  'addFriend.remark': 'Remark',
  'addFriend.defaultGroup': 'Default',
  'addLinkModal.add': 'Added',
  'addLinkModal.invite': 'Invited',
};

const addLimitModal = {
  'addLimitModal.BigTitle': 'Add Limit',
  'addLimitModal.importFromTask': 'Task Import',
  'addLimitModal.customLimit': 'Custom limit',
  'addLimitModal.importFromLocal': 'Local Import',
  'addLimitModal.searchTask.placeholder': 'Search Task',
  'addLimitModal.frequency': 'Frequency',
  'addLimitModal.downloadLimit.title': 'Download and fill in the limit template',
  'addLimitModal.downloadLimit.tip': 'Do not know how to fill it?',
  'addLimitModal.downloadLimit.example': 'View Example',
  'addLimitModal.uploadLimit.title': 'Upload the filled limit',
  'addLimitModal.uploadLimit.hasUploaded': 'Uploaded',
  'addLimitModal.uploadLimit.hasUploaded.tip': 'Click again to upload again',
};

const coordinateModal = {
  'coordinateModal.BigTitle': 'Modify coordinates',
  'coordinateModal.editChart': 'Edit chart',
  'coordinateModal.xTitle': 'X-axis unit',
  'coordinateModal.yTitle': 'Y-axis unit',
  'coordinateModal.y1Title': 'Y1-axis unit',
  'coordinateModal.y2Title': 'Y2-axis unit',
  'coordinateModal.x': 'X-axis boundary(HZ)',
  'coordinateModal.xMin': 'Min X-axis',
  'coordinateModal.xMax': 'Max X-axis',
  'coordinateModal.y': 'Y-axis boundary',
  'coordinateModal.yMin': 'Min Y-axis',
  'coordinateModal.yMax': 'Max Y-axis',
  'coordinateModal.y1Min': 'Min Y1-axis',
  'coordinateModal.y1Max': 'Max Y1-axis',
  'coordinateModal.y2Min': 'Min Y2-axis',
  'coordinateModal.y2Max': 'Max Y2-axis',
  'coordinateModal.auto': 'Auto',
  'coordinateModal.reset': 'Reset',
  'coordinateModal.useTo': 'Apply to Others',
  'coordinateModal.useToProject': 'Apply to Projects',
  'coordinateModal.useToThisFileProject': 'Apply to this File Projects',
  'coordinateModal.chartName': 'Chart name',
  'coordinateModal.setXYTitle': ' Unit',
  'coordinateModal.setCoordinate': 'Coordinate',
  'coordinateModal.all': 'All',
  'coordinateModal.setYSpace': 'Y-axis amplitude',
  'coordinateModal.setYSpace.tip':
    'The slider moves to the right, increasing the amplitude of the Y-axis coordinate',
  'coordinateModal.manual': 'Manual',
  'coordinateModal.setYDvalue': 'Y-axis scale interval',
};

const deleteAppFileModal = {
  'deleteAppFileModal.title': 'Confirm to delete file ',
};

const colorModal = {
  'colorModal.border': 'Border Color',
  'colorModal.fillColor': 'Fill Color',
};

const appSaveModal = {
  'appSaveModal.title': 'Save File',
  'appSaveModal.fileName': 'File Name',
  'appSaveModal.saveToTask': 'Save',
  'appSaveModal.searchTask': 'Search Task',
};

const RefrigerantModal = {
  'RefrigerantModal.errorRange.title': 'Input error',
  'RefrigerantModal.errorRange.desc':
    'The lower limit cannot be greater than or equal to the upper limit',
  'RefrigerantModal.selectParam.title': 'Select fixed parameters',
  'RefrigerantModal.selectParam.desc':
    'To fix a parameter, press OK to fix the second parameter, or press Cancel to fix the first parameter',
};

const beforeExportResuiltModal = {
  'beforeExportResultModal.title': 'Export',
  'beforeExportResultModal.question':
    'The number of curves in the exported table exceeds 255, do you want to export curves as pictures?',
  'beforeExportResultModal.tip':
    'If still exported as curves, some applications may open the file incorrectly',
  'beforeExportResultModal.curveExport': 'Export as Curves',
  'beforeExportResultModal.imageExport': 'Export as Pictures',
};

const beforeExportResuiltModalAsModal = {
  'beforeExportResultModalAsWord.title': 'Export Word report',
  'beforeExportResultModalAsWord.middle.name': 'Report name',
  'beforeExportResultModalAsWord.middle.defalutInput': 'report',
  'beforeExportResultModal.middle.item': 'Check items',
};

const exportResultModal = {
  'exportResultModal.title': 'Export Results',
  'exportResultModal.name': 'Results Name',
};

const recycleDeleteModal = {
  'recycleDeleteModal.title': 'Delete it completely?',
  'recycleDeleteModal.task.desc':
    'This operation will delete the task from the Recycle Bin. After deletion, you will not be able to recover this task. Please operate with caution!',
  'recycleDeleteModal.file.desc':
    'This operation will delete the file from the Recycle Bin. After deletion, you will not be able to recover this task. Please operate with caution!',
};

const delContactModal = {
  'delContactModal.title': 'Delete this contact？',
  'delContactModal.desc': 'Deleting will not affect tasks that are being performed together',
};

const delGroupModal = {
  'delGroupModal.title': 'Delete this group？',
  'delGroupModal.desc': 'Contacts in the group will not be deleted',
  'delGroupModal.task.desc': 'Tasks within the group will not be deleted',
};

const noticeModal = {
  'noticeModal.you': ' you',
  'noticeModal.replyYou': ' replied to you',
  'noticeModal.commentYou': ' comment on you',
  'noticeModal.newThumbtack': ' new thumbtack',
  'noticeModal.beAddTask': 'Pulled into a new task',
  'noticeModal.inviteConfirm': 'Confirm Invitation',
  'noticeModal.beOutTask': 'Removed from task',
  'noticeModal.taskDismiss': 'The task has been dissolved',
  'noticeModal.beAddEnterprise': 'Pulled into a new company',
  'noticeModal.beOutEnterprise': 'Removed from company',
  'noticeModal.enterpriseDissolved': 'The company has been dissolved',
  'noticeModal.addOtherContact': 'Add as a contact?',
  'noticeModal.addOtherContact.desc': 'Whether to add as a contact?',
  'noticeModal.title': 'Message',
  'noticeModal.allRead': 'Read All',
};

const quitModal = {
  'quitModal.creator': 'Confirm to dismiss Task?',
  'quitModal.creator.desc':
    'After dissolving the task, all members will not be able to access the task content, and all content of the task will be moved to the Recycle Bin.',
  'quitModal.member': 'Exit Prompt',
  'quitModal.member.desc': 'This action will exit the task',
};

const cardModal = {
  'cardModal.phone': 'Phone',
  'cardModal.email': 'Email',
  'cardModal.email.hidden': 'Hidden',
  'cardModal.company': 'Company',
  'cardModal.section': 'Section',
  'cardModal.gender': 'Gender',
  'cardModal.gender.male': 'Male',
  'cardModal.gender.female': 'Female',
  'cardModal.address': 'Address',
};

const removeMemberModal = {
  'removeMemberModal.title': 'Confirm to remove member?',
  'removeMemberModal.desc': 'Add again after removal',
};

const showSaveTip = {
  'showSaveTip.title': 'The editing content has not been saved yet',
  'showSaveTip.tip': 'Save to synchronize the latest content to the task',
};

const customWaveDataModal = {
  'customWaveDataModal.title': 'Do you want to use the current custom data?',
  'customWaveDataModal.tip': 'After confirmation, waveform data will be generated',
  'customWaveDataModal.ok': 'OK',
  'customWaveDataModal.cancel': 'NO',
};

const addChannelModal = {
  'addChannelModal.title': 'Add Channel',
  'addChannelModal.custom1': 'Custom',
  'addChannelModal.custom2': 'Custom 2',
  'addChannelModal.channelName': 'Channel Name',
  'addChannelModal.sampleRate': 'Sampling Rate',
  'addChannelModal.unit': 'Unit',
  'addChannelModal.scale': 'Scale Factor',
  'addChannelModal.offset': 'Offset',
  'addChannelModal.createData': 'Generate data',
  'addChannelModal.algorithm': 'Algorithm',
  'addChannelModal.duration': 'Duration(s)',
  'addChannelModal.frequency': 'Frequency(Hz)',
  'addChannelModal.amplitude': 'Amplitude',
  'addChannelModal.formula': 'Formula',
};

export default {
  ...openWayModal,
  ...deleteDirModal,
  ...templateModal,
  ...copyModal,
  ...shareModal,
  ...deleteAttachmentModal,
  ...deleteMessageModal,
  ...addLinkModal,
  ...CorClearDataModal,
  ...addLimitModal,
  ...coordinateModal,
  ...deleteAppFileModal,
  ...colorModal,
  ...appSaveModal,
  ...RefrigerantModal,
  ...exportResultModal,
  ...beforeExportResuiltModal,
  ...beforeExportResuiltModalAsModal,
  ...recycleDeleteModal,
  ...delContactModal,
  ...delGroupModal,
  ...noticeModal,
  ...quitModal,
  ...cardModal,
  ...removeMemberModal,
  ...showSaveTip,
  ...customWaveDataModal,
  ...addChannelModal,
};
