export default {
  'Humidity.humidity.title': '计算相对湿度',
  'Humidity.dryTemp': '干球温度(℃)',
  'Humidity.wetTemp': '湿球温度(℃)',
  'Humidity.pressure': '大气压力(Pa)',
  'Humidity.humidity': '相对湿度(%)',
  'Humidity.wetBall.title': '计算湿球温度',
  'Humidity.dryBall.title': '计算干球温度',
  'Humidity.SPressure': '标准大气压(Pa)',
  'Humidity.dT': '干球开尔文温度',
  'Humidity.wT': '湿球开尔文温度',
  'Humidity.Pqb': '饱和水蒸气分压力(Pa)',
  'Humidity.wPqb': '湿球温度下饱和水蒸汽分压力(Pa)',
  'Humidity.Pq': '水蒸汽分压力(Pa)',
  'Humidity.d': '干空气含湿量 (g/kg)',
  'Humidity.i': '干空气焓值 (kj/kg)',
  'Humidity.p': '密度 (kg/m3)',
  'Humidity.v': '比容 (m3/kg)',
  'Humidity.tl': '露点温度(℃)',
};
