export class Msg {
  msgId: string = '';
  content: string = '';
  sendId: string = '';
  time: number = 0;
  atIds: string[] = [];
  subMsgs: Msg[] = [];

  constructor(msg?: DtlBean.Msg) {
    if (!msg) {
      return;
    }
    if (msg.msgId) {
      this.msgId = msg.msgId;
    }
    if (msg.content) {
      this.content = msg.content;
    }
    if (msg.sendId) {
      this.sendId = msg.sendId;
    }
    if (msg.time) {
      this.time = msg.time;
    }
    if (msg.atIds) {
      this.atIds = [...msg.atIds];
    }
    if (msg && msg.subMsgs) {
      this.subMsgs = msg.subMsgs.map((m) => new Msg(m));
    }
  }

  format(): DtlBean.Msg {
    return {
      msgId: this.msgId,
      content: this.content,
      sendId: this.sendId,
      time: this.time,
      atIds: [...this.atIds],
      subMsgs: this.subMsgs.map((m) => m.format()),
    };
  }

  copy() {
    const n = new Msg();
    n.msgId = this.msgId;
    n.content = this.content;
    n.sendId = this.sendId;
    n.time = this.time;
    n.atIds = [...this.atIds];
    n.subMsgs = this.subMsgs.map((m) => m.copy());
    return n;
  }
}
