import {
  dtlTaskFetchMembersInfo,
  dtlTaskUserCreateLabelTemplate,
  dtlTaskUserDelLabelTemplate,
  dtlTaskUserGetLabelTemplates,
  dtlTaskUserUpdateExtInTask,
  dtlTaskUserUpdateRoleInTask,
  dtlTaskUserUpdateStatusInTask,
  dtlTaskUserUpdateTaskLastOpenTime,
  dtlTaskUserUpdateTaskMark,
} from '@/services/datalib/api/taskUser';
import { doErrResponse, doResponse } from '@/services/datalib/ctrl/util';
import { Err } from '@/services/datalib/enum';
import { doInitDtlStatus, dtlStatus, updateDtlVerLog } from '../index';

export async function getLabelTemplates(): Promise<
  DtlComm.Result<DtlTaskUser.CreateLabelTemplateResult | undefined>
> {
  const err = await doInitDtlStatus();
  if (err) return err;
  return {
    code: Err.OK[0],
    data: { labelTemplates: dtlStatus.labelTemplates.format() },
    message: Err.OK[1],
  };
}

export async function getLabelTemplates2(): Promise<
  DtlComm.Result<DtlTaskUser.CreateLabelTemplateResult | undefined>
> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskUserGetLabelTemplates();
  return doResponse(response);
}

export async function updateStatusInTask(
  body: DtlTaskUser.UpdateStatusInTaskParam,
): Promise<DtlComm.Result<DtlTask.TaskResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskUserUpdateStatusInTask(body);
  const { code, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    const task = dtlStatus.getTask(body.taskId);
    if (task) {
      return {
        code: Err.OK[0],
        data: { task: task.format() },
        message: Err.OK[1],
      };
    }
  }
  return doErrResponse(code, message);
}

export async function updateExtInTask(
  body: DtlTaskUser.UpdateExtInTaskParam,
): Promise<DtlComm.Result<DtlTask.TaskResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskUserUpdateExtInTask(body);
  const { code, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    const task = dtlStatus.getTask(body.taskId);
    if (task) {
      return {
        code: Err.OK[0],
        data: { task: task.format() },
        message: Err.OK[1],
      };
    }
  }
  return doErrResponse(code, message);
}

export async function updateRoleInTask(
  body: DtlTaskUser.UpdateRoleInTaskParam,
): Promise<DtlComm.Result<DtlTask.TaskResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskUserUpdateRoleInTask(body);
  const { code, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    const task = dtlStatus.getTask(body.taskId);
    if (task) {
      return {
        code: Err.OK[0],
        data: { task: task.format() },
        message: Err.OK[1],
      };
    }
  }
  return doErrResponse(code, message);
}

export async function createLabelTemplate(
  body: DtlTaskUser.CreateLabelTemplateParam,
): Promise<DtlComm.Result<DtlTaskUser.CreateLabelTemplateResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskUserCreateLabelTemplate(body);
  return doResponse(response);
}

export async function delLabelTemplate(
  body: DtlTaskUser.DelLabelTemplateParam,
): Promise<DtlComm.Result<DtlTaskUser.CreateLabelTemplateResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskUserDelLabelTemplate(body);
  return doResponse(response);
}

export async function updateTaskMark(
  body: DtlTaskUser.UpdateTaskMarkParam,
): Promise<DtlComm.Result<DtlTask.TaskResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskUserUpdateTaskMark(body);
  const { code, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    const task = dtlStatus.getTask(body.taskId);
    if (task) {
      return {
        code: Err.OK[0],
        data: { task: task.format() },
        message: Err.OK[1],
      };
    }
  }
  return doErrResponse(code, message);
}

export async function FetchTaskMembersInfo(
  body: DtlTaskUser.FetchTaskMembersInfoParam,
): Promise<DtlComm.Result<{ members: Record<string, DtlBean.User> } | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskFetchMembersInfo(body);
  const { code, message, data, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    const task = dtlStatus.getTask(body.taskId);
    if (task) {
      task.updateMembers(data?.members, data?.userInfos);
      return {
        code: Err.OK[0],
        data: { members: task.formatMembers() },
        message: Err.OK[1],
      };
    }
  }
  return doErrResponse(code, message);
}

// export async function updateTaskFileLastOpenTime(
//   body: DtlTaskUser.UpdateTaskFileLastOpenTimeParam,
// ): Promise<DtlComm.Result<DtlBean.TaskFile | undefined>> {
//   const err = await doInitDtlStatus();
//   if (err) return err;
//   const response = await dtlTaskUserUpdateTaskFileLastOpenTime(body);
//   const { code, message, verLog } = response;
//   if (code === Err.OK[0]) {
//     if (verLog) {
//       updateDtlVerLog(verLog);
//     }
//     const [task, err] = await doGetTask(body);
//     if (err) return err;
//     return {
//       code: Err.OK[0],
//       data: task.files.existFileById(body.fileId)?.format(),
//       message: Err.OK[1],
//     };
//   }
//   return doErrResponse(code, message);
// }

export async function updateTaskLastOpenTime(
  body: DtlTaskUser.UpdateTaskLastOpenTimeParam,
): Promise<DtlComm.Result<DtlTaskUser.UpdateTaskLastOpenTimeResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskUserUpdateTaskLastOpenTime(body);
  return doResponse(response);
}

// export async function getRecentFile(): Promise<DtlComm.Result<DtlBean.RecentFile[] | undefined>> {
//   const err = await doInitDtlStatus();
//   if (err) return err;
//   const recentFiles = dtlStatus.taskGroupList.getAppRecentFile(dtlStatus.userInfo.userId);
//   const rs: DtlBean.RecentFile[] = [];
//   for (let i = 0; i < recentFiles.length; i++) {
//     const recentFile = recentFiles[i];
//     const taskId = recentFile.taskId;
//     let task = dtlStatus.getTask(taskId);
//     if (!task || task.deleted) continue;
//     if (!task.initialized) {
//       const [task2, err] = await doGetTask({ taskId });
//       if (err) {
//         if (err.code === Err.TASK_NOT_EXIST[0] || err.code === Err.USER_NOT_EXIST_IN_TASK[0]) {
//           continue;
//         }
//         return err;
//       }
//       task = task2;
//     }
//     const taskFile = task.files.existFileById(recentFile.fileId);
//     if (!taskFile || taskFile.type === TaskFileType.TASKFILE_TYPE_DIR) continue;
//     recentFile.file = taskFile.format();
//     recentFile.taskName = task.name;
//     rs.push(recentFile);
//     // if (rs.length >= 6) break;
//   }
//   return {
//     code: Err.OK[0],
//     data: rs,
//     message: Err.OK[1],
//   };
// }
