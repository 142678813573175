export default {
  'enthalpyDiffLab.searchType.placeholder': '搜索通道',
  'enthalpyDiffLab.report.channelId': '通道',
  'enthalpyDiffLab.report.channelName': '通道名称',
  'enthalpyDiffLab.report.customName': '自定义名称',
  'enthalpyDiffLab.report.unit': '单位',
  'enthalpyDiffLab.report.average': '平均值',
  'enthalpyDiffLab.show': '显示',
  'enthalpyDiffLab.cursor': '游标',
  'enthalpyDiffLab.timeDifference': '时间差',
  'enthalpyDiffLab.channelSetting': '通道设置',
};
