import API_VERSION from '@/services/datalib/api/config';
import request from '@/utils/request';

export async function dtlGetInfo() {
  return request<DtlComm.ResultV<DtlLogin.LoginResult>>(`${API_VERSION}/getInfo`, {
    method: 'POST',
  });
}

export async function dtlPing() {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/ping`, {
    method: 'POST',
  });
}

export async function dtlUserGetUserInfo() {
  return request<DtlComm.ResultV<{ userInfo: DtlBean.User }>>(
    `${API_VERSION}/api/user/getUserInfo`,
    {
      method: 'POST',
    },
  );
}

export async function dtlUserUpdateUserInfo(body: DtlUser.UpdateUserInfoParam) {
  return request<DtlComm.ResultV<{ userInfo: DtlBean.User }>>(
    `${API_VERSION}/api/user/updateUserInfo`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlUserUpdateUserBindEmail(body: DtlUser.UpdateUserBindEmailParam) {
  return request<DtlComm.ResultV<{ userInfo: DtlBean.User }>>(
    `${API_VERSION}/api/user/updateBind`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlUserUpdateUserSetRead(body: DtlUser.UpdateUserSetReadParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/user/setRead`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlUserGetUsers(body: { userIds: string[] }) {
  return request<DtlComm.ResultV<DtlBean.User[]>>(`${API_VERSION}/api/user/getUsers`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlUserGetAllUsers() {
  return request<DtlComm.ResultV<DtlBean.User[]>>(`${API_VERSION}/api/user/getAllUsers`, {
    method: 'POST',
    data: {},
  });
}

export async function dtlUserUpdateUserAddUnread(body: DtlUser.UpdateUserAddUnreadParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/user/addUnread`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}
