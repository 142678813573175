import { InTask } from '@/services/datalib/bean/InTask';
import { Gender, HideInfo, IsReg } from '@/services/datalib/enum';

export class User {
  userId: string = '';
  userName: string = '';
  password: string = '';
  phone: string = '';
  email: string = '';
  companyName: string = '';
  departmentName: string = '';
  createTime: number = 0;
  updateTime: number = 0;
  gender: Gender = Gender.USER_GENDER_NONE;
  hideInfo: HideInfo = HideInfo.USER_HIDEINFO_NO;
  avatar: string = '';
  address: string = '';
  isReg: IsReg = IsReg.USER_ISREG_NO;

  remark: string = '';
  group: string = '';
  isFriend: boolean = false;

  inTask?: InTask;

  v: number = 0;

  format(): DtlBean.User {
    let inTask;
    if (this.inTask) {
      inTask = this.inTask.format();
    }
    let pwd = this.password;
    if (pwd) {
      pwd = '********';
    }
    return {
      userId: this.userId,
      userName: this.userName,
      password: pwd,
      phone: this.phone,
      email: this.email,
      companyName: this.companyName,
      departmentName: this.departmentName,
      createTime: this.createTime,
      updateTime: this.updateTime,
      gender: this.gender,
      hideInfo: this.hideInfo,
      avatar: this.avatar,
      address: this.address,
      isReg: this.isReg,
      remark: this.remark,
      group: this.group,
      isFriend: this.isFriend,
      inTask,
      v: this.v,
    };
  }

  constructor(user?: DtlBean.User) {
    if (!user) {
      return;
    }
    if (user.userId) {
      this.userId = user.userId;
    }
    if (user.userName) {
      this.userName = user.userName;
    }
    if (user.password) {
      this.password = '********';
    }
    if (user.phone) {
      this.phone = user.phone;
    }
    if (user.email) {
      this.email = user.email;
    }
    if (user.companyName) {
      this.companyName = user.companyName;
    }
    if (user.departmentName) {
      this.departmentName = user.departmentName;
    }
    if (user.createTime) {
      this.createTime = user.createTime;
    }
    if (user.updateTime) {
      this.updateTime = user.updateTime;
    }
    if (user.gender) {
      this.gender = user.gender;
    }
    if (user.hideInfo) {
      this.hideInfo = user.hideInfo;
    }
    if (user.avatar) {
      this.avatar = user.avatar;
    }
    if (user.address) {
      this.address = user.address;
    }
    if (user.isReg) {
      this.isReg = user.isReg;
    }
    if (user.remark) {
      this.remark = user.remark;
    }
    if (user.group) {
      this.group = user.group;
    }
    if (user.isFriend) {
      this.isFriend = user.isFriend;
    }
    if (user.inTask) {
      this.inTask = new InTask(user.inTask);
    }
    if (user.v) {
      this.v = user.v;
    }
  }

  updatePwd() {
    this.password = '********';
  }

  updateBasic(user: DtlBean.User) {
    this.userId = user.userId;
    this.userName = user.userName;
    if (user.password) {
      this.password = '********';
    }
    this.phone = user.phone;
    this.email = user.email;
    this.companyName = user.companyName;
    this.departmentName = user.departmentName;
    this.createTime = user.createTime;
    this.updateTime = user.updateTime;
    this.gender = user.gender;
    this.hideInfo = user.hideInfo;
    this.avatar = user.avatar;
    this.address = user.address;
    this.isReg = user.isReg;
    this.v = user.v;
  }

  updateReg(isReg: IsReg) {
    this.isReg = isReg;
  }

  updateRemark(remark: string) {
    this.remark = remark;
  }

  updateFromContact(user: User) {
    this.updateBasic(user);
    this.remark = user.remark;
    this.group = user.group;
    this.isFriend = user.isFriend;
  }
}
