export default {
  'grr.table.experiment': '实验信息',
  'grr.table.dataInfo': '数据信息',
  'grr.table.grrData': 'GRR数据',
  'grr.table.emptyText': '暂无数据',
  'grr.table.noGaugeId': '无GaugeId',
  'grr.chartTab.title': '图表',
  'grr.resultData.title': '结果数据',
  'grr.table.calculation': 'GRR 计算方法',
  'grr.tol.title': '计算设置',
  'grr.tol.zero': '零默认法',
  'grr.tol.zero.desc1': '缺少的Limit，默认为零',
  'grr.tol.zero.desc2': 'Tolerance = 现有Limit - 0',
  'grr.tol.average': '均值调整法',
  'grr.tol.average.desc1': '缺少的Limit，曲线均值代替，并调整',
  'grr.tol.average.desc2': 'Tolerance = (现有Limit - 曲线均值) * 2',
};
