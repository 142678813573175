import {
  dtlUserAddCollectFolder,
  dtlUserFileRecordAdd,
  dtlUserFileRecordDelete,
} from '@/services/datalib/api/fileRecord';
import { TaskFile } from '@/services/datalib/bean/TaskFile';
import { Err, FileRecordStatus, RecentOrCollect } from '@/services/datalib/enum';
import { doErrResponse, doInitDtlStatus, doResponse, dtlStatus, updateDtlVerLog } from '../index';

/*
 * 新增最近打开文件，或新增收藏
 * */
export async function userFileRecordAdd(
  body: DtlFileRecord.AddParam,
): Promise<DtlComm.Result<DtlFileRecord.AddResult | undefined>> {
  const response = await dtlUserFileRecordAdd(body);
  const { code, data, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    return {
      code: Err.OK[0],
      data: {
        file: new TaskFile().fromBean(data?.file).format(),
        fileRecord: dtlStatus.fileRecords
          .getFileRecord(body.recentOrCollect, data?.fileRecord?.fid)
          ?.format(),
      },
      message: Err.OK[1],
    };
  }
  return doErrResponse(code, message);
}

/*
 * 移除最近打开文件，或取消收藏
 * */
export async function userFileRecordDelete(
  body: DtlFileRecord.DeleteParam,
): Promise<DtlComm.Result<undefined>> {
  const response = await dtlUserFileRecordDelete(body);
  return doResponse(response);
}

/*
 * 获取最近文件列表
 * */
export async function userGetRecentFiles(): Promise<
  DtlComm.Result<DtlBean.FileRecord[] | undefined>
> {
  const err = await doInitDtlStatus();
  if (err) return err;
  return {
    code: Err.OK[0],
    data: dtlStatus.fileRecords.recentFiles
      .filter((f) => f.s === FileRecordStatus.Normal)
      .map((f) => f.format()),
    message: Err.OK[1],
  };
}

/*
 * 获取收藏夹
 * */
export async function userGetCollectFiles(): Promise<
  DtlComm.Result<DtlBean.CollectFolder[] | undefined>
> {
  const err = await doInitDtlStatus();
  if (err) return err;
  return {
    code: Err.OK[0],
    data: dtlStatus.collectFolders.format(),
    message: Err.OK[1],
  };
}

/*
 * 添加收藏夹
 * */
export async function userAddCollectFolder(body: {
  folderName: string;
}): Promise<DtlComm.Result<DtlBean.CollectFolder[] | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlUserAddCollectFolder(body);
  const { code, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    return {
      code: Err.OK[0],
      data: dtlStatus.collectFolders.format(),
      message: Err.OK[1],
    };
  }
  return doErrResponse(code, message);
}

/*
 * 获取文件的收藏信息
 * */
export async function userGetCollectFileRecord(body: {
  fileId: string;
}): Promise<DtlComm.Result<DtlBean.FileRecord | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  return {
    code: Err.OK[0],
    data: dtlStatus.fileRecords.getFileRecord(RecentOrCollect.CollectFiles, body.fileId)?.format(),
    message: Err.OK[1],
  };
}
