export default {
  'Refrigerant.select.title': '冷媒信息',
  'Refrigerant.searchPlaceholder': '搜索冷媒',
  'Refrigerant.info.title': '基本信息',
  'Refrigerant.info.CAS': 'CAS编号',
  'Refrigerant.info.chineseName': '中文名称',
  'Refrigerant.info.shortName': '简称',
  'Refrigerant.info.sortName': '分类名称',
  'Refrigerant.info.sort': '分类',
  'Refrigerant.info.englishShortName': '英文简称',
  'Refrigerant.info.englishName': '英文全称',
  'Refrigerant.info.chemicalFormula': '化学分子式',
  'Refrigerant.info.molecularWeight': '分子量',
  'Refrigerant.info.otherName': '别名',
  'Refrigerant.info.mol': '摩尔质量',
  'Refrigerant.info.threePhasePointHumidity': '三相点湿度',
  'Refrigerant.info.normalBoilingPoint': '标准沸点',
  'Refrigerant.info.CRH': '临界湿度',
  'Refrigerant.info.criticalPressure': '临界压力',
  'Refrigerant.info.criticalDensity': '临界密度',
  'Refrigerant.info.eccentricityFactor': '偏心因子',
  'Refrigerant.info.dipole': '偶极矩',
  'Refrigerant.point.tab': '点值查询',
  'Refrigerant.range.tab': '范围查询',
  'Refrigerant.temp.name': '温度',
  'Refrigerant.temp.title': '温度(℃)',
  'Refrigerant.pressure.name': '压力',
  'Refrigerant.pressure.title': '压力(Mpa(绝压))',
  'Refrigerant.density.name': '密度',
  'Refrigerant.density.title': '密度 (kg/m³)',
  'Refrigerant.enthalpy.name': '比焓',
  'Refrigerant.enthalpy.title': '比焓 (kJ/kg)',
  'Refrigerant.entropy.name': '比熵',
  'Refrigerant.entropy.title': '比熵 (kJ/(kg K)',
  'Refrigerant.energy.name': '内能',
  'Refrigerant.energy.title': '内能 (kJ/kg)',
  'Refrigerant.range.step': '步数',
  'Refrigerant.range.upper': '上限',
  'Refrigerant.range.lower': '下限',
};
