import t from '@/locales';
import { ConvPrograms } from '@/services/datalib/bean/ConvPrograms';
import { OpenType } from '@/services/datalib/bean/OpenType';
import { getPathExtension } from '@/services/datalib/bean/util';
import { ExtType, TaskFileType } from '@/services/datalib/enum';

export class OpenTypes {
  list: OpenType[] = [];
  convPrograms: ConvPrograms = new ConvPrograms();

  updateList(T?: DtlBean.ConvProgram[]) {
    if (T) {
      this.convPrograms = new ConvPrograms(T);
    }
    const wavProgram = this.convPrograms.getConvProgram('wav');
    if (wavProgram) {
      this.list.push(
        new OpenType({
          sid: TaskFileType.TASKFIEL_TYPE_WAV,
          name: t('openWayModal.wav.name', '波形预览'),
          desc: t('openWayModal.wav.desc', '支持进行波形预览'),
          programName: wavProgram.name,
          program: wavProgram.copy(),
          asProgram: true,
        }),
      );
    }

    // const excelProgram = this.convPrograms.getConvProgram('excel');
    // if (excelProgram) {
    //   const programCpShow = excelProgram.copy();
    //   const idx = programCpShow.plugins.findIndex((p) => p.id === 'excel002');
    //   if (idx > -1) {
    //     programCpShow.plugins.splice(idx, 1);
    //   }
    //   this.list.push(
    //     new OpenType({
    //       sid: TaskFileType.TASKFIEL_TYPE_EXCEL,
    //       name: '二维数据预览',
    //       desc: '支持二维数据查看和预览',
    //       programName: programCpShow.name,
    //       program: programCpShow,
    //       asProgram: false,
    //     }),
    //   );
    // }

    this.list.push(
      new OpenType({
        sid: TaskFileType.TASKFIEL_TYPE_OFFICE,
        name: t('openWayModal.office.name', 'Office文档预览'),
        desc: t('openWayModal.office.desc', '支持对Office文档进行预览'),
        programName: '',
        program: undefined,
        asProgram: true,
      }),
      new OpenType({
        sid: TaskFileType.TASKFIEL_TYPE_IMG,
        name: t('openWayModal.img.name', '图片预览'),
        desc: t('openWayModal.img.desc', '支持图片查看和预览'),
        programName: '',
        program: undefined,
        asProgram: true,
      }),
      new OpenType({
        sid: TaskFileType.TASKFIEL_TYPE_PDF,
        name: t('openWayModal.pdf.name', 'PDF预览'),
        desc: t('openWayModal.pdf.desc', '支持PDF查看和预览'),
        programName: '',
        program: undefined,
        asProgram: true,
      }),
      new OpenType({
        sid: TaskFileType.TASKFIEL_TYPE_VIDEO,
        name: t('openWayModal.video.name', '视频预览'),
        desc: t('openWayModal.video.desc', '支持视频查看和预览'),
        programName: '',
        program: undefined,
        asProgram: true,
      }),
      new OpenType({
        sid: TaskFileType.TASKFIEL_TYPE_AUDIO,
        name: t('openWayModal.audio.name', '音频预览'),
        desc: t('openWayModal.audio.desc', '支持音频查看和预览'),
        programName: '',
        program: undefined,
        asProgram: true,
      }),
      new OpenType({
        sid: TaskFileType.TASKFIEL_TYPE_Dsp_Common,
        name: '波形查看',
        desc: '支持进行波形预览',
        programName: '',
        program: undefined,
        asProgram: false,
      }),
      new OpenType({
        sid: TaskFileType.TASKFIEL_TYPE_Cpb,
        name: t('openWayModal.cpb.name', 'CPB分析'),
        desc: t('openWayModal.cpb.desc', '支持对音频文件进行CPB分析'),
        programName: '',
        program: undefined,
        asProgram: true,
      }),
      new OpenType({
        sid: TaskFileType.TASKFIEL_TYPE_Dsp,
        name: 'Audio Editor',
        desc: t('openWayModal.dsp.desc', '支持对音频文件进行加工、处理'),
        programName: '',
        program: undefined,
        asProgram: true,
      }),
      new OpenType({
        sid: TaskFileType.TASKFIEL_TYPE_Grr,
        name: t('openWayModal.grr.name', 'GRR分析'),
        desc: t('openWayModal.grr.desc', '支持对表格文件进行GRR分析'),
        programName: '',
        program: undefined,
        asProgram: true,
      }),
      new OpenType({
        sid: TaskFileType.TASKFIEL_TYPE_Dapt,
        name: 'Audio Graph',
        desc: t('openWayModal.dapt.desc', '支持对表格和Dat文件进行Audio Graph分析'),
        programName: '',
        program: undefined,
        asProgram: true,
      }),
      new OpenType({
        sid: TaskFileType.TASKFIEL_TYPE_Correlation,
        name: 'Correlation',
        desc: t('openWayModal.correlation.desc', '支持对多文件进行Correlation分析'),
        programName: '',
        program: undefined,
        asProgram: true,
      }),
      new OpenType({
        sid: TaskFileType.TASKFIEL_TYPE_PRM_SCIC,
        name: t('openWayModal.scic.name', '科学计算'),
        desc: t('openWayModal.scic.desc', '支持对表格文件进行科学计算'),
        programName: '',
        program: undefined,
        asProgram: true,
      }),
      new OpenType({
        sid: TaskFileType.TASKFIEL_TYPE_PRM_SPC,
        name: t('openWayModal.spc.name', '多峰值判断'),
        desc: t('openWayModal.spc.desc', '支持对表格文件进行多峰值判断'),
        programName: '',
        program: undefined,
        asProgram: true,
      }),
      new OpenType({
        sid: TaskFileType.TASKFIEL_TYPE_PRM_IMG,
        name: '',
        desc: '',
        programName: '',
        program: undefined,
        asProgram: false,
      }),
      new OpenType({
        sid: TaskFileType.TASKFIEL_TYPE_PRM_PDF,
        name: '',
        desc: '',
        programName: '',
        program: undefined,
        asProgram: false,
      }),
      new OpenType({
        sid: TaskFileType.TASKFIEL_TYPE_PRM_Enthalpy,
        name: '',
        desc: '',
        programName: '',
        program: undefined,
        asProgram: false,
      }),
      new OpenType({
        sid: TaskFileType.TASKFIEL_TYPE_PRM_Liquid,
        name: '',
        desc: '',
        programName: '',
        program: undefined,
        asProgram: false,
      }),
      new OpenType({
        sid: TaskFileType.TASKFIEL_TYPE_PRM_Season,
        name: '',
        desc: '',
        programName: '',
        program: undefined,
        asProgram: false,
      }),
      new OpenType({
        sid: TaskFileType.TASKFIEL_TYPE_PRM_Refrigerant,
        name: '',
        desc: '',
        programName: '',
        program: undefined,
        asProgram: false,
      }),
      new OpenType({
        sid: TaskFileType.TASKFIEL_TYPE_PRM_Humidity,
        name: '',
        desc: '',
        programName: '',
        program: undefined,
        asProgram: false,
      }),
      new OpenType({
        sid: TaskFileType.TASKFIEL_TYPE_PRM_EnthalpyDiffLab,
        name: '',
        desc: '',
        programName: '',
        program: undefined,
        asProgram: false,
      }),
      new OpenType({
        sid: TaskFileType.TASKFIEL_TYPE_PRM_StrainRawData,
        name: '',
        desc: '',
        programName: '',
        program: undefined,
        asProgram: false,
      }),
      new OpenType({
        sid: TaskFileType.TASKFIEL_TYPE_PRM_StrainTimeHistory,
        name: '',
        desc: '',
        programName: '',
        program: undefined,
        asProgram: false,
      }),
    );
  }

  constructor(t?: DtlBean.ConvProgram[]) {
    this.updateList(t);
  }

  copy(): OpenTypes {
    return new OpenTypes(this.convPrograms.format());
  }

  format(): DtlBean.OpenType[] {
    return this.list.map((m) => m.format());
  }

  formatShowList(): DtlBean.OpenType[] {
    return this.list.filter((l) => l.asProgram).map((m) => m.format());
  }

  formatWavShowList(): DtlBean.OpenType | undefined {
    return this.list.find((l) => l.asProgram && l.sid === TaskFileType.TASKFIEL_TYPE_WAV)?.format();
  }

  getSupportType(sid: number): OpenType | undefined {
    const idx = this.list.findIndex((s) => s.sid === sid);
    if (idx > -1) {
      return this.list[idx];
    }
    return undefined;
  }

  getByProgram(program: string): OpenType | undefined {
    if (!program) return undefined;
    const idx = this.list.findIndex((s) => program.indexOf(s.programName) === 0);
    if (idx > -1) {
      return this.list[idx];
    }
    return undefined;
  }

  getBySid(sid: number): OpenType | undefined {
    if (!sid) return undefined;
    const idx = this.list.findIndex((s) => s.sid === sid);
    if (idx > -1) {
      return this.list[idx];
    }
    return undefined;
  }

  getDefaultType(path: string, fileType: number): OpenType | undefined {
    let openType = undefined;
    if (fileType) {
      if (
        fileType == TaskFileType.TASKFIEL_TYPE_EXCEL ||
        fileType == TaskFileType.TASKFIEL_TYPE_PPT ||
        fileType == TaskFileType.TASKFIEL_TYPE_PPT
      ) {
        openType = this.getSupportType(TaskFileType.TASKFIEL_TYPE_OFFICE);
      } else {
        openType = this.getSupportType(fileType);
      }
    }
    const ext = getPathExtension(path);
    if (!openType && ExtType[TaskFileType.TASKFIEL_TYPE_IMG].indexOf(ext) > -1) {
      openType = this.getSupportType(TaskFileType.TASKFIEL_TYPE_IMG);
    }
    if (!openType && ExtType[TaskFileType.TASKFIEL_TYPE_PDF].indexOf(ext) > -1) {
      openType = this.getSupportType(TaskFileType.TASKFIEL_TYPE_PDF);
    }
    if (!openType && ExtType[TaskFileType.TASKFIEL_TYPE_VIDEO].indexOf(ext) > -1) {
      openType = this.getSupportType(TaskFileType.TASKFIEL_TYPE_VIDEO);
    }
    if (!openType && ExtType[TaskFileType.TASKFIEL_TYPE_AUDIO].indexOf(ext) > -1) {
      openType = this.getSupportType(TaskFileType.TASKFIEL_TYPE_AUDIO);
    }
    if (!openType && ExtType[TaskFileType.TASKFIEL_TYPE_WAV].indexOf(ext) > -1) {
      openType = this.getSupportType(TaskFileType.TASKFIEL_TYPE_WAV);
    }
    if (!openType && ExtType[TaskFileType.TASKFIEL_TYPE_Correlation].indexOf(ext) > -1) {
      openType = this.getSupportType(TaskFileType.TASKFIEL_TYPE_Correlation);
    }
    if (!openType && ExtType[TaskFileType.TASKFILE_TYPE_DAT].indexOf(ext) > -1) {
      openType = this.getSupportType(TaskFileType.TASKFIEL_TYPE_Dapt);
    }
    if (
      !openType &&
      (ExtType[TaskFileType.TASKFIEL_TYPE_EXCEL].indexOf(ext) > -1 ||
        ExtType[TaskFileType.TASKFIEL_TYPE_PPT].indexOf(ext) > -1 ||
        ExtType[TaskFileType.TASKFIEL_TYPE_WORD].indexOf(ext) > -1)
    ) {
      openType = this.getSupportType(TaskFileType.TASKFIEL_TYPE_OFFICE);
    }
    return openType;
  }
}
