import { Log } from '@/services/datalib/bean/Log';

export class VerLog {
  version: number = 0;
  start: number = 0;
  logs: Log[] = [];

  constructor(t?: DtlBean.VerLog) {
    if (!t) {
      return;
    }
    if (t.version) {
      this.version = t.version;
    }
    if (t.start) {
      this.start = t.start;
    }
    if (t.logs) {
      this.logs = t.logs.map((l) => new Log(l));
    }
  }
}
