import { categoryGet, getObjectUrlMustRelease, toAlpFile } from '@/services/datalib';
import { makeSureMDFExtension, newName } from '@/services/datalib/bean/util';
import { Err } from '@/services/datalib/enum';
import JsZip from 'jszip';
// @ts-ignore
import { saveAs } from 'file-saver';
// @ts-ignore
import JsZipUtils from 'jszip-utils';

export enum EMdfType {
  TYPE_RESOURCE = 'TYPE_RESOURCE',
  TYPE_CATEGORY = 'TYPE_CATEGORY',
  TYPE_META = 'TYPE_META',
  TYPE_CORRELATION = 'TYPE_CORRELATION',
  TYPE_GRR = 'TYPE_GRR',
  TYPE_DAPT = 'TYPE_DAPT',
  TYPE_DSP = 'TYPE_DSP',
  TYPE_DSP_COMMON = 'TYPE_DSP_COMMON',
  TYPE_CPB = 'TYPE_CPB',
  TYPE_IMAGE = 'TYPE_IMAGE',
  TYPE_PDF = 'TYPE_PDF',
  TYPE_ENTHALPY = 'TYPE_ENTHALPY',
  TYPE_LIQUID = 'TYPE_LIQUID',
  TYPE_SEASON = 'TYPE_SEASON',
  TYPE_REFRIGERANT = 'TYPE_REFRIGERANT',
  TYPE_HUMIDITY = 'TYPE_HUMIDITY',
  TYPE_SCIC = 'TYPE_SCIC',
  TYPE_ENTHALPY_DIFFERENCE_LAB = 'TYPE_ENTHALPY_DIFFERENCE_LAB',
  TYPE_STRAIN_RAWDATA = 'TYPE_STRAIN_RAWDATA',
  TYPE_STRAIN_TIMEHISTORY = 'TYPE_STRAIN_TIMEHISTORY',
  TYPE_SPC = 'TYPE_SPC',
}

function formatType(type: EMdfType | string): string {
  if (type && type.indexOf('PRM_') === 0) {
    const t = `TYPE_${type.substring(4)}`;
    if (EMdfType[t]) {
      return t;
    }
  }
  return type;
}

export interface IResource {
  id: string;
  name: string;
  url?: string;
  conv?: any;
  size?: number;
  type?: number;
}

export interface IMDF {
  type: string;
  content: string;
  ext?: string;
  child?: IMDF[];
}

export class MDF implements IMDF {
  type: string = '';
  content: string = '';
  ext?: string = '';
  child?: MDF[];

  constructor(mdf?: IMDF) {
    if (!mdf) {
      return;
    }
    this.type = formatType(mdf.type);
    this.content = mdf.content;
    this.ext = mdf.ext;
    this.child = mdf.child?.map((c) => new MDF(c));
  }

  static NewInstanceFromStr(fileContentStr: string): MDF {
    let mdf: IMDF | undefined = undefined;
    try {
      const o = JSON.parse(fileContentStr);
      mdf = o['mdf'] || o['prm'];
    } catch (e) {
      return new MDF();
    }
    if (!mdf) {
      return new MDF();
    }
    return new MDF(mdf);
  }

  static NewInstanceFromArgs(type: string, content: string, ext?: string, child?: MDF[]): MDF {
    return new MDF({ type, content, ext, child });
  }

  static NewInstanceFromObject(mdf?: IMDF) {
    return new MDF(mdf);
  }

  getContent(): string {
    return this.content;
  }

  getExt(): string | undefined {
    return this.ext;
  }

  string(): string {
    return JSON.stringify(this);
  }

  addChild(mdf: IMDF): MDF {
    if (!this.child) {
      this.child = [];
    }
    const instance = new MDF(mdf);
    this.child.push(instance);
    return instance;
  }

  getChild(keys: string[]): MDF | undefined {
    if (!keys) {
      return this;
    }
    if (!this.child || this.child.length === 0) {
      return undefined;
    }
    let mdf: MDF | undefined = this;
    for (let i = 0; i < keys.length; i++) {
      const k = keys[i];
      const idx: any | number = mdf?.child?.findIndex((c) => c.type === k);
      if (idx !== undefined && idx > -1) {
        mdf = mdf?.child?.[idx];
      } else {
        return undefined;
      }
    }
    return mdf;
  }
}

export function MdfInstance(
  type: EMdfType,
  content: string,
  resources: IResource[],
  category?: string,
  meta?: string,
): MDF {
  const mdf = new MDF({ type: type, content: content });
  const res = mdf.addChild({ type: EMdfType.TYPE_RESOURCE, content: '' });
  resources.forEach((r) => {
    res.addChild({ type: r.id, content: JSON.stringify(r) });
  });
  if (category) {
    mdf.addChild({ type: EMdfType.TYPE_CATEGORY, content: category });
  }
  if (meta) {
    mdf.addChild({ type: EMdfType.TYPE_META, content: meta });
  }
  return mdf;
}

export function MdfParse(
  fileContentStr: string,
):
  | { type: string; content: string; resources: IResource[]; category?: string; meta?: any }
  | undefined {
  const mdf = MDF.NewInstanceFromStr(fileContentStr);
  const type = mdf.type;
  const content = mdf.content;
  const resources: IResource[] = [];
  const category = mdf.getChild([EMdfType.TYPE_CATEGORY])?.content;
  const meta = mdf.getChild([EMdfType.TYPE_META])?.content;
  mdf.getChild([EMdfType.TYPE_RESOURCE])?.child?.forEach((c) => {
    let r: IResource;
    try {
      r = JSON.parse(c.content) as IResource;
    } catch (e) {
      return;
    }
    resources.push(r);
  });
  if (!type) {
    return undefined;
  }
  let o = null;
  try {
    if (meta) {
      o = JSON.parse(meta);
    }
  } catch (err) {}
  return { type, content, resources, category, meta: o };
}

export function MdfStringify(
  srcStr: string,
  type: EMdfType,
  content: string,
  resources: IResource[],
  category?: string,
  meta?: any,
): string {
  const mdf = MdfInstance(type, content, resources, category, JSON.stringify(meta || null));
  try {
    let obj = JSON.parse(srcStr);
    if (!(obj instanceof Array)) {
      if (obj && obj['prm']) {
        delete obj['prm'];
      }
      return JSON.stringify(Object.assign({}, obj, { mdf }));
    }
  } catch (e) {}
  return JSON.stringify({ mdf });
}

export async function createMdfBinary(
  fileContentStr: string,
  withCategory: boolean,
): Promise<DtlComm.Result<any>> {
  const zip = new JsZip();

  const mdfInfo = MdfParse(fileContentStr);
  if (!mdfInfo) {
    return { code: Err.REQUEST_DATA_ERROR[0], data: undefined, message: Err.REQUEST_DATA_ERROR[1] };
  }
  let { type, content, resources, category, meta } = mdfInfo;
  if (!withCategory) {
    category = '';
  }
  const existName: Record<string, string> = { app: 'app', category: 'category' };
  const promises: Promise<any>[] = [];
  if (category) {
    promises.push(
      new Promise(async (rs, rj) => {
        const result = await categoryGet({ id: category as string });
        if (result.code === Err.OK[0]) {
          const buf = await toAlpFile(JSON.stringify(result.data), [
            JSON.stringify({ type: 'category' }),
          ]);
          zip.file('category', buf);
        } else {
          console.log('get category err: ', result.message);
        }
        rs(true);
      }),
    );
  }
  resources.forEach((r) => {
    let name = r.name;
    let num = 1;
    while (existName[name]) {
      name = newName(r.name, num, false);
      num++;
    }
    existName[name] = r.name;
    r.name = name;
  });
  promises.push(
    ...resources.map((r) => {
      return getObjectUrlMustRelease(r.url || '')
        .then((objectUrl) => {
          return JsZipUtils.getBinaryContent(objectUrl)
            .then((data: any) => zip.file(r.name, data, { binary: true }))
            .catch((e: any) => console.warn('JsZipUtils getBinaryContent err: ', objectUrl, e))
            .then(() => objectUrl && URL.revokeObjectURL(objectUrl));
        })
        .catch((e: any) => console.warn('getObjectUrlMustRelease err: ', r.url, e));
    }),
  );

  const newResources = resources.map((r) => ({ id: r.id, name: r.name }));
  if (category) {
    newResources.push({ id: category, name: 'category' });
  }
  promises.push(
    new Promise((rs, rj) => {
      zip.file(
        'app',
        MdfStringify(fileContentStr, EMdfType[type], content, newResources, category, meta),
      );
      rs(true);
    }),
  );
  try {
    await Promise.all(promises);
    const content = await zip.generateAsync({
      type: 'blob',
      compression: 'DEFLATE',
      compressionOptions: { level: 3 },
    });
    return { code: Err.OK[0], data: content, message: Err.OK[1] };
  } catch (e) {
    return { code: Err.SERVER_ERROR[0], data: undefined, message: Err.SERVER_ERROR[1] };
  }
}

export async function MdfDownload(
  fileContentStr: string,
  mdfName: string,
  withCategory: boolean = true,
) {
  const rs = await createMdfBinary(fileContentStr, withCategory);
  if (rs.code === Err.OK[0]) {
    saveAs(rs.data, makeSureMDFExtension(mdfName, 'mdf'));
    return { code: Err.OK[0], data: undefined, message: Err.OK[1] };
  }
  return { code: Err.SERVER_ERROR[0], data: undefined, message: Err.SERVER_ERROR[1] };
}

// const Correlation_demo = {
//   type: 'TYPE_CORRELATION',
//   content: 'cor文件的id',
//   child: [
//     {
//       type: 'TYPE_RESOURCE',
//       content: '',
//       child: [
//         {
//           type: 'cor文件的id',
//           content: JSON.stringify({ id: 'cor文件的id', url: '', name: '' }),
//         },
//         {
//           type: 'L1.csv文件的id',
//           content: JSON.stringify({ id: 'L1.csv文件的id', url: '', name: '' }),
//         },
//         {
//           type: 'L2.csv文件的id',
//           content: JSON.stringify({ id: 'L2.csv文件的id', url: '', name: '' }),
//         },
//         {
//           type: 'L3.csv文件的id',
//           content: JSON.stringify({ id: 'L3.csv文件的id', url: '', name: '' }),
//         },
//         {
//           type: 'L4.csv文件的id',
//           content: JSON.stringify({ id: 'L4.csv文件的id', url: '', name: '' }),
//         },
//       ],
//     },
//   ],
// };
