import { Dept } from '@/services/datalib/bean/Dept';
import { DeptUser } from '@/services/datalib/bean/DeptUser';

export class Enterprise {
  id: string = '';
  name: string = '';
  logo: string = '';
  creatorName: string = '';
  creatorTs: number = 0;
  creatorUid: string = '';
  rootDept: Dept = new Dept();
  users: Record<string, DeptUser> = {};
  handOverIds: string[] = [];
  dismiss?: { ts: number; reason: string };

  constructor(t?: DtlBean.Enterprise) {
    if (!t) {
      return;
    }
    if (t.id) {
      this.id = t.id;
    }
    if (t.name) {
      this.name = t.name;
    }
    if (t.logo) {
      this.logo = t.logo;
    }
    if (t.creatorName) {
      this.creatorName = t.creatorName;
    }
    if (t.creatorTs) {
      this.creatorTs = t.creatorTs;
    }
    if (t.creatorUid) {
      this.creatorUid = t.creatorUid;
    }
    if (t.rootDept) {
      this.rootDept = new Dept(t.rootDept);
    }
    if (t.users) {
      Object.keys(t.users).forEach((k) => {
        this.users[k] = new DeptUser(t.users[k]);
      });
    }
    if (t.handOverIds) {
      this.handOverIds = [...t.handOverIds];
    }
    if (t.dismiss) {
      this.dismiss = { ...t.dismiss };
    }
  }

  format(): DtlBean.Enterprise {
    const users: Record<string, DtlBean.DeptUser> = {};
    Object.keys(this.users).forEach((userId) => {
      users[userId] = this.users[userId].format();
    });
    return {
      id: this.id,
      name: this.name,
      logo: this.logo,
      creatorName: this.creatorName,
      creatorTs: this.creatorTs,
      creatorUid: this.creatorUid,
      rootDept: this.rootDept.format(),
      users: users,
      handOverIds: [...this.handOverIds],
      dismiss: this.dismiss ? { ...this.dismiss } : undefined,
    };
  }
}
