export class Conv {
  pid: string = '';
  id: string = '';
  userId: string = '';
  program: string = '';
  originP: string = '';
  data: string = '';
  result: string = '';
  oldResult: string = '';
  ts: number = 0;

  constructor(t?: DtlBean.Conv) {
    if (!t) return;

    if (t.pid) {
      this.pid = t.pid;
    }
    if (t.id) {
      this.id = t.id;
    }
    if (t.userId) {
      this.userId = t.userId;
    }
    if (t.program) {
      this.program = t.program;
    }
    if (t.originP) {
      this.originP = t.originP;
    }
    if (t.data) {
      this.data = t.data;
    }
    if (t.result) {
      this.result = t.result;
    }
    if (t.oldResult) {
      this.oldResult = t.oldResult;
    }
    if (t.ts) {
      this.ts = t.ts;
    }
  }

  copy(): Conv {
    return new Conv(this.format());
  }

  format(): DtlBean.Conv {
    return {
      pid: this.pid,
      id: this.id,
      data: this.data,
      oldResult: this.oldResult,
      program: this.program,
      originP: this.originP,
      result: this.result,
      ts: this.ts,
      userId: this.userId,
    };
  }
}
