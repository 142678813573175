import API_VERSION from '@/services/datalib/api/config';
import request from '@/utils/request';

export async function dtlUserFileRecordAdd(body: DtlFileRecord.AddParam) {
  return request<DtlComm.ResultV<DtlFileRecord.AddResult>>(
    `${API_VERSION}/api/user/fileRecord/add`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlUserFileRecordDelete(body: DtlFileRecord.DeleteParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/user/fileRecord/delete`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlUserAddCollectFolder(body: { folderName: string }) {
  return request<DtlComm.ResultV<string[]>>(`${API_VERSION}/api/user/fileRecord/addCollectFolder`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}
