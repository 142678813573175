import { TaskMark, TaskStatus } from '@/services/datalib/enum';

export class InTask {
  userId: string = '';
  taskStatus: TaskStatus = TaskStatus.TASKSTATUS_WAIT_START;
  lastModifyTime: number = 0;
  joinTime: number = 0;
  inviter: string = '';
  confirmor: string = '';
  deletor: string = '';
  mark: TaskMark = TaskMark.TASKMARK_NONE;
  ext: string = '';
  fileLots: string[] = [];
  taskLot: number = 0;

  constructor(inTask?: DtlBean.InTask) {
    if (!inTask) {
      return;
    }
    if (inTask.userId) {
      this.userId = inTask.userId;
    }
    if (inTask.taskStatus) {
      this.taskStatus = inTask.taskStatus;
    }
    if (inTask.lastModifyTime) {
      this.lastModifyTime = inTask.lastModifyTime;
    }
    if (inTask.joinTime) {
      this.joinTime = inTask.joinTime;
    }
    if (inTask.inviter) {
      this.inviter = inTask.inviter;
    }
    if (inTask.confirmor) {
      this.confirmor = inTask.confirmor;
    }
    if (inTask.deletor) {
      this.deletor = inTask.deletor;
    }
    if (inTask.mark) {
      this.mark = inTask.mark;
    }
    if (inTask.ext) {
      this.ext = inTask.ext;
    }
    if (inTask.fileLots) {
      this.fileLots = [...inTask.fileLots];
    }
    if (inTask.taskLot) {
      this.taskLot = inTask.taskLot;
    }
  }

  format(): DtlBean.InTask {
    return {
      userId: this.userId,
      taskStatus: this.taskStatus,
      lastModifyTime: this.lastModifyTime,
      joinTime: this.joinTime,
      inviter: this.inviter,
      confirmor: this.confirmor,
      deletor: this.deletor,
      mark: this.mark,
      ext: this.ext,
      fileLots: [...this.fileLots],
      taskLot: this.taskLot,
    };
  }
}
