import { toUtf8 } from '@aws-sdk/util-utf8-browser';

class Curve {
  // v2 + v3 + v6
  clusterType: string = 'Data';
  version: number = NaN;
  dimensions: number = NaN;
  curveName: string = '';
  reserved: number = NaN;
  curveName2: string = '';
  xyzCnt: number = NaN;
  xArr: number[] = [];
  yArr: number[] = [];
  zArr: number[] = [];
  xFormat: number = NaN; //0=dB, 1=linear
  yFormat: number = NaN; //0=dB, 1=linear
  zFormat: number = NaN; //0=dB, 1=linear
  xAxisFormat: number = NaN; //0=log, 1=linear
  yAxisFormat: number = NaN; //0=log, 1=linear
  zAxisFormat: number = NaN; //0=log, 1=linear

  //v2 + v3
  xPrefix: string = '';
  yPrefix: string = '';
  zPrefix: string = '';

  // v2 + v3 + v6
  xUnit: string = '';
  yUnit: string = '';
  zUnit: string = '';
  xDbRef: number = NaN;
  yDbRef: number = NaN;
  zDbRef: number = NaN;
  singleValueFlag: number = NaN; //0=normal curve, 1=single value

  //v3 + v6
  protectedFlag: number = NaN; //0=unprotected, 1=protected
  displayXFlag: number = NaN; //0=do not display, 1=display
  displayYFlag: number = NaN; //0=do not display, 1=display
  displayZFlag: number = NaN; //0=do not display, 1=display
  plotColor: number = NaN; //RGBa

  //v6
  plotInterpolation: number = NaN; //0~5
  plotPointStyle: number = NaN; //0~16
  plotLineStyle: number = NaN; //0-4
  plotPointColor: number = NaN; //RGBa
  plotLineWidth: number = NaN; //0-5
  plotBarPlotStyle: number = NaN; //0-10
  fillBaseline: number = NaN; //-1~32767
  testInfo: string = '';
}

export async function readFromSoundCheckDat(file: any): Promise<Curve[]> {
  const isLittleEndian = false;
  const view = getAsDataView(file);
  const buffer = view.buffer;
  const curves: Curve[] = [];
  let outerOffset = 0;
  const curvesCnt = view.getUint32(0, isLittleEndian);
  outerOffset += 4;
  for (let cix = 0; cix < curvesCnt; cix++) {
    const allBytes = view.getUint32(outerOffset, isLittleEndian);
    outerOffset += 4;
    let offset = outerOffset;
    outerOffset += allBytes;

    const curve = new Curve();
    curves.push(curve);

    curve.clusterType = toUtf8(new Uint8Array(buffer.slice(offset, offset + 16)));
    offset += 16;
    curve.version = view.getUint16(offset, isLittleEndian);
    offset += 2;
    curve.dimensions = view.getUint8(offset);
    offset += 1;
    curve.curveName = toUtf8(new Uint8Array(buffer.slice(offset, offset + 42)));
    offset += 42;
    curve.reserved = 0; //3Bytes
    offset += 3;

    const curveNameBytes = view.getUint32(offset, isLittleEndian);
    offset += 4;
    curve.curveName2 = toUtf8(new Uint8Array(buffer.slice(offset, offset + curveNameBytes)));
    offset += curveNameBytes;
    curve.xyzCnt = view.getUint32(offset, isLittleEndian);
    offset += 4;
    for (let j = 0; j < curve.xyzCnt; j++) {
      const x = view.getFloat64(offset, isLittleEndian);
      offset += 8;
      const y = view.getFloat64(offset, isLittleEndian);
      offset += 8;
      const z = view.getFloat64(offset, isLittleEndian);
      offset += 8;
      curve.xArr.push(x);
      curve.yArr.push(y);
      curve.zArr.push(z);
    }
    curve.xFormat = view.getUint16(offset, isLittleEndian); //(0=dB, 1=linear)
    offset += 2;
    curve.yFormat = view.getUint16(offset, isLittleEndian); //(0=dB, 1=linear)
    offset += 2;
    curve.zFormat = view.getUint16(offset, isLittleEndian); //(0=dB, 1=linear)
    offset += 2;
    curve.xAxisFormat = view.getUint16(offset, isLittleEndian); //(0=log, 1=linear)
    offset += 2;
    curve.yAxisFormat = view.getUint16(offset, isLittleEndian); //(0=log, 1=linear)
    offset += 2;
    curve.zAxisFormat = view.getUint16(offset, isLittleEndian); //(0=log, 1=linear)
    offset += 2;

    if (curve.version === 2 || curve.version === 3) {
      {
        const xPrefixBytes = view.getUint32(offset, isLittleEndian);
        offset += 4;
        curve.xPrefix = toUtf8(new Uint8Array(buffer.slice(offset, offset + xPrefixBytes)));
        offset += xPrefixBytes;
      }
      {
        const yPrefixBytes = view.getUint32(offset, isLittleEndian);
        offset += 4;
        curve.yPrefix = toUtf8(new Uint8Array(buffer.slice(offset, offset + yPrefixBytes)));
        offset += yPrefixBytes;
      }
      {
        const zPrefixBytes = view.getUint32(offset, isLittleEndian);
        offset += 4;
        curve.zPrefix = toUtf8(new Uint8Array(buffer.slice(offset, offset + zPrefixBytes)));
        offset += zPrefixBytes;
      }
    }

    {
      const xUnitBytes = view.getUint32(offset, isLittleEndian);
      offset += 4;
      curve.xUnit = toUtf8(new Uint8Array(buffer.slice(offset, offset + xUnitBytes)));
      offset += xUnitBytes;
    }
    {
      const yUnitBytes = view.getUint32(offset, isLittleEndian);
      offset += 4;
      curve.yUnit = toUtf8(new Uint8Array(buffer.slice(offset, offset + yUnitBytes)));
      offset += yUnitBytes;
    }
    {
      const zUnitBytes = view.getUint32(offset, isLittleEndian);
      offset += 4;
      curve.zUnit = toUtf8(new Uint8Array(buffer.slice(offset, offset + zUnitBytes)));
      offset += zUnitBytes;
    }
    curve.xDbRef = view.getFloat64(offset, isLittleEndian);
    offset += 8;
    curve.yDbRef = view.getFloat64(offset, isLittleEndian);
    offset += 8;
    curve.zDbRef = view.getFloat64(offset, isLittleEndian);
    offset += 8;
    curve.singleValueFlag = view.getUint8(offset); //0=normal curve, 1=single value
    offset += 1;
    if (curve.version === 3 || curve.version === 6) {
      curve.protectedFlag = view.getUint8(offset); //0=unprotected, 1=protected
      offset += 1;
      curve.displayXFlag = view.getUint8(offset); //0=do not display, 1=display
      offset += 1;
      curve.displayYFlag = view.getUint8(offset); //0=do not display, 1=display
      offset += 1;
      curve.displayZFlag = view.getUint8(offset); //0=do not display, 1=display
      offset += 1;
      curve.plotColor = view.getUint32(offset, isLittleEndian); //RGBa
      offset += 4;
    }
    if (curve.version === 6) {
      curve.plotInterpolation = view.getUint8(offset); //0~5
      offset += 1;
      curve.plotPointStyle = view.getUint8(offset); //0-16
      offset += 1;
      curve.plotLineStyle = view.getUint8(offset); //0-4
      offset += 1;
      curve.plotPointColor = view.getUint32(offset, isLittleEndian); //RGBa
      offset += 4;
      curve.plotLineWidth = view.getUint8(offset); //0-5
      offset += 1;
      curve.plotBarPlotStyle = view.getUint8(offset); //0-10
      offset += 1;
      curve.fillBaseline = view.getInt16(offset); //-1~32767
      offset += 2;
      {
        const testInfoBytes = view.getUint32(offset, isLittleEndian);
        offset += 4;
        curve.testInfo = toUtf8(new Uint8Array(buffer.slice(offset, offset + testInfoBytes)));
        offset += testInfoBytes;
      }
    }
  }
  return curves;
}

/**
 * Wraps and returns a typed array or ArrayBuffer in a DataView
 *
 * @param  {Mixed}     data A DataView, ArrayBuffer, TypedArray or node Buffer
 * @return {DataView}       A DataView wrapping the passed data
 * @throws {TypeError}      The passed data needs to be of a supported type
 */
function getAsDataView(data: any) {
  if (data instanceof DataView) {
    return data;
  } else if (data instanceof ArrayBuffer) {
    return new DataView(data);
  } else if ('buffer' in data) {
    return new DataView(data.buffer);
  } else {
    throw new TypeError('Could not convert data of type into a DataView.');
  }
}

//readSoundCheckDatFromUrlOss({key:"data-lib-dev/03/63492901f65743362d538ae9/619b5e7aad09312848edf691/02/dapt测试数据/d19ed79596ab119702ba03f3748b494f.dat"}).then(console.log)
//readSoundCheckDatFromUrlOss({key:"data-lib-dev/03/63492901f65743362d538ae9/619b5e7aad09312848edf691/02/dapt测试数据/47b34595fc349dfb373cb3b89a4d2d88.dat"}).then(console.log)
//readSoundCheckDatFromUrlOss({key:"data-lib-dev/03/63492901f65743362d538ae9/619b5e7aad09312848edf691/02/dapt测试数据/5fc965d23e347f04b465d0f1c0a177cf.dat"}).then(console.log)
