import request from '@/utils/request';
import API_VERSION from '@/services/datalib/api/config';

export async function dtlWavRange(body: DtlWav.RangeParam) {
  return request<ArrayBuffer>(`${API_VERSION}/api/wav/range`, {
    method: 'POST',
    responseType: 'arrayBuffer',
    data: {
      ...body,
    },
  });
}
