import { ConvProgramPlugin } from '@/services/datalib/bean/ConvProgramPlugin';

export class ConvProgram {
  name: string = '';
  plugins: ConvProgramPlugin[] = [];

  constructor(t: DtlBean.ConvProgram) {
    if (!t) return;
    if (t.name) {
      this.name = t.name;
    }
    if (t.plugins) {
      this.plugins = t.plugins.map((m) => new ConvProgramPlugin(m));
    }
  }

  copy(): ConvProgram {
    return new ConvProgram(this.format());
  }

  format(): DtlBean.ConvProgram {
    return {
      name: this.name,
      plugins: this.plugins.map((m) => m.format()),
    };
  }
}
