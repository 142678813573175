import API_VERSION from '@/services/datalib/api/config';
import request from '@/utils/request';

export async function dtlOnlyOfficeUpdateInfo(body: DtlOnlineFile.OnlyOfficeUpdateInfoParam) {
  return request<DtlComm.ResultV<DtlOnlineFile.OnlyOfficeUpdateInfoResult>>(
    `${API_VERSION}/api/onlinefile/info`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}
