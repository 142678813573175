export default {
  'AG.searchSN': '搜索Serial Number',
  'AG.searchStationID': '搜索Station ID',
  'AG.colorToSN': '应用至对应Serial Number',
  'AG.colorToAll': '应用至全部',
  'AG.restoreDefault': '恢复默认',
  'AG.restoreDefaultColor': '恢复默认颜色',
  'AG.color.type': '颜色应用类型',
  'AG.color.sameSn': '应用至Serial Number',
  'AG.color.sameId': '应用至Station ID',
  'AG.color.sameDay': '应用至同一天',
  'AG.color.sum': '颜色应用数量',
  'AG.color.all': '应用至相同曲线',
  'AG.color.single': '应用至单条曲线',
  'AG.filterSN': '筛选Serial Number',
  'AG.filterStationID': '筛选Station ID',
  'AG.filterTestResult': '筛选Test Result',
  'AG.showDetail': '展开详情',
  'AG.hiddenDetail': '隐藏详情',
  'AG.colorSetting': '颜色设置',
  'AG.checkSetting': '勾选设置',
  'AG.dat.error.xAxisDiff': '请选择具有相同横坐标值的数据项目',
  'AG.dat.error.xAxisExistDiff': '该合并项目有着不同横坐标的子项目',
  'AG.dat.tips.chooseItem': '请选择数据项目',
  'AG.dat.mergeItem.title': '合并项目',
  'AG.dat.mergeItem.add': '创建合并项目',
  'AG.dat.mergeItem.name': '合并项目名称',
  'AG.dat.mergeError.x': '合并图表出错,合并后的项目存在大于1种的横坐标单位',
  'AG.dat.mergeError.y': '合并图表出错,合并后的项目存在大于2种的纵坐标单位',
  'AG.dat.mergeError.data': '存在错误数据，该图已重新生成',
  'AG.merge.noItem': '暂无合并的项目',
  'AG.checkToProject': '勾选时应用至单个项目',
  'AG.checkToAll': '勾选时应用至全部项目',
  'AG.logX.notPositive': '数据存在非正数，Log 转换失败',
  'AG.logX.point': '每条线只有一个点，Log 转换失败',
  'AG.export.link': '网址',
};
