import {
  dtlUserContactsAddGroup,
  dtlUserContactsAddUser,
  dtlUserContactsAddUsers,
  dtlUserContactsDelGroup,
  dtlUserContactsDelUsers,
  dtlUserContactsGetContacts,
  dtlUserContactsMoveMemberToGroup,
  dtlUserContactsRemarkUser,
  dtlUserContactsSearchUsers,
  dtlUserContactsUpdateGroupName,
} from '@/services/datalib/api/contacts';
import { doResponse } from '@/services/datalib/ctrl/util';
import { Err } from '@/services/datalib/enum';
import { doInitDtlStatus, dtlStatus } from '../index';

export async function contactsGetContacts(): Promise<
  DtlComm.Result<DtlContacts.GetContactsResult | undefined>
> {
  const err = await doInitDtlStatus();
  if (err) return err;
  return {
    code: Err.OK[0],
    data: dtlStatus.contacts.format(),
    message: Err.OK[1],
  };
}

export async function contactsGetContacts2(): Promise<
  DtlComm.Result<DtlContacts.GetContactsResult | undefined>
> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlUserContactsGetContacts();
  return doResponse(response);
}

export async function contactsGetUser(
  body: DtlContacts.GetUserParam,
): Promise<DtlComm.Result<DtlBean.User | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  return {
    code: Err.OK[0],
    data: dtlStatus.contacts.getFriend(body.id)?.format(),
    message: Err.OK[1],
  };
}

export async function contactsSearchUsers(
  body: DtlContacts.SearchUsersParam,
): Promise<DtlComm.Result<DtlContacts.SearchUsersResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlUserContactsSearchUsers(body);
  return doResponse(response);
}

export async function contactsAddUsers(
  body: DtlContacts.AddUsersParam,
): Promise<DtlComm.Result<DtlContacts.AddUsersResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlUserContactsAddUsers(body);
  return doResponse(response);
}

export async function contactsAddUser(
  body: DtlContacts.AddUserParam,
): Promise<DtlComm.Result<DtlContacts.AddUsersResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlUserContactsAddUser(body);
  return doResponse(response);
}

export async function contactsDelUsers(
  body: DtlContacts.DelUsersParam,
): Promise<DtlComm.Result<undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlUserContactsDelUsers(body);
  return doResponse(response);
}

export async function contactsRemarkUser(
  body: DtlContacts.RemarkUserParam,
): Promise<DtlComm.Result<undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlUserContactsRemarkUser(body);
  return doResponse(response);
}

export async function contactsAddGroup(
  body: DtlContacts.AddGroupParam,
): Promise<DtlComm.Result<undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlUserContactsAddGroup(body);
  return doResponse(response);
}

export async function contactsUpdateGroupName(
  body: DtlContacts.UpdateGroupNameParam,
): Promise<DtlComm.Result<undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlUserContactsUpdateGroupName(body);
  return doResponse(response);
}

export async function contactsDelGroup(
  body: DtlContacts.DelGroupParam,
): Promise<DtlComm.Result<undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlUserContactsDelGroup(body);
  return doResponse(response);
}

export async function contactsMoveMemberToGroup(
  body: DtlContacts.MoveMemberToGroupParam,
): Promise<DtlComm.Result<undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlUserContactsMoveMemberToGroup(body);
  return doResponse(response);
}
