import { TaskFile } from '@/services/datalib/bean/TaskFile';
import { TaskShareStatus } from '@/services/datalib/enum';

export class TaskShare {
  shareId: string = '';
  taskId: string = '';
  userId: string = '';
  fileId: string = '';
  status: number = 0;
  createTime: number = 0;
  accessCode: string = '';
  modifyTime: number = 0;
  type: number = 0;
  ext: string = '';
  expiredAt: number = 0;
  valDays: number = 0;
  isOpen: boolean = false;
  needPwd: boolean = false;
  includeComment: boolean = false;
  includeFileInfo: boolean = false;
  includePin: boolean = false;
  includeSrcData: boolean = false;
  taskFile?: TaskFile;

  constructor(t?: DtlBean.TaskShare) {
    if (!t) {
      return;
    }
    this.shareId = t.shareId;
    this.taskId = t.taskId;
    this.userId = t.userId;
    this.fileId = t.fileId;
    this.accessCode = t.accessCode;
    this.createTime = t.createTime;
    this.modifyTime = t.modifyTime;
    this.type = t.type;
    this.ext = t.ext;
    this.expiredAt = t.expiredAt;
    this.valDays = t.valDays;
    this.status = t.status;
    this.isOpen = (t.status & TaskShareStatus.IsOpen) > 0;
    this.needPwd = (t.status & TaskShareStatus.NeedPwd) > 0;
    this.includeComment = (t.status & TaskShareStatus.IncludeComment) > 0;
    this.includeFileInfo = (t.status & TaskShareStatus.IncludeFileInfo) > 0;
    this.includePin = (t.status & TaskShareStatus.IncludePin) > 0;
    this.includeSrcData = (t.status & TaskShareStatus.includeSrcData) > 0;
    if (t.taskFile) {
      this.taskFile = new TaskFile().fromBean(t.taskFile);
    }
  }

  format(): DtlBean.TaskShare {
    return {
      accessCode: this.accessCode,
      createTime: this.createTime,
      expiredAt: this.expiredAt,
      valDays: this.valDays,
      ext: this.ext,
      fileId: this.fileId,
      includeComment: this.includeComment,
      includeFileInfo: this.includeFileInfo,
      includePin: this.includePin,
      includeSrcData: this.includeSrcData,
      isOpen: this.isOpen,
      modifyTime: this.modifyTime,
      needPwd: this.needPwd,
      shareId: this.shareId,
      status: this.status,
      taskId: this.taskId,
      type: this.type,
      userId: this.userId,
      taskFile: this.taskFile?.format(),
      shareType: 1,
    };
  }

  updateTaskFile(t: DtlBean.TaskFile) {
    if (t) {
      this.taskFile = new TaskFile().fromBean(t);
    }
  }
}
