export default {
  'pages.toolPanel.myTool.title': '我的工具',
  'pages.toolPanel.myTool.searchTool.placeholder': '搜索工具',
  'pages.toolPanel.leftSide.tool_table': '工作台',
  'pages.toolPanel.leftSide.item': '任务',
  'pages.toolPanel.leftSide.Audio': '音频预览',
  'pages.toolPanel.leftSide.Video': '视频预览',
  'pages.toolPanel.leftSide.Image': '图片',
  'pages.toolPanel.leftSide.PDF': 'PDF文档',
  'pages.toolPanel.leftSide.office': '文档',
  'pages.toolPanel.toolModal.BigTitle': '添加应用',
  'pages.toolPanel.toolModal.all': '全部',
  'pages.toolPanel.removeTool': '移出我的工具',
  'pages.toolPanel.toolModal.search.placeholder': '搜索',
  'pages.toolPanel.toolModal.addTool.btn': '添加',
  'pages.toolPanel.toolModal.hasAddedTool.btn': '已添加',
  'pages.toolPanel.toolModal.addAll.btn': '全部添加',
  'pages.toolPanel.toolModal.hasAddedAll.btn': '已全部添加',
  'pages.toolPanel.toolModal.openTool.btn': '打开',
  'pages.toolPanel.toolModal.AudioToolPackage': '声学工具包',
  'pages.toolPanel.toolModal.AudioToolPackage.desc': '声学测试解决方案',
  'pages.toolPanel.toolModal.airPackage': '传热学测试计算工具包',
  'pages.toolPanel.toolModal.airPackage.desc': '制冷测量解决方案',
  'pages.toolPanel.GRR.name': 'GRR分析',
  'pages.toolPanel.GRR.type': '统计分析',
  'pages.toolPanel.GRR.desc':
    '测量再现性（测量设备变异）和再生性（测量人员/方法变异），可降低量测误差。',
  'pages.toolPanel.leftSide.GRR': 'GRR',
  'pages.toolPanel.DAPT.name': 'Audio Graph',
  'pages.toolPanel.DAPT.type': '统计分析',
  'pages.toolPanel.DAPT.desc': '提供专业二维测量数据可视化图片，文件数据一目了然。',
  'pages.toolPanel.leftSide.DAPT': 'AG',
  'pages.toolPanel.correlation.name': 'Correlation',
  'pages.toolPanel.correlation.type': '统计分析',
  'pages.toolPanel.correlation.desc':
    '衡量不同测试机台、测试软硬件的一致性，提供平均偏差图、R方图、散点图、箱形图等专业可视化数据图表。',
  'pages.toolPanel.leftSide.Cor': 'Cor',
  'pages.toolPanel.CPB.name': 'CPB分析（声学）',
  'pages.toolPanel.CPB.type': '频谱分析',
  'pages.toolPanel.CPB.desc': '进行声学信号分析，对声学信号进行参数、倍频程方式、参数范围设置。',
  'pages.toolPanel.leftSide.CPB': 'CPB',
  'pages.toolPanel.DSP.name': 'Audio Editor',
  'pages.toolPanel.DSP.type': '频谱分析',
  'pages.toolPanel.DSP.desc':
    '对声音信号文件进行处理，提供提取、变换、分析、综合等多种信号处理方法。',
  'pages.toolPanel.leftSide.DSP': 'AE',
  'pages.toolPanel.WavView.name': '波形查看',
  'pages.toolPanel.WavView.type': '频谱分析',
  'pages.toolPanel.WavView.desc': '对信号、声音等波形进行可视化预览、分析和标记。',
  'pages.toolPanel.leftSide.WavView': '波形查看',
  'pages.toolPanel.Enthalpy.name': '焓差计算',
  'pages.toolPanel.Enthalpy.type': '空调测量',
  'pages.toolPanel.Enthalpy.desc':
    '测定空调机制冷、制热能力的试验方法。对空调机的送风参数、回风参数以及循环风量进行测量，用测出的风量与送风、回风焓差的乘积确定空调机的能力。',
  'pages.toolPanel.leftSide.Enthalpy': '焓差计算',
  'pages.toolPanel.Liquid.name': '液体载冷法计算',
  'pages.toolPanel.Liquid.type': '空调测量',
  'pages.toolPanel.Liquid.desc': '测定水（风）冷冷(热)水机组的水能力和能效等参数。',
  'pages.toolPanel.leftSide.Liquid': '液体载冷',
  'pages.toolPanel.Season.name': '季节能效计算',
  'pages.toolPanel.Season.type': '空调测量',
  'pages.toolPanel.Season.desc': '在一定的时间跨度内 (通常是于时间的性能），计算空调季节能效比。',
  'pages.toolPanel.leftSide.Season': '季节能效',
  'pages.toolPanel.Refrigerant.name': '冷媒物性查询计算',
  'pages.toolPanel.Refrigerant.type': '空调测量',
  'pages.toolPanel.Refrigerant.desc': '查询冷媒的物理性质，并计算在不同条件下表现情况。',
  'pages.toolPanel.leftSide.Refrigerant': '冷媒物性',
  'pages.toolPanel.Humidity.name': '相对湿度计算',
  'pages.toolPanel.Humidity.type': '空调测量',
  'pages.toolPanel.Humidity.desc': '计算空气中水汽压与相同温度下饱和水汽压的百分比。',
  'pages.toolPanel.leftSide.Humidity': '相对湿度',
  'pages.toolPanel.leftSide.SCIC': '科学计算',
  'pages.toolPanel.SCIC.name': '科学计算',
  'pages.toolPanel.SCIC.desc': '进行表格数据处理，使用各种函数进行插值、计算和分析。',
  'pages.toolPanel.SCIC.type': '统计分析',
  'pages.toolPanel.statistic': '数据统计',
  'pages.toolPanel.spectrum': '频谱分析',
  'pages.toolPanel.leftSide.EnthalpyDiffLab': '实验室',
  'pages.toolPanel.EnthalpyDiffLab.name': '空调焓差实验室',
  'pages.toolPanel.StrainRawData.name': '应力分析',
  'pages.toolPanel.StrainTimeHistory.name': '应力分析',
  'pages.toolPanel.leftSide.Strain': '应力分析',
  'pages.toolPanel.leftSide.SPC': '多峰检测',
  'pages.toolPanel.SPC.name': '多峰检测',
  'pages.toolPanel.SPC.type': '统计分析',
  'pages.toolPanel.SPC.desc': '统计并判断数据特征是否符合多峰形状。',
  'pages.toolPanel.remove': '移出我的工具',
  'pages.toolPanel.setting': '设置',
  'pages.toolPanel.defaultPackage.name': '新建工具包',
  'pages.toolPanel.defaultPackage.desc': '自定义工具包',
  'pages.toolPanel.officialPackage': '官方工具包',
  'pages.toolPanel.emptyApp': '暂无工具',
  'pages.toolPanel.newPackage.added': '已添加',
  'pages.toolPanel.newPackage.tool': '工具',
  'pages.toolPanel.addToWorkbench': '添加到工具台',
  'pages.toolPanel.addedToWorkbench': '已添加到工具台',
};
