const processLabels = {
  Alphawave: 'Preview',
  Normalize_Execute: 'Standardization',
  Amplify_Execute: 'Amplification(amplify)',
  Cut_Execute: 'Cut',
  CutReverse_Execute: 'Cut',
  BandPassIrrFir_Execute: 'Band pass filtering',
  BandStopIrrFir_Execute: 'Band stop filtering',
  LowPassIrrFir_Execute: 'Low pass filtering',
  HighPassIrrFir_Execute: 'High pass filtering',
  Loudness_Execute: 'Normalization of loudness',
  NotchFilter_Execute: 'Notch filter',
  SmoothMoving_Execute: 'Moving Smoothing',
  SmoothSG_Execute: 'SG smoothing',
  Smooth_Execute: 'Smooth',
  Silence_Execute: 'Silence',
  Trim_Execute: 'Trim',
  Chirp_Execute: 'Chirp',
  DTMFTones_Execute: 'DTMF-Tone',
  Noise_Execute: 'Noise',
  Tone_Execute: 'Single-Tone',
  NoiseReduction_Execute: 'Noise Reduction',
  remove: 'Remove',
  add: 'Add',
  LowPass_Execute: 'Low Pass Filter',
  HighPass_Execute: 'High Pass Filter',
  LowPassFilter_Execute: 'Low Pass Filter',
  HighPassFilter_Execute: 'High Pass Filter',
  FilterCurveEQ_Execute: 'Notch Curve Equalizer',
};

export default {
  'wav.customData': 'Custom Data',
  'wav.OtherType': 'Other types',
  'wav.nowType': 'Current type',
  'wav.autoType': 'Automatic recognition type',
  'wav.table.channel.title': 'Channel',
  'wav.table.samplingRate.title': 'Sampling Rate',
  'wav.table.length.title': 'Length (second)',
  'wav.table.description': 'Description',
  'wav.table.unit.title': 'Unit',
  'wav.table.unit.desc': 'Waveform units can be customized according to actual conditions',
  'wav.table.startTime.title': 'Begin time(second)',
  'wav.table.startTime.desc': 'Customizable waveform start time',
  'wav.table.scaleFactor.title': 'Scale factor',
  'wav.table.scaleFactor.desc': 'Waveform can be enlarged/reduced on the Y-axis',
  'wav.table.deviation.title': 'Deviation value',
  'wav.table.deviation.desc': 'Customizable Y-axis deviation value, Y-axis translation waveform',
  'wav.sequenceStep': 'Sequence Steps',
  'wav.selectItem.one': ' ',
  'wav.selectItem.two': 'items selected',
  'wav.SequenceSteps': 'Sequences',
  'wav.settings': ' Settings',
  'wav.setting.frequencyRange': 'Frequency range(Hz)',
  'wav.setting.resolution': 'Resolution',
  'wav.setting.window': 'Window',
  'wav.setting.type': 'Type',
  'wav.setting.weightMethod.title': 'Weighting method',
  'wav.setting.weightMethod.lineWay': 'Linear',
  'wav.setting.weightMethod.AWay': 'A weighting',
  'wav.setting.weightMethod.BWay': 'B weighting',
  'wav.setting.weightMethod.CWay': 'C weighting',
  'wav.setting.octaveMode': 'Octave mode',
  'wav.tool.HPF': 'High pass filtering',
  'wav.tool.LPF': 'Low pass filtering',
  'wav.tool.BPF': 'Band pass filtering',
  'wav.tool.BSF': 'Band stop filtering',
  'wav.tool.SMOOTH_MOVING': 'Moving Smoothing',
  'wav.tool.SMOOTH_SG': 'SG smoothing',
  'wav.process': 'Process',
  'wav.info': 'Information',
  'wav.processTool': 'Processing Tools',
  'wav.cutoffFrequency': 'Cutoff frequency (Hz)',
  'wav.passbandFrequency': 'Passband frequency (Hz)',
  'wav.bandPass.lower': 'Lower band pass frequency (Hz)',
  'wav.bandPass.upper': 'Upper band pass frequency (Hz)',
  'wav.waveFilter': 'Wave Filter',
  'wav.filterType': 'Filter Type',
  'wav.filterOrder': 'Filter Order',
  'wav.ripple': 'Ripple (dB)',
  'wav.ZeroPhaseOffset': 'Zero Phase Offset',
  'wav.windowType': 'Window Type',
  'wav.windowSize': 'Window Size',
  'wav.average': 'Average',
  'wav.median': 'Median',
  'wav.computingMethod': 'Computing Method',
  'wav.windowSize.left': 'Window Left Size',
  'wav.windowSize.right': 'Window Right Size',
  'wav.order': 'Order',
  'wav.frequency': 'Frequency',
  ...processLabels,
};
