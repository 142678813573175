const data = {
  'task.data.allData.tab': '全部数据',
  'task.data.recentData.tab': '最近使用',
  'task.data.table.name': '名称',
  'task.data.table.lastOpenTime': '上次打开时间',
  'task.data.table.size': '文件大小',
  'task.data.table.option': '操作',
  'task.data.newDir.placeholder': '请输入名称',
};

const desc = {
  'task.desc.taskNameInput.placeholder': '任务名称',
  'task.desc.status.title': '状态',
  'task.desc.status.wait': '待开始',
  'task.desc.status.running': '进行中',
  'task.desc.status.finish': '已完成',
  'task.desc.creator.title': '创建人',
  'task.desc.taskType.changeBtn': '更换',
  'task.desc.taskType.changeModal.title': '更换任务类型',
  'task.desc.taskType.changeModal.tip': '个人任务可以切换为企业任务类型，提高协同效率！',
  'task.desc.taskType.confirm.title': '确定切换企业任务？',
  'task.desc.taskType.confirm.tip': '切换企业任务后，无法切换回个人任务，请谨慎操作！',
  'task.desc.taskType.change.ok': '切换企业任务成功',
  'task.desc.taskType.change.fail': '切换企业任务失败',
  'task.desc.description.title': '描述',
  'task.desc.description.defaultDesc': '任务描述，简单说明实验的目的、方法、内容、实验环境和步骤。',
  'task.desc.label.title': '标签',
  'task.desc.labelInput.placeholder': '请输入标签名称',
  'task.desc.label.selectColor': '选择颜色:',
  'task.desc.attachment.title': '附件',
  'task.desc.attachment.download.tip': '请下载到本地预览',
  'task.desc.tagTemp': '标签模板',
};

const msg = {
  'task.msg.input.placeholder': '请输入留言',
  'task.msg.reply': '回复:',
  'task.msg.allMember': '全部成员',
};

const member = {
  'task.member.member.tab': '成员',
  'task.member.invite.tab': '邀请',
  'task.member.searchMember.placeholder': '搜索成员',
};

export default {
  'pages.taskDetail.data.tab': '数据',
  'pages.taskDetail.desc.tab': '说明',
  'pages.taskDetail.msg.tab': '留言',
  'pages.taskDetail.member.tab': '成员',
  ...data,
  ...desc,
  ...msg,
  ...member,
};
