import {
  dtlCaCompare,
  dtlCaGenerate,
  dtlConvertBatch,
  dtlCorrelationGenerate,
  dtlCpbGenerate,
  dtlDaptGenerate,
  dtlGrrGenerate,
  dtlMaterialMatch,
  dtlToolsDrawingDistributionDownload,
  dtlToolsDrawingDistributionSearch,
} from '@/services/datalib/api/tools';
import type { Task } from '@/services/datalib/bean/Task';
import { isConvResultSuccess } from '@/services/datalib/bean/util';
import {
  doErrResponse,
  doResponse,
  doSimpleResponse,
  isDev,
  uuid2,
} from '@/services/datalib/ctrl/util';
import { DtlCmd, Err } from '@/services/datalib/enum';
import { doGetTask, doInitDtlStatus, dtlStatus } from '../index';

async function doToolsDrawingDistributionSearch(
  body: DtlTools.DrawingDistributionSearchParam,
): Promise<DtlComm.Result<DtlTools.ConvResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlToolsDrawingDistributionSearch(body);
  return doResponse(response);
}

export async function toolsDrawingDistributionSearch(
  body: DtlTools.DrawingDistributionSearchParam,
): Promise<DtlComm.Result<DtlTools.DrawingDistributionSearchResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  // eslint-disable-next-line no-param-reassign
  body.id = uuid2(32, 16);
  const failed = {
    code: Err.SERVER_ERROR[0],
    data: undefined,
    message: Err.SERVER_ERROR[1],
  };
  let cb = (cancel: boolean, rs: DtlTools.ConvResult | undefined) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isDev() && console.log(cancel, rs);
    return true;
  };
  const pro = new Promise<DtlComm.Result<DtlTools.DrawingDistributionSearchResult | undefined>>(
    (resolve) => {
      let timeout: any;
      cb = (cancel: boolean, rs: DtlTools.ConvResult | undefined) => {
        if (timeout) {
          clearTimeout(timeout);
          timeout = null;
        }
        if (cancel) {
          resolve(failed);
          return true;
        }
        if (rs && rs.id === body.id) {
          if (rs.conv) {
            try {
              const parseRs = JSON.parse(rs.conv.result);
              resolve({ code: Err.OK[0], data: parseRs, message: Err.OK[1] });
              return true;
            } catch (e) {
              console.error(e);
            }
          }
          resolve(failed);
          return true;
        }
        return false;
      };
      dtlStatus.listenEvent.addListener(DtlCmd.User_notify_drawingdistribution, cb);
      timeout = setTimeout(() => {
        dtlStatus.listenEvent.removeListener(DtlCmd.User_notify_drawingdistribution, cb);
      }, 600000);
    },
  );

  const { code, message, data } = await doToolsDrawingDistributionSearch(body);
  if (code !== Err.OK[0]) {
    dtlStatus.listenEvent.removeListener(DtlCmd.User_notify_drawingdistribution, cb);
    return doErrResponse(code, message);
  }
  if (data && data.conv) {
    dtlStatus.listenEvent.removeListener(DtlCmd.User_notify_drawingdistribution, cb);
    try {
      const parseRs = JSON.parse(data.conv.result);
      return { code, data: parseRs, message };
    } catch (e) {
      console.error(e);
      return failed;
    }
  }
  if (!data || !data.id) {
    dtlStatus.listenEvent.removeListener(DtlCmd.User_notify_drawingdistribution, cb);
    return failed;
  }
  return await pro;
}

async function doToolsDrawingDistributionDownload(
  body: DtlTools.DrawingDistributionDownloadParam,
): Promise<DtlComm.Result<DtlTools.ConvResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlToolsDrawingDistributionDownload(body);
  return doResponse(response);
}

export async function toolsDrawingDistributionDownload(
  body: DtlTools.DrawingDistributionDownloadParam,
): Promise<DtlComm.Result<DtlTools.DrawingDistributionSearchResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  // eslint-disable-next-line no-param-reassign
  body.id = uuid2(32, 16);
  const failed = {
    code: Err.SERVER_ERROR[0],
    data: undefined,
    message: Err.SERVER_ERROR[1],
  };
  let cb = (cancel: boolean, rs: DtlTools.ConvResult | undefined) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isDev() && console.log(cancel, rs);
    return true;
  };
  const pro = new Promise<DtlComm.Result<DtlTools.DrawingDistributionSearchResult | undefined>>(
    (resolve) => {
      let timeout: any;
      cb = (cancel: boolean, rs: DtlTools.ConvResult | undefined) => {
        if (timeout) {
          clearTimeout(timeout);
          timeout = null;
        }
        if (cancel) {
          resolve(failed);
          return true;
        }
        if (rs && rs.id === body.id) {
          if (rs.conv) {
            try {
              const parseRs = JSON.parse(rs.conv.result);
              resolve({ code: Err.OK[0], data: parseRs, message: Err.OK[1] });
              return true;
            } catch (e) {
              console.error(e);
            }
          }
          resolve(failed);
          return true;
        }
        return false;
      };
      dtlStatus.listenEvent.addListener(DtlCmd.User_notify_drawingdistribution, cb);
      timeout = setTimeout(() => {
        dtlStatus.listenEvent.removeListener(DtlCmd.User_notify_drawingdistribution, cb);
      }, 600000);
    },
  );

  const { code, message, data } = await doToolsDrawingDistributionDownload(body);
  if (code !== Err.OK[0]) {
    dtlStatus.listenEvent.removeListener(DtlCmd.User_notify_drawingdistribution, cb);
    return doErrResponse(code, message);
  }
  if (data && data.conv) {
    dtlStatus.listenEvent.removeListener(DtlCmd.User_notify_drawingdistribution, cb);
    try {
      const parseRs = JSON.parse(data.conv.result);
      return { code, data: parseRs, message };
    } catch (e) {
      console.error(e);
      return failed;
    }
  }
  if (!data || !data.id) {
    dtlStatus.listenEvent.removeListener(DtlCmd.User_notify_drawingdistribution, cb);
    return failed;
  }
  return await pro;
}

async function doCaGenerate(
  body: DtlTools.CaGenerateParam,
): Promise<DtlComm.Result<DtlTools.ConvResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlCaGenerate(body);
  return doResponse(response);
}

export async function caGenerate(
  body: DtlTools.CaGenerateParam,
): Promise<DtlComm.Result<DtlTools.CaGenerateResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  // eslint-disable-next-line no-param-reassign
  body.id = uuid2(32, 16);
  const failed = {
    code: Err.SERVER_ERROR[0],
    data: undefined,
    message: Err.SERVER_ERROR[1],
  };
  let cb = (cancel: boolean, rs: DtlTools.ConvResult | undefined) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isDev() && console.log(cancel, rs);
    return true;
  };
  const pro = new Promise<DtlComm.Result<DtlTools.CaGenerateResult | undefined>>((resolve) => {
    let timeout: any;
    cb = (cancel: boolean, rs: DtlTools.ConvResult | undefined) => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      if (cancel) {
        resolve(failed);
        return true;
      }
      if (rs && rs.id === body.id) {
        if (rs.conv) {
          try {
            const parseRs = JSON.parse(rs.conv.result);
            resolve({ code: Err.OK[0], data: { data: parseRs }, message: Err.OK[1] });
            return true;
          } catch (e) {
            console.error(e);
          }
        }
        resolve(failed);
        return true;
      }
      return false;
    };
    dtlStatus.listenEvent.addListener(DtlCmd.User_notify_convert_ca_file, cb);
    timeout = setTimeout(() => {
      dtlStatus.listenEvent.removeListener(DtlCmd.User_notify_convert_ca_file, cb);
    }, 600000);
  });
  const { code, message, data } = await doCaGenerate(body);
  if (code !== Err.OK[0]) {
    dtlStatus.listenEvent.removeListener(DtlCmd.User_notify_convert_ca_file, cb);
    return doErrResponse(code, message);
  }
  if (data && data.conv) {
    dtlStatus.listenEvent.removeListener(DtlCmd.User_notify_convert_ca_file, cb);
    try {
      const parseRs = JSON.parse(data.conv.result);
      return { code, data: { data: parseRs }, message };
    } catch (e) {
      console.error(e);
      return failed;
    }
  }
  if (!data || !data.id) {
    dtlStatus.listenEvent.removeListener(DtlCmd.User_notify_convert_ca_file, cb);
    return failed;
  }
  return await pro;
}

async function doCaCompare(
  body: DtlTools.CaCompareParam,
): Promise<DtlComm.Result<DtlTools.ConvResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlCaCompare(body);
  return doResponse(response);
}

export async function caCompare(
  body: DtlTools.CaCompareParam,
): Promise<DtlComm.Result<DtlTools.CaCompareResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  // eslint-disable-next-line no-param-reassign
  body.id = uuid2(32, 16);
  const failed = {
    code: Err.SERVER_ERROR[0],
    data: undefined,
    message: Err.SERVER_ERROR[1],
  };
  let cb = (cancel: boolean, rs: DtlTools.ConvResult | undefined) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isDev() && console.log(cancel, rs);
    return true;
  };
  const pro = new Promise<DtlComm.Result<DtlTools.CaCompareResult | undefined>>((resolve) => {
    let timeout: any;
    cb = (cancel: boolean, rs: DtlTools.ConvResult | undefined) => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      if (cancel) {
        resolve(failed);
        return true;
      }
      if (rs && rs.id === body.id) {
        if (rs.conv) {
          try {
            const parseRs = JSON.parse(rs.conv.result);
            resolve({ code: Err.OK[0], data: { data: parseRs }, message: Err.OK[1] });
            return true;
          } catch (e) {
            console.error(e);
          }
        }
        resolve(failed);
        return true;
      }
      return false;
    };
    dtlStatus.listenEvent.addListener(DtlCmd.User_notify_convert_ca_file, cb);
    timeout = setTimeout(() => {
      dtlStatus.listenEvent.removeListener(DtlCmd.User_notify_convert_ca_file, cb);
    }, 600000);
  });
  const { code, message, data } = await doCaCompare(body);
  if (code !== Err.OK[0]) {
    dtlStatus.listenEvent.removeListener(DtlCmd.User_notify_convert_ca_file, cb);
    return doErrResponse(code, message);
  }
  if (data && data.conv) {
    dtlStatus.listenEvent.removeListener(DtlCmd.User_notify_convert_ca_file, cb);
    try {
      const parseRs = JSON.parse(data.conv.result);
      return { code, data: { data: parseRs }, message };
    } catch (e) {
      console.error(e);
      return failed;
    }
  }
  if (!data || !data.id) {
    dtlStatus.listenEvent.removeListener(DtlCmd.User_notify_convert_ca_file, cb);
    return failed;
  }
  return await pro;
}

async function doCPBGenerate(
  body: DtlTools.CPBGenerateParam,
): Promise<DtlComm.Result<DtlTools.ConvResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlCpbGenerate(body);
  return doResponse(response);
}

export async function cpbGenerate(
  body: DtlTools.CPBGenerateParam,
): Promise<DtlComm.Result<DtlBean.Conv | undefined>> {
  const [task, err] = await doGetTask(body);
  if (err) return err;
  // eslint-disable-next-line no-param-reassign
  body.id = uuid2(32, 16);
  const failed = {
    code: Err.SERVER_ERROR[0],
    data: undefined,
    message: Err.SERVER_ERROR[1],
  };
  {
    const { rs } = checkExistChildConv(task, body.path, body.program, body.data, body.convPid);
    if (rs) return rs;
  }
  let cb = (cancel: boolean, rs: DtlTools.ConvResult | undefined) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isDev() && console.log(cancel, rs);
    return true;
  };
  const pro = new Promise<DtlComm.Result<DtlBean.Conv | undefined>>((resolve) => {
    let timeout: any;
    cb = (cancel: boolean, rs: DtlTools.ConvResult | undefined) => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      if (cancel) {
        // eslint-disable-next-line prefer-promise-reject-errors
        resolve(failed);
        return true;
      }
      if (rs && rs.id === body.id) {
        if (rs.conv) {
          resolve({
            code: Err.OK[0],
            data: rs.conv,
            message: Err.OK[1],
          });
          return true;
        }
        resolve(failed);
        return true;
      }
      return false;
    };
    dtlStatus.listenEvent.addListener(DtlCmd.User_notify_convert_weighting_file, cb);
    timeout = setTimeout(() => {
      dtlStatus.listenEvent.removeListener(DtlCmd.User_notify_convert_weighting_file, cb);
    }, 600000);
  });
  const { code, message, data } = await doCPBGenerate(body);
  if (code !== Err.OK[0]) {
    dtlStatus.listenEvent.removeListener(DtlCmd.User_notify_convert_weighting_file, cb);
    return doErrResponse(code, message);
  }
  if (data && data.conv) {
    dtlStatus.listenEvent.removeListener(DtlCmd.User_notify_convert_weighting_file, cb);
    return { code, data: data.conv, message };
  }
  if (!data || !data.id) {
    dtlStatus.listenEvent.removeListener(DtlCmd.User_notify_convert_weighting_file, cb);
    return failed;
  }
  return await pro;
}

function checkExistChildConv(
  task: Task,
  path: string,
  program: string,
  data: string,
  convPid: string,
): { rs: DtlComm.Result<DtlBean.Conv | undefined> | undefined } {
  const taskFile = task.files.existFile(path);
  if (!taskFile) {
    return {
      rs: {
        code: Err.FILE_OR_DIR_NOT_EXIST[0],
        data: undefined,
        message: Err.FILE_OR_DIR_NOT_EXIST[1],
      },
    };
  }

  const index = taskFile.convs.findIndex((c) => c.id === convPid);
  if (index === -1) {
    return {
      rs: {
        code: Err.SERVER_ERROR[0],
        data: undefined,
        message: Err.SERVER_ERROR[1],
      },
    };
  }

  for (let i = 0; i < taskFile.convs.length; i += 1) {
    const c = taskFile.convs[i];
    if (
      c.pid === convPid &&
      (c.program === program || c.originP === program) &&
      c.data === data &&
      isConvResultSuccess(c.result)
    ) {
      return {
        rs: {
          code: Err.OK[0],
          data: c,
          message: Err.OK[1],
        },
      };
    }
  }

  return {
    rs: undefined,
  };
}

async function doMaterialMatch(
  body: DtlTools.MaterialMatchParam,
): Promise<DtlComm.Result<DtlTools.ConvResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlMaterialMatch(body);
  return doResponse(response);
}

export async function materialMatch(
  body: DtlTools.MaterialMatchParam,
): Promise<DtlComm.Result<DtlTools.MaterialMatchResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  // eslint-disable-next-line no-param-reassign
  body.id = uuid2(32, 16);
  const failed = {
    code: Err.SERVER_ERROR[0],
    data: undefined,
    message: Err.SERVER_ERROR[1],
  };
  let cb = (cancel: boolean, rs: DtlTools.ConvResult | undefined) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isDev() && console.log(cancel, rs);
    return true;
  };
  const pro = new Promise<DtlComm.Result<DtlTools.MaterialMatchResult | undefined>>((resolve) => {
    let timeout: any;
    cb = (cancel: boolean, rs: DtlTools.ConvResult | undefined) => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      if (cancel) {
        resolve(failed);
        return true;
      }
      if (rs && rs.id === body.id) {
        if (rs.conv) {
          try {
            const parseRs = JSON.parse(rs.conv.result);
            resolve({ code: Err.OK[0], data: { data: parseRs }, message: Err.OK[1] });
            return true;
          } catch (e) {
            console.error(e);
          }
        }
        resolve(failed);
        return true;
      }
      return false;
    };
    dtlStatus.listenEvent.addListener(DtlCmd.User_notify_materialmatch, cb);
    timeout = setTimeout(() => {
      dtlStatus.listenEvent.removeListener(DtlCmd.User_notify_materialmatch, cb);
    }, 600000);
  });
  const { code, message, data } = await doMaterialMatch(body);
  if (code !== Err.OK[0]) {
    dtlStatus.listenEvent.removeListener(DtlCmd.User_notify_materialmatch, cb);
    return doErrResponse(code, message);
  }
  if (data && data.conv) {
    dtlStatus.listenEvent.removeListener(DtlCmd.User_notify_materialmatch, cb);
    try {
      const parseRs = JSON.parse(data.conv.result);
      return { code, data: { data: parseRs }, message };
    } catch (e) {
      console.error(e);
      return failed;
    }
  }
  if (!data || !data.id) {
    dtlStatus.listenEvent.removeListener(DtlCmd.User_notify_materialmatch, cb);
    return failed;
  }
  return await pro;
}

export async function convertBatch(
  body: DtlTools.ConvertBatchParam,
): Promise<DtlComm.Result<DtlTools.ConvertBatchResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlStatus.syncGetOrAdd<DtlComm.ResultV<DtlTools.ConvertBatchResult>>(
    body,
    dtlConvertBatch,
  );
  return doSimpleResponse(response);
}

export async function correlationGenerate(
  body: DtlTools.CorrelationGenerateParam,
): Promise<DtlComm.Result<string | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlCorrelationGenerate(body);
  return doSimpleResponse(response);
}

export async function daptGenerate(
  body: DtlTools.DaptGenerateParam,
): Promise<DtlComm.Result<string | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlDaptGenerate(body);
  return doSimpleResponse(response);
}

export async function grrGenerate(
  body: DtlTools.CorrelationGenerateParam,
): Promise<DtlComm.Result<string | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlGrrGenerate(body);
  return doSimpleResponse(response);
}

export async function getConvProgramVersion(body: {
  program: string;
}): Promise<DtlComm.Result<undefined | string>> {
  const programs = body?.program?.split('/');
  const programName = programs?.[0];
  const pluginId = programs?.[1];

  const plugins: DtlBean.ConvProgramPlugin[] | undefined =
    dtlStatus.openTypes.convPrograms.list.find((p: DtlBean.ConvProgram) => p.name === programName)
      ?.plugins;

  const version = plugins?.find((p: DtlBean.ConvProgramPlugin) => p.id === pluginId)?.version;

  return {
    code: Err.OK[0],
    data: version,
    message: Err.OK[1],
  };
}
