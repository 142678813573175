export enum TaskStatus {
  None = 0,
  creatorDepart = 1,
}

export enum DeptUserStatus {
  Normal = 0, // 正常员工
  Depart = 1, // 离职
  Deleted = 2, // 离职后被删除
}

export enum TaskRole {
  Admin = 'admin',
  User = 'user',
  Visitor = 'visitor',
  Uploader = 'uploader',
}

export enum FileRecordStatus {
  Normal = 0,
  Deleted = 1,
}

export enum RecentOrCollect {
  RecentFiles = 'recentfiles',
  CollectFiles = 'collectfiles',
}

export enum TaskShareStatus {
  IsOpen = 1 << 0,
  NeedPwd = 1 << 1,
  IncludeComment = 1 << 2,
  IncludeFileInfo = 1 << 3,
  IncludePin = 1 << 4,
  includeSrcData = 1 << 5,
}

export enum TaskFileStatus {
  NORMAL = 0,
  SHARING = 1 << 0,
}

export enum TaskType {
  COMMON = 0,
  PRIVATE = 1,
  OFFICIAL = 2,
}

export enum Gender {
  USER_GENDER_NONE = 1,
  USER_GENDER_MALE = 2,
  USER_GENDER_FEMA = 3,
}

export enum IsReg {
  USER_ISREG_NO = 1,
  USER_ISREG_YES = 2,
}

export enum HideInfo {
  USER_HIDEINFO_NO = 1,
  USER_HIDEINFO_YES = 2,
}

export enum TaskStatus {
  TASKSTATUS_WAIT_START = 1,
  TASKSTATUS_PROCESSING = 2,
  TASKSTATUS_FINISHED = 3,
}

export enum TaskMark {
  TASKMARK_NONE = 0, // 0
  TASKMARK_WAIT_READ_NEW_TASK = 1, // 1
  TASKMARK_WAIT_READ_NEW_INVITE = 2, // 2
}

export enum TaskFileType {
  TASKFILE_TYPE_DIR = 100,
  TASKFIEL_TYPE_UNKNOWN = 1,
  TASKFIEL_TYPE_IMG = 2,
  TASKFIEL_TYPE_PDF = 3,
  TASKFIEL_TYPE_EXCEL = 4,
  TASKFIEL_TYPE_WORD = 5,
  TASKFIEL_TYPE_PPT = 6,
  TASKFIEL_TYPE_TXT = 7,
  TASKFIEL_TYPE_VIDEO = 8,
  TASKFIEL_TYPE_AUDIO = 9,
  TASKFIEL_TYPE_JSON = 10,
  TASKFIEL_TYPE_YML = 11,
  TASKFIEL_TYPE_JS = 12,
  TASKFIEL_TYPE_WAV = 13,
  TASKFIEL_TYPE_DAPT = 14,
  TASKFIEL_TYPE_CAREPORT = 15,
  TASKFIEL_TYPE_DATACOMPARE = 16,
  TASKFIEL_TYPE_CA = 17,
  TASKFIEL_TYPE_OFFICE = 18,
  TASKFIEL_TYPE_COMPRESS = 19,
  TASKFIEL_TYPE_CONFIG = 20,
  TASKFILE_TYPE_DAT = 21,
  TASKFIEL_TYPE_Correlation = 128,
  TASKFIEL_TYPE_Grr = 129,
  TASKFIEL_TYPE_Dapt = 130,
  TASKFIEL_TYPE_Dsp = 131,
  TASKFIEL_TYPE_Dsp_Common = 132,
  TASKFIEL_TYPE_Cpb = 133,
  TASKFIEL_TYPE_PRM_IMG = 134,
  TASKFIEL_TYPE_PRM_PDF = 135,
  TASKFIEL_TYPE_PRM_Enthalpy = 136,
  TASKFIEL_TYPE_PRM_Liquid = 137,
  TASKFIEL_TYPE_PRM_Season = 138,
  TASKFIEL_TYPE_PRM_Refrigerant = 139,
  TASKFIEL_TYPE_PRM_Humidity = 140,
  TASKFIEL_TYPE_PRM_SCIC = 141,
  TASKFIEL_TYPE_PRM_EnthalpyDiffLab = 142,
  TASKFIEL_TYPE_PRM_StrainRawData = 143,
  TASKFIEL_TYPE_PRM_StrainTimeHistory = 144,
  TASKFIEL_TYPE_PRM_SPC = 145,
}

export const FileTypeWithOpenType = {
  [TaskFileType.TASKFIEL_TYPE_IMG]: [TaskFileType.TASKFIEL_TYPE_PRM_IMG],
  [TaskFileType.TASKFIEL_TYPE_PDF]: [
    TaskFileType.TASKFIEL_TYPE_OFFICE,
    TaskFileType.TASKFIEL_TYPE_PRM_PDF,
  ],
  [TaskFileType.TASKFIEL_TYPE_EXCEL]: [
    TaskFileType.TASKFIEL_TYPE_OFFICE,
    TaskFileType.TASKFIEL_TYPE_Correlation,
    TaskFileType.TASKFIEL_TYPE_Grr,
    TaskFileType.TASKFIEL_TYPE_Dapt,
    TaskFileType.TASKFIEL_TYPE_PRM_SCIC,
  ],
  [TaskFileType.TASKFIEL_TYPE_WORD]: [TaskFileType.TASKFIEL_TYPE_OFFICE],
  [TaskFileType.TASKFIEL_TYPE_PPT]: [TaskFileType.TASKFIEL_TYPE_OFFICE],
  [TaskFileType.TASKFIEL_TYPE_TXT]: [TaskFileType.TASKFIEL_TYPE_OFFICE],
  [TaskFileType.TASKFIEL_TYPE_VIDEO]: [
    TaskFileType.TASKFIEL_TYPE_WAV,
    TaskFileType.TASKFIEL_TYPE_Dsp,
    TaskFileType.TASKFIEL_TYPE_Dsp_Common,
    TaskFileType.TASKFIEL_TYPE_Cpb,
  ],
  [TaskFileType.TASKFIEL_TYPE_AUDIO]: [
    TaskFileType.TASKFIEL_TYPE_WAV,
    TaskFileType.TASKFIEL_TYPE_Dsp,
    TaskFileType.TASKFIEL_TYPE_Dsp_Common,
    TaskFileType.TASKFIEL_TYPE_Cpb,
  ],
  // [TaskFileType.TASKFIEL_TYPE_JSON]:[],
  // [TaskFileType.TASKFIEL_TYPE_YML]:[],
  // [TaskFileType.TASKFIEL_TYPE_JS]:[],
  [TaskFileType.TASKFIEL_TYPE_WAV]: [
    TaskFileType.TASKFIEL_TYPE_VIDEO,
    TaskFileType.TASKFIEL_TYPE_AUDIO,
    TaskFileType.TASKFIEL_TYPE_Dsp,
    TaskFileType.TASKFIEL_TYPE_Dsp_Common,
    TaskFileType.TASKFIEL_TYPE_Cpb,
  ],
  // [TaskFileType.TASKFIEL_TYPE_CAREPORT]:[],
  // [TaskFileType.TASKFIEL_TYPE_DATACOMPARE]:[],
  // [TaskFileType.TASKFIEL_TYPE_CA]:[],
  // [TaskFileType.TASKFIEL_TYPE_OFFICE]:[],
  // [TaskFileType.TASKFIEL_TYPE_COMPRESS]:[],
  // [TaskFileType.TASKFIEL_TYPE_CONFIG]:[],
  [TaskFileType.TASKFILE_TYPE_DAT]: [TaskFileType.TASKFIEL_TYPE_Dapt],
};

export const selectOpenSidObj = {
  [TaskFileType.TASKFIEL_TYPE_UNKNOWN]: 'unknown',
  [TaskFileType.TASKFIEL_TYPE_COMPRESS]: 'unknown',
  [TaskFileType.TASKFIEL_TYPE_IMG]: 'Image',
  [TaskFileType.TASKFIEL_TYPE_PRM_IMG]: 'Image',
  [TaskFileType.TASKFIEL_TYPE_PDF]: 'PDF',
  [TaskFileType.TASKFIEL_TYPE_PRM_PDF]: 'PDF',
  [TaskFileType.TASKFIEL_TYPE_EXCEL]: 'office',
  [TaskFileType.TASKFIEL_TYPE_WORD]: 'office',
  [TaskFileType.TASKFIEL_TYPE_PPT]: 'office',
  [TaskFileType.TASKFIEL_TYPE_TXT]: 'office',
  [TaskFileType.TASKFIEL_TYPE_VIDEO]: 'Video',
  [TaskFileType.TASKFIEL_TYPE_AUDIO]: 'Audio',
  [TaskFileType.TASKFIEL_TYPE_WAV]: 'WavView',
  [TaskFileType.TASKFIEL_TYPE_Dsp_Common]: 'WavView',
  [TaskFileType.TASKFIEL_TYPE_OFFICE]: 'office',
  [TaskFileType.TASKFIEL_TYPE_Correlation]: 'Cor',
  [TaskFileType.TASKFIEL_TYPE_Grr]: 'GRR',
  [TaskFileType.TASKFIEL_TYPE_Dapt]: 'DAPT',
  [TaskFileType.TASKFILE_TYPE_DAT]: 'DAPT',
  [TaskFileType.TASKFIEL_TYPE_Dsp]: 'DSP',
  [TaskFileType.TASKFIEL_TYPE_Cpb]: 'CPB',
  [TaskFileType.TASKFIEL_TYPE_PRM_Enthalpy]: 'Enthalpy',
  [TaskFileType.TASKFIEL_TYPE_PRM_Liquid]: 'Liquid',
  [TaskFileType.TASKFIEL_TYPE_PRM_Season]: 'Season',
  [TaskFileType.TASKFIEL_TYPE_PRM_Refrigerant]: 'Refrigerant',
  [TaskFileType.TASKFIEL_TYPE_PRM_Humidity]: 'Humidity',
  [TaskFileType.TASKFIEL_TYPE_PRM_SCIC]: 'SCIC',
  [TaskFileType.TASKFIEL_TYPE_PRM_EnthalpyDiffLab]: 'EnthalpyDiffLab',
  [TaskFileType.TASKFIEL_TYPE_PRM_StrainRawData]: 'StrainRawData',
  [TaskFileType.TASKFIEL_TYPE_PRM_StrainTimeHistory]: 'StrainTimeHistory',
  [TaskFileType.TASKFIEL_TYPE_PRM_SPC]: 'SinglePeak',
};

export const ExtType = {
  [TaskFileType.TASKFIEL_TYPE_UNKNOWN]: [],
  [TaskFileType.TASKFIEL_TYPE_IMG]: ['png', 'jpg', 'jpeg', 'gif'],
  [TaskFileType.TASKFIEL_TYPE_PDF]: ['pdf'],
  [TaskFileType.TASKFIEL_TYPE_EXCEL]: [
    'xls',
    'xlsx',
    'xlsm',
    'xlt',
    'xltx',
    'xltm',
    'ods',
    'fods',
    'ots',
    'csv',
  ],
  [TaskFileType.TASKFIEL_TYPE_WORD]: [
    'doc',
    'docx',
    'oform',
    'docm',
    'dot',
    'dotx',
    'dotm',
    'odt',
    'fodt',
    'ott',
    'rtf',
    'html',
    'htm',
    'mht',
    'xml',
    'djvu',
    'fb2',
    'epub',
    'xps',
    'oxps',
  ],
  [TaskFileType.TASKFIEL_TYPE_PPT]: [
    'pps',
    'ppsx',
    'ppsm',
    'ppt',
    'pptx',
    'pptm',
    'pot',
    'potx',
    'potm',
    'odp',
    'fodp',
    'otp',
  ],
  [TaskFileType.TASKFIEL_TYPE_TXT]: ['txt'],
  [TaskFileType.TASKFIEL_TYPE_VIDEO]: ['avi', 'mp4', 'wmv', 'mov', 'f4', 'mkv'],
  [TaskFileType.TASKFIEL_TYPE_AUDIO]: ['ogg', 'mp3'],
  [TaskFileType.TASKFIEL_TYPE_JSON]: ['json'],
  [TaskFileType.TASKFIEL_TYPE_YML]: ['yaml', 'yml'],
  [TaskFileType.TASKFIEL_TYPE_JS]: ['js', 'ts'],
  [TaskFileType.TASKFIEL_TYPE_WAV]: ['wav', 'wma', 'vqf', 'hdf', 'tdms', 'msod', 'wfm'],
  [TaskFileType.TASKFIEL_TYPE_COMPRESS]: ['zip', 'rar'],
  [TaskFileType.TASKFIEL_TYPE_Correlation]: ['cor'],
  [TaskFileType.TASKFILE_TYPE_DAT]: ['dat'],
};

export const documentExts = [
  'doc',
  'docx',
  'oform',
  'docm',
  'dot',
  'dotx',
  'dotm',
  'odt',
  'fodt',
  'ott',
  'rtf',
  'txt',
  'html',
  'htm',
  'mht',
  'xml',
  'pdf',
  'djvu',
  'fb2',
  'epub',
  'xps',
  'oxps',
];
export const spreadsheetExts = [
  'xls',
  'xlsx',
  'xlsm',
  'xlt',
  'xltx',
  'xltm',
  'ods',
  'fods',
  'ots',
  'csv',
];
export const presentationExts = [
  'pps',
  'ppsx',
  'ppsm',
  'ppt',
  'pptx',
  'pptm',
  'pot',
  'potx',
  'potm',
  'odp',
  'fodp',
  'otp',
];
export const convertedDocsExts = [
  'docm',
  'doc',
  'dotx',
  'dotm',
  'dot',
  'odt',
  'fodt',
  'ott',
  'xlsm',
  'xls',
  'xltx',
  'xltm',
  'xlt',
  'ods',
  'fods',
  'ots',
  'pptm',
  'ppt',
  'ppsx',
  'ppsm',
  'pps',
  'potx',
  'potm',
  'pot',
  'odp',
  'fodp',
  'otp',
  'rtf',
  'mht',
  'html',
  'htm',
  'xml',
  'epub',
  'fb2',
];
export const officeExts = [...documentExts, ...spreadsheetExts, ...presentationExts];

export enum DtlUnreadCmd {
  UNREAD_TASK_NEW_AT_MSG = 1, // 新@我的留言
  UNREAD_TASK_NEW_REPLY_MSG = 2, // 新回复我的留言
  UNREAD_TASK_ADDED_TO_TASK = 3, // 我被加入到新任务
  UNREAD_TASK_NEW_INVITE_CONFIRM = 4, // 新邀请待我确认
  UNREAD_TASK_DISMISS = 5, // 任务被解散
  UNREAD_TASK_KICKOUT_MEMBER = 6, // 我被移除出任务
  UNREAD_TASK_NEW_MSG = 7, // 新增了留言
  UNREAD_TASK_ADD_ATTACHMENT = 8, // 新增了附件
  UNREAD_TASK_ADD_MEMBER = 9, // 新增了成员
  UNREAD_TASK_ADD_LABEL = 10, // 新增了标签
  UNREAD_TASK_REMOVE_LABEL = 11, // 移除了标签
  UNREAD_TASK_REMOVE_ATTACHMENT = 12, // 移除了附件
  UNREAD_TASK_REMOVE_MEMBER = 13, // 移除了成员
  UNREAD_TASK_FILE_ADD_MSG = 14, // 文件备注@用户
  UNREAD_TASK_FILE_ADD_PIN = 15, // 文件添加图钉
  UNREAD_TASK_FILE_PIN_ADD_MSG = 16, // 图钉添加评论
  UNREAD_TASK_FILE_PIN_NEW_AT_MSG = 17, // 图钉评论@用户
  UNREAD_USER_CONTACT_ADDED = 18, // 对方的通讯录添加了我为联系人
  UNREAD_ENTERPRISE_ADD_USER = 19, // 企业新增了成员
  UNREAD_ENTERPRISE_REMOVE_USER = 20, // 企业移除了成员
  UNREAD_ENTERPRISE_DISMISS = 21, // 企业已解散
}

export enum DtlCmd {
  APP_LOGOUT = 9999999,

  User_cmdstart = 2000000,
  User_register = 2000001,
  User_update_attrs = 2000002,
  User_update_pwd = 2000003,
  User_cmdend = 2099999,

  Taskgroup_cmdstart = 1200000,
  Taskgroup_add_gorup = 1200001,
  Taskgroup_update_gorup_name = 1200002,
  Taskgroup_del_group = 1200003,
  Taskgroup_move_task_to_group = 1200004,
  Taskgroup_update_seq = 1200005,

  TaskArchive_add_gorup = 1200101,
  TaskArchive_update_gorup_name = 1200102,
  TaskArchive_del_group = 1200103,
  TaskArchive_move_task_to_group = 1200104,
  TaskArchive_update_seq = 1200105,

  Taskgroup_archive_task = 1200201,
  Taskgroup_unarchive_task = 1200202,
  Taskgroup_archive_task_group = 1200203,
  Taskgroup_unarchive_task_group = 1200204,

  Taskgroup_add_creating_task = 1200301,
  Taskgroup_add_exist_task = 1200302,
  Taskgroup_task_deleted_by_others = 1200303,
  Taskgroup_task_deleted_by_dismiss = 1200304,
  Taskgroup_task_deleted_by_quit = 1200305,
  Taskgroup_task_recover = 1200306,
  Taskgroup_task_dismiss_completely = 1200307,
  Taskgroup_task_deleted_by_depart = 1200308,
  Taskgroup_add_assign_creator_task = 1200309,
  Taskgroup_cmdend = 1299999,

  User_contacts_cmdstart = 1100000,
  User_contacts_add_users = 1100001, //
  User_contacts_del_users = 1100002, //
  User_contacts_remark_user = 1100003,
  User_contacts_add_group = 1100004,
  User_contacts_update_group_name = 1100005,
  User_contacts_del_group = 1100006,
  User_contacts_move_member_to_group = 1100007,
  User_contacts_cmdend = 1199999,

  User_label_template_cmdstart = 1000000,
  User_create_label_template = 1000003,
  User_del_label_templates = 1000004,
  User_label_template_cmdend = 1099999,

  User_unread_cmdstart = 1300000,
  User_unread_add = 1300001,
  User_unread_delete = 1300002,
  User_unread_cmdend = 1399999,

  User_notify_cmdstart = 1400000,
  User_notify_drawingdistribution = 1400001,
  User_notify_materialmatch = 1400002,
  User_notify_convert_ca_file = 1400003,
  User_notify_convert_weighting_file = 1400004,
  User_notify_convert_file = 1400005,
  User_notify_cmdend = 1499999,

  User_ext_cmdstart = 1500000,
  User_ext_update_usualtools = 1500001,
  User_ext_update_toolsext = 1500002,
  User_ext_cmdend = 1599999,

  Category_cmdstart = 1600000,
  Category_add = 1600001,
  Category_addChild = 1600002,
  Category_updateChild = 1600003,
  Category_deleteChild = 1600004,
  Category_setRead = 1600005,
  Category_cmdend = 1699999,

  User_filerecord_cmdstart = 1700000,
  User_filerecord_add = 1700001,
  User_filerecord_delete = 1700002,
  User_filerecord_addCollectFolder = 1700003,
  User_filerecord_cmdend = 1799999,

  Enterprise_cmdstart = 1800000,
  Enterprise_create = 1800001,
  Enterprise_addDept = 1800002,
  Enterprise_renameDept = 1800003,
  Enterprise_addUser = 1800004,
  Enterprise_updateUser = 1800005,
  Enterprise_update = 1800006,
  Enterprise_departUser = 1800007,
  Enterprise_updateUserExt = 1800008,
  Enterprise_ressignCreator = 1800009,
  Enterprise_delUser = 1800010,
  Enterprise_recoverUser = 1800011,
  Enterprise_delDept = 1800012,
  Enterprise_dismiss = 1800013,
  Enterprise_cmdend = 1899999,

  Task_cmdstart = 0,
  Task_create = 1, //
  Task_update_name = 2,
  Task_update_desc = 3,
  Task_add_labels = 4,
  Task_del_labels = 5,
  Task_add_members = 6, //
  Task_invite_members = 7,
  Task_agree_invite_members = 8, //
  Task_del_members = 9, //
  Task_agree_del_invitees = 10,
  Task_add_attachments = 11,
  Task_del_attachments = 12,
  Task_dismiss = 13, //
  Task_quit = 14, //
  Task_update_role = 15,
  Task_update_user_status = 16,
  Task_update_user_mark = 17,
  Task_send_msg = 18,
  Task_reply_msg = 19,
  Task_update_user_ext = 20,
  Task_update_user_file_lastopentime = 21,
  Task_update_user_task_lastopentime = 23,
  Task_delete_msg = 24,
  Task_creator_depart = 25,
  Task_creator_ressign = 26,
  Task_add_enterprise_id = 27,

  Task_create_dir = 200,
  Task_rename_file = 201,
  Task_move_files = 202,
  Task_del_files = 203,
  Task_add_files = 204,
  Task_copy_files = 205,
  Task_convert_file_result = 207,
  Task_set_opentype = 208,
  Task_file_add_msg = 209,
  Task_file_del_msg = 210,
  Task_file_add_analysis = 211,
  Task_file_update_analysis = 212,
  Task_file_del_analysis = 213,
  Task_copy_files_to_task = 214,
  Task_add_share_file_to_task = 215,
  Task_file_update_content = 216,
  Task_file_update_status = 217,
  Task_del_files_completely = 218,
  Task_del_files_recover = 219,
  Task_onlyoffice_update_info = 2001,
  Task_cmdend = 999999,
}

export type ErrType = [number, string, string];

export class Err {
  static OK: ErrType = [0, '操作成功', 'OK'];
  static FILE_OPEN_FAILED: ErrType = [-10001, '解析失败，请选择其他打开方式', 'FILE_OPEN_FAILED'];
  static DEVICE_TYPE_ERROR: ErrType = [10001, '设备类型错误', 'DEVICE_TYPE_ERROR'];
  static USER_NOT_EXIST: ErrType = [10002, '用户不存在', 'USER_NOT_EXIST'];
  static PHONE_OR_PASSWORD_ERROR: ErrType = [10003, '用户名或密码错误', 'PHONE_OR_PASSWORD_ERROR'];
  static PHONE_FORM_ERROR: ErrType = [10004, '电话号码格式错误', 'PHONE_FORM_ERROR'];
  static PASSWORD_FORM_ERROR: ErrType = [10005, '密码格式错误', 'PASSWORD_FORM_ERROR'];
  static USER_EXIST: ErrType = [10006, '用户已存在', 'USER_EXIST'];
  static USER_INFO_ARGUMENT_ERROR: ErrType = [
    10007,
    '修改用户信息参数错误',
    'USER_INFO_ARGUMENT_ERROR',
  ];
  static AUTH_CODE_SEND_ERROR: ErrType = [10008, '验证码发送错误', 'AUTH_CODE_SEND_ERROR'];
  static AUTH_CODE_SEND_BUSY: ErrType = [10009, '验证码发送频繁', 'AUTH_CODE_SEND_BUSY'];
  static AUTH_CODE_SENT: ErrType = [10010, '验证码已发送', 'AUTH_CODE_SENT'];
  static AUTH_CODE_EXPIRE: ErrType = [10011, '验证码已过期', 'AUTH_CODE_EXPIRE'];
  static AUTH_CODE_ERROR: ErrType = [10012, '验证码错误', 'AUTH_CODE_ERROR'];
  static AUTH_TOKEN_ERROR: ErrType = [10013, '验证token错误', 'AUTH_TOKEN_ERROR'];
  static NEED_CODE: ErrType = [10014, '需要图片验证码', 'NEED_CODE'];
  static VERIFY_CODE_ERROR: ErrType = [10015, '图片验证码错误', 'VERIFY_CODE_ERROR'];
  static LOGIN_ERROR_OFTEN: ErrType = [10016, '密码登录错误频繁', 'LOGIN_ERROR_OFTEN'];
  static USER_NAME_ERROR: ErrType = [10017, '用户名超出范围', 'USER_NAME_ERROR'];
  static EMAIL_ERROR: ErrType = [10018, '邮箱格式错误或长度超出范围', 'EMAIL_ERROR'];
  static TOKEN_ERROR: ErrType = [90001, 'token错误', 'TOKEN_ERROR'];
  static SIZE_ZERO_ERROR: ErrType = [90002, '集合大小为0', 'SIZE_ZERO_ERROR'];
  static REQUEST_DATA_ERROR: ErrType = [90004, '参数类型不匹配或缺少参数', 'REQUEST_DATA_ERROR'];
  static HTTP_SERVER_ERROR: ErrType = [90005, '请求数据错误', 'HTTP_SERVER_ERROR'];
  static TOKEN_EXPIRED: ErrType = [90006, '登录已过期', 'TOKEN_EXPIRED'];
  static SERVER_ERROR: ErrType = [90007, '操作失败请稍后再试', 'SERVER_ERROR'];
  static PHONE_USING_BY_OTHERS: ErrType = [90008, '电话号码占用', 'PHONE_USING_BY_OTHERS'];
  static EMAIL_USING_BY_OTHERS: ErrType = [90009, '邮箱已被占用', 'EMAIL_USING_BY_OTHERS'];
  static CONTACTS_GROUP_NOT_EXIST: ErrType = [90010, '联系人组不存在', 'CONTACTS_GROUP_NOT_EXIST'];
  static CONTACTS_GROUP_EXIST: ErrType = [90011, '联系人组已存在', 'CONTACTS_GROUP_EXIST'];
  static CONTACTS_MEMBER_NOT_EXIST: ErrType = [
    90012,
    '通讯录中不存在该成员',
    'CONTACTS_MEMBER_NOT_EXIST',
  ];
  static ACTION_EXPIRED: ErrType = [90013, '操作超时', 'ACTION_EXPIRED'];
  static TASK_GROUP_NAME_EXIST: ErrType = [90014, '任务分组名已存在', 'TASK_GROUP_NAME_EXIST'];
  static TASK_GROUP_NOT_EXIST: ErrType = [90015, '指定的任务分组不存在', 'TASK_GROUP_NOT_EXIST'];
  static TASK_GROUP_EXIST_TASK: ErrType = [90016, '任务已存在于指定分组', 'TASK_GROUP_EXIST_TASK'];
  static TASK_NOT_EXIST: ErrType = [90017, '任务不存在', 'TASK_NOT_EXIST'];
  static USER_NOT_EXIST_IN_TASK: ErrType = [
    90018,
    '用户不是该任务的成员',
    'USER_NOT_EXIST_IN_TASK',
  ];
  static NO_PERMISSION: ErrType = [90019, '缺少操作权限', 'NO_PERMISSION'];
  static ADMIN_SIZE_OVER: ErrType = [90020, '最多只能设定2位管理员', 'ADMIN_SIZE_OVER'];
  static MSG_RPLIED_NOT_EXIST: ErrType = [90021, '回复的消息不存在', 'MSG_RPLIED_NOT_EXIST'];
  static TASK_GROUP_ERROR: ErrType = [90022, '任务列表错误', 'TASK_GROUP_ERROR'];
  static FILE_OR_DIR_NOT_EXIST: ErrType = [
    90023,
    '指定的文件或文件夹不存在',
    'FILE_OR_DIR_NOT_EXIST',
  ];
  static FILE_MOVE_TO_NOT_DIR: ErrType = [90024, '只能移动到文件夹下', 'FILE_MOVE_TO_NOT_DIR'];
  static FILE_NAME_EXIST: ErrType = [90025, '已存在同名的文件', 'FILE_NAME_EXIST'];
  static DIR_NAME_EXIST: ErrType = [90026, '已存在同名的文件夹', 'DIR_NAME_EXIST'];
  static DIR_PATH_EXIST_BY_FILE: ErrType = [
    90027,
    '父级路径与已有的某个文件路径重复',
    'DIR_PATH_EXIST_BY_FILE',
  ];
  static FILE_IS_CONVERTING: ErrType = [90028, '正在解析该文件，请稍后再试', 'FILE_IS_CONVERTING'];
  static FILE_CONVERT_FAILED: ErrType = [
    90029,
    '打开失败，请选择其他打开方式',
    'FILE_CONVERT_FAILED',
  ];
  static OBJECT_NOT_EXIST: ErrType = [90030, '指定的对象不存在', 'OBJECT_NOT_EXIST'];
  static TASK_MEMBER_OVERSIZE: ErrType = [90031, '任务成员已满，最多50人', 'TASK_MEMBER_OVERSIZE'];
  static ANALYSIS_NOT_EXIST: ErrType = [90032, '指定的分析结果不存在', 'ANALYSIS_NOT_EXIST'];
  static SHARE_NOT_EXIST: ErrType = [90033, '指定的分享不存在', 'SHARE_NOT_EXIST'];
  static SHARE_EXPIRED: ErrType = [90034, '链接已失效', 'SHARE_EXPIRED'];
  static TASK_EXIST: ErrType = [90035, '任务已存在', 'TASK_EXIST'];
  static TARGET_EXIST: ErrType = [90036, '目标已存在', 'TARGET_EXIST'];
  static CREATOR_NO_QUIT: ErrType = [90037, '创建者不能退出任务', 'CREATOR_NO_QUIT'];
  static USERNAME_EXIST: ErrType = [90038, '用户名已存在', 'USERNAME_EXIST'];
  static PARENT_DIR_NOT_EXIST: ErrType = [90039, '父文件不存在', 'PARENT_DIR_NOT_EXIST'];
  static FILE_MSG_NOT_EXIST: ErrType = [90040, '指定的文件备注不存在', 'FILE_MSG_NOT_EXIST'];
  static PRM_FORMAT_ERROR: ErrType = [90041, 'MDF格式错误', 'PRM_CONTENT_FORMAT_ERROR'];
  static SHARE_CANCELED: ErrType = [90042, '该文件已被取消分享', 'SHARE_CANCELED'];
  static SHARE_NEED_AUTH: ErrType = [90043, '该分享需要输入密码', 'SHARE_NEED_AUTH'];
  static FILERECORD_NOT_EXIST: ErrType = [90044, '文件记录不存在', 'FILERECORD_NOT_EXIST'];
  static EXTERNALUSER_NOT_EXIST: ErrType = [90045, '用户尚未申请授权码', 'EXTERNALUSER_NOT_EXIST'];
  static EXTERNALTOKEN_NOT_EXIST: ErrType = [90046, '授权码不正确', 'EXTERNALTOKEN_NOT_EXIST'];
  static INVITE_NOT_EXIST: ErrType = [90047, '不存在该邀请', 'INVITE_NOT_EXIST'];
  static OFFICIAL_NOT_ALLOW_MODIFY = [90048, '不允许修改官方任务', 'OFFICIAL_NOT_ALLOW_MODIFY'];
  static SMS_ERROR = [90049, '短信服务错误，请稍后再试', 'SMS_ERROR'];
  static SMS_NOT_CONFIG = [90050, '未配置短信网关', 'SMS_NOT_CONFIG'];
  static CONTACTS_ADD_SELF_ERROR = [90051, '不能添加自己为联系人', 'CONTACTS_ADD_SELF_ERROR'];
  static CONTACTS_USER_EXIST = [90052, '已存在该联系人', 'CONTACTS_USER_EXIST'];
  static OFFICIAL_NO_SMS = [90053, '官方账号不能获取短信验证码', 'OFFICIAL_NO_SMS'];
  static FILE_CAN_NOT_RECOVER = [90054, '文件无法恢复', 'FILE_CAN_NOT_RECOVER'];
  static COMMON_FILE_NOT_ALLOW = [90055, '普通文件不能存放到配置文件', 'COMMON_FILE_NOT_ALLOW'];
  static CONVERTRESULT_NOT_EXIST = [90056, '转换结果不存在', 'CONVERTRESULT_NOT_EXIST'];
  static FILE_TOO_LARGE = [90057, '文件过大', 'FILE_TOO_LARGE'];
  static ENTERPRISE_NOT_EXIST = [90058, '企业不存在', 'ENTERPRISE_NOT_EXIST'];
  static ENTERPRISE_EXIST = [90059, '企业已存在', 'ENTERPRISE_EXIST'];
  static MORE_THEN_ONE_ENTERPRISE = [90060, '仅可创建一个企业组织', 'MORE_THEN_ONE_ENTERPRISE'];
  static DEPT_NAME_EXIST = [90061, '部门名已存在', 'DEPT_NAME_EXIST'];
  static DEPT_NOT_EXIST = [90062, '指定部门不存在', 'DEPT_NOT_EXIST'];
  static ENTERPRISE_EXIST_USER = [90063, '企业组织架构中已存在该用户', 'ENTERPRISE_EXIST_USER'];
  static ENTERPRISE_NOT_EXIST_USER = [
    90064,
    '企业组织架构中不存在该用户',
    'ENTERPRISE_NOT_EXIST_USER',
  ];
  static EMAIL_NOT_CONFIG = [90065, '未配置邮箱服务', 'EMAIL_NOT_CONFIG'];
  static EMAIL_SERVICE_ERROR = [90066, '邮箱服务错误，请稍后再试', 'EMAIL_SERVICE_ERROR'];
  static ENTERPRISE_CANNOT_CREATOR = [90067, '不能对企业创建者进行该操作', 'ENTERPRISE_IS_CREATOR'];
  static HANDOVER_NOT_EXIST_TASK = [90068, '不存在此待交接的任务', 'HANDOVER_NOT_EXIST_TASK'];
  static HANDOVER_NOT_TO_THAT_USER = [90069, '不能交接任务给该用户', 'HANDOVER_NOT_TO_THAT_USER'];
  static ENTERPRISE_USER_DEPART = [90070, '成员已经离职', 'ENTERPRISE_USER_DEPART'];
  static ENTERPRISE_USER_NO_DEPART = [90071, '成员不是离职状态', 'ENTERPRISE_USER_NO_DEPART'];
  static ENTERPRISE_DISMISS = [90072, '企业已解散', 'ENTERPRISE_DISMISS'];
  static NOT_PERSONAL_TASK = [90073, '当前任务不是个人任务', 'NOT_PERSONAL_TASK'];
  static isOk(rs: { code: number }): boolean {
    return rs.code === 0;
  }

  static isError(rs: { code: number }): boolean {
    return rs.code !== 0;
  }
}
