const domain = process.env.REACT_APP_DOMAIN || '192.168.2.141';
let ossPrefix = '';
let useSSL = false;
if (domain === 'mdctesting.prmeasure.com') {
  ossPrefix = 'https://datalib-pre.oss-cn-shenzhen.aliyuncs.com';
  useSSL = true;
} else if (domain === 'mdc.prmeasure.com') {
  ossPrefix = 'https://datalib-prod.oss-cn-shenzhen.aliyuncs.com';
  useSSL = true;
}
const protocol = useSSL ? 'https://' : 'http://';
const httpPrefix = protocol + domain;

const apps: { name: string; entry?: string }[] = [
  {
    name: 'datalib-microapp-office',
    // entry: `http://localhost:8001/datalib-microapp-office/`,
  },
  {
    name: 'datalib-microapp-grr',
    // entry: `http://localhost:7078/datalib-microapp-grr/`,
  },
  {
    name: 'datalib-microapp-dapt',
    // entry: `http://localhost:7079/datalib-microapp-dapt/`,
  },
  {
    name: 'datalib-microapp-wav',
    // entry: `http://localhost:7080/datalib-microapp-wav/`,
  },
  {
    name: 'datalib-microapp-cpb',
    // entry: `http://localhost:7081/datalib-microapp-cpb/`,
  },
  {
    name: 'datalib-microapp-correlation',
    // entry: `http://localhost:7083/datalib-microapp-correlation/`,
  },
  {
    name: 'datalib-microapp-dsp-common',
    // entry: `http://localhost:7084/datalib-microapp-dsp-common/`,
  },
  {
    name: 'datalib-microapp-dsp',
    // entry: `http://localhost:7082/datalib-microapp-dsp/`,
  },
  {
    name: 'datalib-microapp-pdf',
    // entry: `http://localhost:7085/datalib-microapp-pdf/`,
  },
  {
    name: 'datalib-microapp-image',
    // entry: `http://localhost:7086/datalib-microapp-image/`,
  },
  {
    name: 'datalib-microapp-Enthalpy',
    // entry: `http://localhost:7087/datalib-microapp-aircondition`,
  },
  {
    name: 'datalib-microapp-Liquid',
    // entry: `http://localhost:7087/datalib-microapp-aircondition`,
  },
  {
    name: 'datalib-microapp-Season',
    // entry: `http://localhost:7087/datalib-microapp-aircondition`,
  },
  {
    name: 'datalib-microapp-Refrigerant',
    // entry: `http://localhost:7087/datalib-microapp-aircondition`,
  },
  {
    name: 'datalib-microapp-Humidity',
    // entry: `http://localhost:7087/datalib-microapp-aircondition`,
  },
  {
    name: 'datalib-microapp-sci-computing',
    // entry: `http://localhost:7088/datalib-microapp-sci-computing/`,
  },
  {
    name: 'datalib-microapp-enthalpyDiffLab',
    // entry: `http://localhost:7089/datalib-microapp-enthalpyDiffLab/`,
  },
  {
    name: 'datalib-microapp-excel-transform',
    // entry: `http://localhost:7090/datalib-microapp-excel-transform/`,
  },
  {
    name: 'datalib-microapp-strain',
    // entry: `http://localhost:7091/datalib-microapp-strain/`,
  },
  {
    name: 'datalib-microapp-spc',
    // entry: `http://localhost:7092/datalib-microapp-spc/`,
  },
];
{
  const airs = [
    'datalib-microapp-Enthalpy',
    'datalib-microapp-Liquid',
    'datalib-microapp-Season',
    'datalib-microapp-Refrigerant',
    'datalib-microapp-Humidity',
  ];
  const suffixApps: { name: string; entry?: string }[] = [];
  apps.forEach((app) => {
    const subdomain = airs.some((k) => k === app.name) ? 'datalib-microapp-aircondition' : app.name;
    if (process.env.NODE_ENV === 'development') {
      if (!app.entry) {
        app.entry = `/${subdomain}/`;
      }
    } else {
      app.entry = `/${subdomain}/?_r=${new Date().getTime()}`;
    }
    for (let i = 0; i < 20; i++) {
      suffixApps.push({
        ...app,
        name: app.name + '#' + i,
      });
    }
  });
  apps.push(...suffixApps);
}
const DINGDING = {
  redirect_uri: encodeURIComponent('https://dtl5.alphagraph.cn/auth'),
  client_id: 'dingx5k92d4xf1blz72f',
  scope: 'openid',
  response_type: 'code',
  state: 'xxxxxxxxx',
  prompt: 'consent',
};
const env = {
  development: {
    FILE_UPLOAD_URL: protocol + '192.168.2.141:8010/group1/upload',
    FILE_DOWNLOAD_URL: protocol + '192.168.2.141:8010/group1',
    DTL_HOST: protocol + '192.168.2.25:9088/data_lab',
    GRID_HOST: protocol + '192.168.2.235:7001/grid_handle',
    STS_HOST: protocol + '192.168.2.141/sts',
    SIGNATURE_HOST: protocol + '192.168.2.141/signature',
    MICRO_APP: apps,
    HTTP_PREFIX: httpPrefix,
    OSS_PREFIX: ossPrefix,
    DINGDING,
  },
  production: {
    FILE_UPLOAD_URL: `${httpPrefix}/file_upload`,
    FILE_DOWNLOAD_URL: `${httpPrefix}/file_download`,
    DTL_HOST: `${httpPrefix}/data_lab`,
    GRID_HOST: `${httpPrefix}/grid_handle`,
    STS_HOST: `${httpPrefix}/sts`,
    SIGNATURE_HOST: `${httpPrefix}/signature`,
    MICRO_APP: apps,
    HTTP_PREFIX: httpPrefix,
    OSS_PREFIX: ossPrefix,
    DINGDING,
  },
};
export default env[useSSL ? 'production' : 'development'];
