export default {
  'pages.taskList.title.myTask': '我的任务',
  'pages.taskList.newTaskGroup.placeholder': '新建分组',
  'pages.taskList.rename': '重命名',
  'pages.taskList.move': '移动到...',
  'pages.taskList.addTask': '新建任务',
  'pages.taskList.addPorETask': '新建个人/企业任务',
  'pages.taskList.addTask.desc': '新建任务，上传你的数据',
  'pages.taskList.addTask.modal.BigTitle': '新建任务',
  'pages.taskList.addTask.modal.taskName': '任务名称',
  'pages.taskList.addTask.modal.taskType': '任务类型',
  'pages.taskList.addTask.personalTask': '个人任务',
  'pages.taskList.addTask.enterpriseTask': '企业任务',
  'pages.taskList.addTask.allType': '全部类型',
  'pages.taskList.addTask.filterType': '筛选类型',
  'pages.taskList.addTask.modal.taskName.placeholder': '任务名称',
  'pages.taskList.addTask.modal.taskDesc': '任务说明',
  'pages.taskList.addTask.modal.taskDesc.placeholder':
    '简单说明实验的目的、方法、内容、实验环境和步骤',
  'pages.taskList.table.name': '任务名称',
  'pages.taskList.table.name.placeholder': '请输入任务名称',
  'pages.taskList.table.desc': '说明',
  'pages.taskList.table.creator': '创建者',
  'pages.taskList.table.createTime': '创建时间',
  'pages.taskList.newTaskGroup': '新分组',
  'pages.taskList.newTaskGroup.input.placeholder': '新分组名称',
  'pages.taskList.empty': '暂无任务',
  'pages.taskList.newTask': '新建',
  'pages.taskList.noOtherGroup': '暂无可移动文件夹',
  'pages.taskLeftSide.title': '任务',
  'pages.privateTask': '我的个人任务',
  'pages.officialTask': '魔数云官方任务',
  'pages.newTask': '新任务',
  'pages.newFolder': '新建文件夹',
  'pages.newTaskGroup': '新建分组',
  配置文件: '配置文件',
  原始数据: '原始数据',
  处理结果: '处理结果',
};
