import API_VERSION from '@/services/datalib/api/config';
import request from '@/utils/request';

export async function dtlTaskFileCreateDir(body: DtlTaskFile.CreateDirParam) {
  return request<DtlComm.ResultV<{ fileId: string }>>(`${API_VERSION}/api/task/file/createDir`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskFileRenameFile(body: DtlTaskFile.RenameFileParam) {
  return request<DtlComm.ResultV<{ fileId: string }>>(`${API_VERSION}/api/task/file/renameFile`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskFileMoveFiles(body: DtlTaskFile.MoveFilesParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/file/moveFiles`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskFileDelFiles(body: DtlTaskFile.DelFilesParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/file/delFiles`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskFileDelFilesCompletely(body: DtlTaskFile.DelFilesCompletelyParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/file/delFilesCompletely`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskDismissCompletely(body: DtlTaskFile.TaskDismissCompletelyParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/dismissTaskCompletely`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskFileDelFilesRecover(body: DtlTaskFile.DelFilesCompletelyParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/file/delFilesRecover`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskRecover(body: DtlTaskFile.TaskRecoverParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/recoverTask`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskFileUploadFiles(body: DtlTaskFile.UploadFilesParam) {
  return request<
    DtlComm.ResultV<{
      added: string[];
    }>
  >(`${API_VERSION}/api/task/file/uploadFiles`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

// export async function dtlTaskFileCopyFiles(body: DtlTaskFile.CopyFilesParam) {
//   return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/file/copyFiles`, {
//     method: 'POST',
//     data: {
//       ...body,
//     },
//   });
// }

export async function dtlTaskFileCopyFilesToTask(body: DtlTaskFile.CopyFilesToTaskParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/file/copyToTask`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskFileGetFileTree(body: DtlTaskFile.GetFileTreeParam) {
  return request<DtlComm.ResultV<DtlTaskFile.AllFilesResult>>(
    `${API_VERSION}/api/task/file/getFileTree`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlTaskFileConvert(body: DtlTaskFile.FileConvertParam) {
  return request<DtlComm.ResultV<DtlTaskFile.FileConvertResult>>(
    `${API_VERSION}/api/task/file/convert`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlTaskFileSetOpenType(body: DtlTaskFile.FileSetOpenTypeParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/file/setOpenType`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskFileAddAnalysis(body: DtlTaskFile.FileAddAnalysisParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/file/addAnalysis`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskFileUpdateAnalysis(body: DtlTaskFile.FileUpdateAnalysisParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/file/updateAnalysis`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskFileDelAnalysis(body: DtlTaskFile.FileDelAnalysisParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/file/delAnalysis`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskFileAddConv(body: DtlTaskFile.FileAddConvParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/file/addConv`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskFileShareFileToTask(body: DtlTaskFile.ShareFileToTaskParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/file/shareFileToTask`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskFileAddMsg(body: DtlTaskFile.FileAddMsgParam) {
  return request<DtlComm.ResultV<DtlTaskFile.FileAddMsgResult>>(
    `${API_VERSION}/api/task/file/addMsg`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlTaskFileDelMsg(body: DtlTaskFile.FileDelMsgParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/file/delMsg`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskFileUpdateContent(body: DtlTaskFile.FileUpdateContentParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/file/updateContent`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskFileInfo(body: { taskId: string; fileId: string }) {
  return request<DtlComm.ResultV<DtlBean.TaskFile>>(`${API_VERSION}/api/task/file/info`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}
