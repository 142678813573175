import API_VERSION from '@/services/datalib/api/config';
import request from '@/utils/request';

export async function dtlToolsDrawingDistributionSearch(
  body: DtlTools.DrawingDistributionSearchParam,
) {
  return request<DtlComm.ResultV<DtlTools.ConvResult>>(
    `${API_VERSION}/api/tools/drawingDistribution/search`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlToolsDrawingDistributionDownload(
  body: DtlTools.DrawingDistributionDownloadParam,
) {
  return request<DtlComm.ResultV<DtlTools.ConvResult>>(
    `${API_VERSION}/api/tools/drawingDistribution/download`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlCaGenerate(body: DtlTools.CaGenerateParam) {
  return request<DtlComm.ResultV<DtlTools.ConvResult>>(`${API_VERSION}/api/tools/ca/generate`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlCaCompare(body: DtlTools.CaCompareParam) {
  return request<DtlComm.ResultV<DtlTools.ConvResult>>(`${API_VERSION}/api/tools/ca/compare`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlCpbGenerate(body: DtlTools.CPBGenerateParam) {
  return request<DtlComm.ResultV<DtlTools.ConvResult | undefined>>(
    `${API_VERSION}/api/tools/cpb/generate`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlMaterialMatch(body: DtlTools.MaterialMatchParam) {
  return request<DtlComm.ResultV<DtlTools.ConvResult>>(`${API_VERSION}/api/tools/material/match`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlConvertBatch(body: DtlTools.ConvertBatchParam) {
  return request<DtlComm.ResultV<DtlTools.ConvertBatchResult>>(
    `${API_VERSION}/api/tools/convert/batch`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlCorrelationGenerate(body: DtlTools.CorrelationGenerateParam) {
  return request<DtlComm.ResultV<string>>(`${API_VERSION}/api/tools/correlation/generate`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlDaptGenerate(body: DtlTools.DaptGenerateParam) {
  return request<DtlComm.ResultV<string>>(`${API_VERSION}/api/tools/dapt/generate`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlGrrGenerate(body: DtlTools.CorrelationGenerateParam) {
  return request<DtlComm.ResultV<string>>(`${API_VERSION}/api/tools/grr/generate`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}
