import t from '@/locales';
import { getFullUrl } from '@/services/datalib';
import { ExtType, officeExts, TaskFileType, TaskType } from '@/services/datalib/enum';

export enum CaCreateType {
  CaReport = 1,
  DataCompare = 2,
  Schedules = 3,
}

export enum ToolBoxType {
  PaperSearch = 1,
  MaterialMatch = 2,
}

export enum TabType {
  Item = '1',
  Storage = '2',
}

export enum TaskBarType {
  unknown = 'unknown',
  Tool_Table = 'tool_table',
  File = 'file',
  Item = 'item',
  Del_File = 'del_file',
  FeedBack = 'FeedBack',
  FeedBackOfficial = 'FeedBackOfficial',
  Cor = 'Cor',
  GRR = 'GRR',
  DAPT = 'DAPT',
  WavView = 'WavView', //波形查看
  CPB = 'CPB',
  DSP = 'DSP',
  office = 'office',
  Image = 'Image',
  Video = 'Video',
  Audio = 'Audio',
  PDF = 'PDF',
  Enthalpy = 'Enthalpy',
  Liquid = 'Liquid',
  Season = 'Season',
  Refrigerant = 'Refrigerant',
  Humidity = 'Humidity',
  SCIC = 'SCIC',
  EnthalpyDiffLab = 'EnthalpyDiffLab',
  StrainRawData = 'StrainRawData',
  StrainTimeHistory = 'StrainTimeHistory',
  SinglePeak = 'SinglePeak',
}

export enum ProgramType {
  GRR = 'GRR',
  CPB = 'CPB',
  DAPT = 'DAPT',
  DSP = 'DSP',
  Correlation = 'correlation',
  WavView = 'WavView',
}

export enum FileOpenType {
  ToolFile = 'toolFile',
  ToolCollect = 'toolCollect',
  ToolPanel = 'toolPanel',
  FileInTaskOpen = 'fileInTaskOpen',
  FileRightAnal = 'fileRightAnal',
  Share = 'share',
}

export const FileViewType = TaskBarType;

//微应用名字
export const microAppName = {
  [FileViewType.office]: 'office',
  [FileViewType.WavView]: 'dsp-common',
  [FileViewType.GRR]: 'grr',
  [FileViewType.DAPT]: 'dapt',
  [FileViewType.CPB]: 'cpb',
  [FileViewType.DSP]: 'dsp',
  [FileViewType.Cor]: 'correlation',
  [FileViewType.PDF]: 'pdf',
  [FileViewType.Image]: 'image',
  [FileViewType.Enthalpy]: 'Enthalpy',
  [FileViewType.Liquid]: 'Liquid',
  [FileViewType.Season]: 'Season',
  [FileViewType.Refrigerant]: 'Refrigerant',
  [FileViewType.Humidity]: 'Humidity',
  [FileViewType.SCIC]: 'sci-computing',
  [FileViewType.EnthalpyDiffLab]: 'enthalpyDiffLab',
  [FileViewType.StrainRawData]: 'strain',
  [FileViewType.StrainTimeHistory]: 'strain',
  [FileViewType.SinglePeak]: 'spc',
};

export const hasSourceTabFileTypes = [
  FileViewType.WavView,
  FileViewType.CPB,
  FileViewType.DSP,
  FileViewType.GRR,
  FileViewType.DAPT,
  FileViewType.Cor,
  FileViewType.Humidity,
  FileViewType.Refrigerant,
  FileViewType.Season,
  FileViewType.Liquid,
  FileViewType.Enthalpy,
  FileViewType.SCIC,
  FileViewType.SinglePeak,
];
export const wavTypes = [TaskBarType.WavView, TaskBarType.CPB, TaskBarType.DSP];

export const createTypes = {
  GRR: TaskFileType.TASKFIEL_TYPE_Grr,
  DAPT: TaskFileType.TASKFIEL_TYPE_Dapt,
  CPB: TaskFileType.TASKFIEL_TYPE_Cpb,
  DSP: TaskFileType.TASKFIEL_TYPE_Dsp,
  correlation: TaskFileType.TASKFIEL_TYPE_Correlation,
  SCIC: TaskFileType.TASKFIEL_TYPE_PRM_SCIC,
  SinglePeak: TaskFileType.TASKFIEL_TYPE_PRM_SPC,
};
export const ConvertProgressType = {
  INIT: 'init',
  LOADING: 'loading',
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected',
};

export const FileSizeLimit = {
  [TaskFileType.TASKFIEL_TYPE_IMG]: 10 * 1024 * 1024,
};

export const getPathExtension = (path: string): string => {
  if (!path) return '';
  return path
    .substring(path.lastIndexOf('.') + 1)
    .trim()
    .toLowerCase();
};

export const getFileType = (ext: string) => {
  let type = 1;
  for (const i in ExtType) {
    const list = ExtType[i];
    const index = list.findIndex((v: string) => v === ext);
    if (index >= 0) {
      type = Number(i);
      break;
    }
  }
  return type;
};

export const getImageSize = async (url: string): Promise<{ height: number; width: number }> => {
  return await new Promise((resolve) => {
    const img = new Image();
    img.src = url;
    img.addEventListener('load', () => resolve({ height: img.height, width: img.width }));
  });
};

export const getBase64 = async (img: File) => {
  return await new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result));
    reader.readAsDataURL(img);
  });
};

export const searchExistDirName = (
  path: string,
  filterList: DtlBean.TaskFile[],
  indexList: number[],
) => {
  let index = filterList.findIndex((filter) => path === filter.path);
  if (index !== -1) indexList.push(index);
  else {
    index = filterList.findIndex((filter) => path.indexOf(filter.path + '/') === 0);
    if (index === -1) indexList.push(-1);
    else {
      if (path === filterList[index].path) indexList.push(index);
      else {
        const children = filterList[index].children || [];
        if (children) {
          indexList.push(index);
          searchExistDirName(path, children, indexList);
        } else {
          indexList.push(-1);
        }
      }
    }
  }
  return indexList;
};

export const searchExistFile: any = (path: string, filterList: DtlBean.TaskFile[]) => {
  let index = filterList?.findIndex((filter) => path === filter.path);
  if (index !== -1) return filterList[index];
  else {
    index = filterList?.findIndex((filter) => path?.indexOf(filter.path + '/') === 0);
    if (index !== -1) {
      const children = filterList[index].children || [];
      if (path === filterList[index].path) return filterList[index];
      else if (children.length) return searchExistFile(path, children);
    }
  }
  return null;
};

export const addFileToFileTree = (
  list: DtlBean.TaskFile[],
  file: DtlBean.TaskFile,
  indexList: number[],
) => {
  if (indexList.length && indexList[indexList.length - 1] === -1) {
    if (indexList.length === 1) list.unshift(file);
    else {
      let treeFile = list[indexList[0]];
      for (let i = 1; i < indexList.length - 1; i++) {
        const children = treeFile.children || [];
        treeFile = children[indexList[i]];
      }
      if (!treeFile.children) treeFile.children = [];
      treeFile.children.unshift(file);
    }
  }
  return list;
};

export const getFileIconUrl = (props: { type?: number; name?: string; iconName?: string }) => {
  const { type, name } = props;
  let iconName = props?.iconName || '';

  if (!iconName) {
    let fileType = type;
    if (!fileType && name) {
      const ext = getPathExtension(name);
      fileType = getFileType(ext);
    }
    if (fileType === 1 && name) {
      const ext = getPathExtension(name);
      if (ext === 'dat') {
        fileType = getFileType(ext);
      }
    }

    switch (fileType) {
      case TaskFileType.TASKFIEL_TYPE_IMG:
        iconName = 'picture';
        break;
      case TaskFileType.TASKFIEL_TYPE_PDF:
        iconName = 'pdf';
        break;
      case TaskFileType.TASKFIEL_TYPE_VIDEO:
        iconName = 'video';
        break;
      case TaskFileType.TASKFIEL_TYPE_AUDIO:
      case TaskFileType.TASKFIEL_TYPE_WAV:
        iconName = 'voice';
        break;
      case TaskFileType.TASKFIEL_TYPE_EXCEL:
        iconName = 'data';
        break;
      case TaskFileType.TASKFIEL_TYPE_JS:
      case TaskFileType.TASKFIEL_TYPE_JSON:
      case TaskFileType.TASKFIEL_TYPE_TXT:
      case TaskFileType.TASKFIEL_TYPE_WORD:
      case TaskFileType.TASKFIEL_TYPE_YML:
      case TaskFileType.TASKFIEL_TYPE_PPT:
        iconName = 'text';
        break;
      case TaskFileType.TASKFILE_TYPE_DIR:
        iconName = 'folder';
        break;
      case TaskFileType.TASKFIEL_TYPE_CA:
      case TaskFileType.TASKFIEL_TYPE_CAREPORT:
        iconName = 'caReport';
        break;
      case TaskFileType.TASKFIEL_TYPE_DATACOMPARE:
        iconName = 'dataCompare';
        break;
      case TaskFileType.TASKFIEL_TYPE_OFFICE:
        iconName = 'office';
        break;
      case TaskFileType.TASKFIEL_TYPE_COMPRESS:
        iconName = 'compress';
        break;
      case TaskFileType.TASKFILE_TYPE_DAT:
        iconName = 'dat';
        break;
      case TaskFileType.TASKFIEL_TYPE_Correlation:
        iconName = 'correlation';
        break;
      case TaskFileType.TASKFIEL_TYPE_Dsp_Common:
        iconName = 'dsp_common';
        break;
      case TaskFileType.TASKFIEL_TYPE_Grr:
        iconName = 'grr';
        break;
      case TaskFileType.TASKFIEL_TYPE_Dapt:
        iconName = 'Dapt';
        break;
      case TaskFileType.TASKFIEL_TYPE_Dsp:
        iconName = 'dsp';
        break;
      case TaskFileType.TASKFIEL_TYPE_Cpb:
        iconName = 'Cpb';
        break;
      case TaskFileType.TASKFIEL_TYPE_PRM_IMG:
        iconName = 'picture_result';
        break;
      case TaskFileType.TASKFIEL_TYPE_PRM_PDF:
        iconName = 'pdf_result';
        break;
      case TaskFileType.TASKFIEL_TYPE_PRM_Enthalpy:
        return `home_Enthalpy_active@3x`;
      case TaskFileType.TASKFIEL_TYPE_PRM_Humidity:
        return `home_Humidity_active@3x`;
      case TaskFileType.TASKFIEL_TYPE_PRM_Refrigerant:
        return `home_Refrigerant_active@3x`;
      case TaskFileType.TASKFIEL_TYPE_PRM_Season:
        return `home_Season_active@3x`;
      case TaskFileType.TASKFIEL_TYPE_PRM_Liquid:
        return `home_Liquid_active@3x`;
      case TaskFileType.TASKFIEL_TYPE_PRM_SCIC:
        iconName = 'SCIC';
        break;
      case TaskFileType.TASKFIEL_TYPE_PRM_EnthalpyDiffLab:
        iconName = 'EnthalpyDiffLab';
        break;
      case TaskFileType.TASKFIEL_TYPE_PRM_StrainRawData:
        iconName = 'StrainRawData';
        break;
      case TaskFileType.TASKFIEL_TYPE_PRM_StrainTimeHistory:
        iconName = 'StrainTimeHistory';
        break;
      case TaskFileType.TASKFIEL_TYPE_PRM_SPC:
        iconName = 'SinglePeak';
        break;
      default:
        iconName = 'unclear';
        break;
    }
  }
  return `file_${iconName}@3x`;
};

export const formatNumber = (value: number | string) => {
  let newValue = '';

  if ((!value && value !== 0) || Number.isNaN(value) || Number.isNaN(Number(value))) newValue = '-';
  else if (value.toString().length > 3) newValue = Number(value).toFixed(3);
  else newValue = value.toString();

  return newValue;
};

export const stringSort = (a: string, b: string) => {
  return a.localeCompare(b, 'zh-CN', { numeric: true });
};

export const handleDeepRenamePath = (father: any, changeName: string, oldName: string) => {
  if (father?.children && father.children.length > 0) {
    father.children.forEach((child: any) => {
      handleDeepRenamePath(child, changeName, oldName);
    });
  }
  if (father.path !== changeName) {
    // eslint-disable-next-line no-param-reassign
    father.path = changeName + father.path.slice(oldName.length);
  }
};

export const fileIconUrl = (
  type?: number,
  openWay?: string,
  iconName?: string,
  name?: string,
  sid?: number,
) => {
  let showType = type;

  if (openWay) {
    const array = openWay.split('/');
    if (array && array.length) showType = Number(array[0]);
  }
  if (sid) {
    showType = sid;
  }
  if (showType === TaskFileType.TASKFIEL_TYPE_OFFICE && type === TaskFileType.TASKFIEL_TYPE_EXCEL) {
    showType = TaskFileType.TASKFIEL_TYPE_EXCEL;
  }
  return getFileIconUrl({ type: showType, name, iconName });
};

export const getOpenMethodIconUrl = (type: number) => {
  let iconName = '';

  switch (type) {
    case TaskFileType.TASKFIEL_TYPE_IMG:
      iconName = 'picture';
      break;
    case TaskFileType.TASKFIEL_TYPE_PDF:
      iconName = 'pdf';
      break;
    case TaskFileType.TASKFIEL_TYPE_EXCEL:
      iconName = 'excel';
      break;
    case TaskFileType.TASKFIEL_TYPE_VIDEO:
      iconName = 'video';
      break;
    case TaskFileType.TASKFIEL_TYPE_AUDIO:
      iconName = 'audio';
      break;
    case TaskFileType.TASKFIEL_TYPE_WAV:
      iconName = 'wav';
      break;
    case TaskFileType.TASKFIEL_TYPE_OFFICE:
      iconName = 'office';
      break;
    case TaskFileType.TASKFIEL_TYPE_Correlation:
      iconName = 'correlation';
      break;
    case TaskFileType.TASKFIEL_TYPE_Grr:
      iconName = 'GRR';
      break;
    case TaskFileType.TASKFIEL_TYPE_Dapt:
      iconName = 'DAPT';
      break;
    case TaskFileType.TASKFIEL_TYPE_Dsp:
      iconName = 'dsp';
      break;
    case TaskFileType.TASKFIEL_TYPE_Cpb:
      iconName = 'CPB';
      break;
    case TaskFileType.TASKFIEL_TYPE_PRM_SCIC:
      iconName = 'SCIC';
      break;
    case TaskFileType.TASKFIEL_TYPE_PRM_EnthalpyDiffLab:
      iconName = 'EnthalpyDiffLab';
      break;
    case TaskFileType.TASKFIEL_TYPE_PRM_StrainRawData:
      iconName = 'StrainRawData';
      break;
    case TaskFileType.TASKFIEL_TYPE_PRM_StrainTimeHistory:
      iconName = 'StrainTimeHistory';
      break;
    case TaskFileType.TASKFIEL_TYPE_PRM_SPC:
      iconName = 'SinglePeak';
      break;
    default:
      iconName = 'unclear';
      break;
  }
  return `open_${iconName}`;
};

export const computedFileSize = (fileSize: number) => {
  let size = fileSize / 1024;
  let unit = 'KB';
  if (size > 1000) {
    size /= 1024;
    unit = 'MB';

    if (size > 1000) {
      size /= 1024;
      unit = 'GB';
    }
  }

  return size.toFixed(2) + unit;
};

//随机字符串
export const randomString = () => {
  return Math.random().toString(36).substr(2);
};

//检查div是否溢出
export const checkLength = (ellipsis: any) => {
  const myClientWidth = ellipsis?.clientWidth;
  const myScrollWidth = ellipsis?.scrollWidth;
  if (myClientWidth !== undefined) {
    return myClientWidth < myScrollWidth;
  }

  return false;
};

export const searchFileCatalogue: any = (
  path: string,
  filterList: DtlBean.TaskFile[],
  list: any,
) => {
  let index = filterList.findIndex((filter) => path === filter.path);
  if (index === -1) {
    index = filterList.findIndex((filter) => path.indexOf(filter.path + '/') === 0);
    if (index !== -1) {
      const children = filterList[index].children || [];
      if (children.length) {
        list.push(filterList[index]);
        searchFileCatalogue(path, children, list);
      }
    }
  }
  return list;
};

export function IsOfficeFile(type: TaskFileType, fileName?: string) {
  switch (Number(type)) {
    case TaskFileType.TASKFIEL_TYPE_OFFICE:
    case TaskFileType.TASKFIEL_TYPE_PPT:
    case TaskFileType.TASKFIEL_TYPE_WORD:
    case TaskFileType.TASKFIEL_TYPE_EXCEL:
    case TaskFileType.TASKFIEL_TYPE_CAREPORT:
    case TaskFileType.TASKFIEL_TYPE_DATACOMPARE:
    case TaskFileType.TASKFIEL_TYPE_CA:
    case TaskFileType.TASKFIEL_TYPE_TXT:
      return true;
    default:
      break;
  }
  if (fileName) {
    const ext = getPathExtension(fileName);
    return officeExts.some((s) => s === ext);
  }
  return false;
}

export const getViewType = (type: number) => {
  if (IsOfficeFile(type)) return FileViewType.office;
  else if (type === TaskFileType.TASKFIEL_TYPE_WAV) return FileViewType.WavView;
  else if (type === TaskFileType.TASKFIEL_TYPE_IMG || type === TaskFileType.TASKFIEL_TYPE_PRM_IMG)
    return FileViewType.Image;
  else if (type === TaskFileType.TASKFIEL_TYPE_VIDEO) return FileViewType.Video;
  else if (type === TaskFileType.TASKFIEL_TYPE_AUDIO) return FileViewType.Audio;
  else if (type === TaskFileType.TASKFIEL_TYPE_Correlation) return FileViewType.Cor;
  else if (type === TaskFileType.TASKFIEL_TYPE_Dsp_Common) return FileViewType.WavView;
  else if (type === TaskFileType.TASKFIEL_TYPE_Cpb) return FileViewType.CPB;
  else if (type === TaskFileType.TASKFIEL_TYPE_Dsp) return FileViewType.DSP;
  else if (type === TaskFileType.TASKFIEL_TYPE_Grr) return FileViewType.GRR;
  else if (type === TaskFileType.TASKFIEL_TYPE_Dapt) return FileViewType.DAPT;
  else if (type === TaskFileType.TASKFIEL_TYPE_PDF || type === TaskFileType.TASKFIEL_TYPE_PRM_PDF)
    return FileViewType.PDF;
  else return '';
};

export const getOpenWayNumber = (file: any) => {
  if (file?.openWay) {
    const numberIndex = file?.openWay.indexOf('/');
    return numberIndex !== -1 ? file?.openWay.slice(0, numberIndex) : file?.openWay;
  } else {
    return file.type;
  }
};

export const getShareInTaskInfo = (tasks: any, currentUser: any, shareTaskId: string) => {
  let targetDefaultTaskId: string | undefined = undefined; //记录中任务id
  let targetDefaultTaskInfo = undefined; //记录中任务信息
  let ownTaskInfo = undefined; //在任务中的任务信息
  let myTaskId = undefined; //我的任务id
  let myTaskInfo = undefined; //我的任务信息
  const defaultInfo = localStorage.getItem('defaultTaskInfo')
    ? JSON.parse(localStorage.getItem('defaultTaskInfo') as string)
    : {};
  const defaultLength = Object.keys(defaultInfo).length;
  if (defaultLength) {
    Object.keys(defaultInfo).forEach((userId) => {
      if (currentUser && userId === currentUser.userId) targetDefaultTaskId = defaultInfo[userId];
    });
  }
  if (tasks) {
    Object.keys(tasks).forEach((taskId: string) => {
      if (shareTaskId === taskId) {
        //在任务中
        ownTaskInfo = tasks[taskId];
      }
      if (targetDefaultTaskId && taskId === targetDefaultTaskId) {
        //读浏览器缓存
        targetDefaultTaskInfo = tasks[taskId];
      }
      if (tasks[taskId].type === TaskType.PRIVATE) {
        myTaskId = taskId;
        myTaskInfo = tasks[taskId];
      }
    });
  }

  if (!targetDefaultTaskInfo && !ownTaskInfo) {
    //不在任务且本地没有侧边栏信息,记录默认信息
    if (currentUser?.userId) {
      localStorage.setItem(
        'defaultTaskInfo',
        JSON.stringify({ ...defaultInfo, [currentUser.userId]: myTaskId }),
      );
    }
  }
  return { ownTaskInfo, targetDefaultTaskInfo, myTaskInfo };
};

export const getConvertResultStatus = (convertInfo: any) => {
  const convertResult = convertInfo?.result && JSON.parse(convertInfo?.result);

  const isConvertInit = !convertInfo?.id;

  const isConvertFailed = !!convertResult && convertResult.status < 0;

  const isConvertSuccess = !!convertResult && convertResult.status >= 0;

  let result = ConvertProgressType.LOADING;

  if (isConvertInit) {
    result = ConvertProgressType.INIT;
  }
  if (isConvertFailed) {
    result = ConvertProgressType.REJECTED;
  }
  if (isConvertSuccess) {
    result = ConvertProgressType.FULFILLED;
  }

  return result;
};

export const getSrcFullPath = (key: any) => {
  return getFullUrl(key);
};

export const getTaskBarTypeFromAppId = (appId: string) => appId.split('_')[0];
export const newAppId = (taskBarType: TaskBarType | string) => taskBarType + '_' + randomString();

export const handleOriginFileName = (list: any) => {
  if (list.length) {
    list.forEach((fileInfo: any) => {
      //只有root下自动生成的文件夹需要翻译
      if (
        fileInfo.path.split('/').length === 3 &&
        ['配置文件', '原始数据', '处理结果'].includes(fileInfo.name)
      ) {
        fileInfo.nameLocale = t(fileInfo.name, fileInfo.name);
      }
    });
  }
  return list;
};
