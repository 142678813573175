const button = {
  'btn.newDir': 'New Folder',
  'btn.upload': 'Upload',
  'btn.uploading': 'Uploading',
  'btn.cancel': 'Cancel',
  'btn.submit': 'OK',
  'btn.reset': 'Reset',
  'btn.open': 'Open',
  'btn.remark': 'Remark',
  'btn.remind': '@Remind',
  'btn.labelTemplate': 'Tag Template',
  'btn.saveLabelTemplate': 'Save Tag as Template',
  'btn.delete': 'Delete',
  'btn.sendMsg': 'Send',
  'btn.sendImg': 'Insert Picture',
  'btn.msgRemind': '@ Remind',
  'btn.agreeAll': 'Agree all',
  'btn.reject': 'Reject',
  'btn.agree': 'Agree',
  'btn.addMember': 'Add Members',
  'btn.addToMailList': 'Add to Address Book',
  'btn.add': 'Add',
  'btn.saveToTask': 'Save',
  'btn.saveToTask.gridlab': 'Save',
  'btn.otherSaveToTask': 'Save As',
  'btn.editTpl': 'Edit',
  'btn.importTpl': 'Import template',
  'btn.newTpl': 'New template',
  'btn.analysis': 'Analysis',
  'btn.hasAnalysis': 'Resolved',
  'btn.nextStep': 'Next',
  'btn.addLimit': 'Add limit',
  'btn.use': 'Apply',
  'btn.changeCoordinate': 'Modify coordinates',
  'btn.editChart': 'Edit chart',
  'btn.useToAll': 'Apply to all',
  'btn.clearAll': 'Clear',
  'btn.calculate': 'Calculate',
  'btn.insert': 'Insert',
  'btn.format': 'Format',
  'btn.export': 'Export',
  'btn.exportExcel': 'Export Excel report',
  'btn.exportWord': 'Export Word report',
  'btn.save': 'Save',
  'btn.close': 'Close',
  'btn.setTop': 'Sticky to Top',
  'btn.edit': 'Edit',
  'btn.exitEdit': 'Exit Editing',
  'btn.dealWav': 'Waveform processing',
  'btn.Mark-up': 'Mark',
  'btn.share': 'Share',
  'btn.saveAs': 'Save As',
  'btn.login': 'Sign in',
  'btn.freeUse': 'Free Access',
  'btn.downloadView': 'Download View',
  'btn.more': 'More',
  'btn.setting': 'Settings',
  'btn.inverse': 'Inverse',
  'btn.refresh': 'Refresh',
  'btn.addPackage': 'Add Toolkit',
  'btn.finish': 'Complete',
  'btn.importSheet': 'Import sheet data',
  'btn.copy': 'Copy',
};

const popover = {
  'popover.rename': 'Rename',
  'popover.delete': 'Delete',
  'popover.reset': 'Reset',
  'popover.personalCenter': 'Personal Center',
  'popover.mailList': 'Address book',
  'popover.version': 'Version:',
  'popover.loginOut': 'Sign out',
  'popover.dismiss': 'Dismiss',
  'popover.quit': 'Quit',
  'popover.removeFromList': 'Remove',
  'popover.share': 'Share',
  'popover.export': 'Export',
  'popover.collect': 'Collect',
  'popover.hasCollect': 'Collected',
  'popover.ZoomByRect': 'Rectangle Pick Zoom',
  'popover.ZoomByX': 'X-axis selection zoom',
  'popover.ZoomByY': 'Y-axis selection zoom',
  'popover.resetZoom': 'Zoom to Fit',
  'popover.scaleX': 'Scale X-axis',
  'popover.scaleY': 'Scale Y-axis',
  'popover.thinLine': 'Thin Line',
  'popover.thickLine': 'Thick Line',
  'popover.dashedLine': 'Dashed Line',
  'popover.thickDashedLine': 'Thick Dashed Line',
  'popover.addContact': 'Add contact',
  'popover.moveTo': 'Move to ...',
  'popover.moveOutGroup': 'Remove group',
  'popover.watchCard': 'View information',
  'popover.remarkName': 'Remark',
  'popover.copy': 'Copy',
  'popover.selectData': 'Selected data',
  'popover.forbidden': 'Forbidden',
  'popover.cancelForbidden': 'Cancel Forbidden',
};

const tooltip = {
  'tooltip.preview': 'Preview',
  'tooltip.download': 'Download',
  'tooltip.copyTo': 'Copy to ...',
  'tooltip.share': 'Share',
  'tooltip.collect': 'Collect',
  'tooltip.cancelCollect': 'Cancel Collection',
  'tooltip.openWay': 'Open with',
  'tooltip.save': 'Save',
  'tooltip.delete': 'Delete',
  'tooltip.removeTask': 'Remove',
  'tooltip.tileView': 'Tile View',
  'tooltip.listView': 'List View',
  'tooltip.newDir': 'New Folder',
  'tooltip.upload': 'Upload',
  'tooltip.saveAs': 'Save As',
  'tooltip.dragToMove': 'Drag and Move',
  'tooltip.selectZoomIn': 'Zoom in on selected area',
  'tooltip.resetZoom': 'Zoom to Fit',
  'tooltip.ZoomIn': 'Zoom In',
  'tooltip.ZoomOut': 'Zoom Out',
  'tooltip.controlDimensions': 'Show or Hide annotations',
  'tooltip.undo': 'undo',
  'tooltip.redo': 'redo',
  'tooltip.drawingPin': 'Thumbtack',
  'tooltip.finishDimensions': 'End Mark',
  'tooltip.borderStyle': 'Border style',
  'tooltip.color': 'Color',
  'tooltip.endDealWav': 'End waveform processing',
  'tooltip.localImport': 'Local Import',
  'tooltip.copy': 'Copy',
  'tooltip.paste': 'Paste',
  'tooltip.insertCol': 'Insert Column',
  'tooltip.insertRow': 'Insert Row',
  'tooltip.delColRow': 'Delete Rows/Columns',
  'tooltip.adjust': 'Adjust',
  'tooltip.fullScreen': 'Full screen',
};

const message = {
  'message.revise.ok': 'Modified successfully',
  'message.revise.fail': 'Modified failed',
  'message.addTemp.fail': 'Add failed',
  'message.delTemp.fail': 'Delete failed',
  'message.addTask.ok': 'Create task succeeded！',
  'message.addTask.fail': 'Create task failed！',
  'message.delGroup.ok': 'Success to delete group！',
  'message.delGroup.fail': 'Failed to delete group！',
  'message.moveGroupToArchive.ok': 'Group storage succeeded！',
  'message.moveGroupToArchive.fail': 'Group storage failed！',
  'message.moveGroupOutArchive.ok': 'Group move out from storage group succeeded！',
  'message.moveGroupOutArchive.fail': 'Group move out from storage group failed！',
  'message.addGroup.ok': 'Successfully created a new group！',
  'message.addGroup.fail': 'Failed to create a new group！',
  'message.updateTaskName.ok': 'Successfully modified the group name',
  'message.updateTaskName.fail': 'Failed to modify the group name！',
  'message.moveTask.ok': 'Move task succeeded！',
  'message.moveTask.fail': 'Move task failed！',
  'message.moveTaskToArchive.ok': 'Task storage succeeded！',
  'message.moveTaskToArchive.fail': 'Task storage failed！',
  'message.moveTaskOutArchive.ok': 'Task move out from storage box succeeded！',
  'message.moveTaskOutArchive.fail': 'Task move out from storage box failed！',
  'message.previewNoSupport': 'This type of file preview is not currently supported',
  'message.getUserInfo.fail': 'Failed to obtain user task information',
  'message.registerSuccessAndCompleteInfo':
    'Registration succeeded! Please complete your personal information as soon as possible!',
  'message.canOpenFile.fail': ' Open failed. Please choose another opening method',
  'message.openFile.fail.retry': 'Open failed. Please try again later',
  'message.analyzing': 'Analyzing...',
  'message.operationFailAndAgain': 'Operation failed. Please try again later',
  'message.grrAnalyze': ' analysis',
  'message.onlyExcelSupport': ' only supports Excel format files',
  'message.fileNotExist': 'The original file does not exist',
  'message.shareIsClose': 'Sharing is turned off',
  'message.getShareInfo.fail': 'Failed to obtain shared file information',
  'message.getFiles.fail': 'Failed to get file info',
  'message.copyFileTo.fail': 'Copy failed',
  'message.copyFileTo.ok': 'Copy succeeded',
  'message.getShareFileInfo.fail': 'Failed to obtain file sharing information',
  'message.delRecord.ok': 'Record deleted successfully',
  'message.delRecord.fail': 'Record deleted failed',
  'message.cancelCollect.ok': 'Cancel collection succeeded',
  'message.cancelCollect.fail': 'Cancel collection failed',
  'message.addCollect.ok': 'Collection succeeded',
  'message.addCollect.fail': 'Collection failed',
  'message.newCollectFold.ok': 'Created a new favorite successfully',
  'message.newCollectFold.fail': 'Created a new favorite failed',
  'message.fileMoveOtherCollect.ok': "File moved to 'Other Favorites'",
  'message.fileMoveOtherCollect.fail': "File move to 'Other Favorites' failed",
  'message.recycleDelFile.ok': 'Completely deleted successfully',
  'message.recycleDelFile.fail': 'Delete failed',
  'message.recycle.recover.ok': 'Successfully recover',
  'message.recycle.recover.fail': 'Recover failed',
  'message.recycle.recover.see': 'view now',
  'message.getFileInfo.fail': 'Failed to obtain file information',
  'message.illegalResultName': 'The result name contains illegal characters',
  'message.copyClipBoard': 'Link copied',
  'message.searchFriend.fail': 'Failed to search for contacts！',
  'message.addAllFriends.ok': 'Successfully added all contacts!',
  'message.addAllFriends.fail': 'Failed to add all contacts！',
  'message.addFriend.ok': 'Successfully added contact!',
  'message.addFriend.fail': 'Failed to add contact!',
  'message.addContact.ok': 'Successfully added contact',
  'message.addContact.fail': 'Failed to add contact. Please try again later',
  'message.delContact.ok': 'Successfully deleted contact',
  'message.delContact.fail': 'Failed deleted contact',
  'message.delFriend.ok': 'Successfully deleted contact!',
  'message.delFriend.fail': 'Failed deleted contact!',
  'message.newGroup.ok': 'Successfully added a group!',
  'message.newGroup.fail': 'Failed added a group!',
  'message.changeRemark.ok': 'Successfully modified contact comment name！',
  'message.changeRemark.fail': 'Failed to modify contact comment name！',
  'message.moveInGroup.ok': 'Successfully moved contact to group！',
  'message.moveInGroup.fail': 'Failed to move contact to group！',
  'message.moveOutGroup.ok': 'Successfully removed from the group',
  'message.moveOutGroup.fail': 'Failed to remove from the group',
  'message.changeGroupName.ok': 'Successfully modified the group name！',
  'message.changeGroupName.fail': 'Failed to modify the group name！',
  'message.quitTask.ok': 'Successfully exited the task',
  'message.dismissTask.one': 'Successfully disbanded, can be recovered in the ',
  'message.dismissTask.two': 'Recycle Bin',
  'message.dismissTask.three': ' ',
  'message.fileNoExist': 'File does not exist',
  'message.delFolder.fail': 'Failed to delete folder！',
  'message.delFile.ok': 'Successfully deleted file',
  'message.delFile.fail': 'Failed to delete file！',
  'message.delFile.one': 'Successfully deleted, can be recovered in the ',
  'message.delFile.two': 'Recycle Bin',
  'message.delFile.three': ' ',
  'message.readConfig.error': 'Error reading configuration',
  'message.folderNameIllegal': 'Folder name cannot contain special symbols',
  'message.fileNAmeIllegal': 'File name cannot contain special symbols',
  'message.changeFolderName.ok': 'Successfully modified the folder name',
  'message.changeFolderName.fail': 'Failed to modify folder name',
  'message.changeFileName.ok': 'Successfully modified the file name',
  'message.changeFileName.fail': 'Failed to modify file name',
  'message.folder.existSameName': 'A folder with the same name already exists',
  'message.file.existSameName': 'A file with the same name already exists',
  'message.createFolder.ok': 'Folder created successfully!',
  'message.createFolder.fail': 'Folder created failed!',
  'message.illegalTemplateName': 'The template name contains illegal characters',
  'message.getTaskInfo.fail': 'Failed to obtain task information',
  'message.save.ok': 'Saved successfully',
  'message.save.fail': 'Save failed',
  'message.existTag': 'Tag already exists',
  'message.msg.uploadImgLimit.one': 'Support for uploading up to ',
  'message.msg.uploadImgLimit.two': ' images',
  'message.msg.uploadTypeError': 'Only picture types are supported',
  'message.msg.uploadImgSize': 'Picture must be smaller than',
  'message.changeRole.ok': 'Setup succeeded!',
  'message.changeRole.fail': 'Setup failed. Please try again later!',
  'message.refuseInvite.ok': 'Successfully rejected member invitation!',
  'message.refuseInvite.fail': 'Failed to reject member invitation!',
  'message.removeMember.ok': 'Successfully removed member!',
  'message.removeMember.fail': 'Failed to remove member!',
  'message.addMember.fail': 'Adding failed. Please try again later',
  'message.createLink.fail': 'Failed to generate invitation link. Please try again later',
  'message.getInviteInfo.fail': 'Failed to obtain invitation information. Please try again later',
  'message.linkApply.fail': 'Failed to submit the application. Please try again later',
  'message.enterFourPassword': 'Please enter a 4-8 digit password！',
  'message.sharePage.getData.fail': 'Failed to obtain data！',
  'message.sharePage.saveFile.ok': 'File saved successfully',
  'message.sharePage.saveFile.fail': 'File save failed',
  'message.fileDetail': 'Please enter remarks',
  'message.errorAnal.grr': 'GRR analysis only supports Excel format files',
  'message.errorAnal.dapt': 'Audio Graph only supports Excel and Dat format files',
  'message.errorAnal.cor': 'Correlation only supports Excel format files',
  'message.errorAnal.cpb': 'CPB analysis only supports audio files',
  'message.errorAnal.dsp': 'Audio Editor only supports audio files',
  'message.errorAnal.scic': 'Scientific Computing only supports Excel format files',
  'message.errorAnal.spc': 'Multimodal Detection only supports Excel format files',
  'message.uploadRootError': 'The root directory cannot upload files or folders',
  'message.getSaveFileInfo.fail': 'Failed to obtain saved file information',
  'message.deleteFileOperation.fail': 'Operation failed, the file has been deleted',
  'message.saveTpl.ok': 'Template saved successfully',
  'message.saveTpl.fail': 'Failed to save template',
  'message.uploadTpl.fail': 'Failed to upload template',
  'message.singleDragError.dragMore': 'Single file analysis only supports dragging a single file',
  'message.singleDragError.dragFolder': 'Single file parsing does not support folder import',
  'message.importError.notCSV': 'Please import a CSV or MDF file',
  'message.importError.notCSVOrDAT': 'Please import a CSV, DAT or MDF file',
  'message.importError.noFitMDF': 'Please import a suitable MDF file',
  'message.importError.errorTpl': 'Please import the correct template file',
  'message.readTpl.fail': 'Failed to read template',
  'message.calculateError.noFile': 'Please select the parsing file',
  'message.calculateError.noTpl': 'Please select a parsing template',
  'message.calculateError.dataTplMismatch':
    'There is a mismatch between the file and the data parsing template',
  'message.calculateError.dataTplMismatch.tip':
    'There is a mismatch between the file and the data parsing template,please replace the file or edit the template again',
  'message.calculateError.notSelectData': 'Please select a data item',
  'message.read.fail': 'Read Failure',
  'message.analysis.fail': 'Analysis failed',
  'message.calculate.fail': 'Calculate failed',
  'message.readLimit.fail': 'Failed to read limit',
  'message.uploadLimit.noLimit': 'Please upload the limit file',
  'message.saveUploadLimit.noLimit': 'Please upload the limit file before saving',
  'message.saveLimit.ok': 'Successfully saved limit file',
  'message.saveLimit.fail': 'Failed to save limit file',
  'message.limitFormatError': 'Failed to generate limit, please check the limit file',
  'message.uploadLimit.ok': 'Upload successful',
  'message.readLocalFile.fail': 'Failed to read local file. Please try again later',
  'message.export.fail': 'Export failed',
  'message.export.success': 'Export successed',
  'message.uploadCsv.noCsv': 'Please upload a CSV file',
  'message.uploadCsv.noCsvOrDat': 'Please upload a CSV or DAT file',
  'message.setCoordinates.fail': 'Failed to set coordinates',
  'message.setCoordinates.xAxisFail': 'The minimum value of X-axis needs to be greater than 0',
  'message.mismatchTpl': 'The template does not match, please replace the template',
  'message.networkTimeOut': 'Network timeout, please check for network abnormalities',
  'message.update.fail': 'Update failed',
  'message.selectWaveForm.empty': 'Please select waveform',
  'message.onlyAudioSupported': 'Only audio format files are supported',
  'message.importAudio.empty': 'Please import audio files',
  'message.uploadAudio.empty': 'Please upload audio files',
  'message.uploadFail.someFail': 'Some files failed to upload, please upload again',
  'message.delete.ok': 'Successfully deleted',
  'message.readFile.fail': 'Fail to read file',
  'message.copyCell': 'Copied cell content',
  'message.formulaError': 'Formula error, please check',
  'message.uploadImgFail.noNetWork': 'Image upload failed, please check your network!',
  'message.uploadSnapshotFail.noNetWork': 'Snapshot upload failed, please check your network!',
  'message.selectTime.ctrlA':
    'Please box select the time range or use "ctrl (command)+a" to select all',
  'message.incorrectParams': 'Please set the correct parameters',
  'message.fileNoSupportPlay': 'This file currently does not support to play',
  'message.beMoveOut': 'You have been removed from the task',
  'message.taskBeDismiss': 'The task has been dismissed',
  'message.uploadAttachment.fail': 'Failed to upload attachment',
  'message.getToolInfo.fail': 'Failed to obtain commonly used tool information',
  'message.searchUserError': 'Search user error, ',
  'message.uploadSourceError':
    'There was an error uploading the source file during the save process. Please try again later',
  'message.upload.fail': 'Upload failed',
  'message.fileRemark.ok': 'Remark sent successfully',
  'message.fileRemark.fail': 'Remark send failed',
  'message.readOriginalFile.fail': 'Failed to read File',
  'message.selectDataMismatch': 'The selected data does not match',
  'message.generateOriginalFile.fail': 'Failed to generate the original file',
  'message.chartUpdate.fail': 'Chart update failed',
  'message.csvArrEmpty': 'There are no matching files in the folder',
  'message.AGGetDataError.threeLine': 'Three or more cells of data need to be selected',
  'message.AGGetDataError.headEndNumber': 'The selected data must have numbers at both ends',
  'message.SCICGetDataError.twoCell': 'Two or more cells of data need to be selected',
  'message.SCICGetDataError.number': 'All selected data must have numbers',
  'message.SCICChartError.twoCol': 'Generating a chart requires at least two columns of data',
  'message.SCICChartError.twoRow': 'Generating a chart requires at least two rows of data',
  'message.SCICSmartTimesError.effData':
    'Valid Frequency and Limit column data needs to be filled in',
  'message.AnalysisFailed': 'Analysis failed',
  'message.processing': 'Processing...',
  'message.package.name.empty': 'Name cannot be empty',
  'message.package.name.illegal': 'The name contains illegal characters',
  'message.package.addMax': 'The maximum quantity cannot exceed 8',
  'message.task.into.creatorDepart':
    'The creator of this task has resigned, please wait for the administrator to designate an owner',
  'message.submit.ok': 'Submit successfully',
  'message.submit.fail': 'Submit failed',
};

const breadcrumb = {
  'breadcrumb.allFiles': 'All Files',
};

const checkbox = {
  'checkbox.all': 'Check all',
  'checkbox.all.small': 'All',
};

export default {
  ...button,
  ...popover,
  ...tooltip,
  ...message,
  ...breadcrumb,
  ...checkbox,
};
