import { DeptUserStatus } from '@/services/datalib';

export class DeptUser {
  userId: string = '';
  trueName: string = '';
  employeeId: string = '';
  avatar: string = '';
  gender: number = 0;
  address: string = '';
  hideInfo: number = 0;
  joinTime: number = 0;
  updateTime: number = 0;
  status: DeptUserStatus = DeptUserStatus.Normal;

  previousDept?: string[][];
  phone?: string;
  email?: string;
  isReg?: number;

  constructor(t?: DtlBean.DeptUser) {
    if (!t) {
      return;
    }
    if (t.userId) {
      this.userId = t.userId;
    }
    if (t.trueName) {
      this.trueName = t.trueName;
    }
    if (t.employeeId) {
      this.employeeId = t.employeeId;
    }
    if (t.avatar) {
      this.avatar = t.avatar;
    }
    if (t.gender) {
      this.gender = t.gender;
    }
    if (t.address) {
      this.address = t.address;
    }
    if (t.hideInfo) {
      this.hideInfo = t.hideInfo;
    }
    if (t.joinTime) {
      this.joinTime = t.joinTime;
    }
    if (t.updateTime) {
      this.updateTime = t.updateTime;
    }
    if (t.status) {
      this.status = t.status;
    }
    if (t.previousDept) {
      this.previousDept = t.previousDept.map((a) => [...a]);
    }
    if (t.phone) {
      this.phone = t.phone;
    }
    if (t.email) {
      this.email = t.email;
    }
    if (t.isReg) {
      this.isReg = t.isReg;
    }
  }

  format(): DtlBean.DeptUser {
    return {
      userId: this.userId,
      trueName: this.trueName,
      employeeId: this.employeeId,
      avatar: this.avatar,
      address: this.address,
      gender: this.gender,
      hideInfo: this.hideInfo,
      joinTime: this.joinTime,
      updateTime: this.updateTime,
      status: this.status,
      previousDept: this.previousDept?.map((a) => [...a]),
      phone: this.phone,
      email: this.email,
      isReg: this.isReg,
    };
  }
}
