import { FilePath } from '@/services/datalib/bean/FilePath';

export class DelFile {
  id: string = '';
  userId: string = '';
  ts: number = 0;
  filePaths: FilePath[] = [];

  constructor(delFile: DtlBean.DelFile) {
    if (!delFile) {
      return this;
    }
    if (delFile.id) {
      this.id = delFile.id;
    }
    if (delFile.userId) {
      this.userId = delFile.userId;
    }
    if (delFile.ts) {
      this.ts = delFile.ts;
    }
    if (delFile.filePaths) {
      this.filePaths = delFile.filePaths.map((f) => new FilePath(f));
    }
  }
}
