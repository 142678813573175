const time = {
  'time.justNow': '刚刚',
  'time.minuteAgo': '分钟前',
  'time.hourAgo': '小时前',
};

const appHeader = {
  'appHeader.dataSource': '数据源',
  'appHeader.Untitled': '无标题',
  'appHeader.result': '结果',
};

const userStatus = {
  'user.role.creator': '创建人',
  'user.role.manager': '管理员',
  'user.role.manager.desc': '拥有任务文件管理和增删成员权限',
  'user.role.member': '成员',
  'user.role.member.desc': '拥有任务文件管理权限',
  'user.role.visitor': '访客',
  'user.role.visitor.desc': '仅有阅读权限',
  'status.unregisterButInvite': '未注册，已邀请',
  'status.wait': '待审核',
  'user.role.uploader': '上传浏览者',
  'user.role.uploader.desc': '仅上传和浏览文件权限',
  'user.role.copyError': '缺少该任务保存权限',
};

const Toolbar = {
  'Toolbar.chirp': '啁啾声',
  'Toolbar.noise': '噪声',
  'Toolbar.tone': '单音',
  'Toolbar.DTMF': 'DTMF 音',
  'Toolbar.scaleFit': '缩放到合适',
  'Toolbar.scaleX': '缩放X轴',
  'Toolbar.scaleY': '缩放Y轴',
  'Toolbar.scaleByBox': '矩形选取缩放',
  'Toolbar.scaleByX': 'X轴选取缩放',
  'Toolbar.scaleByY': 'Y轴选取缩放',
  'Toolbar.shape.rect': '矩形',
  'Toolbar.shape.circle': '圆形',
  'Toolbar.shape.arrow': '箭头',
  'Toolbar.shape.text': '文字',
  'Toolbar.shape.picture': '图片',
  'Toolbar.line.solid': '细线',
  'Toolbar.line.bold': '粗线',
  'Toolbar.line.dashed': '虚线',
  'Toolbar.line.boldDashed': '粗虚线',
  'Toolbar.textDecoration.normal': '正常',
  'Toolbar.textDecoration.underline': '下划线',
  'Toolbar.textDecoration.lineThrough': '删除线',
  'Toolbar.undo': '撤销',
  'Toolbar.redo': '重做',
  'Toolbar.scissor': '裁切波形',
  'Toolbar.zoom_in': '放大',
  'Toolbar.zoom_in_select': '选区放大',
  'Toolbar.drag_move': '拖拽移动',
  'Toolbar.selectWave_box': '框选波形',
  'Toolbar.zoom_out': '缩小',
  'Toolbar.addMark': '添加标注',
  'Toolbar.pin': '图钉',
  'Toolbar.borderStyle': '边框样式',
  'Toolbar.color': '颜色',
  'Toolbar.fontsize': '字号',
  'Toolbar.fontStyle': '字符样式',
  'Toolbar.fontBold': '加粗',
  'Toolbar.changeYAxis': '坐标转换',
  'Toolbar.delete': '删除',
  'Toolbar.showDecorates': '显示/隐藏标注',
  'Toolbar.shapeColor.stroke': '边框色',
  'Toolbar.shapeColor.fill': '填充色',
  'Toolbar.shapeColor.text': '文字色',
  'Toolbar.play': '播放',
  'Toolbar.pause': '暂停',
  'Toolbar.stop': '停止',
  'Toolbar.cut': '剪切',
  'Toolbar.muteSelection': '选区内静音',
  'Toolbar.muteOutSelection': '选区外静音',
  'Toolbar.overallRestoration': '整体还原',
  'Toolbar.overallAmplification': '整体放大',
  'Toolbar.overallReduction': '整体缩小',
  'Toolbar.viewReport': '查看报告',
  'Toolbar.yAxis': 'y轴',
  'Toolbar.valueType.currentValue': '当前值',
  'Toolbar.valueType.vernierValue': '游标值',
  'Toolbar.valueType.intervalValue': '区间值',
  'Toolbar.export': '导出',
  'Toolbar.analysisSettings': '分析设置',
};

const Err = {
  操作成功: '操作成功',
  '解析失败，请选择其他打开方式': '解析失败，请选择其他打开方式',
  设备类型错误: '设备类型错误',
  用户不存在: '用户不存在',
  用户名或密码错误: '用户名或密码错误',
  电话号码格式错误: '电话号码格式错误',
  密码格式错误: '密码格式错误',
  用户已存在: '用户已存在',
  修改用户信息参数错误: '修改用户信息参数错误',
  验证码发送错误: '验证码发送错误',
  验证码发送频繁: '验证码发送频繁',
  验证码已发送: '验证码已发送',
  验证码已过期: '验证码已过期',
  验证码错误: '验证码错误',
  验证token错误: '验证token错误',
  需要图片验证码: '需要图片验证码',
  图片验证码错误: '图片验证码错误',
  密码登录错误频繁: '密码登录错误频繁',
  用户名超出范围: '用户名超出范围',
  邮箱格式错误或长度超出范围: '邮箱格式错误或长度超出范围',
  token错误: 'token错误',
  集合大小为0: '集合大小为0',
  参数类型不匹配或缺少参数: '参数类型不匹配或缺少参数',
  请求数据错误: '请求数据错误',
  登录已过期: '登录已过期',
  操作失败请稍后再试: '操作失败请稍后再试',
  电话号码占用: '电话号码占用',
  邮箱已被占用: '邮箱已被占用',
  联系人组不存在: '联系人组不存在',
  联系人组已存在: '联系人组已存在',
  通讯录中不存在该成员: '通讯录中不存在该成员',
  操作超时: '操作超时',
  任务分组名已存在: '任务分组名已存在',
  指定的任务分组不存在: '指定的任务分组不存在',
  任务已存在于指定分组: '任务已存在于指定分组',
  任务不存在: '任务不存在',
  用户不是该任务的成员: '用户不是该任务的成员',
  缺少操作权限: '缺少操作权限',
  最多只能设定2位管理员: '最多只能设定2位管理员',
  回复的消息不存在: '回复的消息不存在',
  任务列表错误: '任务列表错误',
  指定的文件或文件夹不存在: '指定的文件或文件夹不存在',
  只能移动到文件夹下: '只能移动到文件夹下',
  已存在同名的文件: '已存在同名的文件',
  已存在同名的文件夹: '已存在同名的文件夹',
  父级路径与已有的某个文件路径重复: '父级路径与已有的某个文件路径重复',
  '正在解析该文件，请稍后再试': '正在解析该文件，请稍后再试',
  '打开失败，请选择其他打开方式': '打开失败，请选择其他打开方式',
  指定的对象不存在: '指定的对象不存在',
  '任务成员已满，最多50人': '任务成员已满，最多50人',
  指定的分析结果不存在: '指定的分析结果不存在',
  指定的分享不存在: '指定的分享不存在',
  链接已失效: '链接已失效',
  任务已存在: '任务已存在',
  目标已存在: '目标已存在',
  创建者不能退出任务: '创建者不能退出任务',
  用户名已存在: '用户名已存在',
  父文件不存在: '父文件不存在',
  指定的文件备注不存在: '指定的文件备注不存在',
  MDF格式错误: 'MDF格式错误',
  该文件已被取消分享: '该文件已被取消分享',
  该分享需要输入密码: '该分享需要输入密码',
  文件记录不存在: '文件记录不存在',
  用户尚未申请授权码: '用户尚未申请授权码',
  授权码不正确: '授权码不正确',
  不存在该邀请: '不存在该邀请',
  已同意全部成员邀请: '已同意全部成员邀请!',
  '任务即将满员，最多50人': '任务即将满员，最多50人',
  同意全部成员失败: '同意全部成员失败!',
  已同意成员邀请: '已同意成员邀请！',
  同意成员邀请失败: '同意成员邀请失败！',
  已通过: '已通过',
  包含非手机号和邮箱的内容: '包含非手机号和邮箱的内容',
  模板数据不正确: '模板数据不正确',
  未提供文件: '未提供文件',
  提供的文件信息错误: '提供的文件信息错误',
  文件格式不支持: '文件格式不支持',
  SN格式不匹配: 'SN格式不匹配',
  参数错误: '参数错误',
  项目名未指定: '项目名未指定',
  指定的项目不存在: '指定的项目不存在',
  文件中列数少于5列: '文件中列数少于5列',
  文件中行数少于2行: '文件中行数少于2行',
  无法识别列名所在行: '无法识别列名所在行',
  无法识别数据起始行: '无法识别数据起始行',
  灵敏度格式不匹配: '灵敏度格式不匹配',
  分隔符格式不匹配: '分隔符格式不匹配',
  'Station ID格式不匹配': 'Station ID格式不匹配',
  工作表名称有误: '工作表名称有误',
  没用用于插值的空单元格: '没用用于插值的空单元格',
  存在某一列数据两端为非数字: '存在某一列数据两端为非数字',
  存在某一行数据两端为非数字: '存在某一行数据两端为非数字',
  选中的行数需不少于3: '选中的行数需不少于3',
  选中的行数需不少于4: '选中的行数需不少于4',
  选中的行数需不少于5: '选中的行数需不少于5',
  存在某一列的数字单元格不足3个: '存在某一列的数字单元格不足3个',
  存在某一列的数字单元格不足4个: '存在某一列的数字单元格不足4个',
  多文件分析不应使用pcs模板: '多文件分析不应使用pcs模板',
  pcs数不匹配: 'pcs数不匹配',
  'worker destroyed': 'worker destroyed',
  请求参数不正确: '请求参数不正确',
  网络访问失败: '网络访问失败',
  超过范围: '超过范围',
  未选中范围: '未选中范围',
  请先新建过程: '请先新建过程',
  过程不匹配: '过程不匹配',
  过程不存在该方法: '过程不存在该方法',
  找不到实例: '找不到实例',
  cmd不合法: 'cmd不合法',
  方法不存在: '方法不存在',
  数据不存在: '数据不存在',
  '该过程已提交，请使用未提交的过程': '该过程已提交，请使用未提交的过程',
  FUNC不存在: 'FUNC不存在',
  FUNC格式不正确: 'FUNC格式不正确',
  'FUNC FACTORY不存在': 'FUNC FACTORY不存在',
  COMMAND不存在: 'COMMAND不存在',
  ALPHAWAVE参数错误: 'ALPHAWAVE参数错误',
  'Wav Data为空': 'Wav Data为空',
  只能使用新创建的effect来执行方法: '只能使用新创建的effect来执行方法',
  'Get Noise Profile failed': 'Get Noise Profile failed',
  采样率不一致: '采样率不一致',
  选择的样本数据过少: '选择的样本数据过少',
  获取图形失败: '获取图形失败',
  'IIR计算失败，请输入正确的参数': 'IIR计算失败，请输入正确的参数',
  'FIR计算失败，请输入正确的参数': 'FIR计算失败，请输入正确的参数',
  'Smooth计算失败，请输入正确的参数': 'Smooth计算失败，请输入正确的参数',
  'FilterCurveEQ计算失败，请输入正确的参数': 'FilterCurveEQ计算失败，请输入正确的参数',
  'NoiseReduction计算失败，请输入正确的参数': 'NoiseReduction计算失败，请输入正确的参数',
  普通文件不能存放到配置文件: '普通文件不能存放到配置文件',
};

const pin = {
  'pin.enterComment': '请输入评论',
  'pin.saveFileToTask': '请保存文件到任务列表',
  'pin.pictureSupport': '图片仅支持JPG/PNG格式!',
  'pin.selectFileUpload': '选取文件以上传',
  'pin.sendPicture': '发送图片',
  'pin.sendSnapshot': '发送快照',
};

const colorPicker = {
  'colorPicker.StandardColor': '标准色',
  'colorPicker.otherColor': '其他颜色',
  'colorPicker.selectError.allNone': '无法同时设置无颜色',
};

const pageInfo = {
  'pageInfo.nowPage': '第',
  'pageInfo.afterNumber': '页',
  'pageInfo.totalPage': '共',
};

const common = {
  'common.null': '空',
};

export default {
  ...time,
  ...userStatus,
  ...Err,
  ...appHeader,
  ...Toolbar,
  ...pin,
  ...colorPicker,
  ...pageInfo,
  ...common,
};
