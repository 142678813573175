import { dtlStatus, FileRecordStatus } from '@/services/datalib';
import { CollectFolder } from '@/services/datalib/bean/CollectFolder';

export class CollectFolders {
  cfs: CollectFolder[] = [new CollectFolder({ id: '', name: '我的收藏', ts: 0 })];

  constructor(t?: DtlBean.CollectFolder[]) {
    if (!t) {
      return;
    }
    this.cfs.push(...t.map((r) => new CollectFolder(r)));
  }

  format(): DtlBean.CollectFolder[] {
    const m: Record<string, CollectFolder> = {};
    this.cfs.forEach((cf) => {
      m[cf.id] = cf;
      cf.fileRecord = [];
    });
    dtlStatus.fileRecords.collectFiles
      .filter((f) => f.s === FileRecordStatus.Normal)
      .forEach((r) => {
        if (m[r.cid]) {
          m[r.cid].fileRecord.push(r);
        }
      });
    return this.cfs.map((r) => r.format());
  }
}
