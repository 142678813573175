import { dtlStatus, TaskStatus } from '@/services/datalib';
import { Attachment } from '@/services/datalib/bean/Attachment';
import type { Contacts } from '@/services/datalib/bean/Contacts';
import { DelFile } from '@/services/datalib/bean/DelFile';
import { InTask } from '@/services/datalib/bean/InTask';
import { Label } from '@/services/datalib/bean/Label';
import type { Msg } from '@/services/datalib/bean/Msg';
import { Msgs } from '@/services/datalib/bean/Msgs';
import type { TaskFile } from '@/services/datalib/bean/TaskFile';
import { TaskFiles } from '@/services/datalib/bean/TaskFiles';
import { Unread } from '@/services/datalib/bean/Unread';
import { User } from '@/services/datalib/bean/user';
import { TaskMark, TaskType } from '@/services/datalib/enum';

export class Task {
  taskId: string = '';
  type: TaskType = TaskType.COMMON;
  createTime: number = 0;
  name: string = '';
  desc: string = '';
  msgs: Msgs = new Msgs();
  creator: string = '';
  deleted: boolean = false;
  admList: string[] = [];
  labels: Label[] = [];
  attachments: Attachment[] = [];
  userIds: string[] = []; //正式成员列表: 管理员+普通成员
  inviteeIds: string[] = []; //受邀待确认成员列表
  visitorIds: string[] = []; //访问者列表
  uploaderIds: string[] = []; //上传访问者列表
  allUserIds: string[] = []; //正式成员+受邀待确认成员+被删除的成员列表+访问者列表
  members: Record<string, User> = {};
  files: TaskFiles = new TaskFiles();
  delFiles: DelFile[] = [];
  oUnreads: Unread[] = [];
  enterpriseId: string = '';
  status: number = 0;
  v: number = 0;
  initialized: boolean = false;

  format(): DtlBean.Task {
    return {
      taskId: this.taskId,
      type: this.type,
      createTime: this.createTime,
      name: this.name,
      desc: this.desc,
      msgs: this.msgs.formatSome(),
      creator: this.creator,
      deleted: this.deleted,
      admList: [...this.admList],
      labels: this.labels.map((l) => l.format()),
      attachments: this.attachments.map((a) => a.format()),
      userIds: [...this.userIds],
      inviteeIds: [...this.inviteeIds],
      visitorIds: [...this.visitorIds],
      uploaderIds: [...this.uploaderIds],
      allUserIds: [...this.allUserIds],
      members: this.formatMembers(),
      // files: this.files.format(),
      // oUnreads: xxxx
      enterpriseId: this.enterpriseId,
      isCreatorDepart: (this.status & TaskStatus.creatorDepart) > 0,
      files: undefined,
      // delFiles: this.delFiles.map(f => f.format()),
      v: this.v,
    };
  }

  formatMembers(): Record<string, DtlBean.User> {
    const members: Record<string, DtlBean.User> = {};
    Object.keys(this.members).forEach((key) => {
      members[key] = this.members[key].format();
    });
    return members;
  }

  initFinished() {
    this.initialized = true;
  }

  constructor(t?: DtlBean.TaskV) {
    if (!t) {
      return;
    }
    if (t.taskId) {
      this.taskId = t.taskId;
    }
    if (t.type) {
      this.type = t.type;
    }
    if (t.createTime) {
      this.createTime = t.createTime;
    }
    if (t.name) {
      this.name = t.name;
    }
    if (t.desc) {
      this.desc = t.desc;
    }
    if (t.msgs) {
      this.msgs = new Msgs(t.msgs);
    }
    if (t.creator) {
      this.creator = t.creator;
    }
    if (t.deleted) {
      this.deleted = t.deleted;
    }
    if (t.admList) {
      this.admList = [...t.admList];
    }
    if (t.labels) {
      this.labels = t.labels.map((l) => new Label(l));
    }
    if (t.attachments) {
      this.attachments = t.attachments.map((l) => new Attachment(l));
    }
    if (t.userIds) {
      this.userIds = [...t.userIds];
    }
    if (t.inviteeIds) {
      this.inviteeIds = [...t.inviteeIds];
    }
    if (t.visitorIds) {
      this.visitorIds = [...t.visitorIds];
    }
    if (t.uploaderIds) {
      this.uploaderIds = [...t.uploaderIds];
    }
    if (t.allUserIds) {
      this.allUserIds = [...t.allUserIds];
    }
    if (t.userInfos) {
      Object.keys(t.userInfos).forEach((userId) => {
        const userInfo: DtlBean.InTask = t.userInfos[userId];
        const user = new User();
        user.userId = userInfo.userId;
        user.inTask = new InTask(userInfo);
        this.members[userInfo.userId] = user;
      });
    }
    if (t.members) {
      Object.keys(t.members).forEach((userId) => {
        const user = new User(t.members[userId]);
        if (this.members[userId]) {
          this.members[userId].updateBasic(user);
        } else {
          this.members[userId] = user;
        }
      });
    }
    Object.values(this.members).forEach(
      (m) => m && m.userId && m.createTime && (dtlStatus.contacts.usersCache[m.userId] = m),
    );
    if (t.taskFiles) {
      this.files = new TaskFiles(t.taskFiles);
    }
    if (t.delFiles) {
      this.delFiles = t.delFiles.map((f) => new DelFile(f));
    }
    if (t.oUnreads) {
      this.oUnreads = t.oUnreads.map((r) => new Unread(r));
    }
    if (t.enterpriseId) {
      this.enterpriseId = t.enterpriseId;
    }
    if (t.status) {
      this.status = t.status;
    }
    if (t.v) {
      this.v = t.v;
    }
    // if (t.taskFiles) {
    //   this.initFinished();
    // }
  }

  existUserId(userId: string) {
    return (
      this.userIds.some((uid) => uid === userId) ||
      this.visitorIds.some((uid) => uid === userId) ||
      this.uploaderIds.some((uid) => uid === userId)
    );
  }

  updateTaskDesc(desc: string) {
    this.desc = desc;
  }

  updateTaskName(taskName: string) {
    this.name = taskName;
  }

  addLabels(added: Label[]) {
    this.labels.push(...added);
  }

  delLabels(ids: string[]) {
    if (!ids) return;
    ids.forEach((id) => {
      const idx = this.labels.findIndex((label) => label.labelId === id);
      if (idx > -1) {
        this.labels.splice(idx, 1);
      }
    });
  }

  addUsers(
    users: User[],
    admList: string[],
    userIds: string[],
    inviteeIds: string[],
    visitorIds: string[],
    uploaderIds: string[],
    allUserIds: string[],
  ) {
    if (users) {
      users.forEach((user) => {
        this.members[user.userId] = user;
        dtlStatus.contacts.usersCache[user.userId] = user;
      });
    }
    this.updateAdmList([...admList]);
    this.userIds = [...userIds];
    this.visitorIds = [...visitorIds];
    this.uploaderIds = [...(uploaderIds || [])];
    this.allUserIds = [...allUserIds];
    {
      let needUpdateMark = true;
      if (this.inviteeIds.length === inviteeIds.length) {
        const m = {};
        this.inviteeIds.forEach((v) => (m[v] = true));
        if (inviteeIds.every((v) => m[v])) {
          needUpdateMark = false;
        }
      }
      if (needUpdateMark) {
        this.updateAdmInviteMark();
      }
    }
    this.inviteeIds = [...inviteeIds];
  }

  updateAdmInviteMark() {
    if (!this.admList) return;
    this.admList.forEach((userId) => {
      const user = this.members[userId];
      if (user && user.inTask) {
        user.inTask.mark |= TaskMark.TASKMARK_WAIT_READ_NEW_INVITE; // eslint-disable-line no-bitwise
      }
    });
  }

  delUsers(deleted: string[]) {
    if (!deleted) return;
    deleted.forEach((userId) => {
      {
        const idx = this.inviteeIds.findIndex((uid) => uid === userId);
        if (idx > -1) {
          this.inviteeIds.splice(idx, 1);
        }
      }
      {
        const idx = this.admList.findIndex((uid) => uid === userId);
        if (idx > -1) {
          this.admList.splice(idx, 1);
        }
      }
      {
        const idx = this.userIds.findIndex((uid) => uid === userId);
        if (idx > -1) {
          this.userIds.splice(idx, 1);
        }
      }
      {
        const idx = this.visitorIds.findIndex((uid) => uid === userId);
        if (idx > -1) {
          this.visitorIds.splice(idx, 1);
        }
      }
      {
        const idx = this.uploaderIds.findIndex((uid) => uid === userId);
        if (idx > -1) {
          this.uploaderIds.splice(idx, 1);
        }
      }
    });
  }

  addAttachments(attachments: Attachment[]) {
    if (!attachments) return;
    attachments.forEach((a) => {
      if (!this.attachments.some((att) => att.attachmentId === a.attachmentId)) {
        this.attachments.push(a);
      }
    });
  }

  delAttachments(ids: string[]) {
    if (!ids) return;
    ids.forEach((id) => {
      const index = this.attachments.findIndex((att) => att.attachmentId === id);
      if (index > -1) {
        this.attachments.splice(index, 1);
      }
    });
  }

  setDeleted() {
    this.deleted = true;
  }

  updateUserRole(
    admList: string[],
    userIds: string[],
    inviteeIds: string[],
    visitorIds: string[],
    uploaderIds: string[],
  ) {
    this.inviteeIds = [...inviteeIds];
    this.visitorIds = [...visitorIds];
    this.uploaderIds = [...(uploaderIds || [])];
    this.userIds = [...userIds];
    this.updateAdmList([...admList]);
  }

  updateAdmList(admList: string[]) {
    const olds = this.admList;
    if (!admList) {
      this.admList = [];
    } else {
      this.admList = admList;
    }
    this.admList.forEach((id) => {
      if (!olds.some((uid) => uid === id)) {
        const user = this.members[id];
        if (user && user.inTask) {
          user.inTask.mark |= TaskMark.TASKMARK_WAIT_READ_NEW_INVITE; // eslint-disable-line no-bitwise
        }
      }
    });
  }

  updateUserStatus(userId: string, status: TaskStatus) {
    const user = this.members[userId];
    if (user && user.inTask) {
      user.inTask.taskStatus = status;
    }
  }

  updateUserExt(userId: string, ext: string) {
    const user = this.members[userId];
    if (user && user.inTask) {
      user.inTask.ext = ext;
    }
  }

  updateTaskFileLastOpenTime(userId: string, fileId: string, record: string): TaskFile | undefined {
    const user = this.members[userId];
    if (user && user.inTask) {
      const idx = user.inTask.fileLots.findIndex((id) => id.startsWith(fileId));
      if (idx > -1) {
        user.inTask.fileLots.splice(idx, 1);
      }
      user.inTask.fileLots.push(record);
      if (user.inTask.fileLots.length > 50) {
        user.inTask.fileLots.splice(0, user.inTask.fileLots.length - 50);
      }
    }
    return this.files.existFileById(fileId);
  }

  updateTaskMark(userId: string, mark: TaskMark) {
    const user = this.members[userId];
    if (user && user.inTask) {
      user.inTask.mark ^= mark; // eslint-disable-line no-bitwise
    }
  }

  addMsg(msg: Msg) {
    if (!this.initialized || !msg) return;
    this.msgs.addMsg(msg);
  }

  updateMsgAddReply(replyId: string, msg: Msg) {
    if (!this.initialized || !msg) return;
    this.msgs.addReply(replyId, msg);
  }

  deleteMsg(deleteId: string) {
    if (!this.initialized || !deleteId) return;
    this.msgs.deleteMsg(deleteId);
  }

  setCreatorDepart() {
    if (!this.initialized) return;
    this.status = this.status | TaskStatus.creatorDepart;
  }

  addTaskFile(taskFile: TaskFile) {
    if (!this.initialized || !taskFile) return;
    this.files.addFile(taskFile);
  }

  addTaskFileMsg(path: string, msg: DtlBean.Msg): TaskFile | undefined {
    if (!this.initialized || !path || !msg) return undefined;
    return this.files.addTaskFileMsg(path, msg);
  }

  delTaskFileMsg(fileId: string, msgId: string): TaskFile | undefined {
    if (!this.initialized || !fileId || !msgId) return undefined;
    return this.files.delTaskFileMsg(fileId, msgId);
  }

  updateTaskFileOnlyOffice(fileId: string, onlyOffice: DtlBean.OnlyOffice): TaskFile | undefined {
    if (!this.initialized || !fileId || !onlyOffice) return undefined;
    return this.files.updateTaskFileOnlyOffice(fileId, onlyOffice);
  }

  updateTaskFileName(path: string, filePath: DtlBean.FilePath): TaskFile | undefined {
    if (!this.initialized || !path || !filePath) return undefined;
    return this.files.updateFileName(path, filePath);
  }

  moveTaskFiles(
    param: Record<string, DtlBean.FilePath>,
  ): Record<string, DtlBean.TaskFile> | undefined {
    if (!this.initialized || !param) return undefined;
    return this.files.moveFiles(param);
  }

  delTaskFiles(deleted: DtlBean.DelFile[]) {
    if (!deleted) return;
    this.delFiles.push(...deleted.map((f) => new DelFile(f)));
    this.files.delTaskFiles(deleted);
  }

  delTaskFilesCompletely(id: string) {
    if (!id) return;
    this.delFiles = this.delFiles.filter((f) => f.id !== id);
  }

  addTaskFiles(adds?: TaskFile[], replaced?: TaskFile[]) {
    if (!this.initialized) return;
    this.files.addFiles(adds, replaced);
  }

  updateTaskFileContent(fileId: string, content: string) {
    if (!this.initialized) return;
    this.files.updateFileContent(fileId, content);
  }

  updateTaskFileStatus(fileId: string, status: number) {
    if (!this.initialized) return;
    this.files.updateFileStatus(fileId, status);
  }

  updateTaskFile(taskFile: TaskFile) {
    if (!this.initialized || !taskFile) return;
    this.files.updateFile(taskFile);
  }

  updateMembersByContacts(contacts: Contacts) {
    contacts.userList.forEach((u) => {
      if (this.members[u.userId]) {
        this.members[u.userId].updateFromContact(u);
      }
    });
  }

  updateMembers(
    members?: Record<string, DtlBean.User>,
    userInfos?: Record<string, DtlBean.InTask>,
  ) {
    if (members) {
      Object.keys(members).forEach((userId) => {
        const user = new User(members[userId]);
        if (this.members[userId]) {
          this.members[userId].updateBasic(user);
        } else {
          this.members[userId] = user;
        }
      });
    }
    if (userInfos) {
      Object.keys(userInfos).forEach((userId) => {
        const userInfo = userInfos[userId];
        if (this.members[userInfo.userId]) {
          this.members[userInfo.userId].inTask = new InTask(userInfo);
        } else {
          const user = new User();
          user.userId = userInfo.userId;
          user.inTask = new InTask(userInfo);
          this.members[userInfo.userId] = user;
        }
      });
    }
    Object.values(this.members).forEach(
      (m) => m && m.userId && m.createTime && (dtlStatus.contacts.usersCache[m.userId] = m),
    );
  }

  updateTaskLastOpenTime(userId: string, lot: number) {
    const user = this.members[userId];
    if (user && user.inTask) {
      user.inTask.taskLot = lot;
    }
  }

  getFileLots(userId: string): string[] | undefined {
    if (this.members[userId]) {
      return this.members[userId].inTask?.fileLots;
    }
    return undefined;
  }

  updateLotInfo(userId: string) {
    this.files.updateLotInfo(this.getFileLots(userId));
  }

  oAddUnread(unread: Unread) {
    if (!unread) return;
    if (!this.oUnreads.some((u) => u.id === unread.id)) {
      this.oUnreads.push(unread);
    }
    this.oUnreads.sort((a, b) => a.time - b.time);
    this.oUnreads = this.oUnreads.slice(-100);
  }
}
