export default {
  'DelFileContent.title': 'Recycle Bin',
  'DelFileContent.searchFile': 'Search File',
  'DelFileContent.searchTask': 'Search Task',
  'DelFileContent.data.tab': 'Data Recycle',
  'DelFileContent.task.tab': 'Task Recycle',
  'DelFileContent.data': 'File',
  'DelFileContent.task': 'Task',
  'DelFileContent.delTips':
    ' default retention period is 30 days. Expiration will be completely deleted',
  'DelFileContent.table.name': 'Name',
  'DelFileContent.table.source': 'Source',
  'DelFileContent.table.deleter': 'Deleter',
  'DelFileContent.table.deleteTime': 'Delete Time',
  'DelFileContent.table.tips': 'day permanently delete',
  'DelFileContent.table.delete': 'Delete',
  'DelFileContent.table.recover': 'Resume',
  'DelFileContent.data.empty': 'No files available',
  'DelFileContent.task.empty': 'No tasks currently',
  'DelFileContent.recycle.data.empty': 'No files',
  'DelFileContent.recycle.task.empty': 'No tasks',
};
