import { LabelTpl } from '@/services/datalib/bean/LabelTpl';

export class LabelTemplates {
  labelTemplates: LabelTpl[] = [];
  v: number = 0;

  constructor(t?: DtlBean.UserAllTpl) {
    if (!t) {
      return;
    }
    if (t.labelTemplates) {
      this.labelTemplates = t.labelTemplates.map((l) => new LabelTpl(l));
    }
    if (t.v) {
      this.v = t.v;
    }
  }

  createLabelTemplate(labelTpl: LabelTpl) {
    if (!labelTpl) return;
    this.labelTemplates.push(labelTpl);
  }

  deleteLabelTemplate(deleted: string[]) {
    if (!deleted) return;
    deleted.forEach((id) => {
      const idx = this.labelTemplates.findIndex((la) => la.id === id);
      if (idx > -1) {
        this.labelTemplates.splice(idx, 1);
      }
    });
  }

  format(): DtlBean.LabelTpl[] {
    return this.labelTemplates.map((l) => l.format());
  }
}
