export default {
  'pages.toolPanel.myTool.title': 'My Tools',
  'pages.toolPanel.myTool.searchTool.placeholder': 'Search Tool',
  'pages.toolPanel.leftSide.tool_table': 'Workbench',
  'pages.toolPanel.leftSide.item': 'Tasks',
  'pages.toolPanel.leftSide.Audio': 'Audio Preview',
  'pages.toolPanel.leftSide.Video': 'Video Preview',
  'pages.toolPanel.leftSide.Image': 'Picture',
  'pages.toolPanel.leftSide.PDF': 'PDF',
  'pages.toolPanel.leftSide.office': 'Document',
  'pages.toolPanel.toolModal.BigTitle': 'Add App',
  'pages.toolPanel.toolModal.all': 'All',
  'pages.toolPanel.removeTool': 'Remove',
  'pages.toolPanel.toolModal.search.placeholder': 'Search',
  'pages.toolPanel.toolModal.addTool.btn': 'Add',
  'pages.toolPanel.toolModal.hasAddedTool.btn': 'Added',
  'pages.toolPanel.toolModal.addAll.btn': 'Add All',
  'pages.toolPanel.toolModal.hasAddedAll.btn': 'All Added',
  'pages.toolPanel.toolModal.openTool.btn': 'Open',
  'pages.toolPanel.toolModal.AudioToolPackage': 'Audio Toolkit',
  'pages.toolPanel.toolModal.AudioToolPackage.desc': 'Acoustic testing solutions',
  'pages.toolPanel.toolModal.airPackage': 'Heat Transfer Test Calculation Toolkit',
  'pages.toolPanel.toolModal.airPackage.desc': 'Refrigeration Measurement Solution',
  'pages.toolPanel.GRR.name': 'GRR Analysis',
  'pages.toolPanel.GRR.type': 'Statistical Analysis',
  'pages.toolPanel.GRR.desc':
    'Measurement reproducibility (measurement equipment variation) and reproducibility (measurement personnel/method variation) can reduce measurement errors.',
  'pages.toolPanel.leftSide.GRR': 'GRR',
  'pages.toolPanel.DAPT.name': 'Audio Graph',
  'pages.toolPanel.DAPT.type': 'Statistical Analysis',
  'pages.toolPanel.DAPT.desc':
    'Provide professional two-dimensional measurement data visualization images, and the file data is clear at a glance.',
  'pages.toolPanel.leftSide.DAPT': 'AG',
  'pages.toolPanel.correlation.name': 'Correlation',
  'pages.toolPanel.correlation.type': 'Statistical Analysis',
  'pages.toolPanel.correlation.desc':
    'Measure the consistency of different test machines, test software and hardware, and provide professional visual data charts such as average deviation charts, R-square charts, scatter charts, and box charts.',
  'pages.toolPanel.leftSide.Cor': 'Cor',
  'pages.toolPanel.CPB.name': 'CPB Analysis (Acoustics)',
  'pages.toolPanel.CPB.type': 'Spectrum Analysis',
  'pages.toolPanel.CPB.desc':
    'Conduct acoustic signal analysis, and set the parameters, octave mode, and parameter range of the acoustic signal.',
  'pages.toolPanel.leftSide.CPB': 'CPB',
  'pages.toolPanel.DSP.name': 'Audio Editor',
  'pages.toolPanel.DSP.type': 'Spectrum Analysis',
  'pages.toolPanel.DSP.desc':
    'Processing sound signal files, providing multiple signal processing methods such as extraction, transformation, analysis, and synthesis.',
  'pages.toolPanel.leftSide.DSP': 'AE',
  'pages.toolPanel.WavView.name': 'Waveform View',
  'pages.toolPanel.WavView.type': 'Spectrum Analysis',
  'pages.toolPanel.WavView.desc':
    'Visually preview, analyze, and mark waveforms such as signals and sounds.',
  'pages.toolPanel.leftSide.WavView': 'Waveform View',
  'pages.toolPanel.Enthalpy.name': 'Enthalpy Difference Calculation',
  'pages.toolPanel.Enthalpy.type': 'Air conditioning measurement',
  'pages.toolPanel.Enthalpy.desc':
    'Test method for determining the cooling and heating capacities of air conditioners. Measure the supply air parameters, return air parameters, and circulating air volume of the air conditioner, and determine the capacity of the air conditioner by multiplying the measured air volume with the difference in supply air and return air enthalpy.',
  'pages.toolPanel.leftSide.Enthalpy': 'Enthalpy',
  'pages.toolPanel.Liquid.name': 'Liquid Cooling Calculation',
  'pages.toolPanel.Liquid.type': 'Air conditioning measurement',
  'pages.toolPanel.Liquid.desc':
    'Measure parameters such as water capacity and energy efficiency of water (air) cooling (heating) water units.',
  'pages.toolPanel.leftSide.Liquid': 'Liquid',
  'pages.toolPanel.Season.name': 'Seasonal Efficiency Calculation',
  'pages.toolPanel.Season.type': 'Air Conditioning Measurement',
  'pages.toolPanel.Season.desc':
    'Calculate the seasonal energy efficiency ratio of air conditioning over a certain time span (typically based on time performance).',
  'pages.toolPanel.leftSide.Season': 'Season',
  'pages.toolPanel.Refrigerant.name': 'Refrigerant query calculation',
  'pages.toolPanel.Refrigerant.type': 'Air conditioning measurement',
  'pages.toolPanel.Refrigerant.desc':
    'Query the physical properties of the refrigerant and calculate its performance under different conditions.',
  'pages.toolPanel.leftSide.Refrigerant': 'Refrigerant',
  'pages.toolPanel.Humidity.name': 'Relative Humidity Calculation',
  'pages.toolPanel.Humidity.type': 'Air conditioning measurement',
  'pages.toolPanel.Humidity.desc':
    'Calculate the percentage of water vapor pressure in the air compared to saturated water vapor pressure at the same temperature.',
  'pages.toolPanel.leftSide.Humidity': 'Humidity',
  'pages.toolPanel.leftSide.SCIC': 'Scientific Computing',
  'pages.toolPanel.SCIC.name': 'Scientific Computing',
  'pages.toolPanel.SCIC.desc':
    'Perform table data processing, use various functions for interpolation, calculation, and analysis.',
  'pages.toolPanel.SCIC.type': 'Statistical Analysis',
  'pages.toolPanel.statistic': 'Data Statistics',
  'pages.toolPanel.spectrum': 'Spectrum Analysis',
  'pages.toolPanel.leftSide.EnthalpyDiffLab': 'Laboratory',
  'pages.toolPanel.EnthalpyDiffLab.name': 'Air Conditioning Enthalpy Difference Lab',
  'pages.toolPanel.StrainRawData.name': 'Strain Analysis',
  'pages.toolPanel.StrainTimeHistory.name': 'Strain Analysis',
  'pages.toolPanel.leftSide.Strain': 'Strain',
  'pages.toolPanel.leftSide.SPC': 'MD',
  'pages.toolPanel.SPC.name': 'Multimodal Detection',
  'pages.toolPanel.SPC.type': 'Statistical Analysis',
  'pages.toolPanel.SPC.desc': 'Charting the data and determining if the data is unimodal.',
  'pages.toolPanel.remove': 'Remove',
  'pages.toolPanel.setting': 'Set up',
  'pages.toolPanel.defaultPackage.name': 'Default Toolkit',
  'pages.toolPanel.defaultPackage.desc': 'Custom Toolkit',
  'pages.toolPanel.officialPackage': 'Official Toolkit',
  'pages.toolPanel.emptyApp': 'There are currently no tools available',
  'pages.toolPanel.newPackage.added': 'Added',
  'pages.toolPanel.newPackage.tool': 'Tool',
  'pages.toolPanel.addToWorkbench': 'Add to Workbench',
  'pages.toolPanel.addedToWorkbench': 'Added to Workbench',
};
