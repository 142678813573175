import { DtlUnreadCmd } from '@/services/datalib/enum';

export class Unread {
  id: string = '';
  userId: string = '';
  userName: string = '';
  taskId: string = '';
  cmd: number = 0;
  name: string = '';
  time: number = 0;
  ext: Record<string, string> = {};

  constructor(t: DtlBean.Unread) {
    if (!t) return;
    if (t.id) {
      this.id = t.id;
    }
    if (t.userId) {
      this.userId = t.userId;
    }
    if (t.userName) {
      this.userName = t.userName;
    }
    if (t.taskId) {
      this.taskId = t.taskId;
    }
    if (t.cmd) {
      this.cmd = t.cmd;
      this.name = DtlUnreadCmd[this.cmd];
    }
    if (t.time) {
      this.time = t.time;
    }
    if (t.ext) {
      this.ext = { ...t.ext };
    }
  }

  copy(): Unread {
    return new Unread(this.format());
  }

  format(): DtlBean.Unread {
    return {
      id: this.id,
      userId: this.userId,
      userName: this.userName,
      taskId: this.taskId,
      cmd: this.cmd,
      name: DtlUnreadCmd[this.cmd],
      time: this.time,
      ext: { ...this.ext },
    };
  }
}
