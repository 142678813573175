const button = {
  'btn.newDir': '新建文件夹',
  'btn.upload': '上传',
  'btn.uploading': '上传中',
  'btn.cancel': '取消',
  'btn.submit': '确认',
  'btn.reset': '重置',
  'btn.open': '打开',
  'btn.remark': '备注',
  'btn.remind': '@提醒',
  'btn.labelTemplate': '标签模板',
  'btn.saveLabelTemplate': '保存标签为模板',
  'btn.delete': '删除',
  'btn.sendMsg': '发布',
  'btn.sendImg': '插入图片',
  'btn.msgRemind': '@ 提醒',
  'btn.agreeAll': '全部同意',
  'btn.reject': '拒绝',
  'btn.agree': '同意',
  'btn.addMember': '添加成员',
  'btn.addToMailList': '添加到通讯录',
  'btn.add': '添加',
  'btn.saveToTask': '保存到任务',
  'btn.saveToTask.gridlab': '保存',
  'btn.otherSaveToTask': '另存到任务',
  'btn.editTpl': '编辑',
  'btn.importTpl': '导入模板',
  'btn.newTpl': '新建模板',
  'btn.analysis': '开始解析',
  'btn.hasAnalysis': '已解析',
  'btn.nextStep': '下一步',
  'btn.addLimit': '添加Limit',
  'btn.use': '应用',
  'btn.changeCoordinate': '修改坐标',
  'btn.editChart': '编辑图表',
  'btn.useToAll': '应用到全部',
  'btn.clearAll': '清空',
  'btn.calculate': '计算',
  'btn.insert': '插入',
  'btn.format': '格式',
  'btn.export': '导出',
  'btn.exportExcel': '导出Excel报表',
  'btn.exportWord': '导出Word报告',
  'btn.save': '保存',
  'btn.close': '关闭',
  'btn.setTop': '置顶',
  'btn.edit': '编辑',
  'btn.exitEdit': '退出编辑',
  'btn.dealWav': '波形处理',
  'btn.Mark-up': '批注',
  'btn.share': '分享',
  'btn.saveAs': '另存为',
  'btn.login': '登录',
  'btn.freeUse': '免费使用',
  'btn.downloadView': '下载查看',
  'btn.more': '更多',
  'btn.setting': '设置',
  'btn.inverse': '反选',
  'btn.refresh': '更新',
  'btn.addPackage': '添加工具包',
  'btn.finish': '完成',
  'btn.importSheet': '导入表格数据',
  'btn.copy': '复制',
};

const popover = {
  'popover.rename': '重命名',
  'popover.delete': '删除',
  'popover.reset': '重置',
  'popover.personalCenter': '个人中心',
  'popover.mailList': '通讯录',
  'popover.version': '版本:',
  'popover.loginOut': '退出登录',
  'popover.dismiss': '解散',
  'popover.quit': '退出',
  'popover.removeFromList': '从列表移除',
  'popover.share': '分享',
  'popover.export': '导出',
  'popover.collect': '收藏',
  'popover.hasCollect': '已收藏',
  'popover.ZoomByRect': '矩形选取缩放',
  'popover.ZoomByX': 'X轴选取缩放',
  'popover.ZoomByY': 'Y轴选取缩放',
  'popover.resetZoom': '缩放到合适',
  'popover.scaleX': '缩放X轴',
  'popover.scaleY': '缩放Y轴',
  'popover.thinLine': '细线',
  'popover.thickLine': '粗线',
  'popover.dashedLine': '虚线',
  'popover.thickDashedLine': '粗虚线',
  'popover.addContact': '添加联系人',
  'popover.moveTo': '移动到...',
  'popover.moveOutGroup': '移出分组',
  'popover.watchCard': '查看名片',
  'popover.remarkName': '备注',
  'popover.copy': '复制',
  'popover.selectData': '选中的数据',
  'popover.forbidden': '禁用',
  'popover.cancelForbidden': '取消禁用',
};

const tooltip = {
  'tooltip.preview': '预览',
  'tooltip.download': '下载',
  'tooltip.copyTo': '复制到...',
  'tooltip.share': '分享',
  'tooltip.collect': '收藏',
  'tooltip.cancelCollect': '取消收藏',
  'tooltip.openWay': '打开方式',
  'tooltip.save': '保存',
  'tooltip.delete': '删除',
  'tooltip.removeTask': '移出任务',
  'tooltip.tileView': '平铺视图',
  'tooltip.listView': '列表视图',
  'tooltip.newDir': '新建文件夹',
  'tooltip.upload': '上传',
  'tooltip.saveAs': '另存为',
  'tooltip.dragToMove': '拖拽移动',
  'tooltip.selectZoomIn': '选区放大',
  'tooltip.resetZoom': '缩放到合适',
  'tooltip.ZoomIn': '放大',
  'tooltip.ZoomOut': '缩小',
  'tooltip.controlDimensions': '显示/隐藏标注',
  'tooltip.undo': '撤销',
  'tooltip.redo': '重做',
  'tooltip.drawingPin': '图钉',
  'tooltip.finishDimensions': '结束批注',
  'tooltip.borderStyle': '边框样式',
  'tooltip.color': '颜色',
  'tooltip.endDealWav': '结束波形处理',
  'tooltip.localImport': '本地导入',
  'tooltip.copy': '复制',
  'tooltip.paste': '粘贴',
  'tooltip.insertCol': '插入列',
  'tooltip.insertRow': '插入行',
  'tooltip.delColRow': '删除行/列',
  'tooltip.adjust': '适应',
  'tooltip.fullScreen': '全屏',
};

const message = {
  'message.revise.ok': '修改成功',
  'message.revise.fail': '修改失败',
  'message.addTemp.fail': '添加失败',
  'message.delTemp.fail': '删除失败',
  'message.addTask.ok': '新建任务成功！',
  'message.addTask.fail': '新建任务失败！',
  'message.delGroup.ok': '删除分组成功！',
  'message.delGroup.fail': '删除分组失败！',
  'message.moveGroupToArchive.ok': '分组收纳成功！',
  'message.moveGroupToArchive.fail': '分组收纳失败！',
  'message.moveGroupOutArchive.ok': '分组移出收纳成功！',
  'message.moveGroupOutArchive.fail': '分组移出收纳失败！',
  'message.addGroup.ok': '新建分组成功！',
  'message.addGroup.fail': '新建分组失败！',
  'message.updateTaskName.ok': '修改分组名称成功',
  'message.updateTaskName.fail': '修改分组名称失败！',
  'message.moveTask.ok': '移动任务成功！',
  'message.moveTask.fail': '移动任务失败！',
  'message.moveTaskToArchive.ok': '任务收纳成功！',
  'message.moveTaskToArchive.fail': '任务收纳失败！',
  'message.moveTaskOutArchive.ok': '任务移出收纳成功！',
  'message.moveTaskOutArchive.fail': '任务移出收纳失败！',
  'message.previewNoSupport': '暂不支持该类型文件预览',
  'message.getUserInfo.fail': '获取用户任务信息失败',
  'message.registerSuccessAndCompleteInfo': '注册成功！请尽快完善个人信息！',
  'message.canOpenFile.fail': '打开失败，请选择其他打开方式',
  'message.openFile.fail.retry': '文件打开失败，请稍后重新尝试',
  'message.analyzing': '分析中...',
  'message.operationFailAndAgain': '操作失败，请稍后再试',
  'message.grrAnalyze': '分析',
  'message.onlyExcelSupport': '仅支持Excel格式文件',
  'message.fileNotExist': '原文件不存在',
  'message.shareIsClose': '分享已关闭',
  'message.getShareInfo.fail': '获取分享文件信息失败',
  'message.getFiles.fail': '获取文件失败',
  'message.copyFileTo.fail': '复制失败',
  'message.copyFileTo.ok': '复制成功',
  'message.getShareFileInfo.fail': '获取文件分享信息失败',
  'message.delRecord.ok': '删除记录成功',
  'message.delRecord.fail': '删除记录失败',
  'message.cancelCollect.ok': '取消收藏成功',
  'message.cancelCollect.fail': '取消收藏失败',
  'message.addCollect.ok': '收藏成功',
  'message.addCollect.fail': '收藏失败',
  'message.newCollectFold.ok': '新建收藏夹成功',
  'message.newCollectFold.fail': '新建收藏夹失败',
  'message.fileMoveOtherCollect.ok': '文件已移动到“其他收藏夹”',
  'message.fileMoveOtherCollect.fail': '文件移动到“其他收藏夹”失败',
  'message.recycleDelFile.ok': '彻底删除成功',
  'message.recycleDelFile.fail': '删除失败',
  'message.recycle.recover.ok': '恢复成功',
  'message.recycle.recover.fail': '恢复失败',
  'message.recycle.recover.see': '立即查看',
  'message.getFileInfo.fail': '获取文件信息失败',
  'message.illegalResultName': '结果名称包含非法字符',
  'message.copyClipBoard': '已复制链接',
  'message.searchFriend.fail': '搜索联系人失败！',
  'message.addAllFriends.ok': '全部添加联系人成功!',
  'message.addAllFriends.fail': '全部添加联系人失败！',
  'message.addFriend.ok': '添加联系人成功!',
  'message.addFriend.fail': '添加联系人失败!',
  'message.addContact.ok': '添加联系人成功',
  'message.addContact.fail': '添加联系人失败，请稍后再试',
  'message.delContact.ok': '删除联系人成功',
  'message.delContact.fail': '删除联系人失败',
  'message.delFriend.ok': '删除联系人成功!',
  'message.delFriend.fail': '删除联系人失败!',
  'message.newGroup.ok': '新增分组成功!',
  'message.newGroup.fail': '新增分组失败!',
  'message.changeRemark.ok': '修改联系人备注名成功！',
  'message.changeRemark.fail': '修改联系人备注名失败！',
  'message.moveInGroup.ok': '移动联系人到分组成功！',
  'message.moveInGroup.fail': '移动联系人到分组失败！',
  'message.moveOutGroup.ok': '移出分组成功',
  'message.moveOutGroup.fail': '移出分组失败',
  'message.changeGroupName.ok': '修改分组名称成功！',
  'message.changeGroupName.fail': '修改分组名称失败！',
  'message.quitTask.ok': '退出任务成功',
  'message.dismissTask.one': '解散成功，可在',
  'message.dismissTask.two': '回收站',
  'message.dismissTask.three': '中恢复',
  'message.fileNoExist': '文件不存在',
  'message.delFolder.fail': '删除文件夹失败！',
  'message.delFile.ok': '删除文件成功',
  'message.delFile.fail': '删除文件失败！',
  'message.delFile.one': '删除成功，可在',
  'message.delFile.two': '回收站',
  'message.delFile.three': '中恢复',
  'message.readConfig.error': '读取配置出错',
  'message.folderNameIllegal': '文件夹命名不能包含特殊符号',
  'message.fileNAmeIllegal': '文件命名不能包含特殊符号',
  'message.changeFolderName.ok': '修改文件夹名称成功',
  'message.changeFolderName.fail': '修改文件夹名称失败',
  'message.changeFileName.ok': '修改文件名称成功',
  'message.changeFileName.fail': '修改文件名称失败',
  'message.folder.existSameName': '已存在同名的文件夹',
  'message.file.existSameName': '已存在同名的文件',
  'message.createFolder.ok': '文件夹新建成功!',
  'message.createFolder.fail': '文件夹新建失败!',
  'message.illegalTemplateName': '模板名称包含非法字符',
  'message.getTaskInfo.fail': '获取任务信息失败',
  'message.save.ok': '保存成功',
  'message.save.fail': '保存失败',
  'message.existTag': '标签已存在',
  'message.msg.uploadImgLimit.one': '最多支持上传',
  'message.msg.uploadImgLimit.two': '张图片',
  'message.msg.uploadTypeError': '仅支持图片类型',
  'message.msg.uploadImgSize': '图片必须小于',
  'message.changeRole.ok': '设置成功!',
  'message.changeRole.fail': '设置失败,请稍后再试!',
  'message.refuseInvite.ok': '已拒绝成员邀请!',
  'message.refuseInvite.fail': '拒绝成员邀请失败!',
  'message.removeMember.ok': '移出成员成功!',
  'message.removeMember.fail': '移出成员失败!',
  'message.addMember.fail': '添加失败，请稍后再试',
  'message.createLink.fail': '生成邀请链接失败，请稍后再试',
  'message.getInviteInfo.fail': '获取邀请信息失败，请稍后再试',
  'message.linkApply.fail': '提交申请失败，请稍后再试',
  'message.enterFourPassword': '请输入4-8位密码！',
  'message.sharePage.getData.fail': '获取数据失败！',
  'message.sharePage.saveFile.ok': '文件保存成功',
  'message.sharePage.saveFile.fail': '文件保存失败',
  'message.fileDetail': '请输入备注内容',
  'message.errorAnal.grr': 'GRR分析仅支持Excel格式文件',
  'message.errorAnal.dapt': 'Audio Graph仅支持Excel和Dat格式文件',
  'message.errorAnal.cor': 'correlation仅支持Excel格式文件',
  'message.errorAnal.cpb': 'CPB分析仅支持音频文件',
  'message.errorAnal.dsp': 'Audio Editor仅支持音频文件',
  'message.errorAnal.scic': '科学计算仅支持Excel格式文件',
  'message.errorAnal.spc': '多峰检测仅支持Excel格式文件',
  'message.uploadRootError': '根目录不能上传文件或文件夹',
  'message.getSaveFileInfo.fail': '获取保存文件信息失败',
  'message.deleteFileOperation.fail': '操作失败，文件已经被删除',
  'message.saveTpl.ok': '保存模板成功',
  'message.saveTpl.fail': '保存模板失败',
  'message.uploadTpl.fail': '上传模板失败',
  'message.singleDragError.dragMore': '单文件分析仅支持拖入单个文件',
  'message.singleDragError.dragFolder': '单文件解析不支持文件夹导入',
  'message.importError.notCSV': '请导入csv或mdf文件',
  'message.importError.notCSVOrDAT': '请导入csv、dat、mdf文件',
  'message.importError.noFitMDF': '请导入合适的mdf文件',
  'message.importError.errorTpl': '请导入正确的模板文件',
  'message.readTpl.fail': '读取模板失败',
  'message.calculateError.noFile': '请选择解析文件',
  'message.calculateError.noTpl': '请选择解析模板',
  'message.calculateError.dataTplMismatch': '存在文件与数据解析模板不匹配',
  'message.calculateError.dataTplMismatch.tip':
    '存在文件与数据解析模板不匹配，请更换文件或重新编辑模板',
  'message.calculateError.notSelectData': '请选择数据项目',
  'message.read.fail': '读取失败',
  'message.analysis.fail': '解析失败',
  'message.calculate.fail': '计算失败',
  'message.readLimit.fail': '读取limit失败',
  'message.uploadLimit.noLimit': '请上传limit文件',
  'message.saveUploadLimit.noLimit': '请上传limit文件后再保存',
  'message.saveLimit.ok': '保存limit成功',
  'message.saveLimit.fail': '保存limit失败',
  'message.limitFormatError': '生成limit失败，请检查文件内容',
  'message.uploadLimit.ok': '上传成功',
  'message.readLocalFile.fail': '读取本地文件失败，请稍后再试',
  'message.export.fail': '导出失败',
  'message.export.success': '导出成功',
  'message.uploadCsv.noCsv': '请上传csv文件',
  'message.uploadCsv.noCsvOrDat': '请上传csv或dat文件',
  'message.setCoordinates.fail': '设置坐标失败',
  'message.setCoordinates.xAxisFail': '横坐标的最小值需要大于0',
  'message.mismatchTpl': '模板不匹配，请更换模板',
  'message.networkTimeOut': '网络超时，请检查网络异常',
  'message.update.fail': '更新失败',
  'message.selectWaveForm.empty': '请选择波形',
  'message.onlyAudioSupported': '仅支持音频格式文件',
  'message.importAudio.empty': '请导入音频文件',
  'message.uploadAudio.empty': '请上传音频文件',
  'message.uploadFail.someFail': '部分文件上传失败，请重新上传',
  'message.delete.ok': '删除成功',
  'message.readFile.fail': '读取文件失败',
  'message.copyCell': '已复制单元格内容',
  'message.formulaError': '公式错误,请检查',
  'message.uploadImgFail.noNetWork': '图片上传失败，请检查你的网络！',
  'message.uploadSnapshotFail.noNetWork': '快照上传失败，请检查你的网络!',
  'message.selectTime.ctrlA': '请框选时间范围，或使用“ctrl (command) +a”全选',
  'message.incorrectParams': '请设置正确参数',
  'message.fileNoSupportPlay': '该文件暂不支持播放',
  'message.beMoveOut': '您已经被移出任务',
  'message.taskBeDismiss': '该任务已被解散',
  'message.uploadAttachment.fail': '上传附件失败',
  'message.getToolInfo.fail': '获取常用工具信息失败',
  'message.searchUserError': '搜索用户出错',
  'message.uploadSourceError': '保存过程中源文件上传出错，请稍后再试',
  'message.upload.fail': '上传失败',
  'message.fileRemark.ok': '备注发送成功',
  'message.fileRemark.fail': '备注发送失败',
  'message.readOriginalFile.fail': '读取文件失败',
  'message.selectDataMismatch': '选中的数据不匹配',
  'message.generateOriginalFile.fail': '生成原文件失败',
  'message.chartUpdate.fail': '图表更新失败',
  'message.csvArrEmpty': '文件夹没有符合的文件',
  'message.AGGetDataError.threeLine': '需要选中三格及以上的数据',
  'message.AGGetDataError.headEndNumber': '选中的数据两端必须是数字',
  'message.SCICGetDataError.twoCell': '需要选中某一行或列两格及以上的数据',
  'message.SCICChartError.twoCol': '生成图表需要至少两列数据',
  'message.SCICChartError.twoRow': '生成图表需要至少两行数据',
  'message.SCICGetDataError.number': '选中的数据必须是数字',
  'message.SCICSmartTimesError.effData': '需要填写有效 Frequency 和 Limit 列数据',
  'message.AnalysisFailed': '分析失败',
  'message.processing': '处理中...',
  'message.package.name.empty': '名称不能为空',
  'message.package.name.illegal': '名称包含非法字符',
  'message.package.addMax': '最大数量不能超过8个',
  'message.task.into.creatorDepart': '该任务创建者已离职，请等待管理员指定拥有者',
  'message.submit.ok': '提交成功',
  'message.submit.fail': '提交失败',
};

const breadcrumb = {
  'breadcrumb.allFiles': '全部文件',
};

const checkbox = {
  'checkbox.all': '全部',
  'checkbox.all.small': '全部',
};

export default {
  ...button,
  ...popover,
  ...tooltip,
  ...message,
  ...breadcrumb,
  ...checkbox,
};
