import { DelTask } from '@/services/datalib/bean/DelTask';
import { Task } from '@/services/datalib/bean/Task';
import { TaskGroup } from '@/services/datalib/bean/TaskGroup';

export class TaskGroupList {
  noGroupTasks: string[] = [];
  groupList: TaskGroup[] = [];
  archive?: TaskGroupList;
  tasks: Record<string, Task> = {};
  v: number = 0;
  officialTask?: Task = undefined;
  delTasks: DelTask[] = [];

  constructor(t?: DtlBean.TaskGroups) {
    if (!t) {
      return;
    }
    if (t.groups) {
      const idx = t.groups.findIndex((group) => !group.groupName);
      if (idx > -1 && t.groups[idx].taskIds) {
        this.noGroupTasks = [...t.groups[idx].taskIds];
      }
      const groups = t.groups.filter((group) => !!group.groupName);
      this.groupList = groups.map((group) => new TaskGroup(group));
    }
    if (t.archive) {
      this.archive = new TaskGroupList(t.archive);
    }
    if (t.delTasks) {
      this.delTasks = t.delTasks.map((d) => new DelTask(d));
    }
    if (t.tasks) {
      Object.keys(t.tasks).forEach((taskId) => {
        if (!this.tasks[taskId]) this.tasks[taskId] = new Task(t.tasks[taskId]);
      });
    }
    if (t.v) {
      this.v = t.v;
    }
  }

  filterTask(userId: string, tasks?: Record<string, Task>) {
    let allTasks = this.tasks;
    if (tasks) {
      allTasks = tasks;
    }
    this.noGroupTasks = this.noGroupTasks.filter(
      (taskId) =>
        allTasks[taskId] &&
        (allTasks[taskId].userIds.some((id) => id === userId) ||
          allTasks[taskId].visitorIds.some((id) => id === userId) ||
          allTasks[taskId].uploaderIds.some((id) => id === userId)),
    );
    this.groupList.forEach((group) =>
      group.filterTask(
        (taskId) =>
          allTasks[taskId] &&
          (allTasks[taskId].userIds.some((id) => id === userId) ||
            allTasks[taskId].visitorIds.some((id) => id === userId) ||
            allTasks[taskId].uploaderIds.some((id) => id === userId)),
      ),
    );
    this.archive?.filterTask(userId, allTasks);
  }

  format(): DtlBean.TaskGroupList {
    const tasks: Record<number, DtlBean.Task> = {};
    Object.keys(this.tasks).forEach((id) => {
      const t: Task = this.tasks[id];
      tasks[id] = t.format();
    });
    const noGroupTasks = [...this.noGroupTasks];
    if (this.officialTask) {
      noGroupTasks.unshift(this.officialTask.taskId);
    }
    return {
      noGroupTasks: noGroupTasks,
      groupList: this.groupList.map((g) => g.format()),
      archive: this.archive?.format(),
      tasks,
      v: this.v,
    };
  }

  formatGroups(): DtlBean.TaskGroupList {
    const noGroupTasks = [...this.noGroupTasks];
    if (this.officialTask) {
      noGroupTasks.unshift(this.officialTask.taskId);
    }
    return {
      noGroupTasks: noGroupTasks,
      groupList: this.groupList.map((g) => g.format()),
      archive: this.archive?.format(),
      tasks: undefined,
      v: this.v,
    };
  }

  // copy(): TaskGroupList {
  //   const n = new TaskGroupList();
  //   n.noGroupTasks = [...this.noGroupTasks];
  //   n.groupList = this.groupList.map((t) => t.copy());
  //   n.archive = this.archive?.copy();
  //   Object.keys(this.tasks).forEach((key) => {
  //     const task: Task = this.tasks[key];
  //     n.tasks[key] = task.copy();
  //   });
  //   n.v = this.v;
  //   return n;
  // }

  update(taskGroupList: TaskGroupList) {
    this.noGroupTasks = taskGroupList.noGroupTasks;
    this.groupList = taskGroupList.groupList;
    this.archive = taskGroupList.archive;
    this.delTasks = taskGroupList.delTasks;
    this.v = taskGroupList.v;
    Object.keys(taskGroupList.tasks).forEach((key) => {
      if (!this.tasks[key]) this.tasks[key] = taskGroupList.tasks[key];
    });
  }

  addTask(taskGroupId: string, isArchive: boolean, task: Task) {
    this.delTasks = this.delTasks.filter((d) => d.taskId !== task.taskId);
    if (isArchive) {
      this.archive?.addTask(taskGroupId, false, task);
      return;
    }
    const { taskId } = task;
    if (taskGroupId === '') {
      if (!this.noGroupTasks.some((i) => i === taskId)) {
        this.noGroupTasks.unshift(taskId);
      }
    } else {
      const index = this.groupList.findIndex((group) => group.groupId === taskGroupId);
      if (index > -1) {
        if (!this.groupList[index].taskIds.some((i) => i === taskId)) {
          this.groupList[index].taskIds.unshift(taskId);
        }
      }
    }
    // if (!this.tasks[taskId]) this.tasks[taskId] = task;
    // task.initFinished()
  }

  getTask(taskId: string): Task {
    return this.tasks[taskId];
  }

  delTask(taskId: string, delTask?: DelTask) {
    delete this.tasks[taskId];
    this.removeTaskId(taskId, delTask);
  }

  replaceTask(task: Task) {
    this.tasks[task.taskId] = task;
  }

  delTaskCompletely(taskIds: string[]) {
    if (taskIds) {
      taskIds.forEach((taskId) => this.delTask(taskId));
      this.delTasks = this.delTasks.filter((d) => !taskIds.some((id) => id === d.taskId));
    }
  }

  removeTaskId(taskId: string, delTask?: DelTask) {
    if (delTask) {
      this.delTasks.push(delTask);
    }
    const index = this.noGroupTasks.findIndex((id) => id === taskId);
    if (index > -1) {
      this.noGroupTasks.splice(index, 1);
      return;
    }
    for (let i = 0; i < this.groupList.length; i += 1) {
      const group = this.groupList[i];
      const idx = group.taskIds.findIndex((id) => id === taskId);
      if (idx > -1) {
        group.taskIds.splice(idx, 1);
        return;
      }
    }
    this.archive?.removeTaskId(taskId);
  }

  addTaskGroup(taskGroup: TaskGroup, isArchive: boolean = false) {
    if (!taskGroup) return;
    if (isArchive) {
      if (!this.archive) {
        this.archive = new TaskGroupList();
      }
      this.archive.addTaskGroup(taskGroup);
    } else if (!this.groupList.some((g) => g.groupId === taskGroup.groupId)) {
      this.groupList.unshift(taskGroup);
    }
  }

  updateTaskGroupName(groupId: string, groupName: string, isArchive: boolean = false) {
    if (isArchive) {
      if (!this.archive) return;
      this.archive.updateTaskGroupName(groupId, groupName);
    } else {
      const idx = this.groupList.findIndex((g) => g.groupId === groupId);
      if (idx > -1) {
        this.groupList[idx].groupName = groupName;
      }
    }
  }

  deleteTaskGroup(groupId: string, isArchive: boolean = false) {
    if (!groupId) return;
    if (isArchive) {
      if (!this.archive) return;
      this.archive.deleteTaskGroup(groupId);
    } else {
      const idx = this.groupList.findIndex((g) => g.groupId === groupId);
      if (idx > -1) {
        const delGroup = this.groupList[idx];
        this.groupList.splice(idx, 1);
        this.noGroupTasks = [...delGroup.taskIds, ...this.noGroupTasks];
      }
    }
  }

  moveTaskToGroup(taskGroupId: string, taskId: string, isArchive: boolean = false) {
    if (!taskId) return;
    if (isArchive) {
      if (!this.archive) return;
      this.archive.moveTaskToGroup(taskGroupId, taskId);
    } else {
      this.removeTaskId(taskId);
      if (taskGroupId === '') {
        if (!this.noGroupTasks.some((i) => i === taskId)) {
          this.noGroupTasks.unshift(taskId);
        }
        return;
      }
      const index = this.groupList.findIndex((group) => group.groupId === taskGroupId);
      if (index > -1) {
        if (!this.groupList[index].taskIds.some((i) => i === taskId)) {
          this.groupList[index].taskIds.unshift(taskId);
        }
      }
    }
  }

  initTask(task: Task, initFinished: boolean) {
    if (!task) return;
    this.tasks[task.taskId] = task;
    if (initFinished) {
      task.initFinished();
    }
  }

  updateTaskGroupSeq(groupId: string, index: number, isArchive: boolean = false) {
    if (isArchive) {
      if (!this.archive) return;
      this.archive.updateTaskGroupSeq(groupId, index);
    } else {
      const idx = this.groupList.findIndex((g) => g.groupId === groupId);
      if (idx > -1 && index > -1 && index < this.groupList.length) {
        const arr = this.groupList.splice(idx, 1);
        this.groupList.splice(index, 0, arr[0]);
      }
    }
  }

  getAppRecentFile(userId: string): DtlBean.RecentFile[] {
    const rs: DtlBean.RecentFile[] = [];
    Object.keys(this.tasks).forEach((taskId) => {
      const task = this.tasks[taskId];
      task.getFileLots(userId)?.forEach((s) => {
        const arr = s.split(':');
        rs.push({
          file: undefined,
          fileId: arr[0],
          taskId: taskId,
          taskName: task.name,
          ts: Number(arr[1]),
        });
      });
    });
    rs.sort((a, b) => {
      return b.ts - a.ts;
    });
    return rs;
  }

  setOfficialTask(task: Task) {
    this.officialTask = task;
    this.initTask(task, true);
  }

  getRecycleFiles(userId: string): DtlBean.RecycleFile[] {
    const files: DtlBean.RecycleFile[] = [];
    const now = new Date().getTime();
    const dy = 30 * 24 * 60 * 60 * 1000;
    Object.values(this.tasks).forEach((task) => {
      let isAdm = task.creator === userId || task.admList.some((id) => id === userId);
      let deFiles = isAdm ? task.delFiles : task.delFiles.filter((f) => f.userId === userId);
      deFiles = deFiles.filter((f) => f.ts / 1000000 + dy > now);
      files.push(
        ...deFiles.map((f) => ({
          id: f.id,
          taskId: task.taskId,
          userId: f.userId,
          userName: '',
          taskName: task.name,
          ts: f.ts,
          filePath: f.filePaths[0].path,
          fileId: f.filePaths[0].id,
          fileType: f.filePaths[0].type,
          isAdm,
        })),
      );
    });
    files.sort((a, b) => b.ts - a.ts);
    return files;
  }

  getRecycleTasks(): DtlBean.DelTask[] {
    const now = new Date().getTime();
    const dy = 30 * 24 * 60 * 60 * 1000;
    return this.delTasks.filter((d) => d.ts / 1000000 + dy > now).map((d) => d.format());
  }
}
