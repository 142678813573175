export default {
  'pages.addLink.BigTitle': 'Apply to join the task',
  'pages.addLink.backToIndex': 'Return to Home Page',
  'pages.addLink.submitApply': 'Submit',
  'pages.addLink.hasSubmittedApply': 'Submitted',
  'pages.addLink.hasSubmitted.tips': 'Please wait for the administrator to approve',
  'pages.addLink.refresh': 'refresh',
  'pages.addLink.refuse': 'Administrator rejected',
  'pages.addLink.applyAgain': 'Reapply',
  'pages.addLink.linkLose': 'Link has expired',
  'pages.addLink.linkLose.tips': 'Please contact the sharer to regenerate',
};
