export default {
  'Liquid.params': 'Params',
  'Liquid.inletTemp': 'Inlet water temperature',
  'Liquid.volumeFlow': 'Volumetric flow rate',
  'Liquid.outletTemp': 'Outlet water temperature',
  'Liquid.outerSurfaceArea': 'Outer surface area',
  'Liquid.ambientTemp': 'Ambient temperature',
  'Liquid.power': 'Power',
  'Liquid.cool': 'Refrigeration',
  'Liquid.hot': 'Heating',
};
