export class FileRecord {
  id: string = ''; //id
  ts: number = 0; //时间戳
  from: number = 0; //1:来自任务; 2:来自分享
  tid: string = ''; //任务id
  fid: string = ''; //文件id
  sz: number = 0; //文件大小
  fn: string = ''; //文件名
  tn: string = ''; //任务名
  ft: number = 0; //file.type,文件类型
  ow: string = ''; //file.openWay，文件打开方式
  sid: string = ''; //来自的分享的id
  suid: string = ''; //来自的分享的userId
  sun: string = ''; //来自的分享的userName
  s: number = 0; //记录状态，0正常，1已移除该记录
  isCollected: boolean = false; //是否已收藏
  collectId: string = ''; //收藏的id
  cid: string = ''; //收藏夹id

  constructor(t?: DtlBean.FileRecord) {
    if (!t) {
      return;
    }
    this.id = t.id;
    this.ts = t.ts;
    this.from = t.from;
    this.tid = t.tid;
    this.fid = t.fid;
    this.sz = t.sz;
    this.fn = t.fn;
    this.tn = t.tn;
    this.ft = t.ft;
    this.ow = t.ow;
    this.sid = t.sid;
    this.suid = t.suid;
    this.sun = t.sun;
    this.s = t.s;
    this.isCollected = t.isCollected;
    this.collectId = t.collectId;
    this.cid = t.cid;
  }

  format(): DtlBean.FileRecord {
    return {
      ft: this.ft,
      ow: this.ow,
      s: this.s,
      fid: this.fid,
      fn: this.fn,
      from: this.from,
      id: this.id,
      sid: this.sid,
      suid: this.suid,
      sun: this.sun,
      sz: this.sz,
      tid: this.tid,
      tn: this.tn,
      ts: this.ts,
      isCollected: this.isCollected,
      collectId: this.collectId,
      cid: this.cid,
    };
  }
}
