import { doInitDtlStatus, dtlStatus, updateDtlVerLog } from '../index';
import { Err } from '@/services/datalib/enum';
import {
  dtlTaskArchiveAddGroup,
  dtlTaskArchiveDelGroup,
  dtlTaskArchiveMoveTaskToGroup,
  dtlTaskArchiveUpdateGroupName,
  dtlTaskArchiveUpdateSeq,
  dtlTaskGroupAddGroup,
  dtlTaskGroupArchiveTask,
  dtlTaskGroupArchiveTaskGroup,
  dtlTaskGroupDelGroup,
  dtlTaskGroupMoveTaskToGroup,
  dtlTaskGroupUnarchiveTask,
  dtlTaskGroupUnarchiveTaskGroup,
  dtlTaskGroupUpdateGroupName,
  dtlTaskGroupUpdateSeq,
} from '@/services/datalib/api/taskGroup';
import { doErrResponse, doResponse } from '@/services/datalib/ctrl/util';

export async function taskGroupGetTaskTree(): Promise<
  DtlComm.Result<DtlBean.TaskGroupList | undefined>
> {
  const err = await doInitDtlStatus();
  if (err) return err;
  return {
    code: Err.OK[0],
    data: dtlStatus.taskGroupList.format(),
    message: Err.OK[1],
  };
}

export async function taskGroupAddGroup(
  body: DtlTaskGroup.AddGroupParam,
): Promise<DtlComm.Result<DtlTaskGroup.AddGroupResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskGroupAddGroup(body);
  return doResponse(response);
}

export async function taskGroupUpdateGroupName(
  body: DtlTaskGroup.UpdateGroupNameParam,
): Promise<DtlComm.Result<undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskGroupUpdateGroupName(body);
  return doResponse(response);
}

export async function taskGroupDelGroup(
  body: DtlTaskGroup.DelGroupParam,
): Promise<DtlComm.Result<undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskGroupDelGroup(body);
  return doResponse(response);
}

export async function taskGroupMoveTaskToGroup(
  body: DtlTaskGroup.MoveTaskParam,
): Promise<DtlComm.Result<DtlBean.TaskGroupList | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskGroupMoveTaskToGroup(body);
  const { code, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    return {
      code: Err.OK[0],
      data: dtlStatus.taskGroupList.formatGroups(),
      message: Err.OK[1],
    };
  }
  return doErrResponse(code, message);
}

export async function taskArchiveAddGroup(
  body: DtlTaskGroup.AddGroupParam,
): Promise<DtlComm.Result<DtlTaskGroup.AddGroupResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskArchiveAddGroup(body);
  return doResponse(response);
}

export async function taskArchiveUpdateGroupName(
  body: DtlTaskGroup.UpdateGroupNameParam,
): Promise<DtlComm.Result<undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskArchiveUpdateGroupName(body);
  return doResponse(response);
}

export async function taskArchiveDelGroup(
  body: DtlTaskGroup.DelGroupParam,
): Promise<DtlComm.Result<undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskArchiveDelGroup(body);
  return doResponse(response);
}

export async function taskArchiveMoveTaskToGroup(
  body: DtlTaskGroup.MoveTaskParam,
): Promise<DtlComm.Result<DtlBean.TaskGroupList | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskArchiveMoveTaskToGroup(body);
  const { code, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    return {
      code: Err.OK[0],
      data: dtlStatus.taskGroupList.formatGroups(),
      message: Err.OK[1],
    };
  }
  return doErrResponse(code, message);
}

export async function taskGroupArchiveTask(
  body: DtlTaskGroup.ArchiveTaskParam,
): Promise<DtlComm.Result<DtlBean.TaskGroupList | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskGroupArchiveTask(body);
  const { code, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    return {
      code: Err.OK[0],
      data: dtlStatus.taskGroupList.formatGroups(),
      message: Err.OK[1],
    };
  }
  return doErrResponse(code, message);
}

export async function taskGroupUnarchiveTask(
  body: DtlTaskGroup.ArchiveTaskParam,
): Promise<DtlComm.Result<DtlBean.TaskGroupList | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskGroupUnarchiveTask(body);
  const { code, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    return {
      code: Err.OK[0],
      data: dtlStatus.taskGroupList.formatGroups(),
      message: Err.OK[1],
    };
  }
  return doErrResponse(code, message);
}

export async function taskGroupArchiveTaskGroup(
  body: DtlTaskGroup.ArchiveTaskGroupParam,
): Promise<DtlComm.Result<DtlBean.TaskGroupList | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskGroupArchiveTaskGroup(body);
  const { code, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    return {
      code: Err.OK[0],
      data: dtlStatus.taskGroupList.formatGroups(),
      message: Err.OK[1],
    };
  }
  return doErrResponse(code, message);
}

export async function taskGroupUnarchiveTaskGroup(
  body: DtlTaskGroup.ArchiveTaskGroupParam,
): Promise<DtlComm.Result<DtlBean.TaskGroupList | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskGroupUnarchiveTaskGroup(body);
  const { code, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    return {
      code: Err.OK[0],
      data: dtlStatus.taskGroupList.formatGroups(),
      message: Err.OK[1],
    };
  }
  return doErrResponse(code, message);
}

export async function taskGroupUpdateSeq(
  body: DtlTaskGroup.UpdateSeqParam,
): Promise<DtlComm.Result<DtlBean.TaskGroupList | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskGroupUpdateSeq(body);
  const { code, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    return {
      code: Err.OK[0],
      data: dtlStatus.taskGroupList.formatGroups(),
      message: Err.OK[1],
    };
  }
  return doErrResponse(code, message);
}

export async function taskArchiveUpdateSeq(
  body: DtlTaskGroup.UpdateSeqParam,
): Promise<DtlComm.Result<DtlBean.TaskGroupList | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskArchiveUpdateSeq(body);
  const { code, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    return {
      code: Err.OK[0],
      data: dtlStatus.taskGroupList.formatGroups(),
      message: Err.OK[1],
    };
  }
  return doErrResponse(code, message);
}
