export default {
  'cor.single.title': 'Single file analysis',
  'cor.multiple.title': 'Multi file analysis',
  'cor.uploadData.single.desc': 'Drag and drop files, or import local files',
  'cor.reference.title': 'Reference Standards',
  'cor.reference.default': 'Reference standards not applicable',
  'cor.max': 'Max',
  'cor.min': 'Min',
  'cor.median': 'Median',
  'cor.average': 'Average',
  'cor.RSquare.divider': 'Quickly set R Square limit to all projects',
  'cor.RSquare.tip': 'Note: R Square only has a lower limit and defaults to 0.5. It can be customized and applied to all projects',
  'cor.AB.divider': 'Quickly set the Average Bias limit to all projects',
  'cor.AB.tip1': 'Note 1: The existing Upper/Lower Limit for the same project Raw data will be automatically identified, and the Average Bias Upper/Lower Limit will be calculated and displayed',
  'cor.AB.tip2': 'Note 2: If the existing Upper/Lower is missing, it will be calculated as 0 by default',
};
