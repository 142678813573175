import API_VERSION from '@/services/datalib/api/config';
import request from '@/utils/request';

export async function dtlUserExtUsualTools(body: DtlUserExt.UsualToolsParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/user/tools/usual`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlUserToolsExt(body: DtlUserExt.ToolsExtParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/user/tools/ext`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}
