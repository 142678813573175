import { doInitDtlStatus, doResponse } from '@/services/datalib';
import {
  dtlFeedbackAdd,
  dtlFeedbackList,
  dtlFeedbackResolve,
} from '@/services/datalib/api/feedback';

export async function feedbackAdd(
  body: DtlFeedback.AddParam,
): Promise<DtlComm.Result<DtlFeedback.AddResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlFeedbackAdd(body);
  return doResponse(response);
}

export async function feedbackResolve(
  body: DtlFeedback.ResolveParam,
): Promise<DtlComm.Result<DtlFeedback.ListResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlFeedbackResolve(body);
  return doResponse(response);
}

export async function feedbackList(): Promise<DtlComm.Result<DtlFeedback.ListResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlFeedbackList();
  return doResponse(response);
}
