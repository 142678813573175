import API_VERSION from '@/services/datalib/api/config';
import request from '@/utils/request';

export async function dtlTaskInviteGenerate(body: DtlTaskInvite.GenerateParam) {
  return request<DtlComm.ResultV<DtlBean.TaskInvite>>(`${API_VERSION}/api/task/invite/generate`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskInviteVerify(body: DtlTaskInvite.VerifyParam) {
  return request<DtlComm.ResultV<DtlTaskInvite.VerifyResult>>(
    `${API_VERSION}/api/task/invite/verify`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}
