import {
  dtlLogin,
  dtlLogout,
  dtlRegister,
  dtlSendAuthCode,
  dtlUpdatePwd,
  dtlValidateAuthCode,
} from '@/services/datalib/api/login';
import { dtlReset, initDtlSocket, initDtlStatus, initDtlVerLog } from '../index';
import { Err } from '@/services/datalib/enum';
import { Md5 } from 'ts-md5';
import { doSimpleResponse } from '@/services/datalib/ctrl/util';

export async function login(
  body: DtlLogin.LoginParam,
): Promise<DtlComm.Result<{ serverToken: string } | undefined>> {
  const bo = { ...body };
  if (bo.password) {
    bo.password = Md5.hashStr(bo.password).toString();
  }
  const response = await dtlLogin(bo);
  const { code, data, verLog } = response;
  if (code === Err.OK[0]) {
    initDtlStatus(data);
    initDtlVerLog(verLog);
    initDtlSocket();
    let serverToken = '';
    if (data) {
      serverToken = data.serverToken;
    }
    return {
      code: Err.OK[0],
      data: { serverToken },
      message: Err.OK[1],
    };
  }
  return doSimpleResponse(response);
}

export async function logout(): Promise<DtlComm.Result<undefined>> {
  const response = await dtlLogout();
  dtlReset();
  return doSimpleResponse(response);
}

export async function register(
  body: DtlLogin.RegisterParam,
): Promise<DtlComm.Result<{ serverToken: string } | undefined>> {
  const response = await dtlRegister(body);
  const { code, data, verLog } = response;
  if (code === Err.OK[0]) {
    initDtlStatus(data);
    initDtlVerLog(verLog);
    initDtlSocket();
    let serverToken = '';
    if (data) {
      serverToken = data.serverToken;
    }
    return {
      code: Err.OK[0],
      data: { serverToken },
      message: Err.OK[1],
    };
  }
  return doSimpleResponse(response);
}

export async function sendAuthCode(
  body: DtlLogin.SendAuthCodeParam,
): Promise<DtlComm.Result<DtlLogin.SendAuthCodeResult | undefined>> {
  const response = await dtlSendAuthCode(body);
  return doSimpleResponse(response);
}

export async function validateAuthCode(
  body: DtlLogin.ValidateAuthCodeParam,
): Promise<DtlComm.Result<DtlLogin.ValidateAuthCodeResult | undefined>> {
  const response = await dtlValidateAuthCode(body);
  return doSimpleResponse(response);
}

export async function updatePwd(body: DtlLogin.UpdatePwdParam): Promise<DtlComm.Result<undefined>> {
  const bo = { ...body };
  if (bo.password) {
    bo.password = Md5.hashStr(bo.password).toString();
  }
  const response = await dtlUpdatePwd(bo);
  return doSimpleResponse(response);
}
