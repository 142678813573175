export default {
  'pages.settings.BigTitle': '个人信息',
  'pages.settings.desc': '修改账户的基本信息',
  'pages.settings.backToIndex': '返回首页',
  'pages.settings.changeAvatar': '点击左侧更换头像',
  'pages.settings.accountInfo.tab': '账户信息',
  'pages.settings.userInfo.tab': '个人资料',
  'pages.settings.accountInfo.userName': '用户名',
  'pages.settings.accountInfo.email': '邮箱',
  'pages.settings.accountInfo.account': '注册账号',
  'pages.settings.accountInfo.phone': '手机号',
  'pages.settings.accountInfo.changeBind': '换绑',
  'pages.settings.accountInfo.bind': '绑定',
  'pages.settings.accountInfo.mailNewBind': '绑定新邮箱',
  'pages.settings.accountInfo.phoneNewBind': '绑定新手机',
  'pages.settings.accountInfo.mailCode': '邮箱号',
  'pages.settings.accountInfo.mailSafeTip': '为保障账号安全，请验证当前邮箱',
  'pages.settings.accountInfo.phoneSafeTip': '为保障账号安全，请验证当前手机',
  'pages.settings.accountInfo.mailChangeBind': '换绑邮箱',
  'pages.settings.accountInfo.phoneChangeBind': '换绑手机',
  'pages.settings.userInfo.company': '公司名称',
  'pages.settings.userInfo.department': '部门名称',
  'pages.settings.userInfo.gender': '性别',
  'pages.settings.userInfo.address': '地址',
  'pages.settings.userInfo.required': '请输入',
  'pages.settings.userInfo.gender.required': '选择性别',
  'pages.settings.save': '保存',
  'pages.settings.cancel': '取消',
  'pages.settings.openPhoneAndEmail': '公开手机号和邮箱',
  'pages.settings.hasSetPassword': '密码已设置',
  'pages.settings.setPasswordBtn': '修改',
  'pages.settings.accountInfo.email.required': '请输入邮箱',
  'pages.settings.accountInfo.email.error': '邮箱格式不正确，请参考example@xxx.com',
  'pages.settings.accountInfo.userName.required': '请输入用户名',
  'pages.settings.avatar.uploadAvatar': '请上传头像',
  'pages.settings.avatar.uploadTip1': '最大支持',
  'pages.settings.avatar.uploadTip2': 'M的JPG、PNG的图像格式',
  'pages.settings.avatar.uploadError': '头像上传失败! ',
  'pages.settings.completeInfo': '请完善个人信息',
  'pages.settings.changePassword': '修改密码',
  'pages.settings.avatar.formatTip': '头像仅支持JPG/PNG格式!',
  'pages.settings.avatar.sizeLimitTip': '图片必须小于',
  'pages.settings.userName.formatTip': '不支持特殊符号和空格',
  'pages.settings.gender.male': '男',
  'pages.settings.gender.female': '女',
};
