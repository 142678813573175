export default {
  'popover.createEnterprise': 'Create a company',
  'popover.enterpriseManage': 'Enterprise management',

  'enterprise.tab.title.organization': 'Organization',
  'enterprise.tab.title.delUser': 'Resignations',
  'enterprise.tab.title.setting': 'Settings',
  'enterprise.tab.title.member': 'member',
  'enterprise.tab.title.depart': 'department',

  'enterprise.btn.allMembers': 'All Members',
  'enterprise.btn.bindDingTalk': 'Bind DingTalk Contacts',
  'enterprise.btn.addMember': 'Add Member',
  'enterprise.btn.confirm': 'Confirm',
  'enterprise.btn.saveContinue': 'Save and Continue',
  'enterprise.btn.handover': 'Handover',
  'enterprise.btn.recover': 'Resume employment',
  'enterprise.btn.deleteSelect': 'Batch Delete',
  'enterprise.btn.next': 'Next',
  'enterprise.btn.back': 'Back',
  'enterprise.btn.disband.confirm': 'Confirm',
  'enterprise.btn.create.free': 'Create for free',
  'enterprise.btn.create.goto': 'Go now',

  'enterprise.input.searchMember': 'Search Member',
  'enterprise.input.departName.placeholder': 'Please enter the department name',
  'enterprise.input.company.name.placeholder': 'Please enter the company name',

  'message.createCompany.one.tip': 'Each account can only create one new enterprise team',
  '该部门名称已被占用': 'The department name has been occupied',
  'enterprise.message.departName.empty': 'Department name cannot be empty',
  'enterprise.message.input.trueName': 'Please enter your real name',
  'enterprise.message.input.depart': 'Please select at least one department to which you belong',
  'enterprise.message.input.account': 'Please enter your phone number or email',
  'enterprise.message.input.realAccount': 'Please enter the correct phone number or email',
  'enterprise.message.edit.success': 'Modified successfully',
  'enterprise.message.add.success': 'Added successfully',
  'enterprise.message.recover.someFail': 'Partial employee recovery failed',
  'enterprise.message.warnToHandover': 'There are tasks that have not been handed over among the selected employees',
  'enterprise.message.delete.someFail': 'Partial employee deletion failed',
  'enterprise.message.uploadImg.fail': 'Image upload failed',
  'enterprise.message.disband.success': 'Successfully dissolved the enterprise',
  'enterprise.message.company.name.empty': 'Enterprise name cannot be empty',
  'enterprise.message.user.name.empty': 'True name cannot be empty',
  'enterprise.message.company.name.used': 'The company name has been registered, please use another',
  'enterprise.message.somePassFail': 'Partial task assignment failed',
  'enterprise.message.deleteTrue.empty': 'Please select at least one member',
  'enterprise.message.setting.upload.logo.format': 'Uploading images only supports JPG/PNG formats!',

  'enterprise.table.status.unregistered': 'unregistered',
  'enterprise.table.status.admin': 'admin',
  'enterprise.table.more': 'more',
  'enterprise.table.header.name': 'Name',
  'enterprise.table.header.phone': 'Phone',
  'enterprise.table.header.email': 'Email',
  'enterprise.table.header.operate': 'Operate',
  'enterprise.table.header.deleteTime': 'Termination Date',

  'enterprise.dropdown.addDept': 'Add sub department',
  'enterprise.dropdown.rename': 'Rename',
  'enterprise.dropdown.delete': 'Delete',

  'enterprise.modal.rename.title': 'Rename department name',
  'enterprise.modal.delete.member.title': 'Do you want to delete this member?',
  'enterprise.modal.delete.member.tip1': 'After deleting,  you can complete the handover ',
  'enterprise.modal.delete.member.tip2': 'in Resignations',
  'enterprise.modal.delete.member.tip3': ' ',
  'enterprise.modal.delete.depart.title': 'Do you want to delete this department?',
  'enterprise.modal.delete.depart.tip': 'The members of this department will be deleted simultaneously, and this operation is irreversible',
  'enterprise.modal.chooseDepart': 'Please select to add department',
  'enterprise.modal.memberNum': 'Number of members',
  'enterprise.modal.reassign': 'reassign',
  'enterprise.modal.assign': 'assign',
  'enterprise.modal.passTask.title': 'Handover enterprise tasks',
  'enterprise.modal.passTask.tip': 'is the owner of the following enterprise tasks, you can designate other member as the owner',
  'enterprise.modal.deleteTrue.member.title': 'Do you want to completely delete this member?',
  'enterprise.modal.deleteTrue.member.tip': 'The tasks created by this member are deleted simultaneously, and this operation is irreversible',

  'enterprise.drawer.edit.title': 'Edit Member',
  'enterprise.drawer.add.title': 'Add Member',
  'enterprise.drawer.realName': 'Name',
  'enterprise.drawer.department': 'Department',
  'enterprise.drawer.department.placeholder': 'Please select the department',
  'enterprise.drawer.jobNum': 'Job Number',
  'enterprise.drawer.jobNum.placeholder': 'Please enter the job number',

  'enterprise.setting.title': 'Enterprise settings',
  'enterprise.setting.disbandBtn': 'Disband',
  'enterprise.setting.name': 'Name :',
  'enterprise.setting.logo': 'Logo :',
  'enterprise.setting.logo.upload.tip': 'Click to upload the image, supporting up to 1M JPG and PNG image formats',
  'enterprise.setting.disband.disbandTitle': 'Disband enterprise',
  'enterprise.setting.disband.tip': 'Dissolving a company is a high-risk and irreversible operation, please proceed with caution!',
  'enterprise.setting.disband.step1.title': 'Reason',
  'enterprise.setting.disband.step2.title': 'Influence',
  'enterprise.setting.disband.step3.title': 'Authentication',
  'enterprise.setting.disband.step1.choose.title': 'Please select the reason for dissolving the company :',
  'enterprise.setting.disband.step1.choose.one': 'Upcoming resignation, unmanaged enterprise',
  'enterprise.setting.disband.step1.choose.two': 'The enterprise has ceased operations',
  'enterprise.setting.disband.step2.choose.title': 'After the dissolution of the enterprise, the following impacts will occur :',
  'enterprise.setting.disband.step2.choose.one': 'The organizational structure within the enterprise will be cleared, inaccessible, and unrecoverable',
  'enterprise.setting.disband.step2.choose.two': 'All tasks and task data within the enterprise will be cleared, inaccessible, and unrecoverable',
  'enterprise.setting.disband.step2.checkbox': 'I have already realized that dissolving the company will have the above-mentioned impacts',
  'enterprise.setting.disband.step3.verify.title': 'To ensure the security of enterprise information and property, please verify the phone number/email associated with the super administrator account :',
  'enterprise.setting.disband.step3.verify.account': 'phone / email :',
  'enterprise.setting.disband.step3.verify.code': 'code :',

  'enterprise.create.gotoInfo1': 'Enterprise Management ',
  'enterprise.create.gotoInfo1.tip': 'Experience rich enterprise management functions',
  'enterprise.create.gotoInfo2': 'Add Member',
  'enterprise.create.gotoInfo2.tip': 'Invite members and initiate efficient collaboration',
  'enterprise.create.title': 'Magic Data Cloud Collaboration',
  'enterprise.create.tip': 'Quickly create enterprise teams and collaborate more efficiently with colleagues',
  'enterprise.create.input.company.name': 'enterprise name',
  'enterprise.create.input.trueName': 'true name',
  'enterprise.create.success.title': 'Created successfully',
  'enterprise.create.success.tip': 'Go to add members and enable efficient collaboration',
}
