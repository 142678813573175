export class DelTask {
  id: string = '';
  ts: number = 0;
  creator: string = '';
  taskId: string = '';
  taskName: string = '';
  groupId: string = '';
  isArchive: boolean = false;

  constructor(t: DtlBean.DelTask) {
    if (!t) {
      return;
    }
    if (t.id) {
      this.id = t.id;
    }
    if (t.ts) {
      this.ts = t.ts;
    }
    if (t.creator) {
      this.creator = t.creator;
    }
    if (t.taskId) {
      this.taskId = t.taskId;
    }
    if (t.taskName) {
      this.taskName = t.taskName;
    }
    if (t.groupId) {
      this.groupId = t.groupId;
    }
    if (t.isArchive) {
      this.isArchive = t.isArchive;
    }
  }

  format(): DtlBean.DelTask {
    return {
      creator: this.creator,
      groupId: this.groupId,
      id: this.id,
      isArchive: this.isArchive,
      taskId: this.taskId,
      taskName: this.taskName,
      ts: this.ts,
    };
  }
}
