import {
  dtlEnterpriseAddDept,
  dtlEnterpriseAddUser,
  dtlEnterpriseBaseInfos,
  dtlEnterpriseConvertTask,
  dtlEnterpriseCreate,
  dtlEnterpriseDelDept,
  dtlEnterpriseDelUser,
  dtlEnterpriseDepartUser,
  dtlEnterpriseDismiss,
  dtlEnterpriseGetHandoverTasks,
  dtlEnterpriseInfo,
  dtlEnterpriseRecoverUser,
  dtlEnterpriseRenameDept,
  dtlEnterpriseRessignCreator,
  dtlEnterpriseUpdate,
  dtlEnterpriseUpdateUser,
  dtlEnterpriseUpdateUserExt,
} from '@/services/datalib/api/enterprise';
import { Dept } from '@/services/datalib/bean/Dept';
import { Enterprise } from '@/services/datalib/bean/Enterprise';
import { Task } from '@/services/datalib/bean/Task';
import { doResponse, reply, reReply } from '@/services/datalib/ctrl/util';
import { DeptUserStatus, Err } from '../enum';
import { doInitDtlStatus, dtlStatus, updateDtlVerLog } from '../index';

// 新建企业
export async function enterpriseCreate(
  body: DtlEnterprise.CreateParam,
): Promise<DtlComm.Result<DtlEnterprise.EnterpriseResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const rs = await dtlEnterpriseCreate(body);
  return doResponse(rs, { enterprise: new Enterprise(rs.data?.enterprise).format() });
}

// 获取自建企业及成员的phone/email
export async function enterpriseGetSelfInfo(): Promise<
  DtlComm.Result<DtlEnterprise.EnterpriseResult | undefined>
> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const rs = await dtlEnterpriseInfo();
  if (Err.isOk(rs)) {
    dtlStatus.enterpriseList.addOrUpdateEnterprise(rs.data!.enterprise);
  }
  return doResponse(rs, { enterprise: new Enterprise(rs.data?.enterprise).format() });
}

// 获取多个企业基本信息
export async function enterpriseBaseInfos(
  body: DtlEnterprise.BaseInfosParam,
): Promise<DtlComm.Result<Record<string, DtlBean.Enterprise> | undefined>> {
  if (!body || !body.enterpriseIds || body.enterpriseIds.length === 0) {
    return reply(Err.REQUEST_DATA_ERROR);
  }
  const err = await doInitDtlStatus();
  if (err) return err;

  const m: Record<string, DtlBean.Enterprise> = dtlStatus.enterpriseList.getEnterpriseByIds(
    body.enterpriseIds,
  );
  const reqIds: string[] = [];
  body.enterpriseIds.forEach((id) => {
    if (!m[id]) {
      reqIds.push(id);
    }
  });
  if (reqIds.length === 0) {
    return reply(Err.OK, m);
  }
  const response = await dtlEnterpriseBaseInfos({ enterpriseIds: reqIds });
  const { code, data, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    data!.enterprises.forEach((a) => {
      const e = dtlStatus.enterpriseList.addOrUpdateEnterprise(a);
      m[e.id] = e.format();
    });
    return reply(Err.OK, m);
  }
  return reReply(response);
}

// 新增企业的部门
export async function enterpriseAddDept(
  body: DtlEnterprise.AddDeptParam,
): Promise<DtlComm.Result<DtlEnterprise.DeptResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const rs = await dtlEnterpriseAddDept(body);
  return doResponse(rs, { dept: new Dept(rs.data?.dept).format() });
}

// 企业部门重命名
export async function enterpriseRenameDept(
  body: DtlEnterprise.RenameDeptParam,
): Promise<DtlComm.Result<DtlEnterprise.DeptResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const rs = await dtlEnterpriseRenameDept(body);
  return doResponse(rs, { dept: new Dept(rs.data?.dept).format() });
}

// 企业新增用户
export async function enterpriseAddUser(
  body: DtlEnterprise.AddUserParam,
): Promise<DtlComm.Result<DtlEnterprise.EnterpriseResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const rs = await dtlEnterpriseAddUser(body);
  return doResponse(rs, { enterprise: new Enterprise(rs.data?.enterprise).format() });
}

// 企业更新用户信息
export async function enterpriseUpdateUser(
  body: DtlEnterprise.UpdateUserParam,
): Promise<DtlComm.Result<DtlEnterprise.EnterpriseResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const rs = await dtlEnterpriseUpdateUser(body);
  return doResponse(rs, { enterprise: new Enterprise(rs.data?.enterprise).format() });
}

// 更新企业名或logo
export async function enterpriseUpdate(
  body: DtlEnterprise.UpdateParam,
): Promise<DtlComm.Result<DtlEnterprise.EnterpriseResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const rs = await dtlEnterpriseUpdate(body);
  return doResponse(rs, { enterprise: new Enterprise(rs.data?.enterprise).format() });
}

// 获取所有企业
export async function enterpriseGetAll(): Promise<
  DtlComm.Result<{ list: DtlBean.Enterprise[] } | undefined>
> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const data = dtlStatus.enterpriseList.getAllEnterprise();
  return reply(Err.OK, { list: data });
}

// 获取自己创建的企业
export async function enterpriseGetSelf(): Promise<
  DtlComm.Result<{ enterprise: DtlBean.Enterprise | undefined } | undefined>
> {
  const err = await doInitDtlStatus();
  if (err) return err;
  return reply(Err.OK, { enterprise: dtlStatus.enterpriseList.getSelfEnterprise() });
}

// 根据企业id获取企业信息
export async function enterpriseGetById(body: {
  enterpriseId: string;
}): Promise<DtlComm.Result<{ enterprise: DtlBean.Enterprise | undefined } | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  return reply(Err.OK, { enterprise: dtlStatus.enterpriseList.getEnterpriseById(body) });
}

// 更新企业中的个人信息
export async function enterpriseUpdateUserExt(
  body: DtlEnterprise.UpdateUserExtParam,
): Promise<DtlComm.Result<DtlEnterprise.EnterpriseResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const rs = await dtlEnterpriseUpdateUserExt(body);
  return doResponse(rs, { enterprise: new Enterprise(rs.data?.enterprise).format() });
}

// 企业对员工进行离职
export async function enterpriseDepartUser(
  body: DtlEnterprise.DepartUserParam,
): Promise<DtlComm.Result<DtlEnterprise.EnterpriseResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const rs = await dtlEnterpriseDepartUser(body);
  return doResponse(rs, { enterprise: new Enterprise(rs.data?.enterprise).format() });
}

// 获取离职员工列表
export async function enterpriseGetDepartList(body: {
  enterpriseId: string;
}): Promise<DtlComm.Result<DtlEnterprise.DepartInfo[] | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const rs = await dtlEnterpriseGetHandoverTasks(body);
  if (Err.isError(rs)) {
    return reReply(rs);
  }
  if (rs.verLog) {
    updateDtlVerLog(rs.verLog);
  }
  dtlStatus.enterpriseList.addOrUpdateEnterprise(rs.data!.enterprise);
  const m: Record<
    string,
    {
      taskId: string;
      taskName: string;
      taskMemberCnt: number;
    }[]
  > = {};
  if (rs.data?.tasks) {
    rs.data.tasks
      .map((a) => new Task(a))
      .forEach((task) => {
        if (!m[task.creator]) {
          m[task.creator] = [];
        }
        m[task.creator].push({
          taskId: task.taskId,
          taskMemberCnt: task.userIds.length + task.visitorIds.length + task.uploaderIds.length,
          taskName: task.name,
        });
      });
  }
  const list: DtlEnterprise.DepartInfo[] = [];
  const enterprise = new Enterprise(rs.data!.enterprise);
  Object.keys(enterprise.users).forEach((userId) => {
    const user = enterprise.users[userId];
    if (user.status === DeptUserStatus.Depart) {
      list.push({
        userId: userId,
        userName: user.trueName,
        avatar: user.avatar,
        depts: user.previousDept!,
        phone: user.phone!,
        email: user.email!,
        updateTime: user.updateTime,
        tasks: m[userId],
      });
    }
  });
  return reply(Err.OK, list);
}

// 将离职任务指定拥有者
export async function enterpriseRessignCreator(
  body: DtlEnterprise.RessignCreatorParam,
): Promise<DtlComm.Result<DtlEnterprise.EnterpriseResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const rs = await dtlEnterpriseRessignCreator(body);
  return doResponse(rs, { enterprise: new Enterprise(rs.data?.enterprise).format() });
}

// 将离职人员彻底删除
export async function enterpriseDelUser(
  body: DtlEnterprise.DelUserParam,
): Promise<DtlComm.Result<DtlEnterprise.EnterpriseResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const rs = await dtlEnterpriseDelUser(body);
  return doResponse(rs, { enterprise: new Enterprise(rs.data?.enterprise).format() });
}

// 将离职人员恢复在职
export async function enterpriseRecoverUser(
  body: DtlEnterprise.RecoverUserParam,
): Promise<DtlComm.Result<DtlEnterprise.EnterpriseResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const rs = await dtlEnterpriseRecoverUser(body);
  return doResponse(rs, { enterprise: new Enterprise(rs.data?.enterprise).format() });
}

// 删除部门
export async function enterpriseDelDept(
  body: DtlEnterprise.DelDeptParam,
): Promise<DtlComm.Result<DtlEnterprise.EnterpriseResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const rs = await dtlEnterpriseDelDept(body);
  return doResponse(rs, { enterprise: new Enterprise(rs.data?.enterprise).format() });
}

// 解散企业
export async function enterpriseDismiss(
  body: DtlEnterprise.DismissParam,
): Promise<DtlComm.Result<DtlEnterprise.EnterpriseResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const rs = await dtlEnterpriseDismiss(body);
  return doResponse(rs, { enterprise: new Enterprise(rs.data?.enterprise).format() });
}

// 将个人任务转企业任务
export async function enterpriseConvertTask(
  body: DtlEnterprise.ConvertTaskParam,
): Promise<DtlComm.Result<undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const rs = await dtlEnterpriseConvertTask(body);
  return doResponse(rs, undefined);
}
