import strain from '@/locales/en-US/apps/strain';
import addLink from '@/locales/zh-CN/addLink';
import antd from '@/locales/zh-CN/antd';
import AG from '@/locales/zh-CN/apps/AG';
import audioEditor from '@/locales/zh-CN/apps/audioEditor';
import common from '@/locales/zh-CN/apps/common';
import correlation from '@/locales/zh-CN/apps/correlation';
import cpb from '@/locales/zh-CN/apps/cpb';
import Enthalpy from '@/locales/zh-CN/apps/Enthalpy';
import EnthalpyDiffLab from '@/locales/zh-CN/apps/EnthalpyDiffLab';
import grr from '@/locales/zh-CN/apps/grr';
import Humidity from '@/locales/zh-CN/apps/Humidity';
import Liquid from '@/locales/zh-CN/apps/Liquid';
import Refrigerant from '@/locales/zh-CN/apps/Refrigerant';
import SCIC from '@/locales/zh-CN/apps/SCIC';
import Season from '@/locales/zh-CN/apps/Season';
import spc from '@/locales/zh-CN/apps/spc';
import wav from '@/locales/zh-CN/apps/wav';
import modal from '@/locales/zh-CN/modal';
import myFile from '@/locales/zh-CN/myFile';
import normal from '@/locales/zh-CN/normal';
import DelFileContent from '@/locales/zh-CN/TaskDetailContent/DelFileContent';
import FileAnalToolSelector from '@/locales/zh-CN/TaskDetailContent/FileAnalToolSelector';
import FileShortCutPanel from '@/locales/zh-CN/TaskDetailContent/FileShortCutPanel';
import TaskDetailContent from '@/locales/zh-CN/TaskDetailContent/TaskDetailContent';
import uploadModal from '@/locales/zh-CN/TaskDetailContent/uploadModal';
import TaskListContent from '@/locales/zh-CN/TaskListContent/TaskListContent';
import toolPanel from '@/locales/zh-CN/toolPanel';
import contacts from './zh-CN/contacts';
import enterprise from './zh-CN/enterprise';
import gridlab from './zh-CN/gridlab';
import login from './zh-CN/login';
import settings from './zh-CN/settings';
import FeedBackContent from './zh-CN/TaskDetailContent/FeedBackContent';

export default {
  ...login,
  ...settings,
  ...contacts,
  ...addLink,
  ...antd,
  ...modal,
  ...myFile,
  ...normal,
  ...toolPanel,
  ...TaskListContent,
  ...DelFileContent,
  ...FeedBackContent,
  ...FileAnalToolSelector,
  ...FileShortCutPanel,
  ...TaskDetailContent,
  ...uploadModal,
  ...audioEditor,
  ...common,
  ...correlation,
  ...cpb,
  ...Enthalpy,
  ...grr,
  ...Humidity,
  ...Liquid,
  ...Refrigerant,
  ...Season,
  ...wav,
  ...AG,
  ...EnthalpyDiffLab,
  ...SCIC,
  ...spc,
  ...strain,
  ...gridlab,
  ...enterprise,
};
