export default {
  'cor.single.title': '单文件分析',
  'cor.multiple.title': '多文件分析',
  'cor.uploadData.single.desc': '拖拽文件，或本地文件导入',
  'cor.reference.title': '参考标准',
  'cor.reference.default': '不适用参考标准',
  'cor.max': '最大值',
  'cor.min': '最小值',
  'cor.median': '中位数',
  'cor.average': '平均值',
  'cor.RSquare.divider': '快速设置R Square limit 到全部项目',
  'cor.RSquare.tip': '注: R Square 只有下限，默认为0.5，可自定义修改应用到全部项目',
  'cor.AB.divider': '快速设置 Average Bias limit 到全部项目',
  'cor.AB.tip1': '注1: 将自动识别相同项目Raw data现有的Upper/Lower，计算并显示Average Bias Upper/Lower Limit',
  'cor.AB.tip2': '注2: 若缺少现有Upper/Lower，默认Upper/Lower为0计算',
};
