export default {
  'FeedBackContent.title': 'FeedBack',
  'FeedBackContent.popover.title': 'FeedBack',
  'FeedBackContent.popover.title.official': 'FeedBack',
  'FeedBackContent.title.official': 'FeedBack',
  'FeedBackContent.type': 'FeedBack Type',
  'FeedBackContent.adivce': 'Confusions and Suggestions',
  'FeedBackContent.bug': 'Bug',
  'FeedBackContent.subType': 'FeedBack Functional Classification',
  'FeedBackContent.subType.err': 'Please select feedBack functional classification',
  'FeedBackContent.contact': 'Contact Information',
  'FeedBackContent.contact.default': 'Please fill your email address',
  'FeedBackContent.contact.err': 'Please fill in the correct email address',
  'FeedBackContent.desc': 'Describe',
  'FeedBackContent.desc1': 'Please provide detailed ',
  'FeedBackContent.desc2': 'usage scenario description + necessary screenshots',
  'FeedBackContent.desc3': 'help us accurately understand your needs and provide support for you! ',
  'FeedBackContent.desc.default':
    'Please provide a detailed description and attach relevant screenshots',
  'FeedBackContent.desc.err': 'Please enter a valid description of no less than 3 words',
  'FeedBackContent.insertPic': 'Insert Pictures',
  'FeedBackContent.submit': 'Submit',
  'FeedBackContent.pushAdvice': 'Provide confusion and suggestions',
  'FeedBackContent.pushBug': 'Provide Bug',
  'FeedBackContent.resolved': 'Resolved',
  'FeedBackContent.check': 'View',
  'FeedBackContent.toResolve': 'Set as resolved',
  'FeedBackContent.subType.placeholder': 'Please Choose',
  'FeedBackContent.subType.other': 'Other',
  'FeedBackContent.subType.space': 'Task Space',
  'FeedBackContent.subType.account': 'Account',
  'FeedBackContent.success.title': 'Feedback successful',
  'FeedBackContent.success.tip': 'Thank you for your feedback. We will reply as soon as possible',
  'FeedBackContent.success.continue': 'Continue to provide feedback',
  'FeedBackContent.noItem': 'No feedback',
};
