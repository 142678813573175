export class RecordV {
  userId: string = '';
  ts: number = 0;

  constructor(t?: DtlBean.RecordV) {
    if (!t) {
      return;
    }
    if (t.userId) {
      this.userId = t.userId;
    }
    if (t.ts) {
      this.ts = t.ts;
    }
  }

  format(): DtlBean.RecordV {
    return {
      userId: this.userId,
      ts: this.ts,
    };
  }

  copy(): RecordV {
    const n = new RecordV();
    n.userId = this.userId;
    n.ts = this.ts;
    return n;
  }
}
