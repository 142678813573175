import { Unread } from '@/services/datalib/bean/Unread';

export class UnreadList {
  unreadList: Unread[] = [];
  oReadIds: string[] = [];
  v: number = 0;

  constructor(t?: DtlBean.AllUnread) {
    if (!t) return;
    if (t.unreadList) {
      this.unreadList = t.unreadList.map((r) => new Unread(r));
    }
    if (t.oReadIds) {
      this.oReadIds = [...t.oReadIds];
    }
    if (t.v) {
      this.v = t.v;
    }
  }

  addUnread(unread: Unread) {
    if (!unread) return;
    if (!this.unreadList.some((u) => u.id === unread.id)) {
      this.unreadList.push(unread);
    }
    this.unreadList.sort((a, b) => a.time - b.time);
    // this.unreadList = this.unreadList.slice(-100);
  }

  deleteUnread(deleted: string[]) {
    if (!deleted) return;
    const m: Record<string, boolean> = {};
    this.oReadIds.forEach((i) => (m[i] = true));
    this.oReadIds.push(...deleted.filter((k) => !m[k]));
    deleted.forEach((id) => {
      const idx = this.unreadList.findIndex((ur) => ur.id === id);
      if (idx > -1) {
        this.unreadList.splice(idx, 1);
      }
    });
  }

  // copy(): UnreadList {
  //   const n = new UnreadList();
  //   n.unreadList = this.unreadList.map((ur) => ur.copy());
  //   n.v = this.v;
  //   return n;
  // }

  format(): DtlBean.Unread[] {
    return this.unreadList.map((ur) => ur.format());
  }
}
