export default {
  'Enthalpy.nozzleDiameter.title': 'Nozzle diameter',
  'Enthalpy.nozzleDiameter': 'Nozzle diameter(mm)',
  'Enthalpy.nozzleNumber': 'Number',
  'Enthalpy.specs1': 'Specification 1',
  'Enthalpy.specs2': 'Specification 2',
  'Enthalpy.specs3': 'Specification 3',
  'Enthalpy.specs4': 'Specification 4',
  'Enthalpy.inputPlaceholder': 'Optional',
  'Enthalpy.params': 'Params',
  'Enthalpy.insideDryTemp': 'Built-in dry bulb temperature',
  'Enthalpy.NozzleHumidity': 'Temperature before nozzle',
  'Enthalpy.insideWetTemp': 'Built-in wet bulb temperature',
  'Enthalpy.NozzlePressure': 'Nozzle pressure difference',
  'Enthalpy.outletDryTemp': 'Air outlet dry bulb temperature',
  'Enthalpy.heatLeak': 'Heat leakage',
  'Enthalpy.outletWetTemp': 'Air outlet wet bulb temperature',
  'Enthalpy.airLeak': 'Air leakage',
  'Enthalpy.atmosphericPressure': 'Atmospheric pressure',
  'Enthalpy.coolOrHot': 'Refrigeration or Heating',
  'Enthalpy.airBoxPressure': 'Inner bellows static pressure',
  'Enthalpy.cool': 'Refrigeration',
  'Enthalpy.hot': 'Heating',
  'Enthalpy.Fs': 'Wind speed',
  'Enthalpy.BFs': 'Standard wind speed',
  'Enthalpy.Fn': 'Air volume',
  'Enthalpy.BFn': 'Standard air volume',
  'Enthalpy.OutH': 'Inlet air enthalpy value',
  'Enthalpy.H': 'Enthalpy difference',
  'Enthalpy.Q': 'Power',
  'Enthalpy.Qx': 'Sensible heat',
  'Enthalpy.Qq': 'Latent heat',
  'Enthalpy.Qs': 'Dehumidification capacity',
};
