export class MsgList {
  msgMap: Map<number, any> | null;
  singleAddKey: number;
  constructor() {
    this.singleAddKey = 0;
    this.msgMap = new Map();
  }

  public add(key: number, value: any): boolean {
    if (this.msgMap && this.msgMap.has(key)) {
      return false;
    }
    if (key === this.singleAddKey + 1) {
      this.singleAddKey = key;
      return true;
    }
    this.msgMap?.set(key, value);
    return false;
  }

  public getSeqValue(): any {
    const targetNum = this.singleAddKey + 1;
    if (this.msgMap && this.msgMap.has(targetNum)) {
      const value = this.msgMap.get(targetNum);
      if (this.msgMap.delete(targetNum)) {
        this.singleAddKey = targetNum;
        return value;
      }
    }
    return null;
  }

  public clear() {
    if (this.msgMap) {
      this.msgMap.clear();
      this.msgMap = null;
    }
  }
}
