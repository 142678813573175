export default {
  'grr.table.experiment': 'Experimental information',
  'grr.table.dataInfo': 'Data information',
  'grr.table.grrData': 'GRR Data',
  'grr.table.emptyText': 'Empty Data',
  'grr.table.noGaugeId': 'Empty GaugeId',
  'grr.chartTab.title': 'Charts',
  'grr.resultData.title': 'Result data',
  'grr.table.calculation': 'GRR Calculation',
  'grr.tol.title': 'Settings',
  'grr.tol.zero': 'Default zero',
  'grr.tol.zero.desc1': 'Missing Limit, default to zero',
  'grr.tol.zero.desc2': 'Tolerance = existing Limit - 0',
  'grr.tol.average': 'Average-adjusted',
  'grr.tol.average.desc1': 'Missing Limit, replace the curve average and adjust',
  'grr.tol.average.desc2': 'Tolerance = (existing Limit - average) * 2',
};
