import { ConvProgram } from '@/services/datalib/bean/ConvProgram';

export class OpenType {
  sid: number = 1;
  name: string = '';
  desc: string = '';
  programName: string = '';
  program?: ConvProgram;
  asProgram: boolean = false;

  constructor(t: DtlBean.OpenType) {
    if (!t) return;
    if (t.sid) {
      this.sid = t.sid;
    }
    if (t.name) {
      this.name = t.name;
    }
    if (t.desc) {
      this.desc = t.desc;
    }
    if (t.programName) {
      this.programName = t.programName;
    }
    if (t.program) {
      this.program = new ConvProgram(t.program);
    }
    if (t.asProgram) {
      this.asProgram = t.asProgram;
    }
  }

  copy(): OpenType {
    return new OpenType(this.format());
  }

  format(): DtlBean.OpenType {
    return {
      sid: this.sid,
      name: this.name,
      desc: this.desc,
      programName: this.programName,
      program: this.program?.format(),
      asProgram: this.asProgram,
    };
  }
}
