import { fromUtf8, toUtf8 } from '@aws-sdk/util-utf8-browser';
import { gunzip, gzip } from 'fflate';
import { Md5 } from 'ts-md5';

const crypto = require('browserify-aes');

interface AlpOptions {
  version: number;
  compressType: number;
  encryptType: number;
  Aes256KeyIndex: number;
  FileSize?: number;
  FieldSize?: number;
  FieldNum?: number;
  DataSize?: number;
}

const CIPHERS = ['plain-text', 'aes-128-ecb', 'aes-192-ecb', 'aes-256-ecb'];

function getD(index: number): string {
  let d = 'alphagraph';
  for (let i = 0; i <= index && i < CIPHERS.length; i++) {
    d += CIPHERS[i];
  }
  return Md5.hashStr(d).toString();
}

async function doCipher(buff: Uint8Array, i: number): Promise<Uint8Array> {
  return new Promise((resolve, reject) => {
    const suite = crypto.createCipher(CIPHERS[i], getD(i));
    let buf = Buffer.alloc(0);
    suite.on('data', function (d: any) {
      buf = Buffer.concat([buf, d]);
    });
    suite.on('error', function (e: any) {
      reject(e);
    });
    suite.on('end', function () {
      resolve(buf);
    });
    suite.write(buff);
    suite.end();
  });
}

async function doDecipher(buff: Uint8Array, i: number): Promise<Uint8Array> {
  return new Promise((resolve, reject) => {
    const suite = crypto.createDecipher(CIPHERS[i], getD(i));
    let buf = Buffer.alloc(0);
    suite.on('data', function (d: any) {
      buf = Buffer.concat([buf, d]);
    });
    suite.on('error', function (e: any) {
      reject(e);
    });
    suite.on('end', function () {
      resolve(buf);
    });
    suite.write(buff);
    suite.end();
  });
}

export async function toAlpFile(
  data: string,
  headers: string[] = [],
  options?: AlpOptions,
): Promise<ArrayBufferLike> {
  if (!data || data.length === 0) {
    throw new Error('Data could not be none');
  }

  options = options || {
    version: 0,
    compressType: 0,
    encryptType: 1,
    Aes256KeyIndex: 3,
  };

  const field_num = headers.length;
  const arr = headers.map((header) => fromUtf8(header));
  const fieldSize = arr.reduce((a, b) => a + b.length + 4, 0);

  let data2 = fromUtf8(data);
  if (data2.length > 50000) {
    const { err, data: zipData } = await new Promise((rs) =>
      gzip(
        data2,
        {
          level: 1,
          consume: true,
        },
        (err, data) => rs({ err, data }),
      ),
    );
    if (err) {
      throw err;
    }
    data2 = zipData;
    options.compressType = 1;
  }
  arr.push(await doCipher(data2, options.Aes256KeyIndex));

  const fileSize = 24 + arr.reduce((a, b) => a + b.length + 4, 0);

  let output = new Uint8Array(fileSize);
  let view = new DataView(output.buffer);
  const isLittleEndian = true;

  output.set(fromUtf8('ALP'), 0);
  view.setUint8(3, options.version); //version
  view.setUint16(4, options.compressType, isLittleEndian); //compressType
  view.setUint16(6, options.encryptType, isLittleEndian); //encryptType
  view.setUint32(8, options.Aes256KeyIndex, isLittleEndian); //Aes256Key_index
  view.setUint32(12, fileSize, isLittleEndian); //extend_field_num
  view.setUint32(16, fieldSize, isLittleEndian); //extend_field_num
  view.setUint32(20, field_num, isLittleEndian); //extend_field_num
  let offset = 24; //16
  arr.forEach((field) => {
    view.setUint32(offset, field.length, isLittleEndian);
    offset += 4;
    output.set(field, offset);
    offset += field.length;
  });
  return output.buffer;
}

export async function fromAlpFile(
  file: any,
): Promise<{ headers: string[]; data: string; options: AlpOptions }> {
  const isLittleEndian = true;
  const view = getAsDataView(file);
  const buffer = view.buffer;
  const totalSize = view.byteLength;
  if (totalSize < 24) {
    throw new Error('Not AlpFile');
  }
  const magicNumber = toUtf8(new Uint8Array(buffer.slice(0, 3)));
  if (magicNumber !== 'ALP') {
    throw new Error('Not AlpFile');
  }
  const version = view.getUint8(3);
  const compressType = view.getUint16(4, isLittleEndian);
  const encryptType = view.getUint16(6, isLittleEndian);
  const Aes256Key_index = view.getUint32(8, isLittleEndian);
  const fileSize = view.getUint32(12, isLittleEndian);
  const fieldSize = view.getUint32(16, isLittleEndian);
  const fieldNum = view.getUint32(20, isLittleEndian);

  let headers = [];
  let data = '';
  let offset = 24;
  if (fieldNum > 0) {
    for (let i = 0; i < fieldNum; i++) {
      if (offset + 4 > totalSize) {
        break;
      }
      const size = view.getUint32(offset, isLittleEndian);
      offset += 4;
      if (offset + size > totalSize) {
        break;
      }
      if (size > 0) {
        headers.push(toUtf8(new Uint8Array(buffer.slice(offset, offset + size))));
      }
      offset += size;
    }
  }
  let dataSize = 0;
  if (offset + 4 <= totalSize) {
    dataSize = view.getUint32(offset, isLittleEndian);
    offset += 4;
    if (offset + dataSize <= totalSize && dataSize > 0) {
      let dataBuf = await doDecipher(
        new Uint8Array(buffer.slice(offset, offset + dataSize)),
        Aes256Key_index,
      );
      if (compressType === 1) {
        const { err, data: uzipData } = await new Promise((rs) =>
          gunzip(dataBuf, { consume: true }, (err, data) => rs({ err, data })),
        );
        if (err) {
          throw err;
        }
        dataBuf = uzipData;
      }
      data = toUtf8(dataBuf);
    }
    offset += dataSize;
  }
  return {
    headers,
    data,
    options: {
      version: version,
      compressType: compressType,
      encryptType: encryptType,
      Aes256KeyIndex: Aes256Key_index,
      FileSize: fileSize,
      FieldSize: fieldSize,
      FieldNum: fieldNum,
      DataSize: dataSize + 4,
    },
  };
}

/**
 * Wraps and returns a typed array or ArrayBuffer in a DataView
 *
 * @param  {Mixed}     data A DataView, ArrayBuffer, TypedArray or node Buffer
 * @return {DataView}       A DataView wrapping the passed data
 * @throws {TypeError}      The passed data needs to be of a supported type
 */
function getAsDataView(data: any) {
  if (data instanceof DataView) {
    return data;
  } else if (data instanceof ArrayBuffer) {
    return new DataView(data);
  } else if ('buffer' in data) {
    return new DataView(data.buffer);
  } else {
    throw new TypeError('Could not convert data of type into a DataView.');
  }
}

//# ALP文件
// ```text
//
//    0                   1                   2                   3
//    0 1 2 3 4 5 6 7 8 9 0 1 2 3 4 5 6 7 8 9 0 1 2 3 4 5 6 7 8 9 0 1
//    +-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+
//    |magic_number: 24bit                            |version: 8bit  |
//    +-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+
//    |compress_type: 16bit            |encrypt_type: 16bit           |
//    +-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+
//    |Aes256Key_index: 32bit                                         |
//    +-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+
//    |file_size: 32bit                                               |
//    +-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+
//    |field_size: 32bit                                              |
//    +-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+
//    |field_num: 32bit                                               |
//    +-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+
//    |field1_length:  32bit                                          |
//    +-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+
//    |field1_data                                                    |
//    +-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+
//    |......                                                         |
//    +-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+
//    |fieldN_length:  32bit                                          |
//    +-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+
//    |fieldN_data                                                    |
//    +-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+
//    |data_size: 32bit                                               |
//    +-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+
//    |data                                                           |
//    +-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+
// ```
// global.toAlpFile = toAlpFile;
// global.fromAlpFile = fromAlpFile;
