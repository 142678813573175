export default {
  'Liquid.params': '参数',
  'Liquid.inletTemp': '进水温度',
  'Liquid.volumeFlow': '体积流量',
  'Liquid.outletTemp': '出水温度',
  'Liquid.outerSurfaceArea': '外表面积',
  'Liquid.ambientTemp': '环境温度',
  'Liquid.power': '功率',
  'Liquid.cool': '制冷',
  'Liquid.hot': '制热',
};
