import { doInitDtlStatus, doResponse, dtlStatus, Err, getUsers } from '@/services/datalib';
import {
  dtlTaskDismissCompletely,
  dtlTaskFileDelFilesCompletely,
  dtlTaskFileDelFilesRecover,
  dtlTaskRecover,
} from '@/services/datalib/api/taskFile';

export async function userRecycleGetFiles(): Promise<
  DtlComm.Result<{ recycleFiles: DtlBean.RecycleFile[] } | undefined>
> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const files = dtlStatus.taskGroupList.getRecycleFiles(dtlStatus.userInfo.userId);
  const { code, data } = await getUsers({ userIds: files.map((f) => f.userId) });
  if (code === Err.OK[0] && data) {
    files.map((f) => (f.userName = data[f.userId].userName));
  }
  return {
    code: Err.OK[0],
    data: {
      recycleFiles: files,
    },
    message: Err.OK[1],
  };
}

export async function taskFileDelFilesCompletely(
  body: DtlTaskFile.DelFilesCompletelyParam,
): Promise<DtlComm.Result<undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskFileDelFilesCompletely(body);
  return doResponse(response);
}

export async function taskFileDelFilesRecover(
  body: DtlTaskFile.DelFilesRecoverParam,
): Promise<DtlComm.Result<undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskFileDelFilesRecover(body);
  return doResponse(response);
}

export async function taskRecover(
  body: DtlTaskFile.TaskRecoverParam,
): Promise<DtlComm.Result<undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskRecover(body);
  return doResponse(response);
}

export async function userRecycleGetTasks(): Promise<
  DtlComm.Result<{ recycleTasks: DtlBean.DelTask[] } | undefined>
> {
  const err = await doInitDtlStatus();
  if (err) return err;
  return {
    code: Err.OK[0],
    data: {
      recycleTasks: dtlStatus.taskGroupList.getRecycleTasks(),
    },
    message: Err.OK[1],
  };
}

export async function taskDismissCompletely(
  body: DtlTaskFile.TaskDismissCompletelyParam,
): Promise<DtlComm.Result<undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskDismissCompletely(body);
  return doResponse(response);
}
