export class Anal {
  id: string = '';
  type: string = '';
  name: string = '';
  desc: string = '';
  ts: number = 0;
  convId: string = '';
  userId: string = '';
  params: string = '';
  result: string = '';
  ext: string = '';

  constructor(t?: DtlBean.Anal) {
    if (!t) return;
    if (t.id) this.id = t.id;
    if (t.type) this.type = t.type;
    if (t.name) this.name = t.name;
    if (t.desc) this.desc = t.desc;
    if (t.ts) this.ts = t.ts;
    if (t.convId) this.convId = t.convId;
    if (t.userId) this.userId = t.userId;
    if (t.params) this.params = t.params;
    if (t.result) this.result = t.result;
    if (t.ext) this.ext = t.ext;
  }

  format(): DtlBean.Anal {
    return {
      id: this.id,
      type: this.type,
      name: this.name,
      desc: this.desc,
      ts: this.ts,
      convId: this.convId,
      userId: this.userId,
      params: this.params,
      result: this.result,
      ext: this.ext,
    };
  }

  copy(): Anal {
    return new Anal(this.format());
  }
}
