export default {
  S_OK: 'S_OK',
  S_CREATED: 'S_CREATED',
  S_ACCEPTED: 'S_ACCEPTED',
  S_NO_CONTENT: 'S_NO_CONTENT',

  FA_NETWORK_FAIL: 'FA_NETWORK_FAIL',

  FA_INVALID_REQUEST: 'FA_INVALID_REQUEST',
  FA_UNAUTHORIZED: 'FA_UNAUTHORIZED',
  FA_FORBIDDEN: 'FA_FORBIDDEN',
  FA_RESOURCE_NOT_FOUND: 'FA_RESOURCE_NOT_FOUND',
  FA_NOT_ACCEPTABLE: 'FA_NOT_ACCEPTABLE',
  FA_RESOURCE_GONE: 'FA_RESOURCE_GONE',
  FA_UNPROCESSABLE_ENTITY: 'FA_UNPROCESSABLE_ENTITY',

  FA_SERVER_ERROR: 'FA_SERVER_ERROR',
  FA_BAD_GATEWAY: 'FA_BAD_GATEWAY',
  FA_SERVICE_UNAVAILABLE: 'FA_SERVICE_UNAVAILABLE',
  FA_GATEWAY_TIMEOUT: 'FA_GATEWAY_TIMEOUT',
  FA_VERSION_NOT_SUPPORTED: 'FA_VERSION_NOT_SUPPORTED',
};
