export default {
  'enthalpyDiffLab.searchType.placeholder': 'search hear',
  'enthalpyDiffLab.report.channelId': 'ID',
  'enthalpyDiffLab.report.channelName': 'Channel Name',
  'enthalpyDiffLab.report.customName': 'Custom Name',
  'enthalpyDiffLab.report.unit': 'Unit',
  'enthalpyDiffLab.report.average': 'Average',
  'enthalpyDiffLab.show': 'display',
  'enthalpyDiffLab.cursor': 'Cursor',
  'enthalpyDiffLab.timeDifference': 'Time Difference',
  'enthalpyDiffLab.channelSetting': 'Channel settings',
};
