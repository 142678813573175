export default {
  'spc.report.key': 'Index',
  'spc.report.name': 'Item',
  'spc.report.lower': 'LSL',
  'spc.report.upper': 'USL',
  'spc.report.unit': 'Unit',
  'spc.report.result': 'BM',
  'spc.report.score': 'Score',
  'spc.report.thumbnail': 'Histogram',
  'spc.exportResult': 'Export Result',
  'spc.refreshResult': 'Refresh Result',
  'spc.refreshResultTips':
    'The refreshed result will overwrite the current result, witch may be calculated by the iterative algorithm.',
};
