export class OnlyOffice {
  key: string = '';
  cb: OnlyOfficeCb[] = [];

  constructor(t?: DtlBean.OnlyOffice) {
    if (!t) return;
    if (t.key) this.key = t.key;
    if (t.cb) this.cb = t.cb.map((a) => new OnlyOfficeCb(a));
  }

  format(): DtlBean.OnlyOffice {
    return {
      key: this.key,
      cb: this.cb.map((a) => a.format()),
    };
  }

  copy() {
    return new OnlyOffice(this.format());
  }

  get initialized(): boolean {
    return this.key !== '';
  }
}

class OnlyOfficeCb {
  actions: OnlyOfficeCbAction[] = [];
  changesurl: string = '';
  filetype: string = '';
  forcesavetype: number = 0;
  history: OnlyOfficeCbHistory = new OnlyOfficeCbHistory();
  key: string = '';
  status: number = 0;
  url: string = '';
  userdata: string = '';
  users: string[] = [];
  lastsave: string = '';
  notmodified: boolean = false;

  constructor(t?: DtlBean.OnlyOfficeCb) {
    if (!t) return;
    if (t.actions) this.actions = t.actions.map((a) => new OnlyOfficeCbAction(a));
    if (t.changesurl) this.changesurl = t.changesurl;
    if (t.filetype) this.filetype = t.filetype;
    if (t.forcesavetype) this.forcesavetype = t.forcesavetype;
    if (t.history) this.history = new OnlyOfficeCbHistory(t.history);
    if (t.key) this.key = t.key;
    if (t.status) this.status = t.status;
    if (t.url) this.url = t.url;
    if (t.userdata) this.userdata = t.userdata;
    if (t.users) this.users = [...t.users];
    if (t.lastsave) this.lastsave = t.lastsave;
    if (t.notmodified) this.notmodified = t.notmodified;
  }

  format(): DtlBean.OnlyOfficeCb {
    return {
      actions: this.actions.map((a) => a.format()),
      changesurl: this.changesurl,
      filetype: this.filetype,
      forcesavetype: this.forcesavetype,
      history: this.history.format(),
      key: this.key,
      status: this.status,
      url: this.url,
      userdata: this.userdata,
      users: [...this.users],
      lastsave: this.lastsave,
      notmodified: this.notmodified,
    };
  }
}

class OnlyOfficeCbAction {
  type: number = 0;
  userid: string = '';

  constructor(t?: DtlBean.OnlyOfficeCbAction) {
    if (!t) return;
    if (t.type) this.type = t.type;
    if (t.userid) this.userid = t.userid;
  }

  format(): DtlBean.OnlyOfficeCbAction {
    return {
      type: this.type,
      userid: this.userid,
    };
  }
}

class OnlyOfficeCbHistory {
  serverVersion: string = '';
  changes: OnlyOfficeCbHistoryChange[] = [];

  constructor(t?: DtlBean.OnlyOfficeCbHistory) {
    if (!t) return;
    if (t.serverVersion) this.serverVersion = t.serverVersion;
    if (t.changes) this.changes = t.changes.map((a) => new OnlyOfficeCbHistoryChange(a));
  }

  format(): DtlBean.OnlyOfficeCbHistory {
    return {
      serverVersion: this.serverVersion,
      changes: this.changes.map((a) => a.format()),
    };
  }
}

class OnlyOfficeCbHistoryChange {
  created: string = '';
  user: OnlyOfficeUser = new OnlyOfficeUser();

  constructor(t?: DtlBean.OnlyOfficeCbHistoryChange) {
    if (!t) return;
    if (t.created) this.created = t.created;
    if (t.user) this.user = new OnlyOfficeUser(t.user);
  }

  format(): DtlBean.OnlyOfficeCbHistoryChange {
    return {
      created: this.created,
      user: this.user.format(),
    };
  }
}

class OnlyOfficeUser {
  id: string = '';
  name: string = '';

  constructor(t?: DtlBean.OnlyOfficeUser) {
    if (!t) return;
    if (t.id) this.id = t.id;
    if (t.name) this.name = t.name;
  }

  format(): DtlBean.OnlyOfficeUser {
    return {
      id: this.id,
      name: this.name,
    };
  }
}
