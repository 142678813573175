import { doInitDtlStatus, dtlStatus } from '@/services/datalib';
import { dtlUserExtUsualTools, dtlUserToolsExt } from '@/services/datalib/api/userExt';
import { doResponse } from '@/services/datalib/ctrl/util';
import { Err } from '@/services/datalib/enum';

export async function userExtUpdateUsualTools(
  body: DtlUserExt.UsualToolsParam,
): Promise<DtlComm.Result<undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlUserExtUsualTools(body);
  return doResponse(response);
}

export async function userExtGetUsualTools(): Promise<
  DtlComm.Result<{ usualTools: string } | undefined>
> {
  const err = await doInitDtlStatus();
  if (err) return err;
  return {
    code: Err.OK[0],
    data: { usualTools: dtlStatus.userExt.usualTools },
    message: Err.OK[1],
  };
}

export async function userExtUpdateToolsExt(
  body: DtlUserExt.ToolsExtParam,
): Promise<DtlComm.Result<undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlUserToolsExt(body);
  return doResponse(response);
}

export async function userExtGetToolsExt(): Promise<
  DtlComm.Result<{ toolsExt: string } | undefined>
> {
  const err = await doInitDtlStatus();
  if (err) return err;
  return {
    code: Err.OK[0],
    data: { toolsExt: dtlStatus.userExt.toolsExt },
    message: Err.OK[1],
  };
}
