type Callback = (cancel: boolean, data?: any) => boolean;

export class ListenEvent {
  listeners: Record<number, Callback[]> = {};

  addListener(key: number, callback: Callback) {
    if (!this.listeners[key]) {
      this.listeners[key] = [];
    }
    this.listeners[key].push(callback);
  }

  removeListener(key: number, callback: Callback) {
    if (this.listeners[key]) {
      const idx = this.listeners[key].findIndex((cb: Callback) => cb === callback);
      if (idx > -1) {
        callback(true);
        this.listeners[key].splice(idx, 1);
      }
    }
  }

  destroy() {
    Object.keys(this.listeners).forEach((key) => {
      this.listeners[key].forEach((callback: Callback) => {
        try {
          callback(true);
          // eslint-disable-next-line no-empty
        } catch (e) {}
      });
    });
    this.listeners = {};
  }

  fireEvent(key: number, data: any) {
    if (this.listeners[key]) {
      const arr: Callback[] = [];
      this.listeners[key].forEach((callback) => {
        try {
          const needRemove = callback(false, data);
          if (!needRemove) {
            arr.push(callback);
          }
          // eslint-disable-next-line no-empty
        } catch (e) {}
      });
      this.listeners[key] = arr;
    }
  }
}
