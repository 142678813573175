export default {
  'pages.contacts.BigTitle': '通讯录',
  'pages.contacts.desc': '常用联系人',
  'pages.contacts.account': '个人账户',
  'pages.contacts.accountType': '账户类型：',
  'pages.contacts.backToIndex': '返回首页',
  'pages.contacts.addGroupBtn': '新增分组',
  'pages.contacts.addBtn': '添加',
  'pages.contacts.searchBtn': '查找',
  'pages.contacts.groupTitle': '分组',
  'pages.contacts.friendTitle': '联系人',
  'pages.contacts.addGroup.placeholder': '请输入分组名称',
  'pages.contacts.rightPanel.title': '添加联系人',
  'pages.contacts.rightPanel.searchInput.placeholder': '输入手机号或邮箱',
  'pages.contacts.rightPanel.searchInput.tips': '输入多个联系人，用逗号或空格分隔',
  'pages.contacts.rightPanel.resultTitle': '结果',
  'pages.contacts.rightPanel.addAll': '全部添加',
  'pages.contacts.rightPanel.hasAdd': '已添加',
  'pages.contacts.rightPanel.to': '到',
  'pages.contacts.remarkInput.placeholder': '请输入好友备注名',
  'pages.contacts.noRegister': '未注册',
  'pages.contacts.hasAdd': '已添加',
  'pages.contacts.newGroup.placeholder': '请输入分组名称',
};
