const browserTip = {
  'browserTip.title': ' has some compatibility issue and the tool maybe unable to be used',
  'browserTip.tip': 'Suggest upgrading to version 14 or above, or using Chrome browser',
  'browserTip.back': 'Back',
};

const chirpModal = {
  'chirpModal.begin': 'Begin',
  'chirpModal.end': 'End',
  'chirpModal.interpolation': 'Interpolation:',
  'chirpModal.interpolation.line': 'Linear',
  'chirpModal.interpolation.log': 'Logarithm',
};

const noiseModal = {
  'noiseModal.noiseType': 'Noise type:',
  'noiseModal.noiseType.white': 'White noise',
  'noiseModal.noiseType.pink': 'Pink noise',
  'noiseModal.noiseType.brown': 'Brownian noise',
};

const DTMFModal = {
  'DTMFModal.soundStatic': 'Sound/Static noise ratio:',
  'DTMFModal.duty': 'Duty ratio:',
  'DTMFModal.soundDuration': 'Sound Duration:',
  'DTMFModal.emptyDuration': 'Blank Duration:',
};

const common = {
  'common.oscillogram': 'Waveform',
  'common.oscillogram.sine': 'Sine',
  'common.oscillogram.squareWave': 'Square Wave',
  'common.oscillogram.sawtooth': 'Sawtooth',
  'common.oscillogram.squareWaveNoAlias': 'Square wave, no aliasing',
  'common.oscillogram.square': 'Triangle',
  'common.frequency': 'Frequency(Hz):',
  'common.amplitude': 'Amplitude(0-1):',
  'common.length': 'Length:',
  'common.length.hour': 'hour',
  'common.length.minute': 'minute',
  'common.length.second': 'second',
  'common.DTMF': 'DTMF Sequence:',
};

const spectrumDiagramModal = {
  'spectrumDiagramModal.Title': 'Spectrum Chart Settings',
  'spectrumDiagramModal.length': 'Length',
  'spectrumDiagramModal.color': 'Color',
  'spectrumDiagramModal.scale': 'Scale:',
  'spectrumDiagramModal.scale.line': 'Linear',
  'spectrumDiagramModal.scale.log': 'Logarithm',
  'spectrumDiagramModal.scale.mei': 'Mel scale',
  'spectrumDiagramModal.scale.period': 'Period',
  'spectrumDiagramModal.gain': 'Gain(dB):',
  'spectrumDiagramModal.minFrequency': 'Min Frequency:',
  'spectrumDiagramModal.maxFrequency': 'Max Frequency:',
  'spectrumDiagramModal.range': 'Range(dB):',
  'spectrumDiagramModal.highBoost': 'High Boost(dB/dec):',
  'spectrumDiagramModal.algorithm': 'Algorithm',
  'spectrumDiagramModal.algorithm.frequency': 'Frequency',
  'spectrumDiagramModal.algorithm.reassign': 'Reassign',
  'spectrumDiagramModal.algorithm.soundHigh': 'Pitch(EAC)',
  'spectrumDiagramModal.windowSize': 'Window Size:',
  'spectrumDiagramModal.windowType': 'Window Type:',
  'spectrumDiagramModal.zeroFillFactor': 'Zero Fill Factor:',
  'spectrumDiagramModal.rectangle': 'Rectangle',
  'spectrumDiagramModal.windowSize.max': 'Most wideband',
  'spectrumDiagramModal.windowSize.default': 'Default',
  'spectrumDiagramModal.windowSize.min': 'Most narrowband',
  'spectrumDiagramModal.colorScheme': 'Color Scheme',
};

const filterCurveEqualizerModal = {
  'filterCurveEqualizerModal.preSet': 'Preinstall',
  'filterCurveEqualizerModal.preSet.default': 'Default',
  'filterCurveEqualizerModal.preSet.100': '100Hz rumble',
  'filterCurveEqualizerModal.preSet.AM': 'AM radio',
  'filterCurveEqualizerModal.preSet.bassAmplification': 'Bass boost',
  'filterCurveEqualizerModal.preSet.bassCutOff': 'Bass cut',
  'filterCurveEqualizerModal.preSet.voiceRollDown': 'Low rolloff for speech',
  'filterCurveEqualizerModal.preSet.phone': 'Telephone',
  'filterCurveEqualizerModal.preSet.trebleAmplification': 'Treble amplification',
  'filterCurveEqualizerModal.preSet.trebleCutOff': 'Treble cut',
  'filterCurveEqualizerModal.preSet.walkie-talkie': 'Walkie-talkie',
  'filterCurveEqualizerModal.reverse': 'Invert',
  'filterCurveEqualizerModal.flatten': 'Flatten',
  'filterCurveEqualizerModal.linearScale': 'Linear Scale',
  'filterCurveEqualizerModal.gridLines': 'Show grid lines',
};

const noiseReductionModal = {
  'noiseReductionModal.step1': 'Step 1',
  'noiseReductionModal.step1.desc':
    'Select a few seconds of noisy audio to let signal processing know what to filter',
  'noiseReductionModal.step1.getNoiseCharacter.btn': 'Obtaining noise characteristics',
  'noiseReductionModal.step1.getNoiseCharacter.select.placeholder': 'No noise feature selected',
  'noiseReductionModal.step1.getNoiseCharacter.modal':
    'Please box select a segment as the noise characteristic.',
  'noiseReductionModal.step2': 'Step 2',
  'noiseReductionModal.step2.desc':
    'Please select all the audio you want to filter, then select how much noise you want to filter out, and then click OK to reduce the noise.',
  'noiseReductionModal.step2.getAudio': 'Get Audio',
  'noiseReductionModal.step2.getAudio.modal': 'Please select a waveform to reduce noise.',
  'noiseReductionModal.step2.getAudio.select.placeholder': 'No audio selected',
  'noiseReductionModal.denoiseIntensity': 'Noise reduction intensity(dB)',
  'noiseReductionModal.sensitivity': 'Sensitivity',
  'noiseReductionModal.smoothFrequency': 'Frequency smoothing(frequency band)',
  'noiseReductionModal.noise': 'Noise',
  'noiseReductionModal.noise.remove': 'Reduce',
  'noiseReductionModal.noise.keep': 'Residue',
  'noiseReductionModal.noiseTooShort': 'The selected noise sample is too short',
  'noiseReductionModal.noiseRateNotSuit':
    'The sampling rate of the noise sample must match the sound in order to continue',
};

const processingTools = {
  'processingTools.standardization': 'Normalize',
  'processingTools.standardization.desc1': 'Remove DC offset (vertically centered at 0.0)',
  'processingTools.standardization.desc2': 'Normalize the peak amplitude to',
  'processingTools.standardization.desc2.unit': ' Decibel',
  'processingTools.highPassFilter': 'High pass filter',
  'processingTools.lowPassFilter': 'Low pass filter',
  'processingTools.notchFilter': 'Notch filter',
  'processingTools.notchFilter.Q': 'Q (Higher values will shorten the width)',
  'processingTools.amplification': 'Amplify',
  'processingTools.amplification.gain': 'Amplification (dB)',
  'processingTools.amplification.newPeak': 'New peak amplitude (dB)',
  'processingTools.amplification.clippingDistortion': 'Allowable clipping distortion',
  'processingTools.filterCurveEqualizer': 'Filter Curve Equalizer',
  'processingTools.loudnessNormalization': 'Loudness Normalization',
  'processingTools.loudnessNormalization.standardization': 'Normalize',
  'processingTools.loudnessNormalization.perceivedLoudness': 'Perceived loudness',
  'processingTools.loudnessNormalization.RMS': 'RMS',
  'processingTools.loudnessNormalization.to': 'to',
  'processingTools.loudnessNormalization.unit': 'Decibel',
  'processingTools.loudnessNormalization.desc': 'Treat mono as dual (recommended)',
  'processingTools.noiseReduction': 'Noise Reduction',
  'processingTools.isNormalizeStereo': 'Normalize stereo channels independently',
};

export default {
  'audioEditor.timeDomain': 'Waveform',
  'audioEditor.frequencyDomain': 'Frequency Analysis',
  'audioEditor.create': 'Create',
  'audioEditor.chirp': 'Chirp',
  'audioEditor.noise': 'Noise',
  'audioEditor.singleVideo': 'Single Tone',
  'audioEditor.DTMF': 'DTMF Tone',
  'audioEditor.timeFrequency': 'Spectrogram',
  'audioEditor.allView': 'All View',
  'audioEditor.spectrumDiagram.setting': 'Spectrum',
  'audioEditor.process': 'Process',
  'audioEditor.preview': 'Preview',
  'audioEditor.copyAll': 'Copy process and documents',
  'audioEditor.reset': 'Reset',
  'audioEditor.delete': 'Delete',
  ...chirpModal,
  ...noiseModal,
  ...common,
  ...DTMFModal,
  ...spectrumDiagramModal,
  ...processingTools,
  ...noiseReductionModal,
  ...filterCurveEqualizerModal,
  ...browserTip,
};
