export class TaskGroup {
  groupId: string = '';
  groupName: string = '';
  createTime: number = 0;
  lastModifyTime: number = 0;
  taskIds: string[] = [];

  constructor(t: DtlBean.TaskGroup) {
    if (!t) {
      return;
    }
    if (t.groupId) {
      this.groupId = t.groupId;
    }
    if (t.groupName) {
      this.groupName = t.groupName;
    }
    if (t.createTime) {
      this.createTime = t.createTime;
    }
    if (t.lastModifyTime) {
      this.lastModifyTime = t.lastModifyTime;
    }
    if (t.taskIds) {
      this.taskIds = [...t.taskIds];
    }
  }

  copy(): TaskGroup {
    return new TaskGroup(this);
  }

  format(): DtlBean.TaskGroup {
    return {
      groupId: this.groupId,
      groupName: this.groupName,
      createTime: this.createTime,
      lastModifyTime: this.lastModifyTime,
      taskIds: [...this.taskIds],
    };
  }

  filterTask(filter: (taskId: string) => boolean) {
    this.taskIds = this.taskIds.filter((taskId) => filter(taskId));
  }
}
