import {
  dtlGetInfo,
  dtlPing,
  dtlUserGetAllUsers,
  dtlUserGetUsers,
  dtlUserUpdateUserAddUnread,
  dtlUserUpdateUserBindEmail,
  dtlUserUpdateUserInfo,
  dtlUserUpdateUserSetRead,
} from '@/services/datalib/api/user';
import { User } from '@/services/datalib/bean/user';
import {
  doErrResponse,
  doResponse,
  doSimpleResponse,
  isDev,
  IsNonToken,
} from '@/services/datalib/ctrl/util';
import { DtlUnreadCmd, Err } from '@/services/datalib/enum';
import { dtlStatus, initDtlSocket, initDtlStatus, initDtlVerLog, updateDtlVerLog } from '../index';

export async function doInitDtlStatus(): Promise<DtlComm.Result<undefined> | undefined> {
  const e = IsNonToken();
  if (e) return e;
  if (dtlStatus.initialized) return undefined;
  if (isDev()) console.log('init dtlStatus');
  const { code, message, data, verLog } = await dtlStatus.syncGetOrAdd<
    DtlComm.ResultV<DtlLogin.LoginResult>
  >('dtlGetInfo', dtlGetInfo);
  if (code === Err.OK[0]) {
    initDtlStatus(data);
    initDtlVerLog(verLog);
    initDtlSocket();
    return undefined;
  }
  return doErrResponse(code, message);
}

export async function getUserInfo(): Promise<
  DtlComm.Result<{ userInfo: DtlBean.User } | undefined>
> {
  const err = await doInitDtlStatus();
  if (err) return err;
  return {
    code: Err.OK[0],
    data: { userInfo: dtlStatus.userInfo.format() },
    message: Err.OK[1],
  };
}

export async function getUsers(body: {
  userIds: string[];
}): Promise<DtlComm.Result<Record<string, DtlBean.User> | undefined>> {
  // const err = await doInitDtlStatus();
  // if (err) return err;

  const userIds = body.userIds;
  const m: Record<string, DtlBean.User> = {};
  const waitUserIds: string[] = [];
  userIds.forEach((userId) => {
    if (m[userId]) {
      return;
    }
    let user = dtlStatus.contacts.getFriend(userId);
    if (user) {
      m[userId] = user.format();
      return;
    }
    user = dtlStatus.contacts.usersCache[userId];
    if (user) {
      m[userId] = user.format();
      return;
    }
    waitUserIds.push(userId);
  });
  if (waitUserIds.length === 0) {
    return {
      code: Err.OK[0],
      data: m,
      message: Err.OK[1],
    };
  }

  const response = await dtlStatus.syncGetOrAdd<DtlComm.ResultV<DtlBean.User[]>>(
    { userIds: waitUserIds },
    dtlUserGetUsers,
  );
  const { code, data, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    if (data) {
      data.forEach((user) => {
        const u = new User(user);
        dtlStatus.contacts.usersCache[u.userId] = u;
        m[user.userId] = u.format();
      });
    }
    return {
      code: Err.OK[0],
      data: m,
      message: Err.OK[1],
    };
  }
  return doErrResponse(code, message);
}

const cacheAllUsers: { allUsers: DtlBean.User[] | undefined; ts: number } = {
  allUsers: undefined,
  ts: 0,
};

export async function getAllUsers(): Promise<DtlComm.Result<DtlBean.User[] | undefined>> {
  if (cacheAllUsers && new Date().getTime() - cacheAllUsers.ts < 1800 * 1000) {
    return {
      code: Err.OK[0],
      data: cacheAllUsers.allUsers,
      message: Err.OK[1],
    };
  }
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlStatus.syncGetOrAdd<DtlComm.ResultV<DtlBean.User[]>>(
    { _: '_' },
    dtlUserGetAllUsers,
  );
  if (response.code === Err.OK[0]) {
    response.data?.forEach((u) => {
      const user = dtlStatus.contacts.getFriend(u.userId);
      if (user && user.remark) {
        u.userName = user.remark;
      }
    });
    cacheAllUsers.allUsers = response.data?.map((u) => new User(u).format());
    cacheAllUsers.ts = new Date().getTime();
  }
  return doSimpleResponse(response);
}

export async function ping() {
  const e = IsNonToken();
  if (e) return;
  const response = await dtlPing();
  const { code, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
  }
}

export async function updateUserInfo(
  body: DtlUser.UpdateUserInfoParam,
): Promise<DtlComm.Result<{ userInfo: DtlBean.User } | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlUserUpdateUserInfo(body);
  const { code, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    return {
      code: Err.OK[0],
      data: { userInfo: dtlStatus.userInfo.format() },
      message: Err.OK[1],
    };
  }
  return doErrResponse(code, message);
}

export async function updateUserSetRead(
  body: DtlUser.UpdateUserSetReadParam,
): Promise<DtlComm.Result<undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlUserUpdateUserSetRead(body);
  return doResponse(response);
}

export async function getUserUnreadList(): Promise<
  DtlComm.Result<{ unreadList: DtlBean.Unread[] } | undefined>
> {
  const err = await doInitDtlStatus();
  if (err) return err;
  let list = dtlStatus.unreadList.format();
  if (dtlStatus.taskGroupList.officialTask) {
    list.push(...dtlStatus.taskGroupList.officialTask.oUnreads.map((ur) => ur.format()));
  }
  list.sort((a, b) => a.time - b.time);
  const deleteM: Record<string, boolean> = {};
  const officialDeleteM: Record<string, boolean> = {};
  dtlStatus.unreadList.oReadIds.forEach((id) => (officialDeleteM[id] = true));
  const out_task_M: Record<string, boolean> = {};
  const out_enterprise_M: Record<string, boolean> = {};
  const unread_user_contact_added_M: Record<string, boolean> = {};
  console.log('list', list);
  for (let i = list.length - 1; i > -1; i--) {
    const unread = list[i];
    const { cmd, id, taskId, time, userId, ext } = unread;
    if (deleteM[id] || officialDeleteM[id]) {
      continue;
    }
    if (userId === dtlStatus.userInfo.userId || time < dtlStatus.userInfo.createTime) {
      deleteM[id] = true;
      continue;
    }
    if (out_task_M[taskId]) {
      deleteM[id] = true;
      continue;
    }

    if (taskId) {
      const task = dtlStatus.getTask(taskId);
      if (task && out_enterprise_M[task.enterpriseId]) {
        deleteM[id] = true;
        continue;
      }
      if (
        !task &&
        cmd !== DtlUnreadCmd.UNREAD_TASK_KICKOUT_MEMBER &&
        cmd !== DtlUnreadCmd.UNREAD_TASK_DISMISS
      ) {
        deleteM[id] = true;
        continue;
      }
    }

    if (
      cmd === DtlUnreadCmd.UNREAD_TASK_KICKOUT_MEMBER ||
      cmd === DtlUnreadCmd.UNREAD_TASK_ADDED_TO_TASK ||
      cmd === DtlUnreadCmd.UNREAD_TASK_DISMISS
    ) {
      out_task_M[taskId] = true;
    }
    if (cmd === DtlUnreadCmd.UNREAD_TASK_KICKOUT_MEMBER) {
      if (new Date().getTime() - time / 1000000 > 1000 * 60 * 60 * 24 * 10) {
        deleteM[id] = true;
      }
    } else if (cmd === DtlUnreadCmd.UNREAD_USER_CONTACT_ADDED) {
      if (dtlStatus.contacts.getFriend(userId) || unread_user_contact_added_M[userId]) {
        deleteM[id] = true;
      } else {
        unread_user_contact_added_M[userId] = true;
      }
    } else if (cmd === DtlUnreadCmd.UNREAD_TASK_DISMISS) {
      if (dtlStatus.getTask(taskId)) {
        deleteM[id] = true;
        continue;
      }
    }
    if (
      cmd === DtlUnreadCmd.UNREAD_ENTERPRISE_REMOVE_USER ||
      cmd === DtlUnreadCmd.UNREAD_ENTERPRISE_DISMISS ||
      cmd === DtlUnreadCmd.UNREAD_ENTERPRISE_ADD_USER
    ) {
      const { enterpriseId } = ext;
      if (out_enterprise_M[enterpriseId]) {
        deleteM[id] = true;
        continue;
      }
      if (
        cmd === DtlUnreadCmd.UNREAD_ENTERPRISE_REMOVE_USER ||
        cmd === DtlUnreadCmd.UNREAD_ENTERPRISE_DISMISS
      ) {
        out_enterprise_M[enterpriseId] = true;
      }
    }
  }
  {
    const deleteIds = Object.keys(deleteM);
    if (deleteIds.length > 0) {
      updateUserSetRead({ ids: deleteIds.join(',') });
    }
  }
  list = list.filter((f) => !deleteM[f.id] && !officialDeleteM[f.id]);
  for (let i = 0; i < list.length; i += 1) {
    const l = list[i];
    if (l.userId) {
      const user = dtlStatus.contacts.getFriend(l.userId);
      if (user && user.remark) {
        l.userName = user.remark;
      }
    }
    if (l.taskId) {
      const task = dtlStatus.getTask(l.taskId);
      l.taskName = task?.name;
    }
  }
  return {
    code: Err.OK[0],
    data: { unreadList: list },
    message: Err.OK[1],
  };
}

export async function updateUserAddUnread(
  body: DtlUser.UpdateUserAddUnreadParam,
): Promise<DtlComm.Result<undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlUserUpdateUserAddUnread(body);
  return doResponse(response);
}

export async function updateUserBindEmail(
  body: DtlUser.UpdateUserBindEmailParam,
): Promise<DtlComm.Result<{ userInfo: DtlBean.User } | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlUserUpdateUserBindEmail(body);
  const { code, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    return {
      code: Err.OK[0],
      data: { userInfo: dtlStatus.userInfo.format() },
      message: Err.OK[1],
    };
  }
  return doErrResponse(code, message);
}
