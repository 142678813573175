import { CollectFolders } from '@/services/datalib/bean/CollectFolders';
import { Contacts } from '@/services/datalib/bean/Contacts';
import { DelTask } from '@/services/datalib/bean/DelTask';
import { EnterpriseList } from '@/services/datalib/bean/EnterpriseList';
import { FileRecords } from '@/services/datalib/bean/FileRecords';
import { LabelTemplates } from '@/services/datalib/bean/LabelTemplates';
import type { LabelTpl } from '@/services/datalib/bean/LabelTpl';
import { ListenEvent } from '@/services/datalib/bean/ListenEvent';
import { OpenTypes } from '@/services/datalib/bean/OpenTypes';
import { Task } from '@/services/datalib/bean/Task';
import type { TaskGroup } from '@/services/datalib/bean/TaskGroup';
import { TaskGroupList } from '@/services/datalib/bean/TaskGroupList';
import { UnreadList } from '@/services/datalib/bean/UnreadList';
import { User } from '@/services/datalib/bean/user';
import { UserExt } from '@/services/datalib/bean/UserExt';

export class DtlStatus {
  serverToken: string = '';
  pushServer: string = '';
  userInfo: User = new User();
  userExt: UserExt = new UserExt();
  contacts: Contacts = new Contacts();
  taskGroupList: TaskGroupList = new TaskGroupList();
  labelTemplates: LabelTemplates = new LabelTemplates();
  unreadList: UnreadList = new UnreadList();
  openTypes: OpenTypes = new OpenTypes();
  initialized: boolean = false;
  listenEvent: ListenEvent = new ListenEvent();
  fileRecords: FileRecords = new FileRecords();
  collectFolders: CollectFolders = new CollectFolders();
  isSelfOfficialUser: boolean = false;
  enterpriseList: EnterpriseList = new EnterpriseList();
  sync: Record<string, any> = {};

  reset() {
    this.setServerToken('');
    this.pushServer = '';
    this.userInfo = new User();
    this.contacts = new Contacts();
    this.taskGroupList = new TaskGroupList();
    this.labelTemplates = new LabelTemplates();
    this.unreadList = new UnreadList();
    this.openTypes = new OpenTypes();
    this.listenEvent.destroy();
    this.listenEvent = new ListenEvent();
    this.initialized = false;
    this.fileRecords = new FileRecords();
    this.collectFolders = new CollectFolders();
    this.isSelfOfficialUser = false;
    this.enterpriseList = new EnterpriseList();
    this.sync = {};
  }

  initFinished() {
    this.initialized = true;
  }

  setServerToken(serverToken: string) {
    this.serverToken = serverToken;
    if (serverToken) {
      localStorage.setItem('x-auth-token', serverToken);
    } else {
      localStorage.removeItem('x-auth-token');
    }
  }

  setPushServer(pushServer: string) {
    if (pushServer) {
      this.pushServer = pushServer;
    }
  }

  setUserInfo(userInfo?: DtlBean.User) {
    this.userInfo = new User(userInfo);
  }

  setUserExt(userExt?: DtlBean.UserExt) {
    this.userExt = new UserExt(userExt);
  }

  setContacts(contacts?: DtlBean.Contacts) {
    this.contacts = new Contacts(contacts);
  }

  setTaskGroupList(userId: string, taskGroups?: DtlBean.TaskGroups, officialTask?: DtlBean.TaskV) {
    this.taskGroupList = new TaskGroupList(taskGroups);
    this.taskGroupList.filterTask(userId, undefined);
    if (officialTask) {
      this.taskGroupList.setOfficialTask(new Task(officialTask));
      if (officialTask.creator === userId) {
        this.isSelfOfficialUser = true;
      }
    }
  }

  setLabelTemplates(t?: DtlBean.UserAllTpl) {
    this.labelTemplates = new LabelTemplates(t);
  }

  setUnreadList(t?: DtlBean.AllUnread) {
    this.unreadList = new UnreadList(t);
  }

  setOpenTypes(t: DtlBean.ConvProgram[]) {
    this.openTypes = new OpenTypes(t);
  }

  setFileRecords(recentFiles: DtlBean.FileRecord[], collectFiles: DtlBean.FileRecord[]) {
    this.fileRecords = new FileRecords(recentFiles, collectFiles);
  }

  setCollectFolders(collectFolders: DtlBean.CollectFolder[]) {
    this.collectFolders = new CollectFolders(collectFolders);
  }

  setEnterpriseList(allEnterprise: DtlBean.Enterprise[]) {
    this.enterpriseList = new EnterpriseList(allEnterprise);
  }

  getFriend(userId: string): User | undefined {
    return this.contacts.getFriend(userId);
  }

  addFriend(user: User) {
    return this.contacts.addFriend(user);
  }

  delFriend(userId: string) {
    return this.contacts.delFriend(userId);
  }

  remarkFriend(userId: string, remark: string) {
    this.contacts.remarkFriend(userId, remark);
  }

  existGroup(groupName: string): boolean {
    return this.contacts.existGroup(groupName);
  }

  addGroup(groupName: string) {
    return this.contacts.addGroup(groupName);
  }

  updateGroupName(groupName: string, newGroupName: string) {
    return this.contacts.updateGroupName(groupName, newGroupName);
  }

  delGroup(groupName: string) {
    this.contacts.delGroup(groupName);
  }

  moveMemberToGroup(userId: string, groupName: string) {
    this.contacts.moveMemberToGroup(userId, groupName);
  }

  getTask(taskId: string): Task | undefined {
    return this.taskGroupList.getTask(taskId);
  }

  addTask(taskGroupId: string, isArchive: boolean, task: Task) {
    this.taskGroupList.addTask(taskGroupId, isArchive, task);
  }

  delTask(taskId: string, delTask?: DelTask) {
    this.taskGroupList.delTask(taskId, delTask);
  }

  delTaskCompletely(taskIds: string[]) {
    this.taskGroupList.delTaskCompletely(taskIds);
  }

  addTaskGroup(taskGroup: TaskGroup, isArchive: boolean = false) {
    if (!taskGroup) return;
    this.taskGroupList.addTaskGroup(taskGroup, isArchive);
  }

  updateTaskGroupName(groupId: string, groupName: string, isArchive: boolean = false) {
    if (!groupId || !groupName) return;
    this.taskGroupList.updateTaskGroupName(groupId, groupName, isArchive);
  }

  deleteTaskGroup(groupId: string, isArchive: boolean = false) {
    if (!groupId) return;
    this.taskGroupList.deleteTaskGroup(groupId, isArchive);
  }

  taskGroupMoveTaskToGroup(taskGroupId: string, taskId: string, isArchive: boolean = false) {
    if (!taskId) return;
    this.taskGroupList.moveTaskToGroup(taskGroupId, taskId, isArchive);
  }

  updateTaskGroupList(taskGroupList: TaskGroupList) {
    if (!taskGroupList) return;
    this.taskGroupList.update(taskGroupList);
  }

  createLabelTemplate(labelTpl: LabelTpl) {
    this.labelTemplates.createLabelTemplate(labelTpl);
  }

  deleteLabelTemplate(deleted: string[]) {
    this.labelTemplates.deleteLabelTemplate(deleted);
  }

  initTask(task: Task, initFinished: boolean = true) {
    this.taskGroupList.initTask(task, initFinished);
    // task.updateLotInfo(this.userInfo.userId);
  }

  updateTaskGroupSeq(groupId: string, index: number, isArchive: boolean) {
    if (!groupId) return;
    this.taskGroupList.updateTaskGroupSeq(groupId, index, isArchive);
  }

  syncGetOrAdd<T>(param: any, cb: (p: any) => Promise<T>): Promise<T> {
    if (!param) {
      return cb(param);
    }
    const key = JSON.stringify(param);
    if (this.sync[key]) {
      return this.sync[key];
    }
    const result = cb(param);
    this.sync[key] = result;
    result
      .then(() => {
        delete this.sync[key];
      })
      .catch(() => {
        delete this.sync[key];
      });
    return result;
  }
}
