import API_VERSION from '@/services/datalib/api/config';
import request from '@/utils/request';

export async function dtlUserContactsSearchUsers(body: DtlContacts.SearchUsersParam) {
  return request<DtlComm.ResultV<DtlContacts.SearchUsersResult>>(
    `${API_VERSION}/api/user/contacts/searchUsers`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlUserContactsAddUsers(body: DtlContacts.AddUsersParam) {
  return request<DtlComm.ResultV<DtlContacts.AddUsersResult>>(
    `${API_VERSION}/api/user/contacts/addUsers`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlUserContactsAddUser(body: DtlContacts.AddUserParam) {
  return request<DtlComm.ResultV<DtlContacts.AddUsersResult>>(
    `${API_VERSION}/api/user/contacts/addUser`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlUserContactsDelUsers(body: DtlContacts.DelUsersParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/user/contacts/delUsers`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlUserContactsRemarkUser(body: DtlContacts.RemarkUserParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/user/contacts/remarkUser`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlUserContactsGetContacts() {
  return request<DtlComm.ResultV<DtlContacts.GetContactsResult>>(
    `${API_VERSION}/api/user/contacts/getContacts`,
    {
      method: 'POST',
    },
  );
}

export async function dtlUserContactsAddGroup(body: DtlContacts.AddGroupParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/user/contacts/addGroup`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlUserContactsUpdateGroupName(body: DtlContacts.UpdateGroupNameParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/user/contacts/updateGroupName`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlUserContactsDelGroup(body: DtlContacts.DelGroupParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/user/contacts/delGroup`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlUserContactsMoveMemberToGroup(body: DtlContacts.MoveMemberToGroupParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/user/contacts/moveMemberToGroup`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}
