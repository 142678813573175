import { ConvProgram } from '@/services/datalib/bean/ConvProgram';

export class ConvPrograms {
  list: ConvProgram[] = [];

  constructor(t?: DtlBean.ConvProgram[]) {
    if (!t) return;
    this.list = t.map((m) => new ConvProgram(m));
  }

  copy(): ConvPrograms {
    return new ConvPrograms(this.format());
  }

  format(): DtlBean.ConvProgram[] {
    return this.list.map((m) => m.format());
  }

  getConvProgram(programName: string): ConvProgram | undefined {
    if (programName === '') return undefined;
    const idx = this.list.findIndex((p) => p.name === programName);
    if (idx > -1) {
      return this.list[idx];
    }
    return undefined;
  }
}
