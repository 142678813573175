export class FilePath {
  path: string = '';
  id: string = '';
  type: number = 0;
  children: FilePath[] = [];

  copy() {
    const n = new FilePath();
    n.path = this.path;
    n.id = this.id;
    n.type = this.type;
    n.children = this.children.map((v) => v.copy());
    return n;
  }

  constructor(filePath?: DtlBean.FilePath) {
    if (!filePath) {
      return;
    }
    if (filePath.path) {
      this.path = filePath.path;
    }
    if (filePath.id) {
      this.id = filePath.id;
    }
    if (filePath.type) {
      this.type = filePath.type;
    }
    if (filePath.children) {
      this.children = filePath.children.map((f) => new FilePath(f));
    }
  }
}
