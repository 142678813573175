import { extend } from 'umi-request';
import { dtlReset, getVersion } from '@/services/datalib';
import ResponseCode from './ResponseCode';

const errorHandler = (error: { response: Response }) => {
  console.log('error: ', error.response, error);
  const { response } = error;
  if (response && response.status) {
    const errorText = codeMessage[response.status] || response.statusText;
    if (response.status === 401) {
      dtlReset();
      window.location.href = '/login';
    }
    return {
      code: response.status,
      message: errorText,
    };
  }
  return {
    code: 404,
    message: codeMessage[404],
  };
};

const request = extend({
  errorHandler,
});

// request拦截器, 改变url 或 options.
request.interceptors.request.use((url, options) => {
  const version = getVersion();
  const c_token = localStorage.getItem('x-auth-token');
  let headers = {};
  if (c_token) {
    headers = Object.assign(headers, { Authorization: c_token });
  }
  if (version) {
    headers = Object.assign(headers, { 'X-Version': `${version}` });
  }
  return {
    url,
    options: { ...options, headers },
  };
});

const responseCodeMap = {
  200: ResponseCode.S_OK,
  201: ResponseCode.S_CREATED,
  202: ResponseCode.S_ACCEPTED,
  204: ResponseCode.S_NO_CONTENT,
  400: ResponseCode.FA_INVALID_REQUEST,
  401: ResponseCode.FA_UNAUTHORIZED,
  403: ResponseCode.FA_FORBIDDEN,
  404: ResponseCode.FA_RESOURCE_NOT_FOUND,
  406: ResponseCode.FA_NOT_ACCEPTABLE,
  410: ResponseCode.FA_RESOURCE_GONE,
  422: ResponseCode.FA_UNPROCESSABLE_ENTITY,
  500: ResponseCode.FA_SERVER_ERROR,
  502: ResponseCode.FA_BAD_GATEWAY,
  503: ResponseCode.FA_SERVICE_UNAVAILABLE,
  504: ResponseCode.FA_GATEWAY_TIMEOUT,
  505: ResponseCode.FA_VERSION_NOT_SUPPORTED,
};

const codeMessage: Record<number, string> = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

export default request;
