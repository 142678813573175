// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from 'E:/work/frontend/datalib-front-layout/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/Loading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__index' */'@/layouts/index'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Home' */'@/pages/Home'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/index",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Home' */'@/pages/Home'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/home",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Home' */'@/pages/Home'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/login",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Login' */'@/pages/Login'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/settings",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Settings' */'@/pages/Settings'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/contacts",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Contacts' */'@/pages/Contacts'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/logout",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Logout' */'@/pages/Logout'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/test",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Test' */'@/pages/Test'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/testMicroApp",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TestMicroApp' */'@/pages/TestMicroApp'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/share/file_id/:id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__sharePages' */'@/pages/sharePages'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/share/:id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__sharePages' */'@/pages/sharePages'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/addLink/:id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AddLinkPages' */'@/pages/AddLinkPages'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/manage",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EnterpriseManagePage' */'@/pages/EnterpriseManagePage'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
