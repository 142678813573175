const openWayModal = {
  'openWayModal.BigTitle': '打开方式',
  'openWayModal.defaultWay.tips': '默认方式',
  'openWayModal.wav.name': '波形预览',
  'openWayModal.wav.desc': '支持进行波形预览',
  'openWayModal.office.name': 'Office文档预览',
  'openWayModal.office.desc': '支持对Office文档进行预览',
  'openWayModal.img.name': '图片预览',
  'openWayModal.img.desc': '支持图片查看和预览',
  'openWayModal.pdf.name': 'PDF预览',
  'openWayModal.pdf.desc': '支持PDF查看和预览',
  'openWayModal.video.name': '视频预览',
  'openWayModal.video.desc': '支持视频查看和预览',
  'openWayModal.audio.name': '音频预览',
  'openWayModal.audio.desc': '支持音频查看和预览',
  'openWayModal.cpb.name': 'CPB分析',
  'openWayModal.cpb.desc': '支持对音频文件进行CPB分析',
  'openWayModal.dsp.name': 'Audio Editor',
  'openWayModal.dsp.desc': '支持对音频文件进行加工、处理',
  'openWayModal.grr.name': 'GRR分析',
  'openWayModal.grr.desc': '支持对表格文件进行GRR分析',
  'openWayModal.dapt.name': 'Audio Graph',
  'openWayModal.dapt.desc': '支持对表格和Dat文件进行Audio Graph分析',
  'openWayModal.correlation.name': 'Correlation',
  'openWayModal.correlation.desc': '支持对多文件进行Correlation分析',
  'openWayModal.scic.name': '科学计算',
  'openWayModal.scic.desc': '支持对表格文件进行科学计算',
  'openWayModal.spc.name': '多峰检测',
  'openWayModal.spc.desc': '支持对表格数据进行是否多峰形状判断',
  'openWayModal.allKindTitle': '所有类型:',
  'openWayModal.openByOtherWay': '以其他类型打开:',
  'openWayModal.searchTool.placeholder': '搜索工具名',
  'openWayModal.searchType.placeholder': '搜索文件类型',
  'openWayModal.nowDefaultWay': '当前默认: ',
  'openWayModal.autoType': '自动识别类型',
  'analyseModal.BigTitle': '解析方式',
  'openWayModal.document.name': '文档预览',
};

const deleteDirModal = {
  'deleteDirModal.title': '删除该文件夹?',
  'deleteDirModal.desc': '文件夹中的文件将被删除',
};

// 模板
const templateModal = {
  // 重置模板
  'resetModal.BigTitle': '重置项目模板',
  // 通用模板
  'templateModal.title.separator': '分隔符',
  'templateModal.separator.desc.one': '选取频率前的符号作为分隔符，如：FB_Mic AMic FR',
  'templateModal.separator.placeholder': '数据列名中的分隔符',
  'templateModal.title.snFormat': 'SN格式',
  'templateModal.snFormat.desc': '输入样品所在的列名，如：Serial Number / serial_ID',
  'templateModal.snFormat.placeholder': 'SN列名包含的特征字段',
  'templateModal.title.sensitivityFormat': '单值',
  'templateModal.sensitivityFormat.desc.one': '输入单值数据列名包含的特征字段，如：FB_Mic Amic ',
  'templateModal.sensitivityFormat.placeholder': '单值数据列名包含的特征字段',
  'templateModal.sensitivityFormat.auto': '自动识别',
  'templateModal.sensitivityFormat.hand': '手动识别',
  'templateModal.sensitivityFormat.danger': '（风险：可能识别无意义项目）',
  'templateModal.title.stationID': '工站ID',
  'templateModal.stationID.placeholder': '测试设备所在的列名',
  'templateModal.title.fileName': '文件名',
  'templateModal.stationID.desc': '输入测试设备所在的列名，如：StationID / station_id',
  'templateModal.title.station': '工站',
  'templateModal.station.desc': '选取合适工站作为区分',
  'templateModal.title.sampleSize': '样品数',
  'templateModal.sampleSize.desc': '选取的样品数量，自动划行比较',
  // 导入模板
  'importTemplateModal.BigTitle': '导入模板',
  'importTemplateModal.searchTask.tip': '搜索任务',
  // 新建解析模板
  'newTemplateModal.BigTitle': '新建解析模板',
  'newTemplateModal.templateName': '模板名称',
  // 编辑解析模板
  'editTemplateModal.BigTitle': '编辑解析模板',
  // 保存模板覆盖提示
  'templateModal.replace.tip1': '是否覆盖同名模板',
  'templateModal.replace.tip2': '任务中存在相同名称的模板',
  'templateModal.notSave': '不保存',
  'templateModal.SaveTwo': '保留两者',
  'templateModal.replace': '覆盖',
  'autoTplFailModal.title': '自动解析失败',
  'autoTplFailModal.content': '系统自动解析失败，请手动编辑解析模板',
};

const copyModal = {
  'copyModal.BigTitle': '复制文件',
  'copyModal.fileName': '文件名称',
  'copyModal.copyToTask.title': '复制到任务',
  'copyModal.searchInput.placeholder': '搜索任务',
};

const shareModal = {
  'shareModal.BigTitle': '文件分享',
  'shareModal.shareFileDesc': '其他人将看到当前状态的文件',
  'shareModal.shareResultDesc': '其他人将看到当前状态的结果',
  'shareModal.canOtherSee.title': '允许非任务成员查看',
  'shareModal.password': '密码访问:',
  'shareModal.shareTime': '有效期:',
  'shareModal.comments': '包含备注',
  'shareModal.messages': '包含信息',
  'shareModal.data': '包含数据源',
  'shareModal.time.one': '1天',
  'shareModal.time.three': '3天',
  'shareModal.time.seven': '7天',
  'shareModal.time.month': '1个月',
  'shareModal.time.forever': '永久有效',
  'shareModal.time.effective': '前有效',
  'sharePage.password.title': '请输入密码',
  'sharePage.password.placeholder': '4-8位密码',
  'sharePage.passwordError': '密码错误',
  'sharePage.viewResult': '查看结果',
  'sharePage.changeSharePassword': '修改密码',
  'sharePage.changeSharePassword.title': '链接密码设置',
  'sharePage.changeSharePassword.inputPlaceHolder': '请输入新密码',
  'sharePage.changeSharePassword.randomBtn': '随机生成',
  'sharePage.changeSharePassword.rule1': '包含字母或数字',
  'sharePage.changeSharePassword.rule2': '长度为4-8个字符',
  'sharePage.changeSharePassword.safeTip': '安全提示：请勿使用个人常用或其他平台中的密码',
};

const deleteAttachmentModal = {
  'deleteAttachmentModal.title': '确认删除附件',
  'deleteAttachmentModal.desc': '删除后不可恢复',
};

const deleteMessageModal = {
  'deleteMessageModal.title': '是否删除该留言?',
  'deleteMessageModal.desc': '回复将同时删除',
  'deleteMessageModal.reply': '是否删除该回复？',
  'deleteMessageModal.reply.desc': '删除后不可恢复',
};

const CorClearDataModal = {
  'CorClearDataModal.title': '清空数据提示',
  'CorClearDataModal.desc': '选择另一种解析方式，将清空当前数据',
};

const addLinkModal = {
  'addLinkModal.BigTitle': '添加成员',
  'addLinkModal.addByLink': '邀请链接添加',
  'addLinkModal.addByLink.btn': '复制链接',
  'addLinkModal.addByLink.tips': '链接有效期: 3天',
  'addLinkModal.addByMailList': '通讯录添加',
  'addLinkModal.input.placeholder': '输入手机号或邮箱',
  'addLinkModal.addByInput.emptyTips': '多个成员使用空格或逗号分隔',
  'addLinkModal.addByMailList.BigTitle': '通信录添加',
  'addLinkModal.addByMailList.input.placeholder': '搜索联系人',
  'addLinkModal.addByMailList.myFriend': '我的联系人',
  'addLinkModal.addByMailList.myFriend.empty': '暂无联系人',
  'addLinkModal.addByMailList.enterprise': '组织架构',
  'addLinkModal.addByMailList.right.emptyTitle': '请选择添加联系人',
  'addLinkModal.addByMailList.right.selectTitle': '已选择',
  'addLinkModal.addByMailList.searchEmpty': '未搜索到匹配的联系人',
  'addLinkModal.addByMailList.searchFriend': '查找并添加该用户',
  'addFriend.group': '分组',
  'addFriend.remark': '备注',
  'addFriend.defaultGroup': '默认',
  'addLinkModal.add': '已添加',
  'addLinkModal.invite': '已邀请',
};

const addLimitModal = {
  'addLimitModal.BigTitle': '添加limit',
  'addLimitModal.importFromTask': '任务导入',
  'addLimitModal.customLimit': '自定limit',
  'addLimitModal.importFromLocal': '本地导入',
  'addLimitModal.searchTask.placeholder': '搜索任务',
  'addLimitModal.frequency': '频率',
  'addLimitModal.downloadLimit.title': '下载填写limit模板',
  'addLimitModal.downloadLimit.tip': '不知如何填写?',
  'addLimitModal.downloadLimit.example': '查看范例',
  'addLimitModal.uploadLimit.title': '上传已填写的limit',
  'addLimitModal.uploadLimit.hasUploaded': '已上传',
  'addLimitModal.uploadLimit.hasUploaded.tip': '再次点击重新上传',
};

const coordinateModal = {
  'coordinateModal.BigTitle': '修改数据坐标',
  'coordinateModal.editChart': '编辑图表',
  'coordinateModal.xTitle': 'X轴单位',
  'coordinateModal.yTitle': 'Y轴单位',
  'coordinateModal.y1Title': 'Y1轴单位',
  'coordinateModal.y2Title': 'Y2轴单位',
  'coordinateModal.x': 'X轴边界(HZ)',
  'coordinateModal.xMin': 'X轴最小值',
  'coordinateModal.xMax': 'X轴最大值',
  'coordinateModal.y': 'Y轴边界',
  'coordinateModal.yMin': 'Y轴最小值',
  'coordinateModal.yMax': 'Y轴最大值',
  'coordinateModal.y1Min': 'Y1轴最小值',
  'coordinateModal.y1Max': 'Y1轴最大值',
  'coordinateModal.y2Min': 'Y2轴最小值',
  'coordinateModal.y2Max': 'Y2轴最大值',
  'coordinateModal.auto': '自动',
  'coordinateModal.reset': '重置',
  'coordinateModal.useTo': '应用至其他',
  'coordinateModal.useToProject': '应用至项目',
  'coordinateModal.useToThisFileProject': '应用至此文件项目',
  'coordinateModal.chartName': '图表名称',
  'coordinateModal.setXYTitle': '单位设置',
  'coordinateModal.setCoordinate': '坐标设置',
  'coordinateModal.all': '全部',
  'coordinateModal.setYSpace': 'Y轴坐标幅度',
  'coordinateModal.setYSpace.tip': '滑块向右移动，Y轴坐标幅度增大',
  'coordinateModal.manual': '手动',
  'coordinateModal.setYDvalue': 'Y轴刻度间隔',
};

const deleteAppFileModal = {
  'deleteAppFileModal.title': '确认删除文件',
};

const colorModal = {
  'colorModal.border': '边框色',
  'colorModal.fillColor': '填充色',
};

const appSaveModal = {
  'appSaveModal.title': '保存文件',
  'appSaveModal.fileName': '文件名称',
  'appSaveModal.saveToTask': '保存到任务',
  'appSaveModal.searchTask': '搜索任务',
};

const RefrigerantModal = {
  'RefrigerantModal.errorRange.title': '输入错误',
  'RefrigerantModal.errorRange.desc': '下限不能大于或等于上限',
  'RefrigerantModal.selectParam.title': '选择固定参数',
  'RefrigerantModal.selectParam.desc': '需要固定一个参数，按确定固定第二个，按取消固定第一个',
};

const exportResultModal = {
  'exportResultModal.title': '导出结果',
  'exportResultModal.name': '结果名称',
};

const beforeExportResuiltModal = {
  'beforeExportResultModal.title': '导出',
  'beforeExportResultModal.question': '导出表格的曲线数量超过255条，是否将曲线作为图片导出？',
  'beforeExportResultModal.tip': '仍以曲线导出，部分应用打开文件可能出现错误',
  'beforeExportResultModal.curveExport': '曲线导出',
  'beforeExportResultModal.imageExport': '图片导出',
};

const beforeExportResuiltModalAsModal = {
  'beforeExportResultModalAsWord.title': '导出Word报告',
  'beforeExportResultModalAsWord.middle.name': '报告名称',
  'beforeExportResultModalAsWord.middle.defalutInput': '报告',
  'beforeExportResultModal.middle.item': '勾选项目',
};

const recycleDeleteModal = {
  'recycleDeleteModal.title': '是否彻底删除？',
  'recycleDeleteModal.task.desc':
    '此操作将从回收站删除任务，删除后您将无法恢复此任务，请谨慎操作！',
  'recycleDeleteModal.file.desc':
    '此操作将从回收站删除文件，删除后您将无法恢复此文件，请谨慎操作！',
};

const delContactModal = {
  'delContactModal.title': '删除该联系人？',
  'delContactModal.desc': '删除后不会影响共同进行的任务',
};

const delGroupModal = {
  'delGroupModal.title': '删除该分组？',
  'delGroupModal.desc': '组内联系人不会被删除',
  'delGroupModal.task.desc': '分组内的任务不会被删除',
};

const noticeModal = {
  'noticeModal.you': '你',
  'noticeModal.replyYou': '回复了你',
  'noticeModal.commentYou': '评论你',
  'noticeModal.newThumbtack': '新建图钉',
  'noticeModal.beAddTask': '被拉入新任务',
  'noticeModal.inviteConfirm': '邀请确认',
  'noticeModal.beOutTask': '被移出任务',
  'noticeModal.taskDismiss': '任务已解散',
  'noticeModal.beAddEnterprise': '被拉入新公司',
  'noticeModal.beOutEnterprise': '被移出公司',
  'noticeModal.enterpriseDissolved': '公司已解散',
  'noticeModal.addOtherContact': '是否添加对方为联系人',
  'noticeModal.addOtherContact.desc': '添加你为联系人，是否添加对方为联系人',
  'noticeModal.title': '消息',
  'noticeModal.allRead': '全部已读',
};

const quitModal = {
  'quitModal.creator': '确认解散任务?',
  'quitModal.creator.desc':
    '解散任务后，所有成员将无法访问此任务内容，此任务所有内容将被移到回收站。',
  'quitModal.member': '退出提示',
  'quitModal.member.desc': '此操作将退出任务',
};

const cardModal = {
  'cardModal.phone': '电话',
  'cardModal.email': '邮箱',
  'cardModal.email.hidden': '已隐藏',
  'cardModal.company': '公司',
  'cardModal.section': '部门',
  'cardModal.gender': '性别',
  'cardModal.gender.male': '男',
  'cardModal.gender.female': '女',
  'cardModal.address': '地址',
};

const removeMemberModal = {
  'removeMemberModal.title': '确认移除成员?',
  'removeMemberModal.desc': '移除后重新添加',
};

const showSaveTip = {
  'showSaveTip.title': '编辑内容尚未保存',
  'showSaveTip.tip': '保存则同步最新内容到任务',
};

const customWaveDataModal = {
  'customWaveDataModal.title': '是否使用当前自定义数据?',
  'customWaveDataModal.tip': '确定后将生成波形数据',
  'customWaveDataModal.ok': '是',
  'customWaveDataModal.cancel': '否',
};

const addChannelModal = {
  'addChannelModal.title': '添加通道',
  'addChannelModal.custom1': '自定义',
  'addChannelModal.custom2': '自定义2',
  'addChannelModal.channelName': '通道名称',
  'addChannelModal.sampleRate': '采样率',
  'addChannelModal.unit': '单位',
  'addChannelModal.scale': '缩放系数',
  'addChannelModal.offset': '偏差值',
  'addChannelModal.createData': '生成数据',
  'addChannelModal.algorithm': '函数',
  'addChannelModal.duration': '时间长度(s)',
  'addChannelModal.frequency': '频率(Hz)',
  'addChannelModal.amplitude': '振幅',
  'addChannelModal.formula': '公式',
};

export default {
  ...openWayModal,
  ...deleteDirModal,
  ...templateModal,
  ...copyModal,
  ...shareModal,
  ...deleteAttachmentModal,
  ...deleteMessageModal,
  ...addLinkModal,
  ...CorClearDataModal,
  ...addLimitModal,
  ...coordinateModal,
  ...deleteAppFileModal,
  ...colorModal,
  ...appSaveModal,
  ...RefrigerantModal,
  ...exportResultModal,
  ...beforeExportResuiltModal,
  ...beforeExportResuiltModalAsModal,
  ...recycleDeleteModal,
  ...delContactModal,
  ...delGroupModal,
  ...noticeModal,
  ...quitModal,
  ...cardModal,
  ...removeMemberModal,
  ...showSaveTip,
  ...customWaveDataModal,
  ...addChannelModal,
};
