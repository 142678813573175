export default {
  'cpb.paramSetting': '参数设置',
  'cpb.begin': '开始',
  'cpb.pause': '暂停',
  'cpb.end': '结束',
  'cpb.result': '结果',
  'cpb.chartFill': '图表填充',
  'cpb.unit': '单位',
  'cpb.table.channel': '通道',
  'cpb.table.color': '显示颜色',
  'cpb.table.maxFrequency': '最大值-频率',
  'cpb.table.totalAverage': 'Total 平均值',
  'cpb.reset': '重置',
  'cpb.frequency': '频率',
  'cpb.amplitude': '振幅',
  'cpb.analysisFail': '分析失败！',
  'cpb.creatingChart': '图表生成中',
  'cpb.creatingTable': '表格生成中',
};
