import {
  dtlTaskFileAddAnalysis,
  dtlTaskFileAddConv,
  dtlTaskFileAddMsg,
  dtlTaskFileConvert,
  dtlTaskFileCopyFilesToTask,
  dtlTaskFileCreateDir,
  dtlTaskFileDelAnalysis,
  dtlTaskFileDelFiles,
  dtlTaskFileDelMsg,
  dtlTaskFileInfo,
  dtlTaskFileMoveFiles,
  dtlTaskFileRenameFile,
  dtlTaskFileSetOpenType,
  dtlTaskFileShareFileToTask,
  dtlTaskFileUpdateAnalysis,
  dtlTaskFileUpdateContent,
  dtlTaskFileUploadFiles,
} from '@/services/datalib/api/taskFile';
import type { OpenType } from '@/services/datalib/bean/OpenType';
import { TaskFile } from '@/services/datalib/bean/TaskFile';
import { isConvResultSuccess } from '@/services/datalib/bean/util';
import { doErrResponse, doResponse, uuid2 } from '@/services/datalib/ctrl/util';
import { Err } from '@/services/datalib/enum';
import { doGetTask, doInitDtlStatus, dtlStatus, updateDtlVerLog } from '../index';

export async function taskFileGetFileTree(
  body: DtlTaskFile.GetFileTreeParam,
): Promise<DtlComm.Result<DtlTaskFile.AllFilesResult | undefined>> {
  const [task, err] = await doGetTask(body);
  if (err) return err;
  return {
    code: Err.OK[0],
    data: { allFiles: task.files.formatTree() },
    message: Err.OK[1],
  };
}

// export async function taskFileGetRecentFiles(
//   body: DtlTaskFile.GetRecentFilesParam,
// ): Promise<DtlComm.Result<DtlBean.TaskFile[] | undefined>> {
//   const [task, err] = await doGetTask(body);
//   if (err) return err;
//   return {
//     code: Err.OK[0],
//     data: task.files.getRecentFilesFormat(),
//     message: Err.OK[1],
//   };
// }

export async function taskFileCreateDir(
  body: DtlTaskFile.CreateDirParam,
): Promise<DtlComm.Result<DtlBean.TaskFile | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskFileCreateDir(body);
  const { code, message, data, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    const [task, err] = await doGetTask(body);
    if (err) return err;
    const fileId = data?.fileId as string;
    return {
      code: Err.OK[0],
      data: task.files.existFileById(fileId)?.format(),
      message: Err.OK[1],
    };
  }
  return doErrResponse(code, message);
}

export async function taskFileRenameFile(
  body: DtlTaskFile.RenameFileParam,
): Promise<DtlComm.Result<DtlBean.TaskFile | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskFileRenameFile(body);
  const { code, message, data, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    const [task, err] = await doGetTask(body);
    if (err) return err;
    if (data) {
      return {
        code: Err.OK[0],
        data: task.files.existFileById(data.fileId)?.format(),
        message: Err.OK[1],
      };
    }
  }
  return doErrResponse(code, message);
}

export async function taskFileMoveFiles(
  body: DtlTaskFile.MoveFilesParam,
): Promise<DtlComm.Result<undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskFileMoveFiles(body);
  return doResponse(response);
}

export async function taskFileDelFiles(
  body: DtlTaskFile.DelFilesParam,
): Promise<DtlComm.Result<undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskFileDelFiles(body);
  return doResponse(response);
}

export async function taskFileUploadFiles(
  body: DtlTaskFile.UploadFilesParam,
): Promise<DtlComm.Result<DtlTaskFile.UploadFilesResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskFileUploadFiles(body);
  const { code, message, data, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    const [task, err] = await doGetTask(body);
    if (err) return err;
    const added: DtlBean.TaskFile[] = [];
    data?.added.forEach((fileId) => {
      const file = task.files.existFileById(fileId);
      if (file) added.push(file.format());
    });
    return {
      code: Err.OK[0],
      data: { added },
      message: Err.OK[1],
    };
  }
  return doErrResponse(code, message);
}

// export async function taskFileCopyFiles(
//   body: DtlTaskFile.CopyFilesParam,
// ): Promise<DtlComm.Result<undefined>> {
//   const err = await doInitDtlStatus();
//   if (err) return err;
//   const response = await dtlTaskFileCopyFiles(body);
//   return doResponse(response);
// }

export async function taskFileCopyFilesToTask(
  body: DtlTaskFile.CopyFilesToTaskParam,
): Promise<DtlComm.Result<undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskFileCopyFilesToTask(body);
  return doResponse(response);
}

async function taskFileConvert(
  body: DtlTaskFile.FileConvertParam,
): Promise<DtlComm.Result<DtlTaskFile.FileConvertResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlStatus.syncGetOrAdd<DtlComm.ResultV<DtlTaskFile.FileConvertResult>>(
    body,
    dtlTaskFileConvert,
  );
  return doResponse(response);
}

export async function taskFileSetOpenType(
  body: DtlTaskFile.FileSetOpenTypeParam,
): Promise<DtlComm.Result<DtlBean.TaskFile | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskFileSetOpenType(body);
  const { code, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    const [task, err] = await doGetTask(body);
    if (err) return err;
    return {
      code: Err.OK[0],
      data: task.files.existFile(body.path)?.format(),
      message: Err.OK[1],
    };
  }
  return doErrResponse(code, message);
}

export async function taskFileGetById(body: {
  taskId: string;
  fileId: string;
}): Promise<DtlComm.Result<DtlBean.TaskFile | undefined>> {
  const [task, err] = await doGetTask(body);
  if (err) return err;
  return {
    code: Err.OK[0],
    data: task.files.existFileById(body.fileId)?.format(),
    message: Err.OK[1],
  };
}

export async function taskFileGetOpenMethods(body: {
  taskId: string;
  path: string;
}): Promise<DtlComm.Result<DtlTaskFile.GetOpenTypeResult | undefined>> {
  const [task, err] = await doGetTask(body);
  if (err) return err;
  const { file, openType } = task.files.getShowOpenInfo(body.path, dtlStatus.openTypes);
  if (!file) {
    return {
      code: Err.FILE_OR_DIR_NOT_EXIST[0],
      data: undefined,
      message: Err.FILE_OR_DIR_NOT_EXIST[1],
    };
  }
  return {
    code: Err.OK[0],
    data: {
      defaultOpenType: openType?.format(),
      allOpenType: dtlStatus.openTypes.formatShowList(),
    },
    message: Err.OK[1],
  };
}

export async function taskFileGetOpenInfoByProgram(body: {
  taskId: string;
  path: string;
  program: string; //如果program为空则由sid读取已有conv，否则必须匹配conv.program == program
  sid: number;
}): Promise<DtlComm.Result<{ openWay: string; conv?: DtlBean.Conv } | undefined>> {
  const [task, err] = await doGetTask(body);
  if (err) return err;
  let { taskId, path, program, sid } = body;
  if (!sid || !path || !taskId) {
    return { code: Err.REQUEST_DATA_ERROR[0], data: undefined, message: Err.REQUEST_DATA_ERROR[1] };
  }
  const file = task.files.existFile(path);
  if (!file) {
    return {
      code: Err.FILE_OR_DIR_NOT_EXIST[0],
      data: undefined,
      message: Err.FILE_OR_DIR_NOT_EXIST[1],
    };
  }
  const failed = { code: Err.SERVER_ERROR[0], data: undefined, message: Err.SERVER_ERROR[1] };
  const openType = dtlStatus.openTypes.getBySid(sid);
  if (!openType) {
    return failed;
  }
  if (!program) {
    program = openType.programName;
    if (!program) {
      return { code: Err.OK[0], data: { openWay: `${sid}`, conv: undefined }, message: Err.OK[1] };
    }
    const index = file.convs.findIndex(
      (c) => c.originP.split('/')[0] === program && isConvResultSuccess(c.result),
    );
    if (index > -1) {
      const conv = file.convs[index];
      return {
        code: Err.OK[0],
        data: { openWay: `${sid}/${conv.program}`, conv: conv.format() },
        message: Err.OK[1],
      };
    }
  } else {
    const index = file.convs.findIndex(
      (c) => (program === c.program || program === c.originP) && isConvResultSuccess(c.result),
    );
    if (index > -1) {
      const conv = file.convs[index];
      return {
        code: Err.OK[0],
        data: { openWay: `${sid}/${conv.program}`, conv: conv.format() },
        message: Err.OK[1],
      };
    }
  }
  const uuid = uuid2(32, 16);
  const { code, message, data } = await taskFileConvert({
    id: uuid,
    taskId,
    path,
    program,
    data: '',
  });
  if (code !== Err.OK[0]) {
    return doErrResponse(code, message);
  }
  if (data && data.conv) {
    return { code: Err.OK[0], data: { openWay: `${sid}`, conv: data.conv }, message: Err.OK[1] };
  }
  return failed;
}

export async function taskFileGetDefaultOpenInfo(body: {
  taskId: string;
  path: string;
}): Promise<DtlComm.Result<{ openWay: string; conv?: DtlBean.Conv } | undefined>> {
  const [task, err] = await doGetTask(body);
  if (err) return err;
  const file = task.files.existFile(body.path);
  if (!file) {
    return {
      code: Err.FILE_OR_DIR_NOT_EXIST[0],
      data: undefined,
      message: Err.FILE_OR_DIR_NOT_EXIST[1],
    };
  }
  const serverFailed = { code: Err.SERVER_ERROR[0], data: undefined, message: Err.SERVER_ERROR[1] };
  let openType: OpenType | undefined;
  let program: string = '';
  let openWay = file.openWay;
  if (openWay) {
    const arr = openWay.split('/');
    const sid = Number(arr[0]);
    openType = dtlStatus.openTypes.getSupportType(sid);
    if (!openType) {
      return serverFailed;
    }
    if (arr.length >= 3) {
      program = `${arr[1]}/${arr[2]}`;
    } else if (arr.length >= 2) {
      program = `${arr[1]}`;
    } else {
      return { code: Err.OK[0], data: { openWay, conv: undefined }, message: Err.OK[1] };
    }
    const index = file.convs.findIndex(
      (c) => (c.program === program || c.originP === program) && isConvResultSuccess(c.result),
    );
    if (index > -1) {
      const conv = file.convs[index];
      return { code: Err.OK[0], data: { openWay, conv: conv.format() }, message: Err.OK[1] };
    }
  } else {
    openType = dtlStatus.openTypes.getDefaultType(file.path, file.type);
    if (!openType) {
      return serverFailed;
    }
    program = openType.programName;
    if (!program) {
      return {
        code: Err.OK[0],
        data: { openWay: `${openType.sid}`, conv: undefined },
        message: Err.OK[1],
      };
    } else {
      const index = file.convs.findIndex(
        (c) => c.originP.split('/')[0] === program && isConvResultSuccess(c.result),
      );
      if (index > -1) {
        const conv = file.convs[index];
        return {
          code: Err.OK[0],
          data: { openWay: `${openType.sid}/${conv.program}`, conv: conv.format() },
          message: Err.OK[1],
        };
      }
    }
  }
  return await taskFileGetOpenInfoByProgram({
    taskId: body.taskId,
    path: body.path,
    program,
    sid: openType.sid,
  });
}

export async function taskFileAddAnalysis(
  body: DtlTaskFile.FileAddAnalysisParam,
): Promise<DtlComm.Result<DtlBean.TaskFile | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskFileAddAnalysis(body);
  const { code, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    const [task, err] = await doGetTask({ taskId: body.taskId });
    if (err) return err;
    return {
      code: Err.OK[0],
      data: task.files.existFile(body.path)?.format(),
      message: Err.OK[1],
    };
  }
  return doErrResponse(code, message);
}

export async function taskFileUpdateAnalysis(
  body: DtlTaskFile.FileUpdateAnalysisParam,
): Promise<DtlComm.Result<DtlBean.TaskFile | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskFileUpdateAnalysis(body);
  const { code, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    const [task, err] = await doGetTask({ taskId: body.taskId });
    if (err) return err;
    return {
      code: Err.OK[0],
      data: task.files.existFile(body.path)?.format(),
      message: Err.OK[1],
    };
  }
  return doErrResponse(code, message);
}

export async function taskFileDelAnalysis(
  body: DtlTaskFile.FileDelAnalysisParam,
): Promise<DtlComm.Result<DtlBean.TaskFile | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskFileDelAnalysis(body);
  const { code, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    const [task, err] = await doGetTask({ taskId: body.taskId });
    if (err) return err;
    return {
      code: Err.OK[0],
      data: task.files.existFile(body.path)?.format(),
      message: Err.OK[1],
    };
  }
  return doErrResponse(code, message);
}

export async function taskFileAddConv(
  body: DtlTaskFile.FileAddConvParam,
): Promise<DtlComm.Result<DtlBean.TaskFile | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskFileAddConv(body);
  const { code, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    const [task, err] = await doGetTask({ taskId: body.taskId });
    if (err) return err;
    return {
      code: Err.OK[0],
      data: task.files.existFile(body.path)?.format(),
      message: Err.OK[1],
    };
  }
  return doErrResponse(code, message);
}

export async function taskFileShareFileToTask(
  body: DtlTaskFile.ShareFileToTaskParam,
): Promise<DtlComm.Result<undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskFileShareFileToTask(body);
  return doResponse(response);
}

export async function taskFileAddMsg(
  body: DtlTaskFile.FileAddMsgParam,
): Promise<DtlComm.Result<DtlBean.Msg | undefined>> {
  const [task, err] = await doGetTask(body);
  if (err) return err;
  const response = await dtlTaskFileAddMsg(body);
  const { code, message, data, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    const taskFile = task.files.existFile(body.path);
    const idx = taskFile?.msgs.findIndex((m) => m.msgId === data?.msg.msgId);
    let msg = undefined;
    if (idx != undefined && idx > -1) {
      msg = taskFile?.msgs[idx].format();
    }
    return {
      code: Err.OK[0],
      data: msg,
      message: Err.OK[1],
    };
  }
  return doErrResponse(code, message);
}

export async function taskFileDelMsg(
  body: DtlTaskFile.FileDelMsgParam,
): Promise<DtlComm.Result<DtlBean.TaskFile | undefined>> {
  const [task, err] = await doGetTask(body);
  if (err) return err;
  const response = await dtlTaskFileDelMsg(body);
  const { code, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    return {
      code: Err.OK[0],
      data: task.files.existFileById(body.fileId)?.format(),
      message: Err.OK[1],
    };
  }
  return doErrResponse(code, message);
}

export async function taskFileUpdateContent(
  body: DtlTaskFile.FileUpdateContentParam,
): Promise<DtlComm.Result<DtlBean.TaskFile | undefined>> {
  const [task, err] = await doGetTask(body);
  if (err) return err;
  const response = await dtlTaskFileUpdateContent(body);
  const { code, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    return {
      code: Err.OK[0],
      data: task.files.existFileById(body.fileId)?.format(),
      message: Err.OK[1],
    };
  }
  return doErrResponse(code, message);
}

export async function taskFileInfo(body: {
  taskId: string;
  fileId: string;
}): Promise<DtlComm.Result<DtlBean.TaskFile | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const task = dtlStatus.getTask(body.taskId);
  if (task && !task.deleted) {
    const taskFile = task.files.existFileById(body.fileId)?.format();
    if (taskFile) {
      return {
        code: Err.OK[0],
        data: taskFile,
        message: Err.OK[1],
      };
    }
  }
  const response = await dtlTaskFileInfo(body);
  const { code, data, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    return {
      code: Err.OK[0],
      data: new TaskFile().fromBean(data).format(),
      message: Err.OK[1],
    };
  }
  return doErrResponse(code, message);
}

export async function taskFileGetWavType(): Promise<DtlComm.Result<DtlBean.OpenType | undefined>> {
  return {
    code: Err.OK[0],
    data: dtlStatus.openTypes.formatWavShowList(),
    message: Err.OK[1],
  };
}

// global.taskFileInfo = taskFileInfo;
// global.taskFileGetFileTree = taskFileGetFileTree;
