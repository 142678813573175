export class ConvProgramPlugin {
  id: string = '';
  name: string = '';
  version: string = '';
  type: string = '';
  readOnly: boolean = false;
  extensions: string[] = [];
  extensionsStr: string = '';
  desc: string = '';

  constructor(t: DtlBean.ConvProgramPlugin) {
    if (!t) return;
    if (t.id) {
      this.id = t.id;
    }
    if (t.name) {
      this.name = t.name;
    }
    if (t.version) {
      this.version = t.version;
    }
    if (t.type) {
      this.type = t.type;
    }
    if (t.readOnly) {
      this.readOnly = t.readOnly;
    }
    if (t.extensions) {
      this.extensions = [...t.extensions];
    }
    if (t.extensionsStr) {
      this.extensionsStr = t.extensionsStr;
    }
    if (t.desc) {
      this.desc = t.desc;
    } else {
      this.desc = t.extensionsStr;
    }
  }

  copy(): ConvProgramPlugin {
    return new ConvProgramPlugin(this.format());
  }

  format(): DtlBean.ConvProgramPlugin {
    return {
      id: this.id,
      name: this.name,
      version: this.version,
      type: this.type,
      readOnly: this.readOnly,
      extensions: [...this.extensions],
      extensionsStr: this.extensionsStr,
      desc: this.desc,
    };
  }
}
