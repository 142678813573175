/**
 *
 * (c) Copyright Ascensio System SIA 2021
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */

// get file name from the given url
function getFileName(url: string, withoutExtension: boolean = false) {
  if (!url) return '';

  const parts = url.split('/');
  let fileName = parts.pop(); // get the file name from the last part of the url
  fileName = (fileName as string).split('?')[0];

  // get file name without extension
  if (withoutExtension) {
    return fileName.substring(0, fileName.lastIndexOf('.'));
  }

  return fileName;
}

// get file extension from the given url
function getFileExtension(url: string, withoutDot: boolean = false) {
  if (!url) return '';

  const fileName = getFileName(url); // get file name from the given url

  const parts = fileName.toLowerCase().split('.');

  return (withoutDot ? parts.pop() : '.' + parts.pop()) as string; // get the extension from the file name with or without dot
}

// get file type from the given url
function getFileType(url: string) {
  const ext = getFileExtension(url); // get the file extension from the given url

  if (documentExts.indexOf(ext) != -1) return fileType.word; // word type for document extensions
  if (spreadsheetExts.indexOf(ext) != -1) return fileType.cell; // cell type for spreadsheet extensions
  if (presentationExts.indexOf(ext) != -1) return fileType.slide; // slide type for presentation extensions

  return fileType.word; // the default file type is word
}

function getFileDir(url: string) {
  if (!url) return '';
  const idx = url.lastIndexOf('/');
  if (idx > -1) {
    return url.substring(0, idx);
  }
  return '';
}

const fileType = {
  word: 'word',
  cell: 'cell',
  slide: 'slide',
};

// the document extension list
const documentExts = [
  '.doc',
  '.docx',
  '.oform',
  '.docm',
  '.dot',
  '.dotx',
  '.dotm',
  '.odt',
  '.fodt',
  '.ott',
  '.rtf',
  '.txt',
  '.html',
  '.htm',
  '.mht',
  '.xml',
  '.pdf',
  '.djvu',
  '.fb2',
  '.epub',
  '.xps',
  '.oxps',
];

// the spreadsheet extension list
const spreadsheetExts = [
  '.xls',
  '.xlsx',
  '.xlsm',
  '.xlt',
  '.xltx',
  '.xltm',
  '.ods',
  '.fods',
  '.ots',
  '.csv',
];

// the presentation extension list
const presentationExts = [
  '.pps',
  '.ppsx',
  '.ppsm',
  '.ppt',
  '.pptx',
  '.pptm',
  '.pot',
  '.potx',
  '.potm',
  '.odp',
  '.fodp',
  '.otp',
];

// get url parameters
function getUrlParams(url: string) {
  try {
    const query = url.split('?').pop() as string; // take all the parameters which are placed after ? sign in the file url
    const params = query.split('&'); // parameters are separated by & sign
    const map = {}; // write parameters and their values to the map dictionary
    for (let i = 0; i < params.length; i++) {
      const param = params[i];
      const parts = param.split('=');
      map[parts[0]] = parts[1];
    }
    return map;
  } catch (ex) {
    return null;
  }
}

// save all the functions to the fileUtility module to export it later in other files
export { getFileName, getFileExtension, getFileType, getFileDir, getUrlParams };
