import { uploadFile } from '@/services/datalib';
import { getPathExtension } from '@/utils/fileHelper';
import BMF from 'browser-md5-file';
import PATH from 'path';
import { Md5 } from 'ts-md5/dist/md5';

export async function readFileMd5(file: File) {
  const bmf = new BMF();
  return new Promise((resolve, reject) => {
    bmf.md5(
      file,
      (err: string, md5: string) => {
        resolve(md5);
        console.log('err:', err);
        // console.log('md5 string:', md5); // 97027eb624f85892c69c4bcec8ab0f11
      },
      (progress: number) => {
        // console.log('progress number:', progress);
      },
    );
  });
}

//public: [bucketName]/public/avatar/userId
//normal: [bucketName]/[type]/[taskId]/[userId]/[zoneName]/[objectId]

const publicTypes = {
  public: '01',
  encrypt: '02',
  normal: '03',
};
export const zoneTypes = {
  taskList: '01', //任务区域
  taskOption: '02', //单个任务操作区域
  taskExpriment: '03', //任务实验区域
  taskChat: '04', //任务聊天区域
  pinChat: '05', //图钉聊天区域
  feedback: '06', // 反馈区域
};

export async function uploadNormalFile(
  file: File,
  taskId: string,
  userId: string,
  zoneName: string,
  dir: string = '',
) {
  const { path, fullPath, filename } = await formatNormalFilePath(
    file,
    taskId,
    userId,
    zoneName,
    dir,
  );
  const ext = getPathExtension(file.name);
  // let filename = Md5.hashStr((file.name as string).split('.')[0]).toString();

  const { code, data } = await uploadFile({
    file: file,
    filename: `${filename}.${ext}`,
    path: path,
    auth_token: localStorage.getItem('x-auth-token') as string,
  });

  return {
    status: data.res.status === 200 ? 'ok' : data.res.status,
    fullPath: `${fullPath}.${ext}`,
  };
}

export async function uploadFileShowProgress(
  file: File,
  taskId: string,
  userId: string,
  zoneName: string,
  dir: string = '',
  getProgress: { (progressEvent: any): void },
  cancelToken?: (c) => void,
) {
  const { path, fullPath, filename } = await formatNormalFilePath(
    file,
    taskId,
    userId,
    zoneName,
    dir,
  );
  const ext = getPathExtension(file.name);

  const filenameWithExt = `${filename}.${ext}`;
  const rs = await uploadFile(
    {
      file: file,
      filename: filenameWithExt,
      path: path,
      auth_token: localStorage.getItem('x-auth-token') as string,
    },
    {
      onUploadProgress: getProgress,
      cancelToken: cancelToken,
    },
  );

  return {
    code: rs.code,
    fullPath: `${fullPath}.${ext}`,
  };
}

export async function uploadPublicFile(file: File, userId: string, type: string = 'avatar') {
  const { path, fullPath, filename } = await formatPublicFilePath(file, userId, type);

  const ext = getPathExtension(file.name);

  const filenameWithExt = `${filename}.${ext}`;
  const rs = await uploadFile({
    file: file,
    filename: filenameWithExt,
    path: path,
    auth_token: localStorage.getItem('x-auth-token') as string,
  });

  return {
    code: rs.code,
    fullPath: `${fullPath}.${ext}`,
  };
}

const placeholder = '00';

export async function formatNormalFilePath(
  file: File,
  taskId: string,
  userId: string,
  zoneName: string,
  dir: string = '',
) {
  console.log('formatNormalFilePath');
  const context = 'data-lib-dev';
  const publicType = publicTypes.normal;
  const filename = await readFileMd5(file);
  const dirPath = dir ? `/${dir.replace(/(#|%|\*)+/g, '')}` : '';
  const path = `${context}/${publicType}/${taskId || placeholder}/${userId || placeholder}/${
    zoneName || placeholder
  }${dirPath}`;

  return {
    path: path,
    fullPath: PATH.join(path, <string>filename),
    filename,
  };
}

export async function formatPublicFilePath(file: File, userId: string, type: string = 'avatar') {
  const context = 'data-lib-dev';
  const publicType = publicTypes.public;
  const path = `${context}/${publicType}/${type}`;
  //filename: MD5(userId+mix)
  const filename = Md5.hashStr(userId + 'dtl');

  return {
    path: `${context}/${publicType}/${type}`,
    fullPath: PATH.join(path, <string>filename),
    filename,
  };
}

export const FileResponseCode = {
  S_OK: 1,
  S_ERROR: 0,
};
