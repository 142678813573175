const time = {
  'time.justNow': 'a moment ago',
  'time.minuteAgo': ' minutes ago',
  'time.hourAgo': ' hour ago',
};

const appHeader = {
  'appHeader.dataSource': 'Data Source',
  'appHeader.Untitled': 'Untitled',
  'appHeader.result': 'Result',
};

const userStatus = {
  'user.role.creator': 'Creator',
  'user.role.manager': 'Administrator',
  'user.role.manager.desc': 'Have task file management and add/delete member permissions',
  'user.role.member': 'Member',
  'user.role.member.desc': 'Have task file management permissions',
  'user.role.visitor': 'Visitor',
  'user.role.visitor.desc': 'Read permission only',
  'status.unregisterButInvite': 'Not registered but invited',
  'status.wait': 'waiting for examine',
  'user.role.uploader': 'Upload Browser',
  'user.role.uploader.desc': 'Only upload and browse file permissions',
  'user.role.copyError': 'Lack of save permission for this task',
};

const Toolbar = {
  'Toolbar.chirp': 'Chirp',
  'Toolbar.noise': 'Noise',
  'Toolbar.tone': 'Single-Tone',
  'Toolbar.DTMF': 'DTMF-Tone',
  'Toolbar.scaleFit': 'Zoom to Fit',
  'Toolbar.scaleX': 'Scale X-axis',
  'Toolbar.scaleY': 'Scale Y-axis',
  'Toolbar.scaleByBox': 'Rectangle selection scaling',
  'Toolbar.scaleByX': 'X-axis selection scaling',
  'Toolbar.scaleByY': 'Y-axis selection scaling',
  'Toolbar.shape.rect': 'Rect',
  'Toolbar.shape.circle': 'Circle',
  'Toolbar.shape.arrow': 'Arrow',
  'Toolbar.shape.text': 'Text',
  'Toolbar.shape.picture': 'Picture',
  'Toolbar.line.solid': 'Thin Line',
  'Toolbar.line.bold': 'Thick Line',
  'Toolbar.line.dashed': 'Dashed',
  'Toolbar.line.boldDashed': 'Thick Dashed',
  'Toolbar.textDecoration.normal': 'Normal',
  'Toolbar.textDecoration.underline': 'Underline',
  'Toolbar.textDecoration.lineThrough': 'Strikethrough',
  'Toolbar.undo': 'Undo',
  'Toolbar.redo': 'Redo',
  'Toolbar.scissor': 'Cutting Waveform',
  'Toolbar.zoom_in': 'Zoom In',
  'Toolbar.zoom_in_select': 'Zoom in on the selection area',
  'Toolbar.drag_move': 'Dragging and moving',
  'Toolbar.selectWave_box': 'Box Selection Waveform',
  'Toolbar.zoom_out': 'Zoom Out',
  'Toolbar.addMark': 'Add Mark',
  'Toolbar.pin': 'Thumbtack',
  'Toolbar.borderStyle': 'Border Style',
  'Toolbar.color': 'Color',
  'Toolbar.fontsize': 'Font Size',
  'Toolbar.fontStyle': 'Character Style',
  'Toolbar.fontBold': 'Bold',
  'Toolbar.changeYAxis': 'Coordinate Transformation',
  'Toolbar.delete': 'Delete',
  'Toolbar.showDecorates': 'Show/Hide Annotations',
  'Toolbar.shapeColor.stroke': 'Border Color',
  'Toolbar.shapeColor.fill': 'Fill Color',
  'Toolbar.shapeColor.text': 'Font Color',
  'Toolbar.play': 'Play',
  'Toolbar.pause': 'Pause',
  'Toolbar.stop': 'Stop',
  'Toolbar.cut': 'Cut Off',
  'Toolbar.muteSelection': 'Mute within the selection area',
  'Toolbar.muteOutSelection': 'Mute outside the selection area',
  'Toolbar.overallRestoration': 'Overall restoration',
  'Toolbar.overallAmplification': 'Overall amplification',
  'Toolbar.overallReduction': 'Overall reduction',
  'Toolbar.viewReport': 'View Report',
  'Toolbar.yAxis': 'Y-axis',
  'Toolbar.valueType.currentValue': 'Current value',
  'Toolbar.valueType.vernierValue': 'Cursor value',
  'Toolbar.valueType.intervalValue': 'Interval value',
  'Toolbar.export': 'Export',
  'Toolbar.analysisSettings': 'Analysis Settings',
};

const Err = {
  操作成功: 'Operation succeeded',
  '解析失败，请选择其他打开方式': 'Parsing failed. Please choose another open method',
  设备类型错误: 'Device type error',
  用户不存在: 'User does not exist',
  用户名或密码错误: 'User name or password error',
  电话号码格式错误: 'Phone number format error',
  密码格式错误: 'Password format error',
  用户已存在: 'User already exists',
  修改用户信息参数错误: 'Error modifying user information parameters',
  验证码发送错误: 'Verification code sending error',
  验证码发送频繁: 'Frequent sending of verification code',
  验证码已发送: 'Verification code has sent',
  验证码已过期: 'The verification code has expired',
  验证码错误: 'Verification code error',
  验证token错误: 'Validation token error',
  需要图片验证码: 'Image verification code required',
  图片验证码错误: 'Image verification code error',
  密码登录错误频繁: 'Frequent password login errors',
  用户名超出范围: 'User name is out of range',
  邮箱格式错误或长度超出范围: 'Email format error or length out of range',
  token错误: 'Token error',
  集合大小为0: 'The collection size is 0',
  参数类型不匹配或缺少参数: 'Parameter type mismatch or missing parameter',
  请求数据错误: 'Request data error',
  登录已过期: 'Login expired',
  操作失败请稍后再试: 'Operation failed. Please try again later',
  电话号码占用: 'Phone number is already occupied',
  邮箱已被占用: 'The mailbox is already occupied',
  联系人组不存在: 'Contact group does not exist',
  联系人组已存在: 'Contact group already exists',
  通讯录中不存在该成员: 'The member does not exist in the address book',
  操作超时: 'Operation timeout',
  任务分组名已存在: 'Task group name already exists',
  指定的任务分组不存在: 'The specified task group does not exist',
  任务已存在于指定分组: 'Task already exists in the specified group',
  任务不存在: 'Task does not exist',
  用户不是该任务的成员: 'The user is not a member of this task',
  缺少操作权限: 'Missing operation permissions',
  最多只能设定2位管理员: 'You can only set up to 2 administrators',
  回复的消息不存在: 'The reply message does not exist',
  任务列表错误: 'Task List Error',
  指定的文件或文件夹不存在: 'The specified file or folder does not exist',
  只能移动到文件夹下: 'Can only be moved under a folder',
  已存在同名的文件: 'A file with the same name already exists',
  已存在同名的文件夹: 'A folder with the same name already exists',
  父级路径与已有的某个文件路径重复: 'The parent path duplicates an existing file path',
  '正在解析该文件，请稍后再试': 'The file is being parsed. Please try again later',
  '打开失败，请选择其他打开方式': 'Opening failed. Please choose another opening method',
  指定的对象不存在: 'The specified object does not exist',
  '任务成员已满，最多50人': 'Task members are full, up to 50 members',
  指定的分析结果不存在: 'The specified analysis result does not exist',
  指定的分享不存在: 'The specified share does not exist',
  链接已失效: 'Link has expired',
  任务已存在: 'Task already exists',
  目标已存在: 'Target already exists',
  创建者不能退出任务: 'The creator cannot exit the task',
  用户名已存在: 'User name already exists',
  父文件不存在: 'Parent file does not exist',
  指定的文件备注不存在: 'The specified file comment does not exist',
  MDF格式错误: 'MDF format error',
  该文件已被取消分享: 'This file has been unshared',
  该分享需要输入密码: 'This sharing requires a password',
  文件记录不存在: 'File record does not exist',
  用户尚未申请授权码: 'The user has not applied for an authorization code',
  授权码不正确: 'Incorrect authorization code',
  不存在该邀请: 'The invitation does not exist',
  已同意全部成员邀请: 'All member invitations have been agreed!',
  '任务即将满员，最多50人': 'The task is about to be full, with a maximum of 50 people',
  同意全部成员失败: 'Failed to agree to all members!',
  已同意成员邀请: 'Successfully agreed to member invitation！',
  同意成员邀请失败: 'Failed to agree to member invitation！',
  已通过: 'Passed',
  包含非手机号和邮箱的内容: 'Phone number and email format error',
  模板数据不正确: 'Template Error',
  未提供文件: 'No files provided',
  提供的文件信息错误: 'The provided file information is incorrect',
  文件格式不支持: 'File format not supported',
  SN格式不匹配: 'SN format mismatch',
  参数错误: 'Parameter error',
  项目名未指定: 'Project name not specified',
  指定的项目不存在: 'The specified project does not exist',
  文件中列数少于5列: 'Columns number in the file is less than 5',
  文件中行数少于2行: 'Lines number in the file is less than 2',
  无法识别列名所在行: 'Unable to recognize column name in the row',
  无法识别数据起始行: 'Unable to recognize data start row',
  灵敏度格式不匹配: 'Sensitivity format mismatch',
  分隔符格式不匹配: 'Separator format mismatch',
  'Station ID格式不匹配': 'Station ID mismatch',
  工作表名称有误: 'Incorrect sheet name',
  没用用于插值的空单元格: 'Empty cells not used for interpolation',
  存在某一列数据两端为非数字: 'There is a column of data with non numeric characters at both ends',
  存在某一行数据两端为非数字: 'There is a row of data with non numeric characters at both ends',
  选中的行数需不少于3: 'The number of selected rows must be no less than 3',
  选中的行数需不少于4: 'The number of selected rows must be no less than 4',
  存在某一列的数字单元格不足3个: 'There are less than 3 numerical cells in a column',
  选中的行数需不少于5: 'The number of selected rows must be no less than 5 ',
  存在某一列的数字单元格不足4个: 'There are less than 4 numerical cells in a column',
  多文件分析不应使用pcs模板: 'Multi file analysis should not use PCS template',
  pcs数不匹配: 'Mismatched number of pcs',
  'worker destroyed': 'worker destroyed',
  请求参数不正确: 'Incorrect request parameters',
  网络访问失败: 'Network access failure',
  超过范围: 'Beyond range',
  未选中范围: 'Unselected range',
  请先新建过程: 'Please create a new process first',
  过程不匹配: 'Process mismatch',
  过程不存在该方法: 'The method does not exist in the process',
  找不到实例: 'Instance not found',
  cmd不合法: 'Cmd is illegal',
  方法不存在: 'Method does not exist',
  数据不存在: 'Data does not exist',
  '该过程已提交，请使用未提交的过程':
    'The process has been submitted, please use the unsubmitted process',
  FUNC不存在: 'FUNC does not exist',
  FUNC格式不正确: 'FUNC format is incorrect',
  'FUNC FACTORY不存在': 'FUNC FACTORY does not exist',
  COMMAND不存在: 'COMMAND does not exist',
  ALPHAWAVE参数错误: 'ALPHAWAVE parameter error',
  'Wav Data为空': 'Wav Data is empty',
  只能使用新创建的effect来执行方法: 'Only newly created effects can be used to execute methods',
  'Get Noise Profile failed': 'Get Noise Profile failed',
  采样率不一致: 'Inconsistent sampling rate',
  选择的样本数据过少: 'The selected sample data is too small',
  获取图形失败: 'Failed to obtain graphics',
  'IIR计算失败，请输入正确的参数': 'IIR calculation failed, please enter the correct parameters',
  'FIR计算失败，请输入正确的参数': 'FIR calculation failed, please enter the correct parameters',
  'Smooth计算失败，请输入正确的参数':
    'Smooth calculation failed, please enter the correct parameters',
  'FilterCurveEQ计算失败，请输入正确的参数':
    'FilterCurveEQ calculation failed, please enter the correct parameters',
  'NoiseReduction计算失败，请输入正确的参数':
    'NoiseReduction calculation failed, please enter the correct parameters',
  普通文件不能存放到配置文件: 'Common file cannot be stored in the configuration folder',
};

const pin = {
  'pin.enterComment': 'Please enter a comment',
  'pin.saveFileToTask': 'Please save the file to the task list',
  'pin.pictureSupport': 'Image only supports JPG/PNG format!',
  'pin.selectFileUpload': 'Select a file to upload',
  'pin.sendPicture': 'Send picture',
  'pin.sendSnapshot': 'Send snapshot',
};

const colorPicker = {
  'colorPicker.StandardColor': 'Standard Color',
  'colorPicker.otherColor': 'Other Colors',
  'colorPicker.selectError.allNone': 'Cannot set no color at the same time',
};

const pageInfo = {
  'pageInfo.nowPage': 'Page: ',
  'pageInfo.afterNumber': ' ',
  'pageInfo.totalPage': ' Total: ',
};

const common = {
  'common.null': 'null',
};

export default {
  ...time,
  ...userStatus,
  ...Err,
  ...appHeader,
  ...Toolbar,
  ...pin,
  ...colorPicker,
  ...pageInfo,
  ...common,
};
