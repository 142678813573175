export default {
  'AG.searchSN': 'Search Serial Number',
  'AG.searchStationID': 'Search Station ID',
  'AG.colorToSN': 'Apply to Serial Number',
  'AG.colorToAll': 'Apply to All',
  'AG.restoreDefault': 'Restore default values',
  'AG.restoreDefaultColor': 'Restore default values',
  'AG.color.type': 'Color settings Type',
  'AG.color.sameSn': 'same Serial Number',
  'AG.color.sameId': 'same Station ID',
  'AG.color.sameDay': 'same day',
  'AG.color.sum': 'Range of color set',
  'AG.color.all': 'All',
  'AG.color.single': 'Single',
  'AG.filterSN': 'Filter Serial Number',
  'AG.filterStationID': 'Filter Station ID',
  'AG.filterTestResult': 'Filter Test Result',
  'AG.showDetail': 'Show detail',
  'AG.hiddenDetail': 'Hidden detail',
  'AG.colorSetting': 'Color Settings',
  'AG.checkSetting': 'Check Settings',
  'AG.dat.error.xAxisDiff': 'Please select a data item with the same X-axis value',
  'AG.dat.error.xAxisExistDiff': 'The merged item has sub items with different X-axis value',
  'AG.dat.tips.chooseItem': 'Please select a data item',
  'AG.dat.mergeItem.title': 'Merge items',
  'AG.dat.mergeItem.add': 'Create a merge item',
  'AG.dat.mergeItem.name': `Merge item's name`,
  'AG.dat.mergeError.x': 'Error! The merged item has more than one horizontal coordinate unit',
  'AG.dat.mergeError.y': 'Error! The merged item has more than 2 vertical coordinate unit',
  'AG.dat.mergeError.data': 'Error! The image has been regenerated',
  'AG.merge.noItem': 'No merged Items',
  'AG.checkToProject': 'Apply to individual project',
  'AG.checkToAll': 'Apply to all projects',
  'AG.logX.notPositive': 'Log conversion failed due to non positive data',
  'AG.logX.point': 'Each line has only one point, log conversion failed',
  'AG.export.link': 'link',
};
