export default {
  'myFile.BigTitle': 'My Data',
  'myFile.recent.tab': 'Recent',
  'myFile.collection.tab': 'Collection',
  'myFile.recent.input.placeholder': 'Search for recently files',
  'myFile.time.today': 'Today',
  'myFile.time.yesterday': 'Yesterday',
  'myFile.time.sevenday': 'Within Seven Days',
  'myFile.time.thisMonth': 'Earlier this month',
  'myFile.time.more': 'Earlier',
  'myFile.time.January': 'January',
  'myFile.time.February': 'February',
  'myFile.time.March': 'March',
  'myFile.time.April': 'April',
  'myFile.time.May': 'May',
  'myFile.time.June': 'June',
  'myFile.time.July': 'July',
  'myFile.time.August': 'August',
  'myFile.time.September': 'September',
  'myFile.time.October': 'October',
  'myFile.time.November': 'November',
  'myFile.time.December': 'December',
  'myFile.recent.empty': 'The opened file will be here',
  'myFile.recent.searchEmpty': 'No matching data temporarily',
  'myFile.collection.input.placeholder': 'Search for favorite files',
  'myFile.collection.new.placeholder': 'New Favorites',
  'myFile.collection.allCollection': 'All Collections',
  'myFile.collection.table.name': 'Name',
  'myFile.collection.table.source': 'Source',
  'myFile.collection.table.size': 'Size',
  'myFile.collection.table.operation': 'Operations',
  'myFile.collection.modal.title': 'Select Favorites',
  'myFile.collection.modal.desc': 'Select favorite or',
  'myFile.collection.modal.cancelCollect': 'cancel favorite',
  'myFile.collection.table.empty': 'Favorite files will be here',
  'myFile.collection.table.firstEmpty': 'This favorite has not yet collected files',
};
