import { dtlStatus, Err, fromAlpFile, isDev } from '@/services/datalib';
import { readFromSoundCheckDat } from '@/services/datalib/ctrl/SoundCheckDat';
import { FileResponseCode, uploadNormalFile } from '@/utils/fileOptions';
import request from '@/utils/request';
import axios, { AxiosRequestConfig, Canceler } from 'axios';
import { debounce } from 'lodash';
import PATH from 'path';

/*
 * 上传文件
 * 上传过程中，如果3秒内没有进度条更新，则自动重新上传
 * 重复两次上述过程后，第三次不会再限制3秒内更新进度条
 * */
export async function uploadFile(
  body: { file: File; filename: string; path: string; auth_token: string },
  callback?: {
    onUploadProgress?: (progressEvent: any) => void;
    cancelToken?: (c: Canceler) => void;
  },
): Promise<any> {
  let metaData: any;
  {
    const fileKey = PATH.join(body.path, body.filename);
    const metaRes = await getUploadMetaDatas({ oids: [fileKey] });
    if (metaRes.code !== Err.OK[0]) {
      return {
        code: FileResponseCode.S_ERROR,
        data: { res: { status: 400 } },
        message: metaRes?.msg,
      };
    }
    metaData = metaRes.data[0];
  }

  let axioCanceler: Canceler | undefined = undefined;
  let debounceFunc: any;
  let loaded = 0;
  let isClickCancel = false;

  const doCancel = () => {
    isClickCancel = true;
    axioCanceler?.();
    axioCanceler = undefined;
    debounceFunc?.cancel?.();
    debounceFunc = undefined;
  };
  callback?.cancelToken?.(doCancel);

  const getOptions = () => ({
    onUploadProgress: (progressEvent: any) => {
      debounceFunc?.();
      if (progressEvent.lengthComputable) {
        loaded = loaded > progressEvent.loaded ? loaded : progressEvent.loaded;
        callback?.onUploadProgress?.({ loaded: loaded, total: progressEvent.total });
      }
    },
    cancelToken: (c: Canceler) => {
      axioCanceler = c;
    },
  });
  for (let i = 0; i < 2; i++) {
    const result = await new Promise((rs) => {
      debounceFunc = debounce(
        () => {
          if (rs) {
            rs(undefined);
            // @ts-ignore
            rs = undefined;
            axioCanceler?.();
            axioCanceler = undefined;
          }
          debounceFunc = undefined;
        },
        (i + 1) * 1500,
        { trailing: true },
      );
      debounceFunc();

      doUploadFile(body, metaData, getOptions()).then((result) => {
        if (rs) {
          rs(result);
          // @ts-ignore
          rs = undefined;
          debounceFunc?.cancel?.();
          debounceFunc = undefined;
          axioCanceler = undefined;
        }
      });
    });
    if (result) {
      return result;
    }
    if (isClickCancel) {
      return { code: FileResponseCode.S_ERROR, data: { res: { status: 400 } }, message: 'cancel' };
    }
  }
  return doUploadFile(body, metaData, getOptions());
}

export async function doUploadFile(
  body: { file: File; filename: string; path: string; auth_token: string },
  metaData: { postURL: string; formData: Record<string, string> },
  callback?: {
    onUploadProgress?: (progressEvent: any) => void;
    cancelToken?: (c: Canceler) => void;
  },
) {
  const fileKey = PATH.join(body.path, body.filename);
  const formData: any = new FormData();
  formData.append('success_action_status', '200');
  formData.append('key', fileKey);
  Object.keys(metaData.formData).forEach((key) => {
    formData.append(key, metaData.formData[key]);
  });
  formData.append('file', body.file);

  const { CancelToken } = axios;
  const config: AxiosRequestConfig = {
    url: metaData.postURL,
    method: 'post',
    onUploadProgress: function (progressEvent: any) {
      callback?.onUploadProgress?.(progressEvent);
    },
    cancelToken: new CancelToken((c) => {
      callback?.cancelToken?.(c);
    }),
    data: formData,
  };

  try {
    const result = await axios(config);
    return {
      code: FileResponseCode.S_OK,
      data: { res: result },
    };
  } catch (e: any) {
    return { code: FileResponseCode.S_ERROR, data: { res: { status: 400 } }, message: e.message };
  }
}

/**
 *
 * @param name
 * @param key  fullPath,例如'data-lib-dev/03/11/14/02/新文件夹4/405902f7e681eaaddf0f24848cf721c4/743c6503959f752b162c36fd4032b836.xlsx'
 */
export async function downLoadFile(name: string, key: string, file?: File) {
  if (!!file) {
    try {
      const url = window.URL.createObjectURL(file);
      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = url;
      link.download = name;
      link.click();
      window.URL.revokeObjectURL(url);
    } catch (e) {}
    return;
  }
  return getObjectUrlMustRelease(key)
    .then((objectUrl) => {
      try {
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = objectUrl;
        link.download = name;
        link.click();
      } catch (e) {}
      objectUrl && window.URL.revokeObjectURL(objectUrl);
    })
    .catch((e: any) => console.warn('getObjectUrlMustRelease err: ', key, e));
}

export async function getSts() {
  return request<{
    code: number;
    data: {
      AccessKeyId: string;
      AccessKeySecret: string;
      Expiration: string;
      SecurityToken: string;
    };
    msg: string;
  }>(`/sts/file-service`, {
    method: 'POST',
  });
}

/**
 * @description 只读权限sts, 没有上传文件权限
 * @param params
 */
export async function getReadOnlySts(params: { signature: string }) {
  return request<{
    code: number;
    data: {
      AccessKeyId: string;
      AccessKeySecret: string;
      Expiration: string;
      SecurityToken: string;
    };
    msg: string;
  }>(`/sts//read-service`, {
    method: 'POST',
    data: params,
  });
}

/**
 * @description 为每一个需要上次的文件签名获取上传需要的表单字段
 * @param params keys 文件keys 例如：{oids: ['data-lib-dev/1/2/3/1.png']}
 */
export async function getUploadMetaDatas(params: { oids: string[] }) {
  return request<{
    code: number;
    data: {
      postURL: string;
      formData: Record<string, string>;
    }[];
    msg: string;
  }>(`/signature/api/get_post_metadata`, {
    method: 'POST',
    data: params,
  });
}

/**
 * @description 为每一个需要访问的文件，获得签名后的 url
 * @param params
 */

export async function getSignatureUrls(params: { oids: string[] }) {
  return request<{
    code: number;
    data: {
      oids: string[];
    }[];
    msg: string;
  }>(`/signature/api/get_signed_url`, {
    method: 'POST',
    data: params,
  });
}

export async function getSingleSheetData(
  params: {
    fid: string;
    url: string;
  },
  options?: Record<string, any>,
) {
  const { fid, url } = params;
  return request<DtlComm.Result<undefined>>(`${url}/download/${fid}.json`, {
    method: 'GET',
    params: { ...params },
    ...(options || {}),
  });
}

export async function getTransSheet(
  params: {
    fid: string;
  },
  options?: Record<string, any>,
) {
  const { fid } = params;
  return request<DtlComm.Result<undefined>>(`/grid_handle/download/${fid}.json`, {
    method: 'GET',
    params: { fid },
    ...(options || {}),
  });
}

export async function updateSingleSheet(
  params: {
    wbVersion: string;
    wbId: string;
    data: string;
  },
  options?: Record<string, any>,
) {
  return request<DtlComm.Result<undefined>>(`/grid_handle/saveChanges`, {
    method: 'POST',
    data: params,
    ...(options || {}),
  });
}

export async function pullGridSum(
  params: {
    wbId: string;
  },
  options?: Record<string, any>,
) {
  return request<DtlComm.Result<undefined>>('/grid_handle/pullGridSum', {
    method: 'POST',
    data: params,
    ...(options || {}),
  });
}

export async function getFileUrlForXlsx(
  params: {
    wbId: string;
  },
  options?: Record<string, any>,
) {
  return request<DtlComm.Result<undefined>>('/grid_handle/export', {
    method: 'POST',
    data: params,
    ...(options || {}),
  });
}

export async function CorrelationUploadFile(
  file: File,
  taskId: string,
  userId: string,
  zoneName: string,
  dir: string = '',
) {
  return uploadNormalFile(file, taskId, userId, zoneName, dir);
}

export async function readRemoteFile(body: { url: string }, options: Record<string, any> = {}) {
  const rs = await request(body.url + `?_r=${new Date().getTime()}`, {
    method: 'GET',
    responseType: 'blob',
    ...(options || {}),
  });
  const blob = new Blob([rs]);
  const reader = new FileReader();
  const waitRead = new Promise((rs1) => {
    reader.onload = (e) => {
      rs1(reader.result);
    };
  });
  reader.readAsText(blob, '');
  return waitRead;
}

export async function readRemoteFileOss(body: { key: string }) {
  const buffer = await getOssFileBuffer(body.key);
  const blob = new Blob([buffer]);
  const reader = new FileReader();
  const waitRead = new Promise((rs1) => {
    reader.onload = (e) => {
      rs1(reader.result);
    };
  });
  reader.readAsText(blob, '');
  return waitRead;
}

export async function readAlpFromUrl(body: { url: string }, options: Record<string, any> = {}) {
  const rs = await request(body.url + `?_r=${new Date().getTime()}`, {
    method: 'GET',
    responseType: 'arrayBuffer',
    ...(options || {}),
  });
  return fromAlpFile(rs);
}

export async function readAlpFromUrlOss(body: { key: string }) {
  const buffer = await getOssFileBuffer(body.key);
  return fromAlpFile(buffer);
}

export async function readSoundCheckDatFromUrlOss(body: { key: string }) {
  const buffer = await getOssFileBuffer(body.key);
  return readFromSoundCheckDat(buffer);
}

export async function updateFile(file: File, url: string) {
  const result = await uploadFile({
    file,
    filename: url.slice(url.lastIndexOf('/')),
    path: url.slice(0, url.lastIndexOf('/')),
    auth_token: localStorage.getItem('x-auth-token') as string,
  });

  return {
    code: result && result.code === 1 ? 0 : 1,
  };
}

function formatOssKey(url: string): string {
  if (url.toLowerCase().indexOf('http') > -1) {
    const arr = url.split('/');
    if (arr.length >= 4) {
      url = arr.slice(3).join('/');
    }
  }
  if (url.indexOf('file_download/') === 0) {
    url = url.slice(14);
  }
  return url;
}

export async function getOssFileBufferUmiRequest(
  urlOrKey: string,
  options: Record<string, any> = {},
): Promise<ArrayBuffer> {
  const key = formatOssKey(urlOrKey);
  const fullUrl = await getFullUrl(key);
  return await request(fullUrl, {
    method: 'GET',
    responseType: 'arrayBuffer',
    ...(options || {}),
  });
}

export async function getOssFileBuffer(
  urlOrKey: string,
  options: Record<string, any> = {},
): Promise<ArrayBuffer> {
  const key = formatOssKey(urlOrKey);
  const fullUrl = await getFullUrl(key);
  const { CancelToken } = axios;
  const config: AxiosRequestConfig = {
    url: fullUrl,
    method: 'GET',
    responseType: 'arraybuffer',
    onDownloadProgress: function (progressEvent: any) {},
    cancelToken: new CancelToken((c) => {}),
  };
  const { data, status, statusText } = await axios(config);
  if (status === 200) {
    return data;
  } else {
    throw new Error(status + ':' + statusText);
  }
}

export async function getObjectUrlMustRelease(urlOrKey: string): Promise<string> {
  const buf = await getOssFileBuffer(urlOrKey);
  const newBlob = new Blob([buf]);
  return window.URL.createObjectURL(newBlob);
}

export const getFullUrl = async (key: any) => {
  let src = key;
  if (src && typeof src === 'string' && !src.startsWith('http')) {
    src = src.trim();
    if (src.indexOf('/file_download/') === 0) {
      src = src.slice(15);
    }
    src = src.replace(/^\/+$/g, '');
    // src = DOWNLOAD_PREFIX_PATH + '/' + src;

    const rs = await dtlStatus.syncGetOrAdd<{ code: number; data: any }>(
      { oids: [src] },
      getSignatureUrls,
    );

    if (rs?.code === Err.OK[0]) {
      src = rs?.data[0];

      if (src.startsWith('http://192.168.2.235:9001/datalib-dev')) {
        src = src.substring('http://192.168.2.235:9001'.length);
      }

      src = src.replace('-internal.aliyuncs.com', '.aliyuncs.com');
      if (isDev()) {
        const idx = src.indexOf('.aliyuncs.com/data-lib-dev/');
        if (idx > -1) {
          src = src.substring(idx + 13);
        }
      }
    } else {
      src = '';
    }
  }
  return src;
};
