const data = {
  'task.data.allData.tab': 'All Data',
  'task.data.recentData.tab': 'Recent',
  'task.data.table.name': 'Name',
  'task.data.table.lastOpenTime': 'Date Last Opened',
  'task.data.table.size': 'Size',
  'task.data.table.option': 'Operations',
  'task.data.newDir.placeholder': 'Please enter a name',
};

const desc = {
  'task.desc.taskNameInput.placeholder': 'Task Name',
  'task.desc.status.title': 'Status',
  'task.desc.status.wait': 'To be started',
  'task.desc.status.running': 'Ongoing',
  'task.desc.status.finish': 'Completed',
  'task.desc.creator.title': 'Creator',
  'task.desc.taskType.changeBtn': 'Change',
  'task.desc.taskType.changeModal.title': 'Change task type',
  'task.desc.taskType.changeModal.tip':
    'Personal tasks can be switched to enterprise task types to improve collaboration efficiency!',
  'task.desc.taskType.confirm.title': 'Are you sure to switch enterprise tasks?',
  'task.desc.taskType.confirm.tip':
    'After switching enterprise tasks, it is not possible to switch back to personal tasks. Please operate with caution!',
  'task.desc.taskType.change.ok': 'Successfully switched enterprise tasks',
  'task.desc.taskType.change.fail': 'Switching enterprise tasks failed',
  'task.desc.description.title': 'Description',
  'task.desc.description.defaultDesc':
    'Briefly describes the purpose, method, content, experimental environment, and steps of the experiment.',
  'task.desc.label.title': 'Tag',
  'task.desc.labelInput.placeholder': 'Please enter a tag name',
  'task.desc.label.selectColor': 'Choose color:',
  'task.desc.attachment.title': 'Attachment',
  'task.desc.attachment.download.tip': 'Please download to preview',
  'task.desc.tagTemp': 'Tag template',
};

const msg = {
  'task.msg.input.placeholder': 'Please enter a message',
  'task.msg.reply': 'Reply:',
  'task.msg.allMember': 'all',
};

const member = {
  'task.member.member.tab': 'Members',
  'task.member.invite.tab': 'Invite',
  'task.member.searchMember.placeholder': 'Search Member',
};

export default {
  'pages.taskDetail.data.tab': 'Data',
  'pages.taskDetail.desc.tab': 'Details',
  'pages.taskDetail.msg.tab': 'Message',
  'pages.taskDetail.member.tab': 'Member',
  ...data,
  ...desc,
  ...msg,
  ...member,
};
