import { createIntl, createIntlCache, getLocale } from 'umi';
import US from './en-US';
import CN from './zh-CN';

const cache = createIntlCache();
const intl = createIntl(
  {
    locale: getLocale(),
    messages: getLocale() === 'en-US' ? US : CN,
  },
  cache,
);

const t = (id: string, defaultMsg?: string) => {
  return intl.formatMessage({ id, defaultMessage: defaultMsg });
};

export default t;
