import { dtlOnlyOfficeUpdateInfo } from '@/services/datalib/api/onlyoffice';
import {
  getFileDir,
  getFileExtension,
  getFileType,
} from '@/services/datalib/ctrl/onlyOffice/fileUtility';
import { doErrResponse, doResponse } from '@/services/datalib/ctrl/util';
import { Err } from '@/services/datalib/enum';
import moment from 'moment';

export async function updateOnlyOfficeInfo(
  body: DtlOnlineFile.OnlyOfficeUpdateInfoParam,
): Promise<DtlComm.Result<DtlOnlineFile.OnlyOfficeUpdateInfoResult | undefined>> {
  const response = await dtlOnlyOfficeUpdateInfo(body);
  return doResponse(response);
}

const noop = undefined;

function getDefaultConfig() {
  return {
    type: 'desktop', // 'desktop or mobile or embedded',
    width: '100%', //'100% by default',
    height: '100%', //'100% by default',
    documentType: 'cell', //'word' | 'cell' | 'slide',// deprecate 'text' | 'spreadsheet' | 'presentation',
    token: '', //<string> encrypted signature
    document: {
      // title: '', //'document title',
      // url: '', //'document url'
      // fileType: 'xlsx', //'document file type',
      // options: <advanced options>,
      // key: `${new Date().getTime().toString()}_${Math.round(Math.random() * 10000)}`,
      // vkey: 'vkey',
      // info: {
      //   owner: 'owner name',
      //   folder: 'path to document',
      //   uploaded: '<uploaded date>',
      //   sharingSettings: [
      //     {
      //       user: 'user name',
      //       permissions: '<permissions>',
      //       isLink: false,
      //     },
      //   ],
      //   favorite: true, //<file is favorite>' // true/false/undefined (undefined - don't show fav. button)
      // },
      permissions: {
        edit: true, // <can edit>, // default = true
        download: true, //<can download>, // default = true
        reader: true, //<can view in readable mode>,
        review: false, //<can review>, // default = edit
        print: false, //<can print>, // default = true
        comment: false, //<can comment in view mode> // default = edit,
        modifyFilter: true, //<can add, remove and save filter in the spreadsheet> // default = true
        modifyContentControl: false, // <can modify content controls in documenteditor> // default = true
        fillForms: false, //<can edit forms in view mode> // default = edit || review,
        copy: true, // <can copy data> // default = true,
        editCommentAuthorOnly: false, // <can edit your own comments only> // default = false
        deleteCommentAuthorOnly: false, //<can delete your own comments only> // default = false,
        // reviewGroups: ["Group1", ""] // current user can accept/reject review changes made by users from Group1 and users without a group. [] - use groups, but can't change any group's changes
        // commentGroups: { // {} - use groups, but can't view/edit/delete any group's comments
        //   view: ['Group1', ''], // current user can view comments made by users from Group1 and users without a group.
        //   edit: ['Group1', ''], // current user can edit comments made by users from Group1 and users without a group.
        //   remove: ['Group1', ''], // current user can remove comments made by users from Group1 and users without a group.
        // },
        protect: false, //<can protect document> // default = true. show/hide protect tab or protect buttons
      },
    },
    editorConfig: {
      // actionLink: { // open file and scroll to data, used with onMakeActionLink or the onRequestSendNotify event
      //   action: {
      //     type: "bookmark", // or type="comment"
      //       data: <bookmark name> // or comment id
      //   }
      // },
      mode: 'edit', //'view or edit',
      lang: 'zh', //<language code>,
      // location: '', //<location>,
      // canCoAuthoring: <can coauthoring documents>,
      // canBackToFolder: <can return to folder> - deprecated. use "customization.goback" parameter,
      createUrl: '', //'create document url',
      // sharingSettingsUrl: '', //'document sharing settings url',
      fileChoiceUrl: '', //'source url', // for mail merge or image from storage
      // callbackUrl: '', //<url for connection between sdk and portal>,
      // mergeFolderUrl: '', //'folder for saving merged file', // must be deprecated, use saveAsUrl instead
      // saveAsUrl: '', //'folder for saving files'
      // licenseUrl: '', // <url for license>,
      // customerId: '', // <customer id>,
      region: 'zh-cn', //<regional settings> // can be 'en-us' or lang code

      // user: {
      //   id: '', //'user id',
      //   name: '', //'user name',
      //   group: '', //'group name', // for customization.reviewPermissions parameter
      // },
      // recent: [
      //   {
      //     title: '', //'document title',
      //     url: '', //'document url',
      //     folder: '', //'path to document',
      //   },
      // ],
      // templates: [
      //   {
      //     title: '', //'template name', // name - is deprecated
      //     image: '', //'template icon url',
      //     url: '', //http://...',
      //   },
      // ],
      customization: {
        // logo: {
        //   image: '', //url,
        //   imageDark: '', //url, // logo for dark theme
        //   imageEmbedded: '', //url, // deprecated, use image instead
        //   url: '', //http://...
        // },
        // customer: {
        //   name: '', //'SuperPuper',
        //   address: '', //'New-York, 125f-25',
        //   mail: '', //'support@gmail.com',
        //   www: '', //'www.superpuper.com',
        //   info: '', //'Some info',
        //   logo: '',
        //   logoDark: '', // logo for dark theme
        // },
        about: false,
        feedback: false,
        // feedback: {
        //   visible: false,
        //   url: '', // http://...
        // },
        // goback: {
        //   url: '', //'http://...',
        //   text: '', //'Go to London',
        //   blank: true,
        //   requestClose: false, // if true - goback send onRequestClose event instead opening url
        // },
        // reviewPermissions: {
        //   'Group1': ['Group2'], // users from Group1 can accept/reject review changes made by users from Group2
        //   'Group2': ['Group1', 'Group2'], // users from Group2 can accept/reject review changes made by users from Group1 and Group2
        //   'Group3': [''], // users from Group3 can accept/reject review changes made by users without a group
        // },
        anonymous: {
          // set name for anonymous user
          request: false, //bool (default: true), // enable set name
          label: '', //string (default: "Guest") // postfix for user name
        },
        // review: {
        //   hideReviewDisplay: false, // hide button Review mode
        //   hoverMode: false, // true - show review balloons on mouse move, not on click on text
        //   showReviewChanges: false,
        //   reviewDisplay: 'original', // original for viewer, markup for editor
        //   trackChanges: undefined, // true/false - open editor with track changes mode on/off, defines if the document is opened in the review editing mode (true) or not (false) regardless of the document.permissions.review parameter (the review mode is changed only for the current user). If the parameter is undefined, the document.permissions.review value is used (for all the document users),
        // },
        chat: false, //	Defines if the Chat menu button is displayed or hidden. Please note that in case you hide the Chat button, the corresponding chat functionality will also be disabled. The default value is true.
        comments: false, //Defines if the Comments menu button is displayed or hidden. Please note that in case you hide the Comments button, the corresponding commenting functionality will be available for viewing only, adding and editing comments will be unavailable. The default value is true.
        // zoom: 100,//Defines the document display zoom value measured in percent. Can take values larger than 0. For text documents and presentations it is possible to set this parameter to -1 (fitting the document to page option) or to -2 (fitting the document page width to the editor page). The default value is 100.
        compactToolbar: false, //Defines if the top toolbar type displayed is full (false) or compact (true). The default value is false.
        // leftMenu: true,
        // rightMenu: true,
        hideRightMenu: true, // hide or show right panel on first loading
        toolbar: true,
        // statusBar: true,
        // autosave: true,//Defines if the Autosave menu option is enabled or disabled. If set to false, only Strict co-editing mode can be selected, as Fast does not work without autosave. The default value is true, Please note that in case this setting is changed in the editor interface, it will be stored in the browser local storage and will overwrite any values sent as the editorConfig.customization.autosave parameter.
        forcesave: false, //	Adds the request for the file force saving to the callback handler when saving the document within the document editing service (e.g. clicking the Save button, etc.). The default value is false. it will be stored in the browser local storage
        // commentAuthorOnly: false, // must be deprecated. use permissions.editCommentAuthorOnly and permissions.deleteCommentAuthorOnly instead
        // showReviewChanges: false, // must be deprecated. use customization.review.showReviewChanges instead
        help: false, //	Defines if the Help menu button is displayed or hidden. The default value is true.
        compactHeader: true, //	Defines if the additional action buttons are displayed in the upper part of the editor window header next to the logo (false) or in the toolbar (true) making the header more compact. The default value is false.
        toolbarNoTabs: true, //Defines if the top toolbar tabs are distinctly displayed (false) or only highlighted to see which one is selected (true). The default value is false.
        toolbarHideFileName: true, //Defines if the document title is visible on the top toolbar (false) or hidden (true). The default value is false.
        // reviewDisplay: 'original', // must be deprecated. use customization.review.reviewDisplay instead
        // spellcheck: true, //	Defines if the spell checker is automatically switched on or off when the editor is loaded. Spell checker will only be available for the document editor and the presentation editor. The default value is true, it will be stored in the browser local storage
        compatibleFeatures: true, //Defines the use of functionality only compatible with the OOXML format. For example, do not use comments on the entire document. The default value is false.
        // unit: 'cm', // cm, pt, inch,
        // mentionShare: true,// customize tooltip for mention,Defines the hint that describes the event after mentions in a comment. If true, a hint indicates that the user will receive a notification and access to the document. If false, a hint indicates that the user will receive only a notification of the mention. The default value is true.
        // macros: true, // can run macros in document, 	Defines if document macros will be run. The default value is true.
        plugins: false, // can run plugins in document, Defines if plugins will be launched and available. The default value is true.
        // macrosMode: 'warn', // warn about automatic macros, 'enable', 'disable', 'warn',
        // trackChanges: undefined, // true/false - open editor with track changes mode on/off,  // must be deprecated. use customization.review.trackChanges instead
        // hideRulers: false, // hide or show rulers on first loading (presentation or document editor)
        // hideNotes: false, // hide or show notes panel on first loading (presentation editor)
        uiTheme: 'theme-light', // set interface theme: id or default-dark/default-light/theme-dark
        submitForm: false,
      },
      coEditing: {
        mode: 'fast', // <coauthoring mode>, 'fast' or 'strict'. if 'fast' and 'customization.autosave'=false -> set 'customization.autosave'=true
        change: false, // can change co-authoring mode
      },
      plugins: {
        // pluginsData: [],
      },
      // plugins: {
      //   autostart: ['asc.{FFE1F462-1EA2-4391-990D-4CC84940B754}'],
      //   pluginsData: [
      //     'helloworld/config.json',
      //     'chess/config.json',
      //     'speech/config.json',
      //     'clipart/config.json',
      //   ],
      // },
      // wopi: { // only for wopi
      //   FileNameMaxLength: 250, // max filename length for rename, 250 by default
      // },
      // embedded: {
      //   "embedUrl": "https://example.com/embedded?doc=exampledocument1.docx",
      //   "fullscreenUrl": "https://example.com/embedded?doc=exampledocument1.docx#fullscreen",
      //   "saveUrl": "https://example.com/download?doc=exampledocument1.docx",
      //   "shareUrl": "https://example.com/view?doc=exampledocument1.docx",
      //   "toolbarDocked": "top"
      // },
    },
    events: {
      onAppReady: noop, // <application ready callback>,
      onDocumentStateChange: noop, // <document state changed callback>
      onDocumentReady: noop, // <document ready callback>
      onRequestEditRights: noop, // <request rights for switching from view to edit>,
      onRequestHistory: noop, // <request version history>,// must call refreshHistory method
      onRequestHistoryData: noop, // <request version data>,// must call setHistoryData method
      onRequestRestore: noop, //<try to restore selected version>,
      onRequestHistoryClose: noop, // <request closing history>,
      onError: noop, // <error callback>,
      onWarning: noop, // <warning callback>,
      onInfo: noop, //<document open callback>,// send view or edit mode
      onOutdatedVersion: noop, // <outdated version callback>,// send when  previous version is opened
      onDownloadAs: noop, // <download as callback>,// send url of downloaded file as a response for downloadAs method
      onRequestSaveAs: noop, // <try to save copy of the document>,
      onCollaborativeChanges: noop, // <co-editing changes callback>,// send when other user co-edit document
      onRequestRename: noop, //<try to rename document>,
      onMetaChange: noop, // // send when meta information changed
      onRequestClose: noop, //<request close editor>,
      onMakeActionLink: noop, // <request link to document with bookmark, comment...>,// must call setActionLink method
      onRequestUsers: noop, //<request users list for mentions>,// must call setUsers method
      onRequestSendNotify: noop, ////send when user is mentioned in a comment,
      onRequestInsertImage: noop, //<try to insert image>,// must call insertImage method
      onRequestCompareFile: noop, //<request file to compare>,// must call setRevisedFile method
      onRequestSharingSettings: noop, // <request sharing settings>,// must call setSharingSettings method
      onRequestCreateNew: noop, //<try to create document>,
    },
  };
}

export async function getOnlyOfficeConfig(
  userId: string,
  userName: string,
  taskId: string,
  taskFile: any,
  mode: string, //view | edit
  options: any,
  token: string,
) {
  // console.log({ userId, userName, taskId, taskFile, mode, options, token });
  const fileId = taskFile.id;
  const { code, data, message } = await updateOnlyOfficeInfo({ taskId, fileId });
  if (code !== Err.OK[0] || !data) {
    return doErrResponse(code, message);
  }
  const onlyOffice = data.onlyOffice;
  const server = data.server;
  const fileName = taskFile.name;

  const config = Object.assign(getDefaultConfig(), options);
  config.server = server;
  config.documentType = getFileType(fileName);
  config.token = token;
  config.document.title = taskFile.name;
  config.document.url =
    config.server.fileDownloadUrl + '/' + onlyOffice.cb[onlyOffice.cb.length - 1].url;
  config.url = onlyOffice.cb[onlyOffice.cb.length - 1].url;
  config.document.fileType = getFileExtension(config.document.url, true);
  {
    const type =
      /^(?:(xls|xlsx|ods|csv|xlst|xlsy|gsheet|xlsm|xlt|xltm|xltx|fods|ots)|(pps|ppsx|ppt|pptx|odp|pptt|ppty|gslides|pot|potm|potx|ppsm|pptm|fodp|otp)|(doc|docx|doct|odt|gdoc|txt|rtf|pdf|mht|htm|html|epub|djvu|xps|oxps|docm|dot|dotm|dotx|fodt|ott|fb2|xml|oform|docxf))$/.exec(
        config.document.fileType,
      );
    if (!type) {
      console.warn(
        'The "document.fileType" parameter for the config object is invalid. Please correct it.',
      );
      return {
        code: Err.FILE_OPEN_FAILED[0],
        data: undefined,
        message: Err.FILE_OPEN_FAILED[1],
      };
    }
  }
  // config.document.key = onlyOffice.key;
  config.document.key = onlyOffice.cb[onlyOffice.cb.length - 1].key;
  config.document.info = {
    owner: userName,
    folder: getFileDir(taskFile.path),
    uploaded: moment.unix(taskFile.createTime / 1000000000).format('YYYY/MM/DD HH:mm'),
    favorite: undefined, //<file is favorite>' // true/false/undefined (undefined - don't show fav. button)
  };
  config.editorConfig.mode = mode;
  config.editorConfig.callbackUrl = `${config.server.callbackUrl}?fid=${fileId}&pid=${taskId}&uid=${userId}`;
  config.editorConfig.embedded = null;
  config.editorConfig.user = {
    id: userId, //'user id',
    name: userName, //'user name',
    group: '', //'group name', // for customization.reviewPermissions parameter
  };
  config.history = getHistory(config, onlyOffice);
  return {
    code: Err.OK[0],
    data: config,
    message: Err.OK[1],
  };
}

function getHistory(config: any, onlyOffice: DtlBean.OnlyOffice) {
  if (!onlyOffice || !onlyOffice.cb || onlyOffice.cb.length === 0) {
    return { history: null, historyData: null };
  }
  const cbs = onlyOffice.cb;
  const history = [];
  const historyData: any[] = [];
  let versionNum = 1;
  for (let i = 0; i < cbs.length; i++) {
    const cb = cbs[i];
    if (!cb.history || !cb.history.changes || cb.history.changes.length === 0) continue;
    const serverVersion = cb.history.serverVersion;
    const changes = cb.history.changes;
    const key = cb.key;
    const version = versionNum++;
    const created = changes[0].created;
    const user = changes[0].user;
    const url = config.server.fileDownloadUrl + '/' + cb.url;
    let previous = undefined;
    if (historyData.length > 0) {
      previous = {
        key: historyData[historyData.length - 1].key,
        url: historyData[historyData.length - 1].url,
      };
    }
    let changesUrl = undefined;
    if (cb.changesurl) {
      changesUrl = config.server.fileDownloadUrl + '/' + cb.changesurl;
    }
    history.push({
      serverVersion,
      changes,
      key,
      version,
      created,
      user,
    });
    historyData.push({
      version,
      key,
      url,
      previous,
      changesUrl,
    });
  }
  return { history, historyData };
}
