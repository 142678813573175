import API_VERSION from '@/services/datalib/api/config';
import request from '@/utils/request';

export async function dtlEnterpriseCreate(body: DtlEnterprise.CreateParam) {
  return request<DtlComm.ResultV<DtlEnterprise.EnterpriseResult>>(
    `${API_VERSION}/api/enterprise/create`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlEnterpriseInfo() {
  return request<DtlComm.ResultV<DtlEnterprise.EnterpriseResult>>(
    `${API_VERSION}/api/enterprise/info`,
    {
      method: 'POST',
      data: {},
    },
  );
}

export async function dtlEnterpriseBaseInfos(body: DtlEnterprise.BaseInfosParam) {
  return request<DtlComm.ResultV<DtlEnterprise.BaseInfosResult>>(
    `${API_VERSION}/api/enterprise/baseInfos`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlEnterpriseAddDept(body: DtlEnterprise.AddDeptParam) {
  return request<DtlComm.ResultV<DtlEnterprise.DeptResult>>(
    `${API_VERSION}/api/enterprise/addDept`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlEnterpriseRenameDept(body: DtlEnterprise.RenameDeptParam) {
  return request<DtlComm.ResultV<DtlEnterprise.DeptResult>>(
    `${API_VERSION}/api/enterprise/renameDept`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlEnterpriseAddUser(body: DtlEnterprise.AddUserParam) {
  return request<DtlComm.ResultV<DtlEnterprise.EnterpriseResult>>(
    `${API_VERSION}/api/enterprise/addUser`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlEnterpriseUpdateUser(body: DtlEnterprise.UpdateUserParam) {
  return request<DtlComm.ResultV<DtlEnterprise.EnterpriseResult>>(
    `${API_VERSION}/api/enterprise/updateUser`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlEnterpriseUpdate(body: DtlEnterprise.UpdateParam) {
  return request<DtlComm.ResultV<DtlEnterprise.EnterpriseResult>>(
    `${API_VERSION}/api/enterprise/update`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlEnterpriseUpdateUserExt(body: DtlEnterprise.UpdateUserExtParam) {
  return request<DtlComm.ResultV<DtlEnterprise.EnterpriseResult>>(
    `${API_VERSION}/api/enterprise/updateUserExt`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlEnterpriseDepartUser(body: DtlEnterprise.DepartUserParam) {
  return request<DtlComm.ResultV<DtlEnterprise.EnterpriseResult>>(
    `${API_VERSION}/api/enterprise/departUser`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlEnterpriseGetHandoverTasks(body: DtlEnterprise.EnterpriseIdParam) {
  return request<DtlComm.ResultV<DtlEnterprise.GetHandoverTasksResult>>(
    `${API_VERSION}/api/enterprise/getHandoverTasks`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlEnterpriseRessignCreator(body: DtlEnterprise.RessignCreatorParam) {
  return request<DtlComm.ResultV<DtlEnterprise.EnterpriseResult>>(
    `${API_VERSION}/api/enterprise/ressignCreator`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlEnterpriseDelUser(body: DtlEnterprise.DelUserParam) {
  return request<DtlComm.ResultV<DtlEnterprise.EnterpriseResult>>(
    `${API_VERSION}/api/enterprise/delUser`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlEnterpriseRecoverUser(body: DtlEnterprise.RecoverUserParam) {
  return request<DtlComm.ResultV<DtlEnterprise.EnterpriseResult>>(
    `${API_VERSION}/api/enterprise/recoverUser`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlEnterpriseDelDept(body: DtlEnterprise.DelDeptParam) {
  return request<DtlComm.ResultV<DtlEnterprise.EnterpriseResult>>(
    `${API_VERSION}/api/enterprise/delDept`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlEnterpriseDismiss(body: DtlEnterprise.DismissParam) {
  return request<DtlComm.ResultV<DtlEnterprise.EnterpriseResult>>(
    `${API_VERSION}/api/enterprise/dismiss`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlEnterpriseConvertTask(body: DtlEnterprise.ConvertTaskParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/enterprise/convertTask`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}
