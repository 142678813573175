export default {
  'Season.CD.tab': 'Default cooling attenuation coefficient (CD)',
  'Season.SEER.tab': 'Cooling season energy efficiency (SEER)',
  'Season.HSPF.tab': 'Cooling season energy efficiency (HSPF)',
  'Season.CD.D': 'D Operating efficiency (W/W)',
  'Season.CD.DReal': 'D Measured capacity under working conditions (Btu)',
  'Season.CD.C': 'C Operating efficiency (W/W)',
  'Season.CD.CReal': 'C Measured capacity under working conditions (Btu)',
  'Season.CD.time': 'Duration(Hour)',
  'Season.SEER.workA': 'North America A condition',
  'Season.SEER.workB': 'North America B condition',
  'Season.SEER.EV': 'North American refrigeration I',
  'Season.SEER.EV.desc': '(EV center frequency)',
  'Season.SEER.B1': 'North American refrigeration II',
  'Season.SEER.B1.desc': '(B1 Rated minimum frequency)',
  'Season.SEER.F1': 'North American refrigeration III',
  'Season.SEER.F1.desc': '(F1 Minimum cooling frequency)',
  'Season.common.cool': 'Refrigeration capacity (W)',
  'Season.common.coolPower': 'Refrigeration power (W)',
  'Season.HSPF.H1N': 'North American rated heating',
  'Season.HSPF.H1N.desc': '(H1N maximum frequency)',
  'Season.HSPF.H01': 'North American heating I',
  'Season.HSPF.H01.desc': '(H01 maximum frequency)',
  'Season.HSPF.H22': 'North American heating II',
  'Season.HSPF.H22.desc': '(H22 maximum frequency)',
  'Season.HSPF.H2V': 'North American refrigeration II',
  'Season.HSPF.H2V.desc': '(H2V maximum frequency)',
  'Season.HSPF.H32': 'North American heating III',
  'Season.HSPF.H32.desc': '(H32 maximum frequency)',
  'Season.HSPF.H11': 'North American rated heating',
  'Season.HSPF.H11.desc': '(H11 maximum frequency)',
  'Season.HSPF.upper': 'Above -15℃',
  'Season.HSPF.lower': 'Below -15℃',
};
