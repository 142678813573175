import { decBufferToStr, fixTwoDimData, getOssFileBuffer, readFile } from '@/services/datalib';
import { getPathExtension, getPathName } from '@/services/datalib/bean/util';
import { read, set_cptable, utils } from 'xlsx';

// @ts-ignore
import Papa from 'papaparse';

// @ts-ignore
import * as cptable from 'xlsx/dist/cpexcel.full.mjs';

set_cptable(cptable);

type TypeXlsxForPreview = {
  SheetNames: string[];
  SheetDetail: { name: string; detail: any; ref: string }[];
};

export async function getGridFromFile(file: File): Promise<TypeXlsxForPreview> {
  const buffer = await readFile(file);
  return readXlsxForPreview(file.name, buffer as ArrayBuffer);
}

export async function getGridFromServer(fileUrl: string): Promise<TypeXlsxForPreview> {
  const buffer = await getOssFileBuffer(fileUrl);
  return readXlsxForPreview(fileUrl, buffer);
}

function getMaxRef(ref: string, maxRow: number, maxCol: number): string {
  let preRef = utils.decode_range(ref);
  if (preRef.e.r >= maxRow && preRef.e.c >= maxCol) {
    return ref;
  }
  if (preRef.e.r > maxRow) {
    maxRow = preRef.e.r;
  }
  if (preRef.e.c > maxCol) {
    maxCol = preRef.e.c;
  }
  return utils.encode_range({
    s: preRef.s,
    e: {
      r: maxRow,
      c: maxCol,
    },
  });
}

export function readCsv(buf: ArrayBuffer): TypeXlsxForPreview {
  console.time('csv->array');
  const rs = Papa.parse(decBufferToStr(buf), {
    transform(v: any) {
      return {
        t: 's',
        v: v,
      };
    },
  });
  console.timeEnd('csv->array');
  if (rs.errors && rs.errors.length > 0) {
    throw Error('Papa.parse error' + JSON.stringify(rs));
  }
  const twoDimData = rs.data as any[][];
  fixTwoDimData(twoDimData);
  const ref = getMaxRef(
    'A1:Z60',
    twoDimData.length - 1,
    twoDimData.reduce((cnt: number, row: any[]) => (cnt > row.length ? cnt : row.length), 0) - 1,
  );
  return {
    SheetNames: ['Sheet1'],
    SheetDetail: [
      {
        name: 'Sheet1',
        detail: {
          name: 'Sheet1',
          data: twoDimData,
          '!ref': ref,
        },
        ref: ref,
      },
    ],
  };
}

async function readXlsxForPreview(
  pathOrUrl: string,
  buf: ArrayBuffer,
): Promise<TypeXlsxForPreview> {
  const ext = getPathExtension(pathOrUrl);
  if (ext === 'csv') {
    return readCsv(buf);
  }
  const workbook = read(buf, {
    type: 'binary',
    dateNF: 'MM/dd/yy HH:mm:ss',
    cellHTML: false,
    cellNF: true,
    dense: true,
  });
  let luckyJson: any;
  if (ext === 'xlsx') {
    const [exportJsonStr, err] = await new Promise((rs) => {
      const worker = new Worker('/luckyExcel-worker.js');
      worker.postMessage(
        {
          fileName: getPathName(pathOrUrl),
          fileBuffer: buf,
        },
        [buf],
      );
      let isGet = false;
      setTimeout(() => {
        if (!isGet) worker.terminate();
        rs([undefined, undefined]);
      }, 8000);
      worker.onmessage = (e) => {
        rs([e.data.exportJsonStr, e.data.err]);
        worker.terminate();
        isGet = true;
      };
    });
    if (exportJsonStr && !err) {
      luckyJson = JSON.parse(exportJsonStr);
    }
  }
  const ret: TypeXlsxForPreview = {
    SheetNames: workbook.SheetNames,
    SheetDetail: [],
  };
  let luckyIndex = 0;
  workbook.SheetNames.forEach((kn: string) => {
    let ref = workbook.Sheets[kn]['!ref'];
    if (!ref) ref = 'A1:Z60';
    let wbDetail: { name: string; detail: any; ref: string } = {
      name: kn,
      detail: undefined,
      ref,
    };
    let sheetV = {
      name: kn,
      // data: undefined,
    };
    let tempsheet = workbook.Sheets[kn];
    let keys = Object.keys(tempsheet);
    for (let i = keys.length - 1; i >= 0; i--) {
      if (keys[i].indexOf('!') != -1) {
        sheetV[keys[i]] = tempsheet[keys[i]];
        delete tempsheet[keys[i]];
      }
    }
    if (Object.keys(tempsheet).length === 0) {
      ref = 'A1:Z60';
    }
    if (luckyJson && luckyJson.sheets && luckyIndex < luckyJson.sheets.length) {
      const luckySheet = luckyJson.sheets[luckyIndex];
      if (luckySheet.config) {
        let config = luckySheet.config;
        config.borderInfo && (sheetV['!border'] = config.borderInfo) && delete config.borderInfo;
        config.merge && delete config.merge;
        sheetV['!config'] = config;
      }
      luckySheet.celldata.forEach((lcell: any) => {
        if (!tempsheet[lcell.r]) tempsheet[lcell.r] = [];
        if (tempsheet[lcell.r][lcell.c]) {
          lcell.v.ct && delete lcell.v.ct;
          lcell.v.f && delete lcell.v.f;
          lcell.v.v && delete lcell.v.v;
          lcell.v.m && delete lcell.v.m;
          Object.assign(tempsheet[lcell.r][lcell.c], lcell.v);
        } else {
          lcell.v.m && delete lcell.v.m;
          if (lcell.v.ct) {
            lcell.v.ct.fa && (lcell.v.z = lcell.v.ct.fa);
            delete lcell.v.ct;
          }
          tempsheet[lcell.r][lcell.c] = lcell.v;
        }
        tempsheet[lcell.r][lcell.c]
          ? Object.assign(tempsheet[lcell.r][lcell.c], lcell.v)
          : (tempsheet[lcell.r][lcell.c] = lcell.v);
      });
      sheetV['showGridLines'] = luckySheet.showGridLines;
      sheetV['defaultColWidth'] = luckySheet.defaultColWidth;
      sheetV['defaultRowHeight'] = luckySheet.defaultRowHeight;
      if (luckySheet.images) {
        sheetV['!images'] = luckySheet.images;
        let maxRow = 0,
          maxCol = 0;
        Object.keys(luckySheet.images).forEach((key) => {
          const img = luckySheet.images[key];
          if (img.toRow > maxRow) maxRow = img.toRow;
          if (img.toCol > maxCol) maxCol = img.toCol;
        });
        ref = getMaxRef(ref, maxRow, maxCol);
      }
      if (luckySheet.charts) {
        sheetV['!charts'] = luckySheet.charts;
      }
      luckyIndex++;
    }
    sheetV['data'] = tempsheet;
    wbDetail.detail = sheetV;
    wbDetail.ref = sheetV['!ref'] = ref;
    ret.SheetDetail.push(wbDetail);
  });
  return ret;
}
