export default {
  'uploadModal.BigTitle': 'Upload File',
  'uploadModal.addFile': 'Select File',
  'uploadModal.addFileByDir': 'Scan Folder',
  'uploadModal.dragUpload.tips': 'Drag and drop files or directories to this scan',
  'uploadModal.table.fileName': 'File Name',
  'uploadModal.table.fileDir': 'File directory',
  'uploadModal.table.fileSize': 'Size',
  'uploadModal.table.status': 'Status',
  'uploadModal.table.option': 'Operate',
  'uploadModal.table.empty': 'Empty',
  'uploadModal.fileTotalNumber': 'Number of files',
  'uploadModal.fileTotalSize': 'Total Size',
  'uploadModal.totalProgress': 'Progress',
  'uploadModal.table.option.reUpload': 'Reupload',
  'uploadModal.table.option.remove': 'Remove',
  'uploadModal.status.wait': 'To be uploaded',
  'uploadModal.status.cover': 'Will overwrite the same name',
  'uploadModal.status.finish': 'Uploaded',
  'uploadModal.status.fail': 'Upload failed',
  'uploadModal.status.scanFail': 'Scan failed',
  'uploadModal.status.uploading': 'Uploading',
  'uploadModal.status.cancel': 'Canceled',
  'uploadModal.closeBtn': 'Close',
  'uploadModal.uploadBtn': 'Upload',
  'uploadModal.fileNameInput.placeholder': 'Enter file name',
  'uploadModal.normalToSetting.notAllow':
    'Common file cannot be stored in the configuration folder',
};
