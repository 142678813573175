import { Msg } from '@/services/datalib/bean/Msg';
import { OnlyOffice } from '@/services/datalib/bean/OnlyOffice';
import type { OpenType } from '@/services/datalib/bean/OpenType';
import type { OpenTypes } from '@/services/datalib/bean/OpenTypes';
import { TaskFile } from '@/services/datalib/bean/TaskFile';
import { getPathName } from '@/services/datalib/bean/util';
import { TaskFileType } from '@/services/datalib/enum';

export class TaskFiles {
  files: TaskFile[] = [];
  lotInfo: Record<string, number> = {};

  constructor(taskFiles?: DtlBean.TaskFilesV) {
    if (!taskFiles) return;
    const filesVMap: Record<string, DtlBean.TaskFileV> = {};
    taskFiles.files.forEach((file) => {
      filesVMap[file.id] = file;
    });
    const notExist: DtlBean.FilePath[] = [];
    taskFiles.filePaths.forEach((fp) => {
      const f = filesVMap[fp.id];
      if (f) {
        this.files.push(new TaskFile(f, fp));
      } else {
        notExist.push(fp);
      }
    });
    if (notExist.length > 0) {
      // console.warn(notExist);
    }
  }

  addFile(taskFile: TaskFile) {
    if (this.files.some((f) => f.path === taskFile.path)) return;
    this.files.push(taskFile);
  }

  updateFileName(path: string, filePath: DtlBean.FilePath): TaskFile | undefined {
    const idx = this.files.findIndex((f) => f.path === path);
    if (idx === -1) {
      return undefined;
    }
    const file = this.files[idx];
    file.updateFromFilePath(filePath);
    if (file.type === TaskFileType.TASKFILE_TYPE_DIR) {
      this.files.forEach((f) => {
        if (f.path.indexOf(`${path}/`) === 0) {
          f.updatePath(f.path.replace(path, filePath.path));
        }
      });
    }
    return file;
  }

  moveFiles(param: Record<string, DtlBean.FilePath>): Record<string, DtlBean.TaskFile> {
    const moves: Record<string, DtlBean.TaskFile> = {};
    Object.keys(param).forEach((oldPath) => {
      const index = this.files.findIndex((f) => f.path === oldPath);
      if (index === -1) return;
      const file = this.files[index];
      file.updateFromFilePath(param[oldPath]);
      moves[oldPath] = file.format();
    });
    return moves;
  }

  delTaskFiles(deleted: DtlBean.DelFile[]) {
    deleted.forEach((delFile) => {
      const delPath = delFile.filePaths[0].path;
      this.files = this.files.filter(
        (f) => f.path !== delPath && f.path.indexOf(`${delPath}/`) !== 0,
      );
    });
  }

  updateFileContent(fileId: string, content: string) {
    const idx = this.files.findIndex((f) => f.id === fileId);
    if (idx > -1) {
      this.files[idx].content = content;
    }
  }

  updateFileStatus(fileId: string, status: number) {
    const idx = this.files.findIndex((f) => f.id === fileId);
    if (idx > -1) {
      this.files[idx].status = status;
    }
  }

  addFiles(adds?: TaskFile[], replaced?: TaskFile[]) {
    if (replaced) {
      replaced.forEach((r) => {
        const idx = this.files.findIndex((f) => f.path === r.path);
        if (idx > -1) {
          this.files[idx] = r;
        }
      });
    }
    if (adds) {
      adds.forEach((a) => this.files.push(a));
    }
  }

  formatTree(): DtlBean.TaskFile {
    const pathMap: Record<string, DtlBean.TaskFile> = {};
    this.files.forEach((v) => {
      const { path } = v;
      const paths = path.split('/');
      let curP = '';
      const size = paths.length;
      for (let i = 1; i < size; i += 1) {
        const parentP = curP;
        curP = `${curP}/${paths[i]}`;
        let beanTaskFile: DtlBean.TaskFile = pathMap[curP];
        if (!beanTaskFile) {
          beanTaskFile = new TaskFile().format();
          beanTaskFile.path = curP;
          beanTaskFile.name = getPathName(curP);
          beanTaskFile.type = TaskFileType.TASKFILE_TYPE_DIR;
          beanTaskFile.children = [];
          pathMap[curP] = beanTaskFile;
          if (parentP !== '') {
            // @ts-ignore
            pathMap[parentP].children.push(beanTaskFile);
          }
        }
        if (i + 1 === size) {
          Object.assign(beanTaskFile, v.format());
        }
      }
    });
    const waitMap: Record<string, DtlBean.TaskFile> = {};
    Object.keys(pathMap).forEach((path) => {
      let file = pathMap[path];
      if (file.createTime === 0) {
        if (file.children && file.children.length > 0) {
          let subFile = file.children[0];
          if (subFile.createTime > 0) {
            while (file && file.createTime === 0) {
              file.createTime = subFile.createTime;
              file.creator = subFile.creator;
              file.lastModifyTime = subFile.lastModifyTime;
              file.lastModifier = subFile.lastModifier;
              //file.lastOpenTime = subFile.lastOpenTime;
              delete waitMap[subFile.path];
              subFile = file;
              file = waitMap[file.path];
            }
          } else {
            waitMap[subFile.path] = file;
          }
        }
      }
    });
    return pathMap['/root'];
  }

  getRecentFilesFormat(): DtlBean.TaskFile[] {
    return this.files.map((file) => file.format());
  }

  updateFile(taskFile: TaskFile) {
    const idx = this.files.findIndex((f) => f.path === taskFile.path);
    if (idx === -1) {
      return;
    }
    this.files[idx].copyFrom(taskFile);
  }

  getShowOpenInfo(
    path: string,
    openTypes: OpenTypes,
  ): {
    file?: TaskFile;
    openType?: OpenType;
  } {
    if (!path) return {};
    const idx = this.files.findIndex((f) => f.path === path);
    if (idx === -1) {
      return {};
    }
    const file = this.files[idx];
    if (file.openWay) {
      const arr = file.openWay.split('/');
      const sid = Number(arr[0]);
      const openType = openTypes.getSupportType(sid);
      if (openType) {
        return {
          file,
          openType,
        };
      }
    } else {
      return { file };
    }
    const openType = openTypes.getDefaultType(file.path, file.type);
    if (!openType) {
      return { file };
    }
    return {
      file,
      openType,
    };
  }

  existFile(filePath: string): TaskFile | undefined {
    const index = this.files.findIndex((f) => f.path === filePath);
    if (index > -1) {
      return this.files[index];
    }
    return undefined;
  }

  existFileById(fileId: string): TaskFile | undefined {
    const index = this.files.findIndex((f) => f.id === fileId);
    if (index > -1) {
      return this.files[index];
    }
    return undefined;
  }

  addTaskFileMsg(path: string, msg: DtlBean.Msg): TaskFile | undefined {
    const index = this.files.findIndex((f) => f.path === path);
    if (index > -1) {
      this.files[index].msgs.push(new Msg(msg));
      return this.files[index];
    }
    return undefined;
  }

  delTaskFileMsg(fileId: string, msgId: string): TaskFile | undefined {
    const index = this.files.findIndex((f) => f.id === fileId);
    if (index > -1) {
      const file = this.files[index];
      const idx = file.msgs.findIndex((m) => m.msgId === msgId);
      if (idx > -1) {
        file.msgs.splice(idx, 1);
      }
      return file;
    }
    return undefined;
  }

  updateTaskFileOnlyOffice(fileId: string, onlyOffice: DtlBean.OnlyOffice): TaskFile | undefined {
    const index = this.files.findIndex((f) => f.id === fileId);
    if (index > -1) {
      const file = this.files[index];
      const of = new OnlyOffice(onlyOffice);
      if (of.cb && of.cb.length > 0) {
        file.url = of.cb[of.cb.length - 1].url;
      }
      file.onlyOffice = of;
      return file;
    }
    return undefined;
  }

  updateLotInfo(fileLots: string[] | undefined) {
    const idLot: Record<string, number> = {};
    fileLots?.forEach((fileLot) => {
      const a = fileLot.split(':');
      idLot[a[0]] = Number(a[1]);
    });
    this.lotInfo = idLot;
  }
}
