import addLink from '@/locales/en-US/addLink';
import antd from '@/locales/en-US/antd';
import AG from '@/locales/en-US/apps/AG';
import audioEditor from '@/locales/en-US/apps/audioEditor';
import common from '@/locales/en-US/apps/common';
import correlation from '@/locales/en-US/apps/correlation';
import cpb from '@/locales/en-US/apps/cpb';
import Enthalpy from '@/locales/en-US/apps/Enthalpy';
import EnthalpyDiffLab from '@/locales/en-US/apps/EnthalpyDiffLab';
import grr from '@/locales/en-US/apps/grr';
import Humidity from '@/locales/en-US/apps/Humidity';
import Liquid from '@/locales/en-US/apps/Liquid';
import Refrigerant from '@/locales/en-US/apps/Refrigerant';
import SCIC from '@/locales/en-US/apps/SCIC';
import Season from '@/locales/en-US/apps/Season';
import spc from '@/locales/en-US/apps/spc';
import strain from '@/locales/en-US/apps/strain';
import wav from '@/locales/en-US/apps/wav';
import contacts from '@/locales/en-US/contacts';
import modal from '@/locales/en-US/modal';
import myFile from '@/locales/en-US/myFile';
import normal from '@/locales/en-US/normal';
import settings from '@/locales/en-US/settings';
import DelFileContent from '@/locales/en-US/TaskDetailContent/DelFileContent';
import FileAnalToolSelector from '@/locales/en-US/TaskDetailContent/FileAnalToolSelector';
import FileShortCutPanel from '@/locales/en-US/TaskDetailContent/FileShortCutPanel';
import TaskDetailContent from '@/locales/en-US/TaskDetailContent/TaskDetailContent';
import uploadModal from '@/locales/en-US/TaskDetailContent/uploadModal';
import TaskListContent from '@/locales/en-US/TaskListContent/TaskListContent';
import toolPanel from '@/locales/en-US/toolPanel';
import enterprise from './en-US/enterprise';
import gridlab from './en-US/gridlab';
import login from './en-US/login';
import FeedBackContent from './en-US/TaskDetailContent/FeedBackContent';

export default {
  ...login,
  ...settings,
  ...contacts,
  ...addLink,
  ...antd,
  ...modal,
  ...myFile,
  ...normal,
  ...toolPanel,
  ...TaskListContent,
  ...DelFileContent,
  ...FeedBackContent,
  ...FileAnalToolSelector,
  ...FileShortCutPanel,
  ...TaskDetailContent,
  ...uploadModal,
  ...audioEditor,
  ...common,
  ...correlation,
  ...cpb,
  ...Enthalpy,
  ...grr,
  ...Humidity,
  ...Liquid,
  ...Refrigerant,
  ...Season,
  ...wav,
  ...AG,
  ...EnthalpyDiffLab,
  ...SCIC,
  ...spc,
  ...strain,
  ...gridlab,
  ...enterprise,
};
