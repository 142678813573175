import { doInitDtlStatus, doResponse } from '@/services/datalib';
import { dtlTaskInviteGenerate, dtlTaskInviteVerify } from '@/services/datalib/api/taskInvite';

/*
 * 用于生成邀请链接
 * */
export async function taskInviteGenerate(
  body: DtlTaskInvite.GenerateParam,
): Promise<DtlComm.Result<DtlBean.TaskInvite | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskInviteGenerate(body);
  return doResponse(response);
}

/*
 * 用于使用邀请链接
 * */
export async function taskInviteVerify(
  body: DtlTaskInvite.VerifyParam,
): Promise<DtlComm.Result<DtlTaskInvite.VerifyResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskInviteVerify(body);
  return doResponse(response);
}
