import { MsgList } from '@/utils/comet/msgList';
import CometTopic from './cometTopic';
import cometMsg from './comet_pb';

const timeoutMs: number = 60000;
const timeoutInterval: number = (timeoutMs * 4) / 10;

export enum CometCmd {
  CmdActiveLogIn = 1,
  CmdAutoLogIn = 2,
  CmdAutoReLogIn = 3,
  CmdMessage = 4,
  CmdNotify = 5,
  CmdPushId = 6,
  CmdSend = 7,
}

enum RemoteErrorCode {
  WebSockCloseCodeTokenFailed = 3001,
  WebSockCloseCodeForce = 3002,
  WebSockCloseCodeSilence = 3003,
}

enum LocalErrorCode {
  WebSockCloseCodeUseDefault,
  WebSockCloseCodeActive = 3301,
  WebSockCloseCodeToReconnect = 3302,
}

export enum CometSerialize {
  JSON = 1,
  PROTOBUF,
}

export interface ICometSendMsg {
  cmd: number;
  id: number;
  extra: string;
}

export interface ICometOptions {
  host: string;
  token: string;
  isAutoConnect: boolean;
}

export interface ICometMessage {
  topic: string;
  id?: number;
  message?: string;
}

export class Comet {
  constructor(dispatch: (arg: ICometMessage) => void) {
    this.serializeType = CometSerialize.PROTOBUF;
    this.dispatch = dispatch;
    // this.initStates();
    // eslint-disable-next-line no-lone-blocks
    {
      this.userCloseFlag = false;
      this.allowReconnectInternal = true;
      this.reconnectCount = true;
      this.connAsReconnect = false;
      this.receiveId = 0;
      this.options = null;
      this.conn = null;
      // if (this.serializeType === CometSerialize.PROTOBUF) {
      this.msgList = new MsgList();
      // }
      this.heartbeatTs = new Date().valueOf();
      this.timer = null;
    }
  }

  private options: ICometOptions | null;
  private conn: WebSocket | null;
  // 调用者主动关闭
  private userCloseFlag: boolean;
  // 是否被强制下线或token失效
  private allowReconnectInternal: boolean;
  // 是否允许重连(正在重连与否)
  private reconnectCount: boolean;
  // 是否为断线重连
  private connAsReconnect: boolean;
  // 消息序列化方式
  private readonly serializeType: CometSerialize;

  private receiveId: number;

  private msgList: MsgList | null;

  private dispatch: (arg: ICometMessage) => void;

  private heartbeatTs: number;

  private timer: any | null;

  private initStates() {
    this.userCloseFlag = false;
    this.allowReconnectInternal = true;
    this.reconnectCount = true;
    this.connAsReconnect = false;
    this.receiveId = 0;
    this.options = null;
    this.conn = null;
    // if (this.serializeType === CometSerialize.PROTOBUF) {
    this.msgList = new MsgList();
    // }
    this.heartbeatTs = new Date().valueOf();
    this.timer = null;
  }

  public Run(options: ICometOptions) {
    this.ShutDown();
    this.initStates();
    this.options = options;
    this.connect();
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const comet = this;
    this.timer = setInterval(() => {
      const now = new Date().valueOf();
      // console.log('check ... ', now - comet.heartbeatTs);
      if (now - comet.heartbeatTs > timeoutMs) {
        console.log('websock timeout ....');
        comet.reconnect();
      }
    }, timeoutInterval);
  }

  private connect() {
    const connection = new WebSocket(`${this.options?.host}/dtl-comet`);
    connection.onopen = (event) => {
      console.log('Connection opened: ', event);
      this.heartbeatTs = new Date().valueOf();
      this.notifyState(WebSocket.OPEN);
    };
    connection.onclose = (event) => {
      console.log('Connection closed: ', event);
      this.notifyState(WebSocket.CLOSED);
      switch (event.code) {
        case RemoteErrorCode.WebSockCloseCodeForce:
          this.dispatch({
            topic: CometTopic.FORCE_KICK_OUT,
            message: event.reason,
          });
          this.allowReconnectInternal = false;
          break;
        case RemoteErrorCode.WebSockCloseCodeTokenFailed:
          this.dispatch({
            topic: CometTopic.TOKEN_FAILED,
          });
          this.allowReconnectInternal = false;
          break;
        case RemoteErrorCode.WebSockCloseCodeSilence:
          this.allowReconnectInternal = false;
          break;
        default:
          this.setReconnect();
          return;
      }
      this.ShutDown();
    };
    connection.onerror = (event) => {
      console.error('Connection error: ', event);
      // this.notifyState(WebSocket.CLOSED);
      // this.setReconnect();
    };
    connection.onmessage = (event) => {
      // eslint-disable-next-line no-multi-assign
      const recvId = (this.receiveId += 1);
      // console.log('local msgId:', recvId, '; got data: ', event.data);
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const comet = this;
      switch (this.serializeType) {
        case CometSerialize.JSON:
          this.receiveMsg(JSON.parse(event.data));
          // comet.asyncReceiveMsg(JSON.parse(event.data), recvId);
          break;
        case CometSerialize.PROTOBUF:
          // eslint-disable-next-line no-case-declarations
          const reader = new FileReader();
          reader.addEventListener('error', function () {
            console.error('recvId:', recvId, ' -- fileReader read Blob error');
          });
          reader.addEventListener('abort', function () {
            console.error('recvId:', recvId, ' -- fileReader read Blob abort');
          });
          reader.addEventListener('loadend', function () {
            // reader.result 包含被转化为类型数组 typed array 的 blob
            // @ts-ignore
            const bMsg = cometMsg.deserializeBinary(new Uint8Array(reader.result));
            const msg = bMsg.toObject();
            comet.asyncReceiveMsg(msg, recvId);
          });
          reader.readAsArrayBuffer(event.data);
          break;
        default:
          console.log('received data,but have unknown serializeType:', this.serializeType);
      }

      // const msg = JSON.parse(event.data);
      // this.receiveMsg(msg);

      // //使用了Blob的arrayBuffer(),IE不支持
      // let arr = new Uint8Array(await (new Response(event.data)).arrayBuffer());
      // console.log("arr length:", arr.length);
      // const bMsg = cometMsg.deserializeBinary(arr);
      // const msg = bMsg.toObject();
      // comet.asyncReceiveMsg(msg, recvId);
    };

    this.conn = connection;
  }

  private setReconnect() {
    if (this.reconnectCount) {
      this.heartbeatTs = 0;
    }
  }

  private reconnect() {
    if (this.reconnectCount) {
      this.reconnectCount = false;
      this.connAsReconnect = true;
      this.destroyConn(LocalErrorCode.WebSockCloseCodeToReconnect, 'to reconnect');
      // setTimeout(() =>{
      if (!this.userCloseFlag && this.allowReconnectInternal) {
        this.connect();
        this.reconnectCount = true;
      }
      // }, 2000);
    }
  }

  private destroyConn(
    code: number = LocalErrorCode.WebSockCloseCodeUseDefault,
    reason: string = 'default',
  ) {
    if (this.conn === null) {
      return;
    }
    const preConn = this.conn;
    this.conn = null;
    preConn.onopen = null;
    preConn.onmessage = null;
    preConn.onclose = null;
    preConn.onerror = null;
    switch (code) {
      case LocalErrorCode.WebSockCloseCodeUseDefault:
        preConn.close();
        break;
      default:
        preConn.close(code, reason);
    }
  }

  private notifyState(connState: number) {
    switch (connState) {
      case WebSocket.OPEN:
        console.log('connection open ...');
        if (!this.options) return;
        this.sendMsg({
          // eslint-disable-next-line no-nested-ternary
          cmd: this.connAsReconnect
            ? CometCmd.CmdAutoReLogIn
            : this.options.isAutoConnect
              ? CometCmd.CmdAutoLogIn
              : CometCmd.CmdActiveLogIn,
          id: 0,
          extra: this.options.token,
        });
        break;
      case WebSocket.CLOSED:
        console.log('connection closed ...');
        break;
      default:
        break;
    }
  }

  private asyncReceiveMsg(msg: ICometSendMsg, seqId: number) {
    // console.log("async receive id:", seqId);
    if (!this.msgList) {
      console.error('async receive data, but msgList is null ???');
      return;
    }
    if (this.msgList.add(seqId, msg)) {
      this.receiveMsg(msg);
    }
    let sMsg = null;
    while (1) {
      if (this.msgList) {
        sMsg = this.msgList.getSeqValue();
        if (sMsg) {
          this.receiveMsg(sMsg);
          // eslint-disable-next-line no-continue
          continue;
        }
      }
      break;
    }
  }

  private receiveMsg(msg: ICometSendMsg) {
    if (this.userCloseFlag) return;

    // console.log("got msg:", msg.id);
    if (msg == null) return;
    let topic = '';
    let message: string = '';
    let id: number = 0;
    switch (msg.cmd) {
      case CometCmd.CmdActiveLogIn:
      case CometCmd.CmdAutoLogIn:
      case CometCmd.CmdAutoReLogIn:
        if (msg.extra === 'successloading') {
          topic = `${msg.cmd.toString()}/login_success`;
        }
        // else if (msg.extra === 'tokenfailed'){
        //   topic = msg.cmd.toString() + '/login_failed';
        //   this.ShutDown();
        // } else {
        //   console.log("server error:", msg.extra);
        // }
        break;
      // case CometCmd.CmdMessage:
      //   topic = CometTopic.COMET_MESSAGE;
      //   id = msg.id;
      //   message = msg.extra;
      //   break;
      case CometCmd.CmdNotify:
        topic = CometTopic.COMET_NOTIFY;
        id = msg.id;
        message = msg.extra;
        break;
      case CometCmd.CmdPushId:
        topic = CometTopic.COMET_PUSHID;
        id = msg.id;
        this.heartbeatTs = new Date().valueOf();
        break;
      default:
        return;
    }

    this.dispatch({
      topic,
      id,
      message,
    });
  }

  private sendMsg(msg: ICometSendMsg) {
    let data: any;
    let cMsg: cometMsg;
    switch (this.serializeType) {
      case CometSerialize.JSON:
        data = JSON.stringify(msg);
        break;
      case CometSerialize.PROTOBUF:
        // eslint-disable-next-line
        cMsg = new cometMsg();
        cMsg.setCmd(msg.cmd);
        cMsg.setId(msg.id);
        cMsg.setExtra(msg.extra);
        data = cMsg.serializeBinary();
        break;
      default:
        console.log('unknown serialize type:', this.serializeType);
        return;
    }
    this.conn?.send(data);
  }

  public ShutDown() {
    if (!this.userCloseFlag) {
      this.userCloseFlag = true;
      if (this.timer != null) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.destroyConn(LocalErrorCode.WebSockCloseCodeActive, 'user close');
      if (this.msgList) {
        const { msgList } = this;
        this.msgList = null;
        msgList.clear();
      }
    }
  }
}
