export default {
  'pages.contacts.BigTitle': 'Address Book',
  'pages.contacts.desc': 'Frequent Contacts',
  'pages.contacts.account': 'Individual Account',
  'pages.contacts.accountType': 'Account Type: ',
  'pages.contacts.backToIndex': 'Return to Home Page',
  'pages.contacts.addGroupBtn': 'Add Group',
  'pages.contacts.addBtn': 'Add',
  'pages.contacts.searchBtn': 'Search',
  'pages.contacts.groupTitle': 'Groups',
  'pages.contacts.friendTitle': 'Contacts',
  'pages.contacts.addGroup.placeholder': 'Please enter a group name',
  'pages.contacts.rightPanel.title': 'Add Contacts',
  'pages.contacts.rightPanel.searchInput.placeholder': 'Enter phone number or email',
  'pages.contacts.rightPanel.searchInput.tips':
    'Enter multiple contacts separated by commas or spaces',
  'pages.contacts.rightPanel.resultTitle': 'Result',
  'pages.contacts.rightPanel.addAll': 'Add all',
  'pages.contacts.rightPanel.hasAdd': 'Added',
  'pages.contacts.rightPanel.to': ' ',
  'pages.contacts.remarkInput.placeholder': 'Please enter remark',
  'pages.contacts.noRegister': 'Unregistered',
  'pages.contacts.hasAdd': 'Added',
  'pages.contacts.newGroup.placeholder': 'Please enter a group name',
};
