export default {
  'pages.login.BigTitle': 'Magic Data Cloud',
  'pages.login.desc':
    'An unified interactive data cloud format takes new experience of cloud based professional data collaboration',
  'pages.login.company': '2022 珠海精实测控技术股份有限公司',
  'pages.login.loginTab': 'Sign in',
  'pages.login.registerTab': 'Sign up',
  'pages.login.username.placeholder': 'Phone number or email',
  'pages.login.username.format': 'Phone number or email format error',
  'pages.login.password.placeholder': 'Password',
  'pages.login.username.required': 'Please enter a user name!',
  'pages.login.password.required': 'Please enter a password!',
  'pages.login.rememberMe': 'Remember me',
  'pages.login.forgotPassword': 'Forgot password?',
  用户名或密码错误: 'User name or password error',
  'pages.login.captchaLogin': 'Use Verification Code',
  'pages.login.passwordLogin': 'Use Password',
  'pages.login.existAccount': 'Existing account',
  'pages.login.loginFail': "Can't sign in?",
  'pages.login.registerPhone.required': 'Please enter your phone number',
  'pages.login.registerPhone.placeholder': 'Phone number',
  'pages.login.phoneNumber.invalid': 'Mobile phone number format error',
  'pages.login.captcha.placeholder': 'Code',
  'pages.login.phoneLogin.getVerificationCode': 'Get Code',
  'pages.login.captcha.required': 'Please enter a verification code!',
  'pages.login.captcha.invalid': 'Verification code format error',
  'pages.login.registerLogin': 'Sign up',
  'pages.login.phoneLogin.errorMessage': 'Verification code error',
  'pages.login.second': 'second',
  'pages.login.captcha.success.hasCode': 'The verification code is:',
  'pages.login.captcha.success.hasNoCode': 'Successfully sent verification code!',
  'pages.login.captcha.sendBusy':
    'The verification code has been sent frequently. Please try again later!',
  'pages.login.captcha.sendFail': 'Failed to obtain verification code!',
  'pages.login.loginFail.failTitle': 'There was a problem signing in',
  'pages.login.loginFail.failDesc':
    'When you encounter difficulties logging in, please check if there are any issues with your network connection and input. If this still cannot be resolved, please contact 020-8632288',
  'pages.login.confirm': 'confirm',
  'pages.login.forgotPassword.title': 'Forgot password',
  'pages.login.forgotPassword.username': 'Account:',
  'pages.login.forgotPassword.captcha': 'Verification Code:',
  'pages.login.forgotPassword.password': 'Password:',
  'pages.login.forgotPassword.confirmPassword': 'Confirm:',
  'pages.login.forgotPassword.username.placeholder': 'Phone number',
  'pages.login.forgotPassword.captcha.placeholder': 'Verification Code',
  'pages.login.forgotPassword.password.placeholder':
    'More than 6 bits, including at least two formats',
  'pages.login.forgotPassword.confirmPassword.placeholder': 'Please enter the password again',
  'pages.login.forgotPassword.confirmPassword.differentError':
    'The two password entries do not match!',
  'pages.login.forgotPassword.nextStep': 'Next',
  'pages.login.forgotPassword.cancel': 'Cancel',
  'pages.login.forgotPassword.save': 'Save',
  'pages.registerLoginError': 'Registration failed, please try again!',
  'pages.loginError': 'Login failed, please try again!',
  'pages.login.forgotPassword.username.placeholder.two': 'phone or email',
  'pages.login.registerPhone.required.two': 'Please enter your phone number or email',
  'pages.login.phoneNumber.invalid.two': 'Mobile phone number or email format error',
  'passwordLogin': 'Password Login',
  'phoneL': 'Mobile Login',
  'emailL': 'Email Login',
  'dingL': 'DingTalk Login',
  'register': 'Sign up',
  'pages.login.email.login': 'email',
  'pages.login.email.login.placeholder': 'Please enter your email',
  'pages.login.email.invalid': 'Email format error',
  'pages.login.ding.tip': 'Use DingTalk scan the QR code and log in',
  'pages.login.ding.refresh': 'refresh',
  'phoneR': 'Mobile registration',
  'emailR': 'Email registration',
};
