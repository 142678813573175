export default {
  'Humidity.humidity.title': 'Calculate relative humidity',
  'Humidity.dryTemp': 'Dry bulb temperature(℃)',
  'Humidity.wetTemp': 'Wet bulb temperature(℃)',
  'Humidity.pressure': 'Atmospheric pressure(Pa)',
  'Humidity.humidity': 'Relative humidity(%)',
  'Humidity.wetBall.title': 'Calculate wet bulb temperature',
  'Humidity.dryBall.title': 'Calculate dry bulb temperature',
  'Humidity.SPressure': 'Standard atmospheric pressure (Pa)',
  'Humidity.dT': 'Dry bulb Kelvin temperature',
  'Humidity.wT': 'Wet bulb Kelvin temperature',
  'Humidity.Pqb': 'Pressure of saturated vapor(Pa)',
  'Humidity.wPqb': 'Pressure of saturated vapor at wet bulb temperature(Pa)',
  'Humidity.Pq': 'Pressure of vapor(Pa)',
  'Humidity.d': 'Moisture content of dry air(g/kg)',
  'Humidity.i': 'Dry air enthalpy value(kj/kg)',
  'Humidity.p': 'Density(kg/m3)',
  'Humidity.v': 'Specific volume(m3/kg)',
  'Humidity.tl': 'Dew point(℃)',
};
