import API_VERSION from '@/services/datalib/api/config';
import request from '@/utils/request';

export async function dtlFeedbackAdd(body: DtlFeedback.AddParam) {
  return request<DtlComm.ResultV<DtlFeedback.AddResult>>(`${API_VERSION}/api/feedback/add`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlFeedbackResolve(body: DtlFeedback.ResolveParam) {
  return request<DtlComm.ResultV<DtlFeedback.ListResult>>(`${API_VERSION}/api/feedback/resolve`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlFeedbackList() {
  return request<DtlComm.ResultV<DtlFeedback.ListResult>>(`${API_VERSION}/api/feedback/list`, {
    method: 'POST',
    data: {},
  });
}
