import { User } from '@/services/datalib/bean/user';

export class Contacts {
  userList: User[] = [];
  groupNameList: string[] = [];
  v: number = 0;
  usersCache: Record<string, User> = {};

  constructor(t?: DtlBean.Contacts) {
    if (!t) {
      return;
    }
    if (t.users) {
      this.userList = t.users.map((user) => {
        const u = new User(user);
        this.usersCache[u.userId] = u;
        return u;
      });
    }
    if (t.friends) {
      const m: Record<string, User> = {};
      this.userList.forEach((u) => (m[u.userId] = u));
      const newUserList: User[] = [];
      t.friends.forEach((member) => {
        if (m[member.userId]) {
          newUserList.push(m[member.userId]);
        }
      });
      this.userList = newUserList;
    }
    if (t.groups) {
      this.groupNameList = [...t.groups];
    }
    if (t.v) {
      this.v = t.v;
    }
  }

  getFriend(userId: string): User | undefined {
    const index = this.userList.findIndex((user) => user.userId === userId);
    if (index > -1) {
      return this.userList[index];
    }
    return undefined;
  }

  addFriend(user: User) {
    const friend = this.getFriend(user.userId);
    if (!friend) {
      this.userList.push(user);
      this.usersCache[user.userId] = user;
    }
  }

  delFriend(userId: string) {
    const index = this.userList.findIndex((user) => user.userId === userId);
    if (index > -1) {
      this.userList.splice(index, 1);
    }
  }

  remarkFriend(userId: string, remark: string) {
    const user = this.getFriend(userId);
    user?.updateRemark(remark);
  }

  existGroup(groupName: string): boolean {
    return this.groupNameList.some((name) => name === groupName);
  }

  addGroup(groupName: string) {
    if (this.existGroup(groupName)) {
      return;
    }
    this.groupNameList.push(groupName);
  }

  updateGroupName(groupName: string, newGroupName: string) {
    const index = this.groupNameList.findIndex((name) => name === groupName);
    if (index > -1) {
      this.groupNameList[index] = newGroupName;
    }
    for (let i = 0; i < this.userList.length; i += 1) {
      if (this.userList[i].group === groupName) {
        this.userList[i].group = newGroupName;
      }
    }
  }

  delGroup(groupName: string) {
    const index = this.groupNameList.findIndex((name) => name === groupName);
    if (index > -1) {
      this.groupNameList.splice(index, 1);
    }
    for (let i = 0; i < this.userList.length; i += 1) {
      if (this.userList[i].group === groupName) {
        this.userList[i].group = '';
      }
    }
  }

  moveMemberToGroup(userId: string, groupName: string) {
    const index = this.userList.findIndex((user) => user.userId === userId);
    if (index > -1) {
      this.userList[index].group = groupName;
    }
  }

  format(): DtlContacts.GetContactsResult {
    const nogroup: DtlBean.User[] = [];
    const groupMap: Record<string, DtlBean.User[]> = {};
    this.groupNameList.forEach((groupName) => {
      if (groupName) {
        groupMap[groupName] = [];
      }
    });
    this.userList.forEach((user) => {
      if (!user.group) {
        nogroup.push(user.format());
      } else {
        let g = groupMap[user.group];
        if (!g) {
          g = [];
          groupMap[user.group] = g;
        }
        g.push(user.format());
      }
    });
    return {
      noGroup: nogroup,
      groups: groupMap,
    };
  }
}
