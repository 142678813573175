export default {
  'Season.CD.tab': '默认制冷衰减系数 (CD)',
  'Season.SEER.tab': '制冷季节能效 (SEER)',
  'Season.HSPF.tab': '制冷季节能效 (HSPF)',
  'Season.CD.D': 'D工况效能(W/W)',
  'Season.CD.DReal': 'D工况实测能力(Btu)',
  'Season.CD.C': 'C工况效能(W/W)',
  'Season.CD.CReal': 'C工况实测能力(Btu)',
  'Season.CD.time': '周期时长(Hour)',
  'Season.SEER.workA': '北美A工况',
  'Season.SEER.workB': '北美B工况',
  'Season.SEER.EV': '北美制冷 I',
  'Season.SEER.EV.desc': '(EV中间频率)',
  'Season.SEER.B1': '北美制冷 II',
  'Season.SEER.B1.desc': '(B1额定最小频率)',
  'Season.SEER.F1': '北美制冷 III',
  'Season.SEER.F1.desc': '(F1最小制冷频率)',
  'Season.common.cool': '制冷量(W)',
  'Season.common.coolPower': '制冷功率(W)',
  'Season.HSPF.H1N': '北美额定制热',
  'Season.HSPF.H1N.desc': '(H1N对应最大频率)',
  'Season.HSPF.H01': '北美制热 I',
  'Season.HSPF.H01.desc': '(H01对应最大频率)',
  'Season.HSPF.H22': '北美制热 II',
  'Season.HSPF.H22.desc': '(H22对应最大频率)',
  'Season.HSPF.H2V': '北美制冷 II',
  'Season.HSPF.H2V.desc': '(H2V对应最大频率)',
  'Season.HSPF.H32': '北美制热 III',
  'Season.HSPF.H32.desc': '(H32对应最大频率)',
  'Season.HSPF.H11': '北美额定制热',
  'Season.HSPF.H11.desc': '(H11对应最大频率)',
  'Season.HSPF.upper': '-15℃以上',
  'Season.HSPF.lower': '-15℃以下',
};
