// 从接口种获取子应用配置，export 出来的 qiankun 变量是一个 promise
import * as datalib from '@/services/datalib';
import { isDev } from '@/services/datalib';
import { freeAppNameWithId } from '@/utils/microId';
import serverSetting from '../config/serverSetting';
import './assets/icons';

export const qiankun = new Promise((resolve) => {
  resolve({
    apps: serverSetting.MICRO_APP,
    lifeCycles: {
      beforeLoad: (props: any) => {
        console.log('beforeLoad : ', props);
      },
      // beforeMount: (props: any) => {
      //   console.log('beforeMount : ', props);
      // },
      // afterMount: (props: any) => {
      //   console.log('afterMount: ', props);
      // },
      // beforeUnmount: (props: any) => {
      //   console.log('beforeUnmount : ', props);
      // },
      afterUnmount: (props: any) => {
        console.log('afterUnmount  : ', props);
        freeAppNameWithId(props.name);
      },
    },
  });
});

export const locale = {
  getLocale() {
    const localStorage = window.localStorage.getItem('umi_locale');
    let lang = localStorage || navigator.language;
    if (lang.split('-')[0].toLowerCase() === 'en') lang = 'en-US';
    return lang;
  },
};

if (isDev()) {
  // @ts-ignore
  global.datalib = datalib;
}
