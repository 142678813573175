export default {
  'popover.createEnterprise': '创建企业',
  'popover.enterpriseManage': '企业管理后台',

  'enterprise.tab.title.organization': '组织架构',
  'enterprise.tab.title.delUser': '离职员工',
  'enterprise.tab.title.setting': '企业设置',
  'enterprise.tab.title.member': '成员',
  'enterprise.tab.title.depart': '部门',

  'enterprise.btn.allMembers': '全部成员',
  'enterprise.btn.bindDingTalk': '绑定钉钉通讯录',
  'enterprise.btn.addMember': '添加成员',
  'enterprise.btn.confirm': '确认',
  'enterprise.btn.saveContinue': '保存并继续添加',
  'enterprise.btn.handover': '去交接',
  'enterprise.btn.recover': '恢复在职',
  'enterprise.btn.deleteSelect': '批量删除',
  'enterprise.btn.next': '下一步',
  'enterprise.btn.back': '上一步',
  'enterprise.btn.disband.confirm': '确认解散',
  'enterprise.btn.create.free': '免费创建',
  'enterprise.btn.create.goto': '立即前往',

  'enterprise.input.searchMember': '搜索成员',
  'enterprise.input.departName.placeholder': '请输入部门名称',
  'enterprise.input.company.name.placeholder': '请输入企业名称',

  'message.createCompany.one.tip': '每个账号仅可新建一个企业团队',
  '该部门名称已被占用': '该部门名称已被占用',
  'enterprise.message.departName.empty': '部门名称不能为空',
  'enterprise.message.input.trueName': '请输入真实姓名',
  'enterprise.message.input.depart': '请至少选择一个所属部门',
  'enterprise.message.input.account': '请输入手机号或邮箱',
  'enterprise.message.input.realAccount': '请输入正确的手机号或邮箱',
  'enterprise.message.edit.success': '修改成功',
  'enterprise.message.add.success': '添加成功',
  'enterprise.message.recover.someFail': '部分员工恢复失败',
  'enterprise.message.warnToHandover': '选择员工中存在未交接任务',
  'enterprise.message.delete.someFail': '部分员工删除失败',
  'enterprise.message.uploadImg.fail': '上传图片失败',
  'enterprise.message.disband.success': '解散企业成功',
  'enterprise.message.company.name.empty': '企业名称不能为空',
  'enterprise.message.user.name.empty': '真实姓名不能为空',
  'enterprise.message.company.name.used': '该企业名称已被注册，请使用其他名称',
  'enterprise.message.somePassFail': '部分任务指定失败',
  'enterprise.message.deleteTrue.empty': '请选择至少一名成员',
  'enterprise.message.setting.upload.logo.format': '上传图片仅支持JPG/PNG格式!',

  'enterprise.table.status.unregistered': '待注册',
  'enterprise.table.status.admin': '超级管理员',
  'enterprise.table.more': '更多',
  'enterprise.table.header.name': '姓名',
  'enterprise.table.header.phone': '手机',
  'enterprise.table.header.email': '邮箱',
  'enterprise.table.header.operate': '操作',
  'enterprise.table.header.deleteTime': '离职日期',

  'enterprise.dropdown.addDept': '添加子部门',
  'enterprise.dropdown.rename': '重命名',
  'enterprise.dropdown.delete': '删除',

  'enterprise.modal.rename.title': '重命名部门名称',
  'enterprise.modal.delete.member.title': '是否删除该成员?',
  'enterprise.modal.delete.member.tip1': '删除成员后，可在',
  'enterprise.modal.delete.member.tip2': '离职员工',
  'enterprise.modal.delete.member.tip3': '交接工作',
  'enterprise.modal.delete.depart.title': '是否删除该部门?',
  'enterprise.modal.delete.depart.tip': '该部门成员将同时删除，此操作不可逆',
  'enterprise.modal.chooseDepart': '请选择添加部门',
  'enterprise.modal.memberNum': '成员数',
  'enterprise.modal.reassign': '重新指定',
  'enterprise.modal.assign': '指定拥有者',
  'enterprise.modal.passTask.title': '交接企业任务',
  'enterprise.modal.passTask.tip': '是下列企业任务的拥有者，你可以指定企业任务拥有者',
  'enterprise.modal.deleteTrue.member.title': '是否彻底删除该成员?',
  'enterprise.modal.deleteTrue.member.tip': '该成员创建的任务同时删除，此操作不可逆',

  'enterprise.drawer.edit.title': '编辑成员',
  'enterprise.drawer.add.title': '添加成员',
  'enterprise.drawer.realName': '真实姓名',
  'enterprise.drawer.department': '所属部门',
  'enterprise.drawer.department.placeholder': '请选择所属部门',
  'enterprise.drawer.jobNum': '工号',
  'enterprise.drawer.jobNum.placeholder': '请输入工号',

  'enterprise.setting.title': '企业设置',
  'enterprise.setting.disbandBtn': '解散企业',
  'enterprise.setting.name': '企业名称 :',
  'enterprise.setting.logo': '企业logo :',
  'enterprise.setting.logo.upload.tip': '点击上传图片，最大支持1M的JPG、PNG的图像格式',
  'enterprise.setting.disband.disbandTitle': '解散企业',
  'enterprise.setting.disband.tip': '解散企业是高风险且不可撤销操作，请谨慎操作！',
  'enterprise.setting.disband.step1.title': '选择原因',
  'enterprise.setting.disband.step2.title': '确认影响',
  'enterprise.setting.disband.step3.title': '验证身份',
  'enterprise.setting.disband.step1.choose.title': '请选择解散企业原因 :',
  'enterprise.setting.disband.step1.choose.one': '即将离职，无人管理企业',
  'enterprise.setting.disband.step1.choose.two': '企业已停止运营',
  'enterprise.setting.disband.step2.choose.title': '解散企业后，将产生以下影响 :',
  'enterprise.setting.disband.step2.choose.one': '企业内的组织架构将被清空，不可访问，且不可恢复',
  'enterprise.setting.disband.step2.choose.two': '企业内的所有任务以及任务数据将被清空，不可访问，且不可恢复',
  'enterprise.setting.disband.step2.checkbox': '我已经了解散企业会产生以上影响',
  'enterprise.setting.disband.step3.verify.title': '为确保企业的信息、财产安全，请验证超级管理员帐号相关联的手机号/邮箱 :',
  'enterprise.setting.disband.step3.verify.account': '手机号 / 邮箱 :',
  'enterprise.setting.disband.step3.verify.code': '验证码 :',

  'enterprise.create.gotoInfo1': '企业管理后台',
  'enterprise.create.gotoInfo1.tip': '体验丰富的企业管理功能',
  'enterprise.create.gotoInfo2': '添加成员',
  'enterprise.create.gotoInfo2.tip': '邀请成员，开启高效协作',
  'enterprise.create.title': '魔数云企业协作',
  'enterprise.create.tip': '快速创建企业团队，与同事共同协作更高效',
  'enterprise.create.input.company.name': '企业名称',
  'enterprise.create.input.trueName': '真实姓名',
  'enterprise.create.success.title': '创建成功',
  'enterprise.create.success.tip': '前往添加成员，开启高效协作',
}
