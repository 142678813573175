import API_VERSION from '@/services/datalib/api/config';
import request from '@/utils/request';

export async function externalUploadFile(
  body: {
    token: string;
    dir: string;
    fileName: string;
    file: File;
  },
  options: Record<string, object | string | number> = {},
) {
  const deleteHeadersOptions = options;
  delete deleteHeadersOptions.headers;

  const formData = new FormData();
  formData.append('token', body.token);
  formData.append('dir', body.dir);
  formData.append('fileName', body.fileName);
  formData.append('file', body.file);
  return request<DtlComm.Result<undefined>>(`${API_VERSION}/api/external/uploadFile`, {
    method: 'POST',
    data: formData,
    ...(deleteHeadersOptions || {}),
  });
}

export async function externalGetToken(body: { taskId?: string }) {
  return request<DtlComm.Result<undefined>>(`${API_VERSION}/api/external/getToken`, {
    method: 'POST',
  });
}

export async function externalUploadFileTest() {
  const formData = new FormData();
  formData.append('token', '8aee48aa55135aeb8e938bcada2a416b');
  formData.append('dir', 'dir1/dir2/dir3/dir4');
  formData.append('fileName', 'fileB.txt');
  formData.append('file', new File(['1234567890'], '123.txt'));
  // const rs = await fetch('http://127.0.0.1:9088/data_lab/v1/api/external/uploadFile', {
  const rs = await fetch('/data_lab/v1/api/external/uploadFile', {
    headers: {
      Accept: 'application/json',
    },
    method: 'POST',
    body: formData,
  });
  return rs.json();
}

// @ts-ignore
// global.externalUploadFileTest = externalUploadFileTest;
// global.externalUploadFile = externalUploadFile
// global.externalGetToken = externalGetToken
