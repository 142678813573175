export default {
  'scic.data': 'Data',
  'scic.dataSource': 'Scientific Computing Data Source',
  'scic.max': 'Max',
  'scic.min': 'Min',
  'scic.average': 'Average',
  'scic.total': 'Total',
  'scic.sum': 'Sum',
  'scic.interpolation': 'Interpolation',
  'scic.calculation': 'Calculation',
  'scic.drag': 'Drag and drop tools, add shortcuts',
  'scic.interpolation.piecewise': 'Piecewise Interpolation',
  'scic.interpolation.piecewise.linear': 'Linear interpolation',
  'scic.interpolation.piecewise.quadratic': 'Quadratic interpolation',
  'scic.interpolation.piecewise.cubic': 'Cubic interpolation',
  'scic.interpolation.octave': 'Octave interpolation',
  'scic.interpolation.octave.time': 'Interpolation multiple',
  'scic.interpolation.octave.selectTime': 'Select frequency data',
  'scic.interpolation.octave.selectTimeTip': 'Other data will use linear interpolation',
  'scic.interpolation.octave.selectDataByCol': 'Get data by column',
  'scic.interpolation.octave.timeUnit': 'octave',
  'scic.interpolation.octave.timeOne': '1/1 octave',
  'scic.interpolation.octave.timeTwo': '1/2 octave',
  'scic.interpolation.octave.timeThree': '1/3 octave',
  'scic.interpolation.octave.twoNumberError':
    'Each set of data requires at least two digits. Please choose another method to get data',
  'scic.interpolation.smartOctave': 'Intelligent octave interpolation',
  'scic.interpolation.smartOctave.tip':
    'Description: This operation will automatically recognize the Frequency and Limit columns and perform interpolation',
  'scic.interpolation.addSheetName': 'Interpolation',
  'scic.modal.octave.chart': 'Generate chart',
  'scic.modal.piecewise.title': 'Generate interpolation comparison chart?',
  'scic.modal.piecewise.choose': `Remember to choose, don't ask again before closing`,
  'scic.modal.piecewise.no': 'No',
  'scic.modal.piecewise.yes': 'Generate',
  'scic.chart': 'Chart',
  'scic.chart.line': 'Line Chart',
  'scic.chart.line.disable':
    'Paint line chart After selecting data from 2 rows and 2 columns or more',
  'scic.chart.line.modalTitle': 'Paint line chart',
  'scic.chart.line.selectX': 'Select X-axis data',
  'scic.chart.delete': 'Delete',
  'scic.transposition': 'Transposition',
  'scic.paint': 'Paint',
};
