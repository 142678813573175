import { doErrResponse, doInitDtlStatus, dtlStatus, updateDtlVerLog } from '@/services/datalib';
import {
  dtlCategoryAdd,
  dtlCategoryAddChild,
  dtlCategoryDeleteChild,
  dtlCategoryGet,
  dtlCategorySetRead,
  dtlCategoryUpdateChild,
} from '@/services/datalib/api/category';
import { doResponse } from '@/services/datalib/ctrl/util';
import { Err } from '@/services/datalib/enum';

export async function categoryGet(
  body: DtlCategory.GetParam,
): Promise<DtlComm.Result<DtlBean.Category | undefined>> {
  // const err = await doInitDtlStatus();
  // if (err) return err;
  const response = await dtlCategoryGet(body);
  return doResponse(response);
}

export async function categoryAdd(
  body: DtlCategory.AddParam,
): Promise<DtlComm.Result<DtlBean.Category | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlCategoryAdd(body);
  return doResponse(response);
}

export async function categoryAddChild(
  body: DtlCategory.AddChildParam,
): Promise<DtlComm.Result<DtlCategory.CategoryResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlCategoryAddChild(body);
  return doResponse(response);
}

export async function categoryUpdateChild(
  body: DtlCategory.UpdateChildParam,
): Promise<DtlComm.Result<DtlCategory.CategoryResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlCategoryUpdateChild(body);
  return doResponse(response);
}

export async function categoryDeleteChild(
  body: DtlCategory.DeleteChildParam,
): Promise<DtlComm.Result<DtlCategory.CategoryResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlCategoryDeleteChild(body);
  return doResponse(response);
}

export async function categorySetRead(
  body: DtlCategory.SetReadParam,
): Promise<DtlComm.Result<Record<string, number> | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlCategorySetRead(body);
  const { code, data, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    const category = data as DtlBean.Category;
    let record: Record<string, number> = {};
    try {
      record = JSON.parse(category.content);
    } catch (e) {}
    return {
      code: Err.OK[0],
      data: record,
      message: Err.OK[1],
    };
  } else if (code === Err.OBJECT_NOT_EXIST[0]) {
    return {
      code: Err.OK[0],
      data: {},
      message: Err.OK[1],
    };
  }
  return doErrResponse(code, message);
}

export async function categoryGetRead(body: {
  categoryId: string;
}): Promise<DtlComm.Result<Record<string, number> | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlCategoryGet({
    id: `${body.categoryId}_${dtlStatus.userInfo.userId}_r`,
  });
  const { code, data, message, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    const category = data as DtlBean.Category;
    let record: Record<string, number> = {};
    try {
      record = JSON.parse(category.content);
    } catch (e) {}
    return {
      code: Err.OK[0],
      data: record,
      message: Err.OK[1],
    };
  } else if (code === Err.OBJECT_NOT_EXIST[0]) {
    return {
      code: Err.OK[0],
      data: {},
      message: Err.OK[1],
    };
  }
  return doErrResponse(code, message);
}

// global.categorySetRead = categorySetRead
// global.categoryGetRead = categoryGetRead
// global.categoryGet = categoryGet
// global.categoryAdd = categoryAdd
// global.categoryAddChild = categoryAddChild
// global.categoryUpdateChild = categoryUpdateChild
// global.categoryDeleteChild = categoryDeleteChild

/*
批注/图钉 功能接口说明
  先使用 categoryAdd 创建一个数据块，该数据块用于保存所有批注/图钉等信息
  保存结果时将该数据块的id保存到结果中，打开结果时，调用categoryGet获取该数据块的内容

  调用categoryAddChild往数据块添加批注,或图钉,或图钉的评论
  调用categoryUpdateChild往数据块修改批注,或图钉,或评论
  调用categoryDeleteChild删除数据块里的信息

  图钉里评论的@功能，除了调用categoryAddChild用于保存该评论，另增一个接口用于实现通知用户
  评论里的头像/姓名等用户信息，另增一个接口通过userId来获取

数据块结构如下
{
    "id": "6343c4d443bda03f9bb7e285",
    "type": "自定义字符串，如datalib-microapp-dsp-common或其他字符串，用于标识本数据块的内容用于datalib-microapp-dsp-common",
    "content": "自定义内容",
    "ext": "扩展内容",
    "ts": 1665385684713273100, //时间戳
    "userid": "619b5e7aad09312848edf691", //用户id
    "taskid": "63311fe3f1bc8bca482b5c22", //任务id，如果不为空则推送消息到该任务所有成员
    "child": [ //子数据块
        {
            "_id": "6343c4e343bda03f9bb7e286",
            "type": "自定义字符串，如矩形，表示本数据块的内容用于表示矩形",
            "content": "矩形相关信息",
            "ext": "扩展内容",
            "ts": 1665385684713273100, //时间戳
            "userid": "619b5e7aad09312848edf691", //用户id
            "taskid": "63311fe3f1bc8bca482b5c22", //任务id，如果不为空则推送消息到该任务所有成员
            "child": [ ],
        },
        {
            "_id": "6343c4e343bda03f9bb7e286",
            "type": "自定义字符串，如图钉，表示本数据块的内容用于表示矩形",
            "content": "图钉相关信息",
            "ext": "扩展内容",
            "ts": 1665385684713273100, //时间戳
            "userid": "619b5e7aad09312848edf691", //用户id
            "taskid": "63311fe3f1bc8bca482b5c22", //任务id，如果不为空则推送消息到该任务所有成员
            "child": [
                      {
                        "_id": "6343c4e343bda03f9bb7e286",
                        "type": "图钉的评论",
                        "content": "评论的相关信息",
                        "ext": "扩展内容",
                        "ts": 1665385684713273100, //时间戳
                        "userid": "619b5e7aad09312848edf691", //用户id
                        "taskid": "63311fe3f1bc8bca482b5c22", //任务id，如果不为空则推送消息到该任务所有成员
                        "child": [ ],
                      },
                      {
                        "_id": "6343c4e343bda03f9bb7e286",
                        "type": "图钉的评论",
                        "content": "评论的相关信息",
                        "ext": "扩展内容",
                        "ts": 1665385684713273100, //时间戳
                        "userid": "619b5e7aad09312848edf691", //用户id
                        "taskid": "63311fe3f1bc8bca482b5c22", //任务id，如果不为空则推送消息到该任务所有成员
                        "child": [ ],
                      },
            ],
        },
    ]
}
*/
