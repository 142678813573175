export function getPathDir(path: string) {
  const idx = path.lastIndexOf('/');
  if (idx > -1) {
    return path.slice(0, idx);
  }
  return '';
}

export function getPathName(path: string = '') {
  return path.substring(path.lastIndexOf('/') + 1);
}

export function getPathExtension(path: string) {
  const pathName = getPathName(path);
  return pathName
    .substring(pathName.lastIndexOf('.') + 1)
    .trim()
    .toLowerCase();
}

export function makeSureMDFExtension(path: string, ext: string) {
  const idx = path.lastIndexOf('/');
  const idx2 = path.lastIndexOf('.');
  if (idx2 < idx) {
    return path + '.' + ext;
  }
  const suffix = path
    .slice(idx2 + 1)
    .trim()
    .toLowerCase();
  if (suffix === ext || suffix === 'prm' || !Number.isNaN(Number(suffix))) {
    return path.slice(0, idx2) + '.' + ext;
  } else {
    return path + '.' + ext;
  }
}

export function makeSureExtension(path: string, ext: string) {
  const idx = path.lastIndexOf('/');
  const idx2 = path.lastIndexOf('.');
  if (idx2 < idx) {
    return path + '.' + ext;
  }
  return path.slice(0, idx2) + '.' + ext;
}

export function isConvResultSuccess(result: string) {
  if (result) {
    try {
      return JSON.parse(result).status === 0;
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
  return false;
}

export function newName(path: string, num: number, isDir: boolean): string {
  if (isDir) {
    return `${path}(${num})`;
  } else {
    const name = getPathName(path);
    let dir = getPathDir(path);
    if (dir !== '') {
      dir = dir + '/';
    }
    const idx = name.lastIndexOf('.');
    if (idx > -1) {
      return `${dir}${name.slice(0, idx)}(${num}).${name.slice(idx + 1)}`;
    } else {
      return `${path}(${num})`;
    }
  }
}
