import { doInitDtlStatus, dtlStatus, updateDtlVerLog } from '@/services/datalib';
import {
  dtlTaskShareGetShareInfo,
  dtlTaskShareOpenShare,
  dtlTaskShareUpdateAccessCode,
  dtlTaskShareVerifyExt,
} from '@/services/datalib/api/taskShare';
import { OpenType } from '@/services/datalib/bean/OpenType';
import { TaskShare } from '@/services/datalib/bean/TaskShare';
import { isConvResultSuccess } from '@/services/datalib/bean/util';
import { doErrResponse } from '@/services/datalib/ctrl/util';
import { Err, TaskShareStatus } from '@/services/datalib/enum';

export async function taskShareOpenShare(
  body: DtlTaskShare.OpenShareParam,
): Promise<DtlComm.Result<DtlBean.TaskShare | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  let status = 0;
  if (body.isOpen) {
    status |= TaskShareStatus.IsOpen;
  }
  if (body.needPwd) {
    status |= TaskShareStatus.NeedPwd;
  }
  if (body.includeComment) {
    status |= TaskShareStatus.IncludeComment;
  }
  if (body.includeFileInfo) {
    status |= TaskShareStatus.IncludeFileInfo;
  }
  if (body.includePin) {
    status |= TaskShareStatus.IncludePin;
  }
  if (body.includeSrcData) {
    status |= TaskShareStatus.includeSrcData;
  }
  const response = await dtlTaskShareOpenShare(Object.assign({}, body, { status }));
  const { code, message, data, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    const taskShare = new TaskShare(data);
    return {
      code: Err.OK[0],
      data: taskShare.format(),
      message: Err.OK[1],
    };
  }
  return doErrResponse(code, message);
}

export async function taskShareAdd(
  body: DtlTaskShare.AddParam,
): Promise<DtlComm.Result<DtlBean.TaskShare | undefined>> {
  return doErrResponse(Err.SERVER_ERROR[0], Err.SERVER_ERROR[1]);
  // const err = await doInitDtlStatus();
  // if (err) return err;
  // const response = await dtlTaskShareAdd(body);
  // const { code, message, data, verLog } = response;
  // if (code === Err.OK[0]) {
  //   if (verLog) {
  //     updateDtlVerLog(verLog);
  //   }
  //   const taskShare = new TaskShare(data);
  //   return {
  //     code: Err.OK[0],
  //     data: taskShare.format(),
  //     message: Err.OK[1],
  //   };
  // }
  // return doErrResponse(code, message);
}

export async function taskShareUpdateAccessCode(
  body: DtlTaskShare.UpdateAccessCodeParam,
): Promise<DtlComm.Result<DtlBean.TaskShare | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const response = await dtlTaskShareUpdateAccessCode(body);
  const { code, message, data, verLog } = response;
  if (code === Err.OK[0]) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    const taskShare = new TaskShare(data);
    return {
      code: Err.OK[0],
      data: taskShare.format(),
      message: Err.OK[1],
    };
  }
  return doErrResponse(code, message);
}

// export async function taskShareVerify(
//   body: DtlTaskShare.VerifyParam,
// ): Promise<DtlComm.Result<DtlBean.TaskShare | undefined>> {
//   //const err = await doInitDtlStatus();
//   //if (err) return err;
//   const response = await dtlTaskShareVerify(body);
//   const { code, message, data, verLog } = response;
//   if (code === Err.OK[0]) {
//     if (verLog) {
//       updateDtlVerLog(verLog);
//     }
//     const taskShare = new TaskShare(data);
//     return {
//       code: Err.OK[0],
//       data: taskShare.format(),
//       message: Err.OK[1],
//     };
//   }
//   return doErrResponse(code, message);
// }

export async function taskShareVerifyExt(
  body: DtlTaskShare.VerifyParam,
): Promise<DtlComm.Result<DtlBean.TaskShare | undefined>> {
  const response = await dtlTaskShareVerifyExt(body);
  const { code, message, data, verLog } = response;
  if (code === Err.OK[0] && data) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    dtlStatus.setOpenTypes(data.convPrograms);
    const taskShare = new TaskShare(data.share);
    taskShare.updateTaskFile(data.taskFile);
    return {
      code: Err.OK[0],
      data: taskShare.format(),
      message: Err.OK[1],
    };
  }
  return doErrResponse(code, message);
}

export async function taskShareGetShareInfo(
  body: DtlTaskShare.GetShareInfoParam,
): Promise<DtlComm.Result<DtlBean.TaskShare | undefined>> {
  const response = await dtlTaskShareGetShareInfo(body);
  const { code, message, data, verLog } = response;
  if (code === Err.OK[0] && data) {
    if (verLog) {
      updateDtlVerLog(verLog);
    }
    const taskShare = new TaskShare(data);
    return {
      code: Err.OK[0],
      data: taskShare.format(),
      message: Err.OK[1],
    };
  }
  return doErrResponse(code, message);
}

export function taskShareGetFileOpenInfo(body: {
  taskFile: DtlBean.TaskFile;
}): DtlComm.Result<{ openWay: string; conv?: DtlBean.Conv } | undefined> {
  const { taskFile } = body;
  if (!taskFile) {
    return {
      code: Err.REQUEST_DATA_ERROR[0],
      data: undefined,
      message: Err.REQUEST_DATA_ERROR[1],
    };
  }
  const file = taskFile;
  const serverFailed = { code: Err.SERVER_ERROR[0], data: undefined, message: Err.SERVER_ERROR[1] };
  let openType: OpenType | undefined;
  let program: string = '';
  let openWay = file.openWay;
  if (openWay) {
    const arr = openWay.split('/');
    const sid = Number(arr[0]);
    openType = dtlStatus.openTypes.getSupportType(sid);
    if (!openType) {
      return serverFailed;
    }
    if (arr.length >= 3) {
      program = `${arr[1]}/${arr[2]}`;
    } else if (arr.length >= 2) {
      program = `${arr[1]}`;
    } else {
      return { code: Err.OK[0], data: { openWay, conv: undefined }, message: Err.OK[1] };
    }
    if (file.convs) {
      const index = file.convs.findIndex(
        (c) => (c.program === program || c.originP === program) && isConvResultSuccess(c.result),
      );
      if (index > -1) {
        const conv = file.convs[index];
        return { code: Err.OK[0], data: { openWay, conv: conv }, message: Err.OK[1] };
      }
    }
  } else {
    openType = dtlStatus.openTypes.getDefaultType(file.path, file.type);
    if (!openType) {
      return serverFailed;
    }
    program = openType.programName;
    if (!program) {
      return {
        code: Err.OK[0],
        data: { openWay: `${openType.sid}`, conv: undefined },
        message: Err.OK[1],
      };
    } else {
      if (file.convs) {
        const index = file.convs.findIndex(
          (c) => c.originP.split('/')[0] === program && isConvResultSuccess(c.result),
        );
        if (index > -1) {
          const conv = file.convs[index];
          return {
            code: Err.OK[0],
            data: { openWay: `${openType.sid}/${conv.program}`, conv: conv },
            message: Err.OK[1],
          };
        }
      }
    }
  }
  return serverFailed;
}
