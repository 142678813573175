import request from '@/utils/request';
import API_VERSION from '@/services/datalib/api/config';

export async function dtlTaskGroupAddGroup(body: DtlTaskGroup.AddGroupParam) {
  return request<DtlComm.ResultV<DtlTaskGroup.AddGroupResult>>(
    `${API_VERSION}/api/user/taskGroup/addGroup`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlTaskGroupUpdateGroupName(body: DtlTaskGroup.UpdateGroupNameParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/user/taskGroup/updateGroupName`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskGroupDelGroup(body: DtlTaskGroup.DelGroupParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/user/taskGroup/delGroup`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskGroupMoveTaskToGroup(body: DtlTaskGroup.MoveTaskParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/user/taskGroup/moveTask`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskArchiveAddGroup(body: DtlTaskGroup.AddGroupParam) {
  return request<DtlComm.ResultV<DtlTaskGroup.AddGroupResult>>(
    `${API_VERSION}/api/user/taskArchive/addGroup`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlTaskArchiveUpdateGroupName(body: DtlTaskGroup.UpdateGroupNameParam) {
  return request<DtlComm.ResultV<undefined>>(
    `${API_VERSION}/api/user/taskArchive/updateGroupName`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlTaskArchiveDelGroup(body: DtlTaskGroup.DelGroupParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/user/taskArchive/delGroup`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskArchiveMoveTaskToGroup(body: DtlTaskGroup.MoveTaskParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/user/taskArchive/moveTask`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskGroupGetTaskTree() {
  return request<DtlComm.ResultV<DtlBean.TaskGroups>>(
    `${API_VERSION}/api/user/taskGroup/getTaskTree`,
    {
      method: 'POST',
    },
  );
}

export async function dtlTaskGroupArchiveTask(body: DtlTaskGroup.ArchiveTaskParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/user/taskGroup/archiveTask`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskGroupUnarchiveTask(body: DtlTaskGroup.ArchiveTaskParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/user/taskGroup/unarchiveTask`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskGroupArchiveTaskGroup(body: DtlTaskGroup.ArchiveTaskGroupParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/user/taskGroup/archiveTaskGroup`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskGroupUnarchiveTaskGroup(body: DtlTaskGroup.ArchiveTaskGroupParam) {
  return request<DtlComm.ResultV<undefined>>(
    `${API_VERSION}/api/user/taskGroup/unarchiveTaskGroup`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlTaskGroupUpdateSeq(body: DtlTaskGroup.UpdateSeqParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/user/taskGroup/updateSeq`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskArchiveUpdateSeq(body: DtlTaskGroup.UpdateSeqParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/user/taskArchive/updateSeq`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}
