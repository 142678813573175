export class Dept {
  id: string = '';
  name: string = '';
  createTs: number = 0;
  modifyTs: number = 0;
  depts: Dept[] = [];
  userIds: string[] = [];

  constructor(t?: DtlBean.Dept) {
    if (!t) {
      return;
    }
    if (t.id) {
      this.id = t.id;
    }
    if (t.name) {
      this.name = t.name;
    }
    if (t.createTs) {
      this.createTs = t.createTs;
    }
    if (t.modifyTs) {
      this.modifyTs = t.modifyTs;
    }
    if (t.depts) {
      this.depts = t.depts.map((a) => new Dept(a));
    }
    if (t.userIds) {
      this.userIds = [...t.userIds];
    }
  }

  format(): DtlBean.Dept {
    return {
      id: this.id,
      name: this.name,
      createTs: this.createTs,
      modifyTs: this.modifyTs,
      depts: this.depts.map((a) => a.format()),
      userIds: [...this.userIds],
    };
  }
}
