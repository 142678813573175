import { Msg } from '@/services/datalib/bean/Msg';

export class Msgs {
  msgs: Msg[] = [];

  constructor(msgs?: DtlBean.Msg[]) {
    if (!msgs) return;
    this.msgs = msgs.map((m) => new Msg(m));
  }

  format(): DtlBean.Msg[] {
    return this.msgs.map((m) => m.format());
  }

  formatSome(): DtlBean.Msg[] {
    return this.msgs.slice(-5).map((m) => m.format());
  }

  copyFrom(msgs: Msgs) {
    if (!msgs) return;
    this.msgs = msgs.msgs.map((m) => m.copy());
  }

  addMsg(msg: Msg) {
    if (!this.msgs.some((m) => m.msgId === msg.msgId)) {
      this.msgs.push(msg);
    }
  }

  addReply(replyId: string, msg: Msg) {
    if (!msg) return;
    const index = this.msgs.findIndex((m) => m.msgId === replyId);
    if (index === -1) {
      return;
    }
    const replyMsg = this.msgs[index];
    if (!replyMsg.subMsgs.some((m) => m.msgId === msg.msgId)) {
      replyMsg.subMsgs.push(msg);
    }
  }

  deleteMsg(deleteId: string) {
    if (!deleteId) return;
    for (let i = 0; i < this.msgs.length; i += 1) {
      const msg = this.msgs[i];
      if (msg.msgId === deleteId) {
        this.msgs.splice(i, 1);
        return;
      } else {
        for (let n = 0; n < msg.subMsgs.length; n += 1) {
          const sMsg = msg.subMsgs[n];
          if (sMsg.msgId === deleteId) {
            msg.subMsgs.splice(n, 1);
            return;
          }
        }
      }
    }
  }
}
