import API_VERSION from '@/services/datalib/api/config';
import request from '@/utils/request';

export async function dtlCategoryGet(body: DtlCategory.GetParam) {
  return request<DtlComm.ResultV<DtlBean.Category>>(`${API_VERSION}/api/category/get`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlCategoryAdd(body: DtlCategory.AddParam) {
  return request<DtlComm.ResultV<DtlBean.Category>>(`${API_VERSION}/api/category/add`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlCategoryAddChild(body: DtlCategory.AddChildParam) {
  return request<DtlComm.ResultV<DtlCategory.CategoryResult>>(
    `${API_VERSION}/api/category/addChild`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlCategoryUpdateChild(body: DtlCategory.UpdateChildParam) {
  return request<DtlComm.ResultV<DtlCategory.CategoryResult>>(
    `${API_VERSION}/api/category/updateChild`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlCategoryDeleteChild(body: DtlCategory.DeleteChildParam) {
  return request<DtlComm.ResultV<DtlCategory.CategoryResult>>(
    `${API_VERSION}/api/category/deleteChild`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlCategorySetRead(body: DtlCategory.SetReadParam) {
  return request<DtlComm.ResultV<DtlBean.Category>>(`${API_VERSION}/api/category/setRead`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}
