const processLabels = {
  Alphawave: '预览',
  Normalize_Execute: '标准化',
  Amplify_Execute: '增幅（放大）',
  Cut_Execute: '裁剪',
  CutReverse_Execute: '裁剪',
  BandPassIrrFir_Execute: '带通滤波',
  BandStopIrrFir_Execute: '带阻滤波',
  LowPassIrrFir_Execute: '低通滤波',
  HighPassIrrFir_Execute: '高通滤波',
  Loudness_Execute: '响度归一化',
  NotchFilter_Execute: '陷波滤波器',
  SmoothMoving_Execute: 'Moving平滑',
  SmoothSG_Execute: 'SG平滑',
  Smooth_Execute: '平滑',
  Silence_Execute: '静音',
  Trim_Execute: '静音',
  Chirp_Execute: '啁啾声',
  DTMFTones_Execute: 'DTMF音',
  Noise_Execute: '噪音',
  Tone_Execute: '单音',
  NoiseReduction_Execute: '降噪',
  remove: '删除',
  add: '新增',
  LowPass_Execute: '低通滤波器',
  HighPass_Execute: '高通滤波器',
  LowPassFilter_Execute: '低通滤波器',
  HighPassFilter_Execute: '高通滤波器',
  FilterCurveEQ_Execute: '陷波曲线均衡器',
};

export default {
  'wav.customData': '自定义数据',
  'wav.OtherType': '其他类型',
  'wav.nowType': '当前类型',
  'wav.autoType': '自动识别类型',
  'wav.table.channel.title': '通道',
  'wav.table.samplingRate.title': '采样率',
  'wav.table.length.title': '长度(秒)',
  'wav.table.description': '说明',
  'wav.table.unit.title': '单位',
  'wav.table.unit.desc': '可根据实际情况自定义波形单位',
  'wav.table.startTime.title': '开始时间(秒)',
  'wav.table.startTime.desc': '可自定义波形开始时间',
  'wav.table.scaleFactor.title': '缩放系数',
  'wav.table.scaleFactor.desc': '可Y轴上放大/缩小波形',
  'wav.table.deviation.title': '偏差值',
  'wav.table.deviation.desc': '可自定义Y轴偏差值，Y轴上平移波形',
  'wav.sequenceStep': '序列步骤',
  'wav.selectItem.one': '已选',
  'wav.selectItem.two': '项',
  'wav.SequenceSteps': '序列步骤',
  'wav.settings': '设置',
  'wav.setting.frequencyRange': '频率范围(Hz)',
  'wav.setting.resolution': '分辨率',
  'wav.setting.window': '窗口',
  'wav.setting.type': '类型',
  'wav.setting.weightMethod.title': '计权方式',
  'wav.setting.weightMethod.lineWay': '线性',
  'wav.setting.weightMethod.AWay': 'A计权',
  'wav.setting.weightMethod.BWay': 'B计权',
  'wav.setting.weightMethod.CWay': 'C计权',
  'wav.setting.octaveMode': '倍频程方式',
  'wav.tool.HPF': '高通滤波',
  'wav.tool.LPF': '低通滤波',
  'wav.tool.BPF': '带通滤波',
  'wav.tool.BSF': '带阻滤波',
  'wav.tool.SMOOTH_MOVING': 'Moving平滑',
  'wav.tool.SMOOTH_SG': 'SG平滑',
  'wav.process': '过程',
  'wav.info': '信息',
  'wav.processTool': '处理工具',
  'wav.cutoffFrequency': '截止频率(Hz)',
  'wav.passbandFrequency': '通带频率(Hz)',
  'wav.bandPass.lower': '通带较小频率（Hz）',
  'wav.bandPass.upper': '通带较大频率（Hz）',
  'wav.waveFilter': '滤波器',
  'wav.filterType': '滤波类型',
  'wav.filterOrder': '滤波器阶数',
  'wav.ripple': '波纹（dB）',
  'wav.ZeroPhaseOffset': '零相位偏移',
  'wav.windowType': '窗口类型',
  'wav.windowSize': '窗口大小',
  'wav.average': '平均数',
  'wav.median': '中位数',
  'wav.computingMethod': '计算方法',
  'wav.windowSize.left': '窗口左侧大小',
  'wav.windowSize.right': '窗口右侧大小',
  'wav.order': '阶数',
  'wav.frequency': '频率',
  ...processLabels,
};
