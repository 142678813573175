export default {
  'pages.login.BigTitle': '魔数云',
  'pages.login.desc': '云端专业数据协作全新体验，统一的可交互数据文件云格式',
  'pages.login.company': '2022 珠海精实测控技术股份有限公司',
  'pages.login.loginTab': '登录',
  'pages.login.registerTab': '注册',
  'pages.login.username.placeholder': '手机号或邮箱',
  'pages.login.username.format': '用户名格式错误',
  'pages.login.password.placeholder': '密码',
  'pages.login.username.required': '请输入用户名!',
  'pages.login.password.required': '请输入密码!',
  'pages.login.rememberMe': '记住我',
  'pages.login.forgotPassword': '忘记密码?',
  用户名或密码错误: '用户名或密码错误',
  'pages.login.captchaLogin': '验证码登录',
  'pages.login.passwordLogin': '密码登录',
  'pages.login.existAccount': '已有账号',
  'pages.login.loginFail': '无法登录?',
  'pages.login.registerPhone.required': '请输入手机号',
  'pages.login.registerPhone.placeholder': '11位手机号',
  'pages.login.phoneNumber.invalid': '手机号格式错误',
  'pages.login.captcha.placeholder': '验证码',
  'pages.login.phoneLogin.getVerificationCode': '获取验证码',
  'pages.login.captcha.required': '请输入验证码!',
  'pages.login.captcha.invalid': '验证码格式错误',
  'pages.login.registerLogin': '注册并登录',
  'pages.login.phoneLogin.errorMessage': '验证码错误',
  'pages.login.second': '秒',
  'pages.login.captcha.success.hasCode': '获取验证码成功！验证码为： ',
  'pages.login.captcha.success.hasNoCode': '发送验证码成功！',
  'pages.login.captcha.sendBusy': '验证码发送频繁，请稍后再试！',
  'pages.login.captcha.sendFail': '获取验证码失败！',
  'pages.login.loginFail.failTitle': '登录时遇到问题',
  'pages.login.loginFail.failDesc':
    '当您遇到无法登录请检查网络连接和输入是否有问题，如果仍然无法解决，请联系020-8632288',
  'pages.login.confirm': '确定',
  'pages.login.forgotPassword.title': '忘记密码',
  'pages.login.forgotPassword.username': '账号:',
  'pages.login.forgotPassword.captcha': '验证码:',
  'pages.login.forgotPassword.password': '密码:',
  'pages.login.forgotPassword.confirmPassword': '确认密码:',
  'pages.login.forgotPassword.username.placeholder': '手机号或邮箱',
  'pages.login.forgotPassword.captcha.placeholder': '验证码',
  'pages.login.forgotPassword.password.placeholder': '6位以上，至少包含两种格式',
  'pages.login.forgotPassword.confirmPassword.placeholder': '请再次输入密码',
  'pages.login.forgotPassword.confirmPassword.differentError': '两次密码输入不一致！',
  'pages.login.forgotPassword.nextStep': '下一步',
  'pages.login.forgotPassword.cancel': '取消',
  'pages.login.forgotPassword.save': '保存',
  'pages.registerLoginError': '注册失败，请重试!',
  'pages.loginError': '登录失败，请重试!',
  'pages.login.forgotPassword.username.placeholder.two': '手机号或邮箱',
  'pages.login.registerPhone.required.two': '请输入手机号或邮箱',
  'pages.login.phoneNumber.invalid.two': '账号格式错误',
  'passwordLogin': '密码登录',
  'phoneL': '手机登录',
  'emailL': '邮箱登录',
  'dingL': '钉钉登录',
  'register': '注册账号',
  'pages.login.email.login': '邮箱',
  'pages.login.email.login.placeholder': '请输入邮箱',
  'pages.login.email.invalid': '邮箱格式错误',
  'pages.login.ding.tip': '请使用 钉钉 扫二维码登录',
  'pages.login.ding.refresh': '刷新',
  'phoneR': '手机注册',
  'emailR': '邮箱注册',
};
