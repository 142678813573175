import { FileRecordStatus, RecentOrCollect } from '@/services/datalib';
import { FileRecord } from '@/services/datalib/bean/FileRecord';

function sort(a: FileRecord, b: FileRecord) {
  return b.ts - a.ts;
}

export class FileRecords {
  recentFiles: FileRecord[] = [];
  collectFiles: FileRecord[] = [];
  recentFilesM: Record<string, FileRecord> = {};
  collectFilesM: Record<string, FileRecord> = {};

  constructor(recentFiles?: DtlBean.FileRecord[], collectFiles?: DtlBean.FileRecord[]) {
    if (collectFiles) {
      this.collectFiles = collectFiles
        .filter((r) => r.s !== FileRecordStatus.Deleted)
        .map((r) => {
          const fr = new FileRecord(r);
          fr.isCollected = true;
          fr.collectId = fr.id;
          this.collectFilesM[r.fid] = fr;
          return fr;
        });
    }
    if (recentFiles) {
      this.recentFiles = recentFiles.map((r) => {
        const fr = new FileRecord(r);
        const cr = this.collectFilesM[fr.fid];
        if (cr && cr.s !== FileRecordStatus.Deleted) {
          fr.isCollected = true;
          fr.collectId = cr.id;
        }
        this.recentFilesM[fr.fid] = fr;
        return fr;
      });
    }
    this.recentFiles.sort(sort);
  }

  getFileRecord(recentOrCollect: string, fileId?: string): FileRecord | undefined {
    if (!fileId) {
      return undefined;
    }
    let f: FileRecord | undefined;
    if (recentOrCollect === RecentOrCollect.RecentFiles) {
      f = this.recentFilesM[fileId];
    } else if (recentOrCollect === RecentOrCollect.CollectFiles) {
      f = this.collectFilesM[fileId];
    }
    if (f && f.s !== FileRecordStatus.Deleted) {
      return f;
    }
    return undefined;
  }

  addFileRecord(recentOrCollect: string, fileRecord: DtlBean.FileRecord) {
    const fr = new FileRecord(fileRecord);
    if (recentOrCollect === RecentOrCollect.RecentFiles) {
      const cr = this.collectFilesM[fr.fid];
      if (cr && cr.s !== FileRecordStatus.Deleted) {
        fr.isCollected = true;
        fr.collectId = cr.id;
      }
      const idx = this.recentFiles.findIndex((r) => r.fid === fr.fid);
      if (idx > -1) {
        this.recentFiles.splice(idx, 1);
      }
      this.recentFiles.unshift(fr);
      this.recentFilesM[fr.fid] = fr;
    } else if (recentOrCollect === RecentOrCollect.CollectFiles) {
      fr.isCollected = true;
      fr.collectId = fr.id;
      const f = this.recentFilesM[fr.fid];
      if (f) {
        f.isCollected = true;
        f.collectId = fr.id;
      }
      const idx = this.collectFiles.findIndex((s) => s.fid === fr.fid);
      if (idx > -1) {
        this.collectFiles.splice(idx, 1);
      }
      this.collectFiles.unshift(fr);
      this.collectFilesM[fr.fid] = fr;
    }
    this.recentFiles.sort(sort);
  }

  deleteFileRecord(recentOrCollect: string, id: string) {
    if (recentOrCollect === RecentOrCollect.RecentFiles) {
      const idx = this.recentFiles.findIndex((s) => s.id === id);
      if (idx > -1) {
        this.recentFiles[idx].s = FileRecordStatus.Deleted;
      }
    } else if (recentOrCollect === RecentOrCollect.CollectFiles) {
      const idx = this.collectFiles.findIndex((s) => s.id === id);
      if (idx > -1) {
        const fr = this.collectFiles[idx];
        delete this.collectFilesM[fr.fid];
        this.collectFiles.splice(idx, 1);
        const f = this.recentFilesM[fr.fid];
        if (f) {
          f.isCollected = false;
          f.collectId = '';
        }
      }
    }
  }
}
