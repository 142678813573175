import type { FileRecord } from '@/services/datalib/bean/FileRecord';

export class CollectFolder {
  id: string = '';
  name: string = '';
  ts: number = 0;
  fileRecord: FileRecord[] = [];

  constructor(t?: DtlBean.CollectFolder) {
    if (!t) {
      return;
    }
    this.id = t.id;
    this.name = t.name;
    this.ts = t.ts;
  }

  format(): DtlBean.CollectFolder {
    return {
      id: this.id,
      name: this.name,
      ts: this.ts,
      fileRecord: this.fileRecord.map((f) => f.format()),
    };
  }
}
