// eslint-disable-next-line max-classes-per-file
class SC {
  column_Product: number = -1;
  column_SerialNumber: number = -1;
  column_Station_ID: number = -1;
  column_StartTime: number = -1;
}

class SR {
  row_GaugeId: number = -1;
  row_Upper_Limit: number = -1;
  row_Lower_Limit: number = -1;
  row_Data_start: number = -1;
}

class GrrSample {
  sc: SC;
  sr: SR;

  arr: any[];
  SerialNumberColumn: any[];
  StationIdColumn: any[];
  StartTimeColumn: any[];
  columnIndex: number = 0;
  testcasePerSample: number = 0;
  operators: number = 0;
  trials: number = 0;
  parts: number = 0;

  repeatability: number = 0;
  reproducibility: number = 0;
  gageRR: number = 0;
  result: string = '';
  sigmaPart: number = 0;
  sigmaFactor: number = 0;
  sigmaPxf: number = 0;
  sigmaError: number = 0;

  ucLr: number = 0;
  ev: number = 0;
  av: number = 0;
  rr: number = 0;
  rbar: number = 0;
  tol: number = 0;
  xDiff: number = 0;

  get partName(): string {
    const arr = this.gaugeId.split(' ');
    return arr[arr.length - 1].replace('@', '-');
  }

  get sigmaEv(): number {
    return this.ev / 5.15;
  }

  get sigmaAv(): number {
    return this.av / 5.15;
  }

  get sigmaRr(): number {
    return this.rr / 5.15;
  }

  get gaugeId(): string {
    if (this.arr.length > this.sr.row_GaugeId && NotNull(this.arr[this.sr.row_GaugeId])) {
      return String(this.arr[this.sr.row_GaugeId]);
    }
    return '';
  }

  get upperLimit(): number {
    if (this.arr.length > this.sr.row_Upper_Limit && IsNumber(this.arr[this.sr.row_Upper_Limit])) {
      return Number(this.arr[this.sr.row_Upper_Limit]);
    }
    return 0;
  }

  get lowerLimit(): number {
    if (this.arr.length > this.sr.row_Lower_Limit && IsNumber(this.arr[this.sr.row_Lower_Limit])) {
      return Number(this.arr[this.sr.row_Lower_Limit]);
    }
    return 0;
  }

  get isEffective(): boolean {
    if (this.arr.length > this.sr.row_Upper_Limit && this.arr.length > this.sr.row_Lower_Limit) {
      return (
        IsNumber(this.arr[this.sr.row_Upper_Limit]) && IsNumber(this.arr[this.sr.row_Lower_Limit])
      );
    }
    return false;
  }

  constructor(
    column: any[],
    columnIndex: number,
    testcasePerSample: number,
    testers: number,
    testTimes: number,
    SerialNumberColumn: any[],
    StationIdColumn: any[],
    StartTimeColumn: any[],
    sc: SC,
    sr: SR,
  ) {
    this.arr = column;
    this.SerialNumberColumn = SerialNumberColumn;
    this.StationIdColumn = StationIdColumn;
    this.StartTimeColumn = StartTimeColumn;
    this.columnIndex = columnIndex;
    this.testcasePerSample = testcasePerSample;
    this.operators = testers;
    this.trials = testTimes;
    this.sc = sc;
    this.sr = sr;
    this.update();
  }

  updateTableData(arr: number[][]) {
    if (arr.length !== this.arr.length - this.sr.row_Data_start) {
      throw new Error('data format error');
    }
    for (let i = 0; i < arr.length; i += 1) {
      this.arr[i + this.sr.row_Data_start] = Number(arr[i]);
    }
    this.update();
  }

  isCorrect(rValue: number | string): boolean {
    return Number(rValue) <= this.ucLr;
  }

  update() {
    const allTrials = this.arr.length - this.sr.row_Data_start;
    const xBars = [];
    for (let i = 0; i < this.operators; i += 1) {
      xBars[i] = 0;
    }

    let minR = Infinity;
    let maxR = -Infinity;
    let totalR = 0;
    let totalV = 0;
    const rows = [];
    let rowSum = 0;
    const trials = [];
    let trialsSum = 0;
    for (let i = this.sr.row_Data_start; i < this.arr.length; i += 1) {
      if (IsNumber(this.arr[i])) {
        const value = Number(this.arr[i]);
        rowSum += value;
        totalV += value;
        trialsSum += value;
        if (minR > value) {
          minR = value;
        }
        if (maxR < value) {
          maxR = value;
        }
        if ((i - this.sr.row_Data_start) % this.trials === this.trials - 1) {
          totalR += maxR - minR;
          minR = Infinity;
          maxR = -Infinity;
          trials.push(trialsSum);
          trialsSum = 0;
        }

        const times = Math.floor((i - this.sr.row_Data_start) / this.trials);
        const tester = times % this.operators;
        xBars[tester] += value;
        if (tester + 1 === this.operators) {
          rows.push(rowSum);
          rowSum = 0;
        }
      }
    }

    let xBarMax = xBars[0];
    let xBarMin = xBarMax;
    for (let i = 1; i < xBars.length; i += 1) {
      const value = xBars[i];
      if (xBarMax < value) {
        xBarMax = value;
      }
      if (xBarMin > value) {
        xBarMin = value;
      }
    }
    const xBarSpieces = Math.floor(allTrials / this.operators);
    const XbarDiff = (xBarMax - xBarMin) / xBarSpieces;

    const RBarSpieces = Math.floor(allTrials / this.trials);
    const Rbar = totalR / RBarSpieces;

    let D4 = 2.58;
    if (this.trials === 2) {
      D4 = 3.27;
    } else if (this.trials === 3) {
      D4 = 2.58;
    }
    this.ucLr = Rbar * D4;

    let K1 = 3.05;
    if (this.trials === 2) {
      K1 = 4.56;
    } else if (this.trials === 3) {
      K1 = 3.05;
    }
    const EV = Rbar * K1;

    let K2 = 2.7;
    if (this.operators === 2) {
      K2 = 3.65;
    } else if (this.operators === 3) {
      K2 = 2.7;
    }
    const n = Math.floor(allTrials / this.trials / this.operators);
    const AV = Math.sqrt(Math.abs(XbarDiff * K2 * (XbarDiff * K2) - (EV * EV) / n / this.trials));

    let RR = 0;
    if (Number.isNaN(AV)) {
      RR = Math.sqrt(EV * EV);
    } else {
      RR = Math.sqrt(EV * EV + AV * AV);
    }
    const TOL = this.upperLimit - this.lowerLimit;
    const repeatability = (EV / TOL) * 100;
    let reproducibility = NaN;
    if (!Number.isNaN(AV)) {
      reproducibility = (AV / TOL) * 100;
    }
    const gageRR = (RR / TOL) * 100;

    let result = 'Excellent';
    if (RR <= 0.1 * TOL) {
      result = 'Excellent';
    } else if (RR <= 0.2 * TOL) {
      result = 'Adequate';
    } else if (RR <= 0.3 * TOL) {
      result = 'Marginal';
    } else {
      result = 'Unaccept';
    }

    const k = this.operators;
    const r = this.trials;
    const DF_F = k - 1;
    const DF_P = n - 1;
    const DF_PF = (n - 1) * (k - 1);
    const DF_E = n * k * r - 1;

    let knr = 0;
    for (let i = 0; i < xBars.length; i += 1) {
      const avg = xBars[i] / xBarSpieces;
      knr += (avg * avg) / n / r;
    }
    const x2_nkr = ((totalV / k / r) * (totalV / k / r)) / n / k / r;
    const SSf = knr - x2_nkr;

    let nkr = 0;
    for (let i = 0; i < rows.length; i += 1) {
      const avg = rows[i] / k / r;
      nkr += (avg * avg) / k / r;
    }
    const SSp = nkr - x2_nkr;

    let nk_r = 0;
    for (let i = 0; i < trials.length; i += 1) {
      const avg = trials[i] / r;
      nk_r += (avg * avg) / r;
    }
    const SSpf = nk_r - nkr - knr + x2_nkr;

    let nkrx2 = 0;
    for (let i = this.sr.row_Data_start; i < this.arr.length; i += 1) {
      nkrx2 += this.arr[i] * this.arr[i];
    }
    const SSt = nkrx2 - x2_nkr;

    const SSe = SSt - SSf - SSp - SSpf;

    const MSp = SSp / DF_P;
    const MSf = SSf / DF_F;
    const MSpf = SSpf / DF_PF;
    const MSe = SSe / DF_E;

    this.sigmaPart = Math.sqrt(Math.abs(((MSp - MSpf) / k) * r));
    this.sigmaFactor = Math.sqrt(Math.abs(((MSf - MSpf) / n) * r));
    this.sigmaPxf = Math.sqrt(Math.abs((MSpf - MSe) / r));
    this.sigmaError = Math.sqrt(Math.abs(MSe));
    this.repeatability = repeatability;
    this.reproducibility = reproducibility;
    this.gageRR = gageRR;
    this.result = result;
    this.ev = EV;
    this.av = AV;
    this.rr = RR;
    this.rbar = Rbar;
    this.tol = TOL;
    this.xDiff = XbarDiff;
    this.parts = n;
  }

  getSummary() {
    return [
      this.gaugeId,
      this.lowerLimit,
      this.upperLimit,
      this.repeatability,
      this.reproducibility,
      this.gageRR,
      this.result,
      this.sigmaPart,
      this.sigmaFactor,
      this.sigmaPxf,
      this.sigmaError,
    ];
  }

  getTableData() {
    const data = [];
    let minR = Infinity;
    let maxR = -Infinity;

    let row = [];
    for (let i = this.sr.row_Data_start; i < this.arr.length; i += 1) {
      const value = Number(this.arr[i]);
      row.push(value);
      if (IsNumber(value)) {
        if (minR > value) {
          minR = value;
        }
        if (maxR < value) {
          maxR = value;
        }
      }
      if ((i - this.sr.row_Data_start) % this.trials === this.trials - 1) {
        row.push(maxR - minR);
        minR = Infinity;
        maxR = -Infinity;
      }
      if (((i - this.sr.row_Data_start) % this.testcasePerSample) + 1 === this.testcasePerSample) {
        data.push(row);
        row = [];
      }
    }
    return data;
  }

  getUnitPlot() {
    const labelList = [];
    const unitList = [];
    const midList = [];
    const avgList = [];
    let row = [];
    let sum = 0;
    let sumCnt = 0;
    for (let i = this.sr.row_Data_start; i < this.arr.length; i += 1) {
      const value = Number(this.arr[i]);
      row.push(value);
      if (IsNumber(value)) {
        sum += value;
        sumCnt += 1;
      }
      if (((i - this.sr.row_Data_start) % this.testcasePerSample) + 1 === this.testcasePerSample) {
        row.sort((a, b) => {
          if (IsNumber(a) && IsNumber(b)) {
            return a - b;
          }
          return 0;
        });
        midList.push(row[Math.floor(row.length / 2)]);
        // avgList.push(sum / sumCnt);
        unitList.push(row);
        labelList.push(this.SerialNumberColumn[i]);
        row = [];
        // sum = 0;
        // sumCnt = 0;
      }
    }
    const avg = sum / sumCnt;
    for (let i = 0; i < labelList.length; i += 1) {
      avgList[i] = avg;
    }
    return {
      unitList,
      labelList,
      midList,
      avgList,
    };
  }

  getFactorPlot() {
    const result: any[] = [];
    for (let i = 0; i < this.operators; i += 1) {
      result[i] = [];
    }
    for (let i = this.sr.row_Data_start; i < this.arr.length; i += 1) {
      const index = i - this.sr.row_Data_start;
      result[Math.floor((index / this.trials) % this.operators)].push(Number(this.arr[i]));
    }
    return result;
  }

  getSerialNumberData() {
    const result = [];
    let rowData = [];
    for (let i = this.sr.row_Data_start; i < this.arr.length; i += 1) {
      const value = this.arr[i];
      rowData.push(value);
      if (((i - this.sr.row_Data_start) % this.testcasePerSample) + 1 === this.testcasePerSample) {
        result.push({
          data: rowData,
          serialNumber: this.SerialNumberColumn[i],
          stationId: this.StationIdColumn[i],
          startTime: this.StartTimeColumn[i - this.testcasePerSample + 1],
        });
        rowData = [];
      }
    }
    return result;
  }
}

export function grrAnalysis(twoDimData: any[][]) {
  const sc = new SC();
  const sr = new SR();
  let cCnt = 0;
  twoDimData.forEach((row) => {
    if (cCnt < row.length) {
      cCnt = row.length;
    }
  });
  const rCnt = twoDimData.length;
  if (rCnt < 7 || cCnt < 5) {
    return undefined;
  }

  for (let n = 0; n < rCnt; n += 1) {
    for (let i = 0; i < twoDimData[n].length; i += 1) {
      const v = twoDimData[n][i];
      if (!v) {
        // eslint-disable-next-line no-continue
        continue;
      }
      const val = `${v}`.trim().toLowerCase();
      switch (val) {
        case 'product':
          sc.column_Product = i;
          break;
        case 'serialnumber':
          sc.column_SerialNumber = i;
          break;
        case 'station id':
          sc.column_Station_ID = i;
          break;
        case 'starttime':
        case 'test start time':
          sc.column_StartTime = i;
          break;
        // case 'parametric':
        //   sc.column_Parametric = i;
        //   break;
        default:
          break;
      }
    }
    if (
      sc.column_SerialNumber === -1 ||
      sc.column_StartTime === -1 ||
      sc.column_Station_ID === -1 ||
      sc.column_Product === -1
    ) {
      if (n + 1 === rCnt) {
        return undefined;
      }
    } else {
      sr.row_GaugeId = n;
      break;
    }
  }

  for (let n = 0; n < rCnt; n += 1) {
    const content = twoDimData[n][0];
    if (NotNull(content)) {
      const val = `${content}`.trim().toLowerCase();
      if (val.indexOf('upper limit') !== -1) {
        sr.row_Upper_Limit = n;
      } else if (val.indexOf('lower limit') !== -1) {
        sr.row_Lower_Limit = n;
      } else if (val.indexOf('----') === -1 && sr.row_Lower_Limit > -1 && sr.row_Upper_Limit > -1) {
        sr.row_Data_start = n;
        break;
      }
    }
  }
  if (
    sr.row_GaugeId === -1 ||
    sr.row_Data_start === -1 ||
    sr.row_Lower_Limit === -1 ||
    sr.row_Upper_Limit === -1
  ) {
    return undefined;
  }

  const productColumn = [];
  const SerialNumberColumn = [];
  const StationIdColumn = [];
  const StartTimeColumn = [];

  for (let i = 0; i < rCnt; i += 1) {
    if (NotNull(twoDimData[i][sc.column_Product])) {
      productColumn[i] = twoDimData[i][sc.column_Product];
    }
    if (NotNull(twoDimData[i][sc.column_SerialNumber])) {
      SerialNumberColumn[i] = twoDimData[i][sc.column_SerialNumber];
    }
    if (NotNull(twoDimData[i][sc.column_Station_ID])) {
      StationIdColumn[i] = twoDimData[i][sc.column_Station_ID];
    }
    if (NotNull(twoDimData[i][sc.column_StartTime])) {
      StartTimeColumn[i] = twoDimData[i][sc.column_StartTime];
    }
  }

  let testcasePerSample = 1;
  for (let i = sr.row_Data_start + 1; i < SerialNumberColumn.length; i += 1) {
    if (SerialNumberColumn[sr.row_Data_start] === SerialNumberColumn[i]) {
      testcasePerSample += 1;
    } else {
      break;
    }
  }
  let testers = 3;
  let testTimes = 3;
  if (testcasePerSample === 9) {
    testers = 3;
    testTimes = 3;
  } else if (testcasePerSample === 6) {
    testers = 2;
    testTimes = 3;
  } else if (testcasePerSample === 4) {
    testers = 2;
    testTimes = 2;
  } else {
    testers = 1;
    testTimes = testcasePerSample;
  }

  const guageColumnList = [];
  for (let i = 0; i < cCnt; i += 1) {
    if (
      !(
        IsNumber(twoDimData[sr.row_Lower_Limit][i]) &&
        IsNumber(twoDimData[sr.row_Upper_Limit][i]) &&
        NotNull(twoDimData[sr.row_GaugeId][i]) &&
        NotNull(twoDimData[sr.row_Data_start][i])
      )
    ) {
      continue;
    }
    const column = [];
    for (let j = 0; j < rCnt; j += 1) {
      if (NotNull(twoDimData[j][i])) {
        column[j] = twoDimData[j][i];
      }
    }
    const grrSample = new GrrSample(
      column,
      i,
      testcasePerSample,
      testers,
      testTimes,
      SerialNumberColumn,
      StationIdColumn,
      StartTimeColumn,
      sc,
      sr,
    );
    if (grrSample.isEffective) {
      guageColumnList.push(grrSample);
    }
    // if (grrSample.gaugeId.indexOf("FR@9000") > -1) {
    //   console.log("getSummary", grrSample.getSummary())
    //   console.log("getTableData", grrSample.getTableData())
    //   console.log("getUnitPlot", grrSample.getUnitPlot())
    //   console.log("getFactorPlot", grrSample.getFactorPlot())
    //   console.log("partName", grrSample.partName)
    // }
  }

  return {
    Product: productColumn[sr.row_Data_start],
    Milestone: '',
    TestSuit: StationIdColumn[sr.row_Data_start],
    Units: (rCnt - sr.row_Data_start) / testcasePerSample,
    Factor: '',
    Trials: testTimes,
    data: guageColumnList,
  };
}

function NotNull(s: any) {
  return s !== undefined && s !== null && s !== '';
}

function IsNumber(s: any) {
  return s !== undefined && s !== null && s !== '' && !Number.isNaN(Number(s));
}

export function parseLuckysheet(data: { v: number }[][]) {
  const rs = new Array(data.length);
  for (let i = 0; i < data.length; i += 1) {
    if (data[i]) {
      const row = data[i];
      const drow = new Array(row.length);
      rs[i] = drow;
      for (let j = 0; j < row.length; j += 1) {
        if (row[j]) {
          drow[j] = row[j].v;
        }
      }
    }
  }
  return rs;
}
