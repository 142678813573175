import { Err } from '@/services/datalib/enum';
import { dtlWavRange } from '@/services/datalib/api/wav';
import { isDev } from '@/services/datalib/ctrl/util';
import { doInitDtlStatus } from '@/services/datalib';

export async function wavRange(
  body: DtlWav.RangeParam,
): Promise<DtlComm.Result<DtlWav.RangeResult | undefined>> {
  const err = await doInitDtlStatus();
  if (err) return err;
  const failed = {
    code: Err.SERVER_ERROR[0],
    data: undefined,
    message: Err.SERVER_ERROR[1],
  };
  let ab = await dtlWavRange(body);

  if (!ab || !ab.byteLength) {
    return failed;
  }

  let success = false;
  const uint32Arr = new Uint16Array(ab.slice(0, 8));
  if (uint32Arr[0] === 0 && uint32Arr[1] === 0 && uint32Arr[2] === 0 && uint32Arr[3] === 0) {
    success = true;
  }

  if (!success) {
    try {
      const str = await ab2str(ab);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      isDev() && console.log('wavRange failed:', str);
      return JSON.parse(str);
    } catch (e) {
      console.error(e);
    }
    return failed;
  }
  ab = ab.slice(8);

  const int32Arr = new Float32Array(ab.slice(0, ab.byteLength - 20));
  const data = new Array(int32Arr.length);
  int32Arr.forEach((value, index) => {
    data[index] = value;
  });
  const info = new Float32Array(ab.slice(ab.byteLength - 20));
  let itemSize = 4;
  const level = info[4];
  if (level === 0) {
    itemSize = 1;
  }
  const count = data.length / itemSize;

  return {
    code: Err.OK[0],
    data: {
      data,
      count,
      start: 0,
      itemSize,
      level,
      t0: body.t0,
      t1: body.t1,
    },
    message: Err.OK[1],
  };
}

// ArrayBuffer转字符串
async function ab2str(u: ArrayBuffer): Promise<string> {
  const b = new Blob([u]);
  const r = new FileReader();
  r.readAsText(b, 'utf-8');
  return new Promise((rs) => {
    r.onload = function () {
      rs(r.result as string);
    };
  });
}
