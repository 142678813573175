export default {
  'scic.data': '数据',
  'scic.dataSource': '科学计算数据源',
  'scic.max': '最大值',
  'scic.min': '最小值',
  'scic.average': '平均值',
  'scic.total': '计数',
  'scic.sum': '求和',
  'scic.interpolation': '插值类',
  'scic.calculation': '计算类',
  'scic.drag': '拖拽工具，添加快捷方式',
  'scic.interpolation.piecewise': '分段插值',
  'scic.interpolation.piecewise.linear': '线性插值',
  'scic.interpolation.piecewise.quadratic': '二次插值',
  'scic.interpolation.piecewise.cubic': 'Cubic插值',
  'scic.interpolation.octave': '倍频程插值',
  'scic.interpolation.octave.time': '插值倍数',
  'scic.interpolation.octave.selectTime': '选择频率数据',
  'scic.interpolation.octave.selectTimeTip': '其他数据将使用线性插值',
  'scic.interpolation.octave.selectDataByCol': '按列取数据',
  'scic.interpolation.octave.timeUnit': '倍频程',
  'scic.interpolation.octave.timeOne': '1/1 倍频程',
  'scic.interpolation.octave.timeTwo': '1/2 倍频程',
  'scic.interpolation.octave.timeThree': '1/3 倍频程',
  'scic.interpolation.octave.twoNumberError': '每组数据至少需要两个数字,请选择其他方式取数据',
  'scic.interpolation.smartOctave': '智能化倍频程插值',
  'scic.interpolation.smartOctave.tip': '说明：此操作将自动识别 Frequency 和 Limit 列，并进行插值',
  'scic.interpolation.addSheetName': '插值',
  'scic.modal.octave.chart': '生成图表',
  'scic.modal.piecewise.title': '是否生成插值对比图？',
  'scic.modal.piecewise.choose': '记住选择，关闭前不再询问',
  'scic.modal.piecewise.no': '不生成',
  'scic.modal.piecewise.yes': '生成',
  'scic.chart': '图表类',
  'scic.chart.line': '折线图',
  'scic.chart.line.disable': '选中2行2列以上数据后可生成折线图',
  'scic.chart.line.modalTitle': '插入折线图',
  'scic.chart.line.selectX': '选择X轴数据',
  'scic.chart.delete': '删除',
  'scic.transposition': '转置',
  'scic.paint': '画图',
};
