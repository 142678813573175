import API_VERSION from '@/services/datalib/api/config';
import request from '@/utils/request';

export async function dtlTaskCreateTask(body: DtlTask.CreateTaskParam) {
  return request<DtlComm.ResultV<{ taskId: string }>>(`${API_VERSION}/api/task/createTask`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskGetTask(body: DtlTask.GetTaskParam) {
  return request<DtlComm.ResultV<DtlTask.TaskResultV>>(`${API_VERSION}/api/task/getTask`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskUpdateTaskName(body: DtlTask.UpdateTaskNameParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/updateTaskName`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskUpdateTaskDesc(body: DtlTask.UpdateTaskDescParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/updateTaskDesc`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskAddTaskLabels(body: DtlTask.AddTaskLabelsParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/addTaskLabels`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskDelTaskLabels(body: DtlTask.DelTaskLabelsParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/delTaskLabels`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskAddTaskMembers(body: DtlTask.AddTaskMembersParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/addTaskMembers`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskInviteTaskMembers(body: DtlTask.InviteTaskMembersParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/inviteTaskMembers`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskAgreeInviteTaskMembers(body: DtlTask.AgreeInviteTaskMembersParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/agreeInviteTaskMembers`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskDelTaskMembers(body: DtlTask.DelTaskMembersParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/delTaskMembers`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskDelTaskInvitees(body: DtlTask.DelTaskInviteesParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/delTaskInvitees`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskAddTaskAttachments(body: DtlTask.AddTaskAttachmentsParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/addTaskAttachments`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskDelTaskAttachments(body: DtlTask.DelTaskAttachmentsParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/delTaskAttachments`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskDismissTask(body: DtlTask.DismissTaskParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/dismissTask`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskQuitTask(body: DtlTask.DismissTaskParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/quitTask`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

// export async function dtlTaskUpdateRole(body: DtlTask.UpdateRoleParam) {
//   return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/updateRole`, {
//     method: 'POST',
//     data: {
//       ...body,
//     },
//   });
// }

export async function dtlTaskSendMsg(body: DtlTask.SendMsgParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/sendMsg`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskReplyMsg(body: DtlTask.ReplyMsgParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/replyMsg`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskDeleteMsg(body: DtlTask.DeleteMsgParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/deleteMsg`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskGetMsgList(body: DtlTask.GetMsgListParam) {
  return request<DtlComm.ResultV<DtlTask.GetMsgListResult>>(`${API_VERSION}/api/task/getMsgList`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}
