import { getOssFileBuffer } from '@/services/datalib';
import { getPathExtension, makeSureExtension } from '@/services/datalib/bean/util';
import { AsyncZippable, gunzip, gzip, strFromU8, strToU8, zip } from 'fflate';
import { read, set_cptable, utils, write } from 'xlsx';

// @ts-ignore
import Papa from 'papaparse';

// @ts-ignore
import * as cptable from 'xlsx/dist/cpexcel.full.mjs';

set_cptable(cptable);

export async function gzipStrToU8(str: string): Promise<{ err: Error | null; data: Uint8Array }> {
  return new Promise((rs) =>
    gzip(
      strToU8(str),
      {
        level: 1,
        consume: true,
      },
      (err, data) => rs({ err, data }),
    ),
  );
}

export async function gunzipStrFromU8(
  uint8Array: Uint8Array,
): Promise<{ err: Error | null; data: string }> {
  return new Promise((rs) =>
    gunzip(uint8Array, { consume: true }, (err, data) => rs({ err, data: strFromU8(data) })),
  );
}

export async function zipExportData(
  str: string,
  images?: Record<string, Blob>,
): Promise<{ err: Error | null; data: Uint8Array }> {
  if (!images) {
    return gzipStrToU8(str);
  }

  const unzipData: AsyncZippable = {};
  unzipData['data.json'] = strToU8(str);
  const keys = Object.keys(images);
  for (let i = 0; i < keys.length; i++) {
    const blob = images[keys[i]];
    const buf = await blob.arrayBuffer();
    unzipData[keys[i]] = new Uint8Array(buf);
  }
  return new Promise((rs) => {
    zip(
      unzipData,
      {
        level: 4,
        consume: true,
      },
      (err, data) => {
        rs({ err, data });
      },
    );
  });
}

export async function readFile(file: any): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = () => {
      resolve(fr.result as ArrayBuffer);
    };
    fr.onerror = reject;
    fr.readAsArrayBuffer(file);
  });
}

export async function getSheetDataFromFile(
  file: File,
  needSheetInfo?: boolean,
): Promise<Array<any>[]> {
  const buffer = await readFile(file);
  return readXlsx(file.name, buffer as ArrayBuffer, needSheetInfo);
}

export async function getSheetDataFromServer(
  fileUrl: string,
  needSheetInfo?: boolean,
): Promise<Array<any>[]> {
  const buffer = await getOssFileBuffer(fileUrl);
  return readXlsx(fileUrl, buffer, needSheetInfo);
}

export async function getCsvFromData(arr: Array<any>[]): Promise<string> {
  return utils.sheet_to_csv(
    utils.json_to_sheet(arr, {
      skipHeader: true,
      dateNF: `YYYY-MM-DD HH:mm:ss`,
    }),
    { rawNumbers: true, skipHidden: true, dateNF: `YYYY-MM-DD HH:mm:ss` },
  );
}

const EXT_ARR = [
  'xlsx',
  'xlsm',
  'xlsb',
  'xls',
  'xla',
  'biff8',
  'biff5',
  'biff2',
  'xlml',
  'ods',
  'fods',
  'csv',
  'txt',
  'sylk',
  'slk',
  'html',
  'dif',
  'rtf',
  'prn',
  'eth',
  'dbf',
];

export async function getFileFromSheetData(arr: Array<any>[], fileName: string): Promise<File> {
  let ext = getPathExtension(fileName);
  if (!EXT_ARR.some((e) => e === ext)) {
    ext = EXT_ARR[0];
  }
  fileName = makeSureExtension(fileName, ext);
  const ws = utils.json_to_sheet(arr, { skipHeader: true, dateNF: `YYYY-MM-DD HH:mm:ss` });
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, ws, fileName.split('.')[0]);
  const uint8Array = write(workbook, { type: 'buffer', compression: true, bookType: ext as any });
  return new window.File([uint8Array.buffer], fileName);
}

export async function getSheetsFileFromSheetData(
  fileName: string,
  nameList: string[],
  dataList: (string | number)[][][],
): Promise<File> {
  let ext = getPathExtension(fileName);
  if (!EXT_ARR.some((e) => e === ext)) {
    ext = EXT_ARR[0];
  }
  fileName = makeSureExtension(fileName, ext);
  const workbook = utils.book_new();
  const length = nameList.length;
  for (let i = 0; i < length; i++) {
    const ws = utils.json_to_sheet(dataList[i], {
      skipHeader: true,
      dateNF: `YYYY-MM-DD HH:mm:ss`,
    });
    utils.book_append_sheet(workbook, ws, nameList[i]);
  }
  const uint8Array = write(workbook, { type: 'buffer', compression: true, bookType: ext as any });
  return new window.File([uint8Array.buffer], fileName);
}

function readXlsx(pathOrUrl: string, buf: ArrayBuffer, needSheetInfo: boolean = false): any {
  const ext = getPathExtension(pathOrUrl);
  if (ext != 'csv') {
    const workbook = read(buf, { type: 'binary', cellDates: true });
    const sheetNames = workbook.SheetNames;
    if (!needSheetInfo) {
      const worksheet = workbook.Sheets[sheetNames[0]];
      return utils.sheet_to_json<Array<any>>(worksheet, { header: 1 });
    }
    const dataList: any[][] = [];
    for (let name of sheetNames) {
      const worksheet = workbook.Sheets[name];
      dataList.push(utils.sheet_to_json<Array<any>>(worksheet, { header: 1 }));
    }
    return {
      nameList: sheetNames,
      dataList: dataList,
    };
  } else {
    console.time('csv->array');
    const rs = Papa.parse(decBufferToStr(buf));
    console.timeEnd('csv->array');
    if (rs.errors && rs.errors.length > 0) {
      throw Error('Papa.parse error' + JSON.stringify(rs));
    }
    const twoDimData = rs.data as any[][];
    fixTwoDimData(twoDimData);
    if (!needSheetInfo) {
      return twoDimData;
    } else {
      return {
        nameList: ['Sheet1'],
        dataList: [twoDimData],
      };
    }
  }
}

export function decBufferToStr(buf: ArrayBuffer) {
  const uint8Arr = new Uint8Array(buf);
  let csvStr = '';
  if (uint8Arr.length < 10 * 1024 * 1024) {
    let cp = 'utf-8';
    if (!isUTF8(uint8Arr)) {
      cp = 'gbk';
    }
    csvStr = new TextDecoder(cp).decode(uint8Arr);
  } else {
    csvStr = new TextDecoder('utf-8').decode(uint8Arr);
    if (csvStr.indexOf('�') > -1) {
      csvStr = new TextDecoder('gbk').decode(uint8Arr);
    }
  }
  return csvStr;
}

export function fixTwoDimData(twoDimData: any[][]) {
  if (twoDimData && twoDimData.length > 0) {
    const lastRow = twoDimData[twoDimData.length - 1];
    if (lastRow.length === 1 && lastRow[0] === '') {
      twoDimData.splice(twoDimData.length - 1, 1);
    }
  }
}

export function jsonToCsv(twoDimData: any[][]): string {
  console.time('jsonToCsv');
  const str = Papa.unparse(twoDimData);
  console.timeEnd('jsonToCsv');
  return str;
}

export function isUTF8(bytes: Uint8Array) {
  var i = 0;
  while (i < bytes.byteLength) {
    if (
      // ASCII
      bytes[i] == 0x09 ||
      bytes[i] == 0x0a ||
      bytes[i] == 0x0d ||
      (0x20 <= bytes[i] && bytes[i] <= 0x7e)
    ) {
      i += 1;
      continue;
    }

    if (
      // non-overlong 2-byte
      0xc2 <= bytes[i] &&
      bytes[i] <= 0xdf &&
      0x80 <= bytes[i + 1] &&
      bytes[i + 1] <= 0xbf
    ) {
      i += 2;
      continue;
    }

    if (
      // excluding overlongs
      (bytes[i] == 0xe0 &&
        0xa0 <= bytes[i + 1] &&
        bytes[i + 1] <= 0xbf &&
        0x80 <= bytes[i + 2] &&
        bytes[i + 2] <= 0xbf) || // straight 3-byte
      (((0xe1 <= bytes[i] && bytes[i] <= 0xec) || bytes[i] == 0xee || bytes[i] == 0xef) &&
        0x80 <= bytes[i + 1] &&
        bytes[i + 1] <= 0xbf &&
        0x80 <= bytes[i + 2] &&
        bytes[i + 2] <= 0xbf) || // excluding surrogates
      (bytes[i] == 0xed &&
        0x80 <= bytes[i + 1] &&
        bytes[i + 1] <= 0x9f &&
        0x80 <= bytes[i + 2] &&
        bytes[i + 2] <= 0xbf)
    ) {
      i += 3;
      continue;
    }

    if (
      // planes 1-3
      (bytes[i] == 0xf0 &&
        0x90 <= bytes[i + 1] &&
        bytes[i + 1] <= 0xbf &&
        0x80 <= bytes[i + 2] &&
        bytes[i + 2] <= 0xbf &&
        0x80 <= bytes[i + 3] &&
        bytes[i + 3] <= 0xbf) || // planes 4-15
      (0xf1 <= bytes[i] &&
        bytes[i] <= 0xf3 &&
        0x80 <= bytes[i + 1] &&
        bytes[i + 1] <= 0xbf &&
        0x80 <= bytes[i + 2] &&
        bytes[i + 2] <= 0xbf &&
        0x80 <= bytes[i + 3] &&
        bytes[i + 3] <= 0xbf) || // plane 16
      (bytes[i] == 0xf4 &&
        0x80 <= bytes[i + 1] &&
        bytes[i + 1] <= 0x8f &&
        0x80 <= bytes[i + 2] &&
        bytes[i + 2] <= 0xbf &&
        0x80 <= bytes[i + 3] &&
        bytes[i + 3] <= 0xbf)
    ) {
      i += 4;
      continue;
    }
    return false;
  }
  return true;
}
