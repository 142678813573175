export default {
  'Enthalpy.nozzleDiameter.title': '喷嘴直径',
  'Enthalpy.nozzleDiameter': '喷嘴直径(mm)',
  'Enthalpy.nozzleNumber': '开启数量(个)',
  'Enthalpy.specs1': '规格一',
  'Enthalpy.specs2': '规格二',
  'Enthalpy.specs3': '规格三',
  'Enthalpy.specs4': '规格四',
  'Enthalpy.inputPlaceholder': '选填',
  'Enthalpy.params': '参数',
  'Enthalpy.insideDryTemp': '内置干球温度',
  'Enthalpy.NozzleHumidity': '喷嘴前温度',
  'Enthalpy.insideWetTemp': '内置湿球温度',
  'Enthalpy.NozzlePressure': '喷嘴压差',
  'Enthalpy.outletDryTemp': '出风干球温度',
  'Enthalpy.heatLeak': '漏热量',
  'Enthalpy.outletWetTemp': '出风湿球温度',
  'Enthalpy.airLeak': '漏风量',
  'Enthalpy.atmosphericPressure': '大气压力',
  'Enthalpy.coolOrHot': '制冷或制热',
  'Enthalpy.airBoxPressure': '内侧风箱静压',
  'Enthalpy.cool': '制冷',
  'Enthalpy.hot': '制热',
  'Enthalpy.Fs': '风速',
  'Enthalpy.BFs': '标准风速',
  'Enthalpy.Fn': '风量',
  'Enthalpy.BFn': '标准风量',
  'Enthalpy.OutH': '进风焓值',
  'Enthalpy.H': '焓差',
  'Enthalpy.Q': '能力',
  'Enthalpy.Qx': '显热量',
  'Enthalpy.Qq': '潜热量',
  'Enthalpy.Qs': '除湿量',
};
