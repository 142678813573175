const browserTip = {
  'browserTip.title': '存在兼容性问题，可能无法使用工具',
  'browserTip.tip': '建议升级到14以上版本，或使用Chrome浏览器',
  'browserTip.back': '返回',
};

const chirpModal = {
  'chirpModal.begin': '开始',
  'chirpModal.end': '结束',
  'chirpModal.interpolation': '插值:',
  'chirpModal.interpolation.line': '线性',
  'chirpModal.interpolation.log': '对数',
};

const noiseModal = {
  'noiseModal.noiseType': '噪音类型:',
  'noiseModal.noiseType.white': '白噪音',
  'noiseModal.noiseType.pink': '粉红噪音',
  'noiseModal.noiseType.brown': '布朗噪音',
};

const DTMFModal = {
  'DTMFModal.soundStatic': '音/静躁比:',
  'DTMFModal.duty': '占空比:',
  'DTMFModal.soundDuration': '音的持续时间:',
  'DTMFModal.emptyDuration': '空白持续时间:',
};

const common = {
  'common.oscillogram': '波形图',
  'common.oscillogram.sine': '正弦',
  'common.oscillogram.squareWave': '方波',
  'common.oscillogram.sawtooth': '锯齿',
  'common.oscillogram.squareWaveNoAlias': '方波，无混叠',
  'common.oscillogram.square': '三角形',
  'common.frequency': '频率(Hz):',
  'common.amplitude': '振幅(0-1):',
  'common.length': '长度:',
  'common.length.hour': '时',
  'common.length.minute': '分',
  'common.length.second': '秒',
  'common.DTMF': 'DTMF序列:',
};

const spectrumDiagramModal = {
  'spectrumDiagramModal.Title': '频谱图设置',
  'spectrumDiagramModal.length': '长度',
  'spectrumDiagramModal.color': '颜色',
  'spectrumDiagramModal.scale': '比例:',
  'spectrumDiagramModal.scale.line': '线性',
  'spectrumDiagramModal.scale.log': '对数',
  'spectrumDiagramModal.scale.mei': '梅尔刻度',
  'spectrumDiagramModal.scale.period': '周期',
  'spectrumDiagramModal.gain': '增益(dB):',
  'spectrumDiagramModal.minFrequency': '最低频率:',
  'spectrumDiagramModal.maxFrequency': '最高频率:',
  'spectrumDiagramModal.range': '范围(dB):',
  'spectrumDiagramModal.highBoost': '高频提升(dB/dec):',
  'spectrumDiagramModal.algorithm': '算法',
  'spectrumDiagramModal.algorithm.frequency': '频率',
  'spectrumDiagramModal.algorithm.reassign': '重新分配',
  'spectrumDiagramModal.algorithm.soundHigh': '音高(EAC)',
  'spectrumDiagramModal.windowSize': '窗口大小:',
  'spectrumDiagramModal.windowType': '窗口类型:',
  'spectrumDiagramModal.zeroFillFactor': '零填充因子:',
  'spectrumDiagramModal.rectangle': '矩形',
  'spectrumDiagramModal.windowSize.max': '最宽频带',
  'spectrumDiagramModal.windowSize.default': '默认',
  'spectrumDiagramModal.windowSize.min': '最窄频带',
  'spectrumDiagramModal.colorScheme': '颜色方案',
};

const filterCurveEqualizerModal = {
  'filterCurveEqualizerModal.preSet': '预设',
  'filterCurveEqualizerModal.preSet.default': '默认',
  'filterCurveEqualizerModal.preSet.100': '100Hz轰响',
  'filterCurveEqualizerModal.preSet.AM': 'AM广播',
  'filterCurveEqualizerModal.preSet.bassAmplification': '低音增幅',
  'filterCurveEqualizerModal.preSet.bassCutOff': '低音切除',
  'filterCurveEqualizerModal.preSet.voiceRollDown': '语音低滚降',
  'filterCurveEqualizerModal.preSet.phone': '电话',
  'filterCurveEqualizerModal.preSet.trebleAmplification': '高音增幅',
  'filterCurveEqualizerModal.preSet.trebleCutOff': '高音切除',
  'filterCurveEqualizerModal.preSet.walkie-talkie': '对讲机(walkie-talkie)',
  'filterCurveEqualizerModal.reverse': '反向',
  'filterCurveEqualizerModal.flatten': '变平坦',
  'filterCurveEqualizerModal.linearScale': '线性频率缩放',
  'filterCurveEqualizerModal.gridLines': '显示网格线',
};

const noiseReductionModal = {
  'noiseReductionModal.step1': '步骤 1',
  'noiseReductionModal.step1.desc': '选取几秒只有噪声的音频让信号处理知道过滤什么',
  'noiseReductionModal.step1.getNoiseCharacter.btn': '取得噪声特征',
  'noiseReductionModal.step1.getNoiseCharacter.select.placeholder': '没有选中噪声特征',
  'noiseReductionModal.step1.getNoiseCharacter.modal': '请框选一段作为噪声特征。',
  'noiseReductionModal.step2': '步骤 2',
  'noiseReductionModal.step2.desc':
    '请选择所有要过滤的音频，再选择要过滤掉多少噪音，然后单击"确定"来降噪。',
  'noiseReductionModal.step2.getAudio': '获取音频',
  'noiseReductionModal.step2.getAudio.modal': '请框选一段波形去降噪。',
  'noiseReductionModal.step2.getAudio.select.placeholder': '没有选择音频',
  'noiseReductionModal.denoiseIntensity': '降噪强度(dB)',
  'noiseReductionModal.sensitivity': '灵敏度',
  'noiseReductionModal.smoothFrequency': '频率平滑(频带)',
  'noiseReductionModal.noise': '噪声',
  'noiseReductionModal.noise.remove': '去除',
  'noiseReductionModal.noise.keep': '保留噪音',
  'noiseReductionModal.noiseTooShort': '选择的噪音样本太短',
  'noiseReductionModal.noiseRateNotSuit': '噪音样本的采样率必须和声音的匹配才能继续',
};

const processingTools = {
  'processingTools.standardization': '标准化',
  'processingTools.standardization.desc1': '移除DC偏移量(垂直居中于0.0)',
  'processingTools.standardization.desc2': '将峰值幅度标准化为',
  'processingTools.standardization.desc2.unit': '分贝',
  'processingTools.highPassFilter': '高通滤波器',
  'processingTools.lowPassFilter': '低通滤波器',
  'processingTools.notchFilter': '陷波滤波器',
  'processingTools.notchFilter.Q': 'Q (更高值将缩短宽度)',
  'processingTools.amplification': '增幅(放大)',
  'processingTools.amplification.gain': '增幅 (dB)',
  'processingTools.amplification.newPeak': '新的峰值幅度 (dB)',
  'processingTools.amplification.clippingDistortion': '允许截幅失真',
  'processingTools.filterCurveEqualizer': '滤波曲线均衡器',
  'processingTools.loudnessNormalization': '响度归一化',
  'processingTools.loudnessNormalization.standardization': '标准化',
  'processingTools.loudnessNormalization.perceivedLoudness': '感知的响度',
  'processingTools.loudnessNormalization.RMS': '方均根',
  'processingTools.loudnessNormalization.to': '到',
  'processingTools.loudnessNormalization.unit': '分贝',
  'processingTools.loudnessNormalization.desc': '将单声道视为双声道(推荐)',
  'processingTools.noiseReduction': '降噪',
  'processingTools.isNormalizeStereo': '分别标准化立体声的两个声道',
};

export default {
  'audioEditor.timeDomain': '时域',
  'audioEditor.frequencyDomain': '频域',
  'audioEditor.create': '生成',
  'audioEditor.chirp': '啁啾声',
  'audioEditor.noise': '噪音',
  'audioEditor.singleVideo': '单音',
  'audioEditor.DTMF': 'DTMF 音',
  'audioEditor.timeFrequency': '时频谱',
  'audioEditor.allView': '多视图',
  'audioEditor.spectrumDiagram.setting': '频谱图',
  'audioEditor.process': '过程',
  'audioEditor.preview': '预览',
  'audioEditor.copyAll': '复制过程及文件',
  'audioEditor.reset': '重新设置',
  'audioEditor.delete': '删除',
  ...chirpModal,
  ...noiseModal,
  ...common,
  ...DTMFModal,
  ...spectrumDiagramModal,
  ...processingTools,
  ...noiseReductionModal,
  ...filterCurveEqualizerModal,
  ...browserTip,
};
