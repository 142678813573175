const m: Record<string, Record<number, boolean>> = {};
const mt: Record<string, string> = {};

export function getAppNameWithId(appName: string, myType: string) {
  if (mt[myType]) {
    return mt[myType];
  }
  let id = -1;
  let md = m[appName];
  if (!md) {
    md = {};
    m[appName] = md;
  }
  for (let i = 0; i < 10000; i++) {
    if (!md[i]) {
      id = i;
      md[i] = true;
      break;
    }
  }
  if (id > -1) {
    mt[myType] = appName + '#' + id;
    return mt[myType];
  } else {
    throw new Error('microapp too many');
  }
}

export function freeAppNameWithId(appNameWithId: string) {
  const idx = appNameWithId.lastIndexOf('#');
  if (idx === -1) {
    return;
  }
  const appName = appNameWithId.slice(0, idx);
  const id = Number(appNameWithId.slice(idx + 1));
  if (Number.isNaN(id)) {
    return;
  }
  const md = m[appName];
  if (!md) {
    return;
  }
  md[id] = false;
}
