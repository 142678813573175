export class Attachment {
  attachmentId: string = '';
  creator: string = '';
  createTime: number = 0;
  type: number = 0;
  name: string = '';
  size: number = 0;
  url: string = '';
  content: string = '';

  constructor(a: DtlBean.Attachment) {
    if (!a) {
      return;
    }
    if (a.attachmentId) {
      this.attachmentId = a.attachmentId;
    }
    if (a.creator) {
      this.creator = a.creator;
    }
    if (a.createTime) {
      this.createTime = a.createTime;
    }
    if (a.type) {
      this.type = a.type;
    }
    if (a.name) {
      this.name = a.name;
    }
    if (a.size) {
      this.size = a.size;
    }
    if (a.url) {
      this.url = a.url;
    }
    if (a.content) {
      this.content = a.content;
    }
  }

  copy(): Attachment {
    return new Attachment({
      attachmentId: this.attachmentId,
      creator: this.creator,
      createTime: this.createTime,
      type: this.type,
      name: this.name,
      size: this.size,
      url: this.url,
      content: this.content,
    });
  }

  format(): DtlBean.Attachment {
    return {
      attachmentId: this.attachmentId,
      creator: this.creator,
      createTime: this.createTime,
      type: this.type,
      name: this.name,
      size: this.size,
      url: this.url,
      content: this.content,
    };
  }
}
