import request from '@/utils/request';
import API_VERSION from '@/services/datalib/api/config';

export async function dtlLogin(body: DtlLogin.LoginParam) {
  return request<DtlComm.ResultV<DtlLogin.LoginResult>>(`${API_VERSION}/login`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlRegister(body: DtlLogin.RegisterParam) {
  return request<DtlComm.ResultV<DtlLogin.RegisterResult>>(`${API_VERSION}/register`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlSendAuthCode(body: DtlLogin.SendAuthCodeParam) {
  return request<DtlComm.ResultV<DtlLogin.SendAuthCodeResult>>(`${API_VERSION}/sendAuthCode`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlValidateAuthCode(body: DtlLogin.ValidateAuthCodeParam) {
  return request<DtlComm.ResultV<DtlLogin.ValidateAuthCodeResult>>(
    `${API_VERSION}/validateAuthCode`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlUpdatePwd(body: DtlLogin.UpdatePwdParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/updatePwd`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlLogout() {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/logout`, {
    method: 'POST',
  });
}
