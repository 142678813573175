export default {
  'detailsSide.detail': 'Detail',
  'detailsSide.tool.title': 'Analysis Tools',
  'detailsSide.tool.select.all': 'All',
  'detailsSide.remark.title': 'Remarks',
  'detailsSide.remark.allPeople': 'All members',
  'detailsSide.info.title': 'Information',
  'detailsSide.info.size': 'Size',
  'detailsSide.info.openWay': 'Open with',
  'detailsSide.info.openWay.default': 'Default Preview',
  'detailsSide.info.openWay.select': 'Select',
  'detailsSide.info.uploadInfo': 'Upload',
  'detailsSide.remark.delete.desc': 'Confirm Delete Remarks?',
  'detailsSide.description': 'Description',
  'detailsSide.revise': 'Revise',
};
