const common = {
  'gridlab.oldSaved': '文件已覆盖更新',
  'gridlab.newSaved': '文件已保存',
  'gridlab.share': '发布',
  'gridlab.share.title': '链接发布',
  'gridlab.share.tip': '开启后自动复制链接，可直接发送给好友',
  'gridlab.share.copy': '复制链接',
  'gridlab.share.open': '打开链接',
  'gridlab.share.lastUpdateTime': '上次更新时间：',
  'gridlab.share.update': '更新',
  'gridlab.share.updated': '已更新',
  'gridlab.share.updateSuccess': '保存并更新成功',
  'gridlab.share.wait': '请稍候',
  'gridlab.share.loadFail': '读取分享信息失败',
  'gridlab.share.fail': '分享失败',
  'gridlab.share.readOnly': '仅阅读',
  'gridlab.share.canDownload': '可下载',
  'gridlab.share.updateFail': '更新分享信息失败',
  'gridlab.share.copySuccess': '复制链接成功',
  'gridlab.share.downloadSource': '下载源文件',
  'gridlab.source.loadFail': '读取源文件信息失败',
  'gridlab.login': '请先登录',
  'gridlab.share.before': '请上传至云端空间后再发布',
};

const grr = {
  'gridlab.grr.title': 'GRR 分析',
};

const scic = {
  'gridlab.scic.title': '科学计算',
};

export default {
  ...grr,
  ...common,
  ...scic,
};
