export default {
  'uploadModal.BigTitle': '文件上传',
  'uploadModal.addFile': '添加文件',
  'uploadModal.addFileByDir': '扫描文件夹',
  'uploadModal.dragUpload.tips': '拖拽文件或目录到此扫描',
  'uploadModal.table.fileName': '文件名',
  'uploadModal.table.fileDir': '文件目录',
  'uploadModal.table.fileSize': '文件大小',
  'uploadModal.table.status': '状态',
  'uploadModal.table.option': '操作',
  'uploadModal.table.empty': '暂无文件',
  'uploadModal.fileTotalNumber': '文件数量',
  'uploadModal.fileTotalSize': '总计大小',
  'uploadModal.totalProgress': '进度',
  'uploadModal.table.option.reUpload': '重新上传',
  'uploadModal.table.option.remove': '移除',
  'uploadModal.status.wait': '待上传',
  'uploadModal.status.cover': '将覆盖同名',
  'uploadModal.status.finish': '已上传',
  'uploadModal.status.fail': '上传失败',
  'uploadModal.status.scanFail': '扫描失败',
  'uploadModal.status.uploading': '正在上传',
  'uploadModal.status.cancel': '已取消',
  'uploadModal.closeBtn': '关闭',
  'uploadModal.uploadBtn': '上传全部',
  'uploadModal.fileNameInput.placeholder': '请输入文件名',
  'uploadModal.normalToSetting.notAllow': '普通文件不能存放到配置文件',
};
