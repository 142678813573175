export default {
  'spc.report.key': '序号',
  'spc.report.name': '名称',
  'spc.report.lower': '下限',
  'spc.report.upper': '上限',
  'spc.report.unit': '单位',
  'spc.report.result': 'BM',
  'spc.report.score': '分数',
  'spc.report.thumbnail': '直方图',
  'spc.exportResult': '导出结果',
  'spc.refreshResult': '刷新结果',
  'spc.refreshResultTips': '刷新将会覆盖当前的结果（新的结果可能由迭代后的算法得出）。',
};
