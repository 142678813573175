export default {
  'pages.taskList.title.myTask': 'My Tasks',
  'pages.taskList.newTaskGroup.placeholder': 'New Group',
  'pages.taskList.rename': 'Rename',
  'pages.taskList.move': 'Move to ...',
  'pages.taskList.addTask': 'New Task',
  'pages.taskList.addPorETask': 'New a personal/enterprise task',
  'pages.taskList.addTask.desc': 'Create tasks and upload data',
  'pages.taskList.addTask.modal.BigTitle': 'New Task',
  'pages.taskList.addTask.modal.taskName': 'Task Name',
  'pages.taskList.addTask.modal.taskType': 'Task Type',
  'pages.taskList.addTask.personalTask': 'Personal Task',
  'pages.taskList.addTask.enterpriseTask': 'Enterprise Task',
  'pages.taskList.addTask.allType': 'All Task',
  'pages.taskList.addTask.filterType': 'Filter Task',
  'pages.taskList.addTask.modal.taskName.placeholder': 'Task Name',
  'pages.taskList.addTask.modal.taskDesc': 'Task Description',
  'pages.taskList.addTask.modal.taskDesc.placeholder':
    'Briefly explain the purpose, method, content, experimental environment, and steps of the experiment',
  'pages.taskList.table.name': 'Task Name',
  'pages.taskList.table.name.placeholder': 'Enter a task name',
  'pages.taskList.table.desc': 'Task Description',
  'pages.taskList.table.creator': 'Creator',
  'pages.taskList.table.createTime': 'Create Time',
  'pages.taskList.newTaskGroup': 'New Group',
  'pages.taskList.newTaskGroup.input.placeholder': 'New task group name',
  'pages.taskList.empty': 'No Tasks',
  'pages.taskList.newTask': 'Create',
  'pages.taskList.noOtherGroup': 'No other group to move',
  'pages.taskLeftSide.title': 'Task',
  'pages.privateTask': 'Private Task',
  'pages.officialTask': 'MDC Official Task',
  'pages.newTask': 'Untitled Task',
  'pages.newFolder': 'Untitled Folder',
  'pages.newTaskGroup': 'Untitled Group',
  配置文件: 'Profile',
  原始数据: 'Original Data',
  处理结果: 'Analysis Results',
};
