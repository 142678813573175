import request from '@/utils/request';
import API_VERSION from '@/services/datalib/api/config';

export async function dtlTaskUserUpdateStatusInTask(body: DtlTaskUser.UpdateStatusInTaskParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/user/updateStatusInTask`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskUserUpdateExtInTask(body: DtlTaskUser.UpdateExtInTaskParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/user/updateExtInTask`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskUserUpdateRoleInTask(body: DtlTaskUser.UpdateRoleInTaskParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/user/updateRoleInTask`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskUserCreateLabelTemplate(body: DtlTaskUser.CreateLabelTemplateParam) {
  return request<DtlComm.ResultV<DtlTaskUser.CreateLabelTemplateResult>>(
    `${API_VERSION}/api/task/user/createLabelTemplate`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlTaskUserDelLabelTemplate(body: DtlTaskUser.DelLabelTemplateParam) {
  return request<DtlComm.ResultV<DtlTaskUser.CreateLabelTemplateResult>>(
    `${API_VERSION}/api/task/user/delLabelTemplate`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlTaskUserGetLabelTemplates() {
  return request<DtlComm.ResultV<DtlTaskUser.CreateLabelTemplateResult>>(
    `${API_VERSION}/api/task/user/getLabelTemplates`,
    {
      method: 'POST',
    },
  );
}

export async function dtlTaskUserUpdateTaskMark(body: DtlTaskUser.UpdateTaskMarkParam) {
  return request<DtlComm.ResultV<undefined>>(`${API_VERSION}/api/task/user/updateTaskMark`, {
    method: 'POST',
    data: {
      ...body,
    },
  });
}

export async function dtlTaskFetchMembersInfo(body: DtlTaskUser.FetchTaskMembersInfoParam) {
  return request<DtlComm.ResultV<DtlTaskUser.FetchTaskMembersInfoResult>>(
    `${API_VERSION}/api/task/user/fetchMembers`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlTaskUserUpdateTaskFileLastOpenTime(
  body: DtlTaskUser.UpdateTaskFileLastOpenTimeParam,
) {
  return request<DtlComm.ResultV<undefined>>(
    `${API_VERSION}/api/task/user/updateTaskFileLastOpenTime`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}

export async function dtlTaskUserUpdateTaskLastOpenTime(
  body: DtlTaskUser.UpdateTaskLastOpenTimeParam,
) {
  return request<DtlComm.ResultV<DtlTaskUser.UpdateTaskLastOpenTimeResult>>(
    `${API_VERSION}/api/task/user/updateTaskLastOpenTime`,
    {
      method: 'POST',
      data: {
        ...body,
      },
    },
  );
}
