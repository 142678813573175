import { Label } from '@/services/datalib/bean/Label';

export class LabelTpl {
  labels: Label[] = [];
  name: string = '';
  id: string = '';

  constructor(l?: DtlBean.LabelTpl) {
    if (!l) {
      return;
    }
    if (l.labels) {
      this.labels = l.labels.map((la) => new Label(la));
    }
    if (l.name) {
      this.name = l.name;
    }
    if (l.id) {
      this.id = l.id;
    }
  }

  copy() {
    const n = new LabelTpl();
    n.id = this.id;
    n.name = this.name;
    n.labels = this.labels.map((l) => l.copy());
    return n;
  }

  format(): DtlBean.LabelTpl {
    return {
      labels: this.labels.map((l) => l.format()),
      name: this.name,
      id: this.id,
    };
  }
}
