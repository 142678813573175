export default {
  'common.uploadData.title': '导入数据',
  'common.uploadData.single.title': '单文件导入',
  'common.uploadData.multiple.title': '多文件导入',
  'common.uploadData.dragIn.title': '拖入文件',
  'common.uploadData.multiple.desc': '拖拽文件或目录导入，支持本地文件',
  'common.uploadData.multiple.desc.gridlab': '拖拽文件，支持本地文件',
  'common.uploadData.selectFile.title': '选择文件',
  'common.uploadData.selectFile.desc': '点击任务导入或本地导入按钮后选择需要进行分析的文件',
  'common.uploadData.importFromTask': '任务导入',
  'common.uploadData.importFromLocal': '本地导入',
  'common.uploadData.hasUploaded.tip': '文件已导入，可拖入其他文件替换',
  'common.calculateData.title': '解析数据',
  'common.selectTemplate.tip': '未选择模板',
  'common.selectTemplate.tip.none': '无需模板',
  'common.dataMission': '数据任务',
  'common.dataMission.tip': '解析文件后得到数据任务',
  'common.dataMission.selectAll': '全部',
  'common.chartPage.dataItem.title': '数据项目',
  'common.chartPage.dataItem.data.title': '数据',
  'common.chartPage.dataItem.sensitivity.title': '单值',
  'common.chartPage.dataItem.noItem': '无数据项目',
  'common.chartPage.dataItem.select': '多选',
  'common.chartPage.dataItem.cancelSelect': '取消多选',
  'common.chartPage.dataItem.search': '搜索项目',
  'common.chartPage.dataItem.searchEmpty': '暂无匹配的项目',
  'common.chartPage.dataItem.all': '全部项目',
  'common.chartPage.noItem': '暂无项目',
  'common.chartPage.gridSetting': '网格线设置',
  'common.chartPage.grid.xMain': '主轴主要水平网格线',
  'common.chartPage.grid.yMain': '主轴主要垂直网格线',
  'common.chartPage.grid.xSecondary': '主轴次要水平网格线',
  'common.chartPage.grid.ySecondary': '主轴次要垂直网格线',
  'common.chartPage.overlimit.redCurve': '曲线标红',
  'common.chartPage.overlimit.redPoint': '点标红',
  'common.chartPage.mergeError': '合并图表出错',
  'common.analyseWay': '解析方式',
  'common.aircondition.result': '计算结果',
  'common.coordinate': '坐标',
  'common.unit': '单位',
  'common.CustomData': '自定义数据',
  'common.nowType': '当前类型',
  'common.OtherType': '其他类型',
  'common.channel': '通道',
  'common.channel.errorRate': '未设置正确的采样率',
  'common.shortcutCopy.ios': '请用command+c复制！',
  'common.shortcutCopy.windows': '请用ctrl+c复制！',
  'common.shortcutPaste.ios': '请用command+v粘贴！',
  'common.shortcutPaste.windows': '请用ctrl+v粘贴！',
  'common.onlyCsvSupport': '仅支持csv文件',
  'common.addChannel': '添加通道',
  'common.render.wait': '正在渲染，请稍候...',
  'commo.export.wait': '正在导出，请稍候...',
  'common.analysis.wait': '正在解析，请稍候...',
  'common.analysis.fail': '解析失败',
  'common.analysis.fail.tip': '请选择正确类型，打开此文件',
  'common.import.wait': '正在导入，请稍候...',
  'common.fileName.placeholder': '文件名',
  'common.openFail.incorrectType': '请选择正确类型打开',
  'common.pasteFormatError': '粘贴格式错误',
  'common.result': '导出结果',
  'common.CurveInterpolation': '曲线插值',
  'common.AdditionOperation': '加法运算',
  'common.AdditionOperation.placeholder': '请输入加数',
  'common.MultiplicationOperation': '乘法运算',
  'common.MultiplicationOperation.placeholder': '请输入乘数',
  'common.newTemplate': '数据解析模板',
  'common.customizedData': '自定义数据',
  'common.testRequire': '请填入完整模板数据!',
  'common.comma': '，',
  'common.autoLimit': '自动生成',
  'common.autoLimit.tip': '按照每频点均值的±4 Sigma生成',
  'common.autoLimit.applyAll': '应用到全部项目',
};
