export class Label {
  labelId: string = '';
  color: string = '';
  text: string = '';

  constructor(label: DtlBean.Label) {
    if (!label) {
      return;
    }
    if (label.labelId) {
      this.labelId = label.labelId;
    }
    if (label.color) {
      this.color = label.color;
    }
    if (label.text) {
      this.text = label.text;
    }
  }

  copy() {
    return new Label({ labelId: this.labelId, color: this.color, text: this.text });
  }

  format(): DtlBean.Label {
    return {
      labelId: this.labelId,
      color: this.color,
      text: this.text,
    };
  }
}
