export default {
  'pages.settings.BigTitle': 'Personal Information',
  'pages.settings.desc': 'Modify basic information',
  'pages.settings.backToIndex': 'Return',
  'pages.settings.changeAvatar': 'Click on the left to change the avatar',
  'pages.settings.accountInfo.tab': 'Account information',
  'pages.settings.userInfo.tab': 'Personal information',
  'pages.settings.accountInfo.userName': 'User',
  'pages.settings.accountInfo.email': 'Email',
  'pages.settings.accountInfo.account': 'Account',
  'pages.settings.accountInfo.phone': 'Phone',
  'pages.settings.accountInfo.changeBind': 'Binding',
  'pages.settings.accountInfo.bind': 'Binding',
  'pages.settings.accountInfo.mailNewBind': 'Bind a new email',
  'pages.settings.accountInfo.phoneNewBind': 'Bind a new phone',
  'pages.settings.accountInfo.mailCode': 'Email number',
  'pages.settings.accountInfo.mailSafeTip':
    'To ensure account security, please verify your current email address',
  'pages.settings.accountInfo.phoneSafeTip':
    'To ensure account security, please verify your current phone',
  'pages.settings.accountInfo.mailChangeBind': 'Change email binding',
  'pages.settings.accountInfo.phoneChangeBind': 'Change phone binding',
  'pages.settings.userInfo.company': 'Company',
  'pages.settings.userInfo.department': 'Dept',
  'pages.settings.userInfo.gender': 'Gender',
  'pages.settings.userInfo.address': 'Address',
  'pages.settings.userInfo.required': 'Please enter',
  'pages.settings.userInfo.gender.required': 'Select gender',
  'pages.settings.save': 'Save',
  'pages.settings.cancel': 'Cancel',
  'pages.settings.openPhoneAndEmail': 'Public phone number and email ',
  'pages.settings.hasSetPassword': 'Password has been set',
  'pages.settings.setPasswordBtn': 'Revise',
  'pages.settings.accountInfo.email.required': 'Please enter your email address',
  'pages.settings.accountInfo.email.error':
    'The email format is incorrect, please refer to example@xxx.com',
  'pages.settings.accountInfo.userName.required': 'Please enter user name',
  'pages.settings.avatar.uploadAvatar': 'Please upload your avatar',
  'pages.settings.avatar.uploadTip1': 'Supports up to ',
  'pages.settings.avatar.uploadTip2': 'M JPG and PNG image formats',
  'pages.settings.avatar.uploadError': 'Failed to upload the avatar!',
  'pages.settings.completeInfo': 'Please complete your personal information',
  'pages.settings.changePassword': 'Change Password',
  'pages.settings.avatar.formatTip': 'The avatar only supports JPG/PNG format!',
  'pages.settings.avatar.sizeLimitTip': 'Picture must be smaller than',
  'pages.settings.userName.formatTip': 'Special symbols and spaces are not supported',
  'pages.settings.gender.male': 'male',
  'pages.settings.gender.female': 'female',
};
