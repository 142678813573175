import { Anal } from '@/services/datalib/bean/Anal';

export class Anals {
  lastId: string = '';
  list: Anal[] = [];
  userId: string = '';
  ts: number = 0;

  constructor(t?: DtlBean.Anals) {
    if (!t) return;
    if (t.lastId) this.lastId = t.lastId;
    if (t.list) this.list = t.list.map((a) => new Anal(a));
    if (t.userId) this.userId = t.userId;
    if (t.ts) this.ts = t.ts;
  }

  format(): DtlBean.Anals | undefined {
    if (this.list.length === 0) {
      return undefined;
    }
    return {
      lastId: this.lastId,
      list: this.list.map((a) => a.format()),
      userId: this.userId,
      ts: this.ts,
    };
  }

  copy(): Anals {
    return new Anals(this.format());
  }
}
