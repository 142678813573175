export default {
  'Refrigerant.select.title': 'Refrigerant information',
  'Refrigerant.searchPlaceholder': 'Search for refrigerant',
  'Refrigerant.info.title': 'Basic information',
  'Refrigerant.info.CAS': 'CAS Number',
  'Refrigerant.info.chineseName': 'Chinese name',
  'Refrigerant.info.shortName': 'Shorter form',
  'Refrigerant.info.sortName': 'Category Name',
  'Refrigerant.info.sort': 'Sort',
  'Refrigerant.info.englishShortName': 'English abbreviation',
  'Refrigerant.info.englishName': 'English name',
  'Refrigerant.info.chemicalFormula': 'Chemical Molecular Formula',
  'Refrigerant.info.molecularWeight': 'Molecular weight',
  'Refrigerant.info.otherName': 'Alias',
  'Refrigerant.info.mol': 'Molar mass',
  'Refrigerant.info.threePhasePointHumidity': 'Three-phase point humidity',
  'Refrigerant.info.normalBoilingPoint': 'Standard boiling point',
  'Refrigerant.info.CRH': 'Critical humidity',
  'Refrigerant.info.criticalPressure': 'Critical pressure',
  'Refrigerant.info.criticalDensity': 'Critical density',
  'Refrigerant.info.eccentricityFactor': 'Eccentricity factor',
  'Refrigerant.info.dipole': 'Dipole moment',
  'Refrigerant.point.tab': 'Point Query',
  'Refrigerant.range.tab': 'Range Query',
  'Refrigerant.temp.name': 'Temperature',
  'Refrigerant.temp.title': 'Temperature(℃)',
  'Refrigerant.pressure.name': 'Pressure',
  'Refrigerant.pressure.title': 'Pressure(Mpa(absolute pressure))',
  'Refrigerant.density.name': 'Density',
  'Refrigerant.density.title': 'Density (kg/m³)',
  'Refrigerant.enthalpy.name': 'Specific enthalphy',
  'Refrigerant.enthalpy.title': 'Specific enthalphy (kJ/kg)',
  'Refrigerant.entropy.name': 'Specific entropy',
  'Refrigerant.entropy.title': 'Specific entropy (kJ/(kg K)',
  'Refrigerant.energy.name': 'Internal energy',
  'Refrigerant.energy.title': 'Internal energy (kJ/kg)',
  'Refrigerant.range.step': 'Step',
  'Refrigerant.range.upper': 'Upper',
  'Refrigerant.range.lower': 'Lower',
};
