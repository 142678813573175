export default {
  'DelFileContent.title': '回收站',
  'DelFileContent.searchFile': '搜索文件',
  'DelFileContent.searchTask': '搜索任务',
  'DelFileContent.data.tab': '数据回收站',
  'DelFileContent.task.tab': '任务回收站',
  'DelFileContent.data': '文件',
  'DelFileContent.task': '任务',
  'DelFileContent.delTips': '默认保留30天，过期将彻底删除',
  'DelFileContent.table.name': '名称',
  'DelFileContent.table.source': '来源',
  'DelFileContent.table.deleter': '删除人',
  'DelFileContent.table.deleteTime': '删除时间',
  'DelFileContent.table.tips': '天后永久删除',
  'DelFileContent.table.delete': '彻底删除',
  'DelFileContent.table.recover': '恢复',
  'DelFileContent.data.empty': '暂无文件',
  'DelFileContent.task.empty': '暂无任务',
  'DelFileContent.recycle.data.empty': '回收站暂无文件',
  'DelFileContent.recycle.task.empty': '回收站暂无任务',
};
