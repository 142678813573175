const common = {
  'girdlab.oldSaved': 'The file has been overwritten and updated',
  'girdlab.newSaved': 'File saved',
  'gridlab.share': 'Share',
  'gridlab.share.title': 'Link sharing',
  'gridlab.share.tip':
    'Automatically copy the link after opening, which can be directly sent to friends',
  'gridlab.share.copy': 'Copy',
  'gridlab.share.open': 'Open',
  'gridlab.share.lastUpdateTime': 'Last update time: ',
  'gridlab.share.update': 'Update',
  'gridlab.share.updated': 'Updated',
  'gridlab.share.updateSuccess': 'Save and update successful',
  'gridlab.share.wait': 'Please wait',
  'gridlab.share.loadFail': 'Failed to load shared information',
  'gridlab.share.fail': 'Sharing failed',
  'gridlab.share.readOnly': 'Ready Only',
  'gridlab.share.canDownload': 'Downloadable',
  'gridlab.share.updateFail': 'Failed to update sharing information',
  'gridlab.share.copySuccess': 'Copy successful',
  'gridlab.share.downloadSource': 'Download',
  'gridlab.source.loadFail': 'Failed to load source file',
  'gridlab.login': 'Please login',
  'gridlab.share.before': 'Please upload to the cloud space before publishing',
};

const grr = {
  'gridlab.grr.title': 'GRR Analysis',
};

const scic = {
  'gridlab.scic.title': 'Scientific Computing',
};

export default {
  ...common,
  ...grr,
  ...scic,
};
